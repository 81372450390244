import {Nav, Tab} from 'react-bootstrap';
import MyCalendar from './MyCalendar';
import {PageAllTopTitle} from '../../../../common/ManagementComponents/PageAllTopTitle';
import {OutlineBtn} from '../../../../common/ManagementComponents/Btn/Btn';
import {PageInnerTitle} from '../../../../common/ManagementComponents/pageInnerTitle/Title';
import {InputBox} from '../../../../common/ManagementComponents/form/Form';
import Select from 'react-select';
import {styled} from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import * as React from 'react';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AddLinkIcon from '@mui/icons-material/AddLink';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Checkbox from '@mui/material/Checkbox';
import {Controller, useForm} from 'react-hook-form';
import {useState} from 'react';
import draftToHtml from 'draftjs-to-html';
import {convertToRaw} from 'draft-js';
import {Block, Notify} from 'notiflix';
import Axios from './../../../../../config/api';
import {useNavigate, useParams} from 'react-router-dom';
import {StatusCode} from '../../../../../constants';
import {useEffect} from 'react';
import moment from 'moment';
import {createEventId} from './event-utils';
//
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Modal from 'react-bootstrap/Modal';
import ToggleButton from '@mui/material/ToggleButton';
import {AiFillCaretDown, AiFillCaretUp, AiOutlineClose} from 'react-icons/ai';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Selects from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {NewEditor} from '../../../../common/NewComponents/NewEditor/NewEditor';
const momentTZ = require('moment-timezone');

//
const Android12Switch = styled(Switch)(({theme}) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 13,
      height: 13,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="13" width="13" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="13" width="13" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 13,
    height: 13,
    margin: 0.9,
  },
}));
const DoesNotRepeat = [
  {
    value: null,
    label: 'Does not repeat',
  },
  {
    value: 'WEEKDAY',
    label: 'Every weekday (Mon - Fri)',
  },
  {
    value: 'DAILY',
    label: 'Daily',
  },
  {
    value: 'WEEKLY',
    label: 'Weekly',
  },
  {
    value: 'MONTHLY',
    label: 'Monthly',
  },
  {
    value: 'YEARLY',
    label: 'Yearly',
  },
];
const timeOptions = Array.from({length: 24 * 2}, (_, index) => {
  const hour = Math.floor(index / 2);
  const minutes = index % 2 === 0 ? '00' : '30';
  const formattedHour = hour % 12 || 12;
  const amPm = hour < 12 ? 'am' : 'pm';

  return {
    value: `${hour.toString().padStart(2, '0')}:${minutes}:00`,
    label: `${formattedHour.toString()}:${minutes}${amPm}`,
  };
});
function getUpcomingAndSecondUpcomingTimes(timeOptions) {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();
  const currentTimeString = `${currentHour
    .toString()
    .padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}:00`;
  const upcomingTimes = timeOptions
    .filter(time => time.value > currentTimeString)
    .slice(0, 3);
  return {
    upcomingTime: upcomingTimes[0],
    secondUpcomingTime: upcomingTimes[1],
    ThirdUpcomingTime: upcomingTimes[2],
  };
}
//getting upcoming times
const UpcomingTimes = getUpcomingAndSecondUpcomingTimes(timeOptions);
//preparing schedule meeting initial state
const meetingFormState = {
  start_time: UpcomingTimes.upcomingTime,
  end_time: UpcomingTimes.ThirdUpcomingTime,
  date: moment().format('YYYY-MM-DD'),
};

export const ScheduleMeeting = () => {
  //initializing hooks
  const params = useParams();
  const navigate = useNavigate();
  //react-form-hook
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
    setValue,
    getValues,
  } = useForm();
  //initializing states
  const [data, setData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [newEvent, setNewEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [activeTab, setActiveTab] = useState('first');
  const [timezone, setTimezone] = React.useState(null);
  //form states
  const [meeting, setMeeting] = useState(meetingFormState);
  const [serverErrors, setServerErrors] = useState({});
  const [users, setUsers] = useState([]);
  //getting meetings data initially
  useEffect(() => {
    getSelectFieldsData();
    fetchMeetings();
    addInitalEventInCalendar();
    if (params?.meeting_id) fetchMeeting();
    else setTimezone(moment.tz.guess());
  }, []);
  //updating events state if netword data is updated or local new meeting
  useEffect(() => {
    setEvents([...(data ? data : []), ...(newEvent ? [newEvent] : [])]);
  }, [data, newEvent]);

  const addInitalEventInCalendar = () => {
    setValue('start_time', meetingFormState.start_time);
    setValue('end_time', meetingFormState.end_time);
    setValue('date', meetingFormState.date);
    //updating state
    setNewEvent({
      id: createEventId(),
      title: 'New Meeting',
      start: moment(
        `${meetingFormState.date}T${meetingFormState.start_time?.value}`,
      ).toISOString(),
      end: moment(
        `${meetingFormState.date}T${meetingFormState.end_time?.value}`,
      ).toISOString(),
      editable: true,
      className: 'newEvent',
      backgroundColor: 'orange',
    });
  };
  //updating new meeting state
  const onNewEventChangeCb = newEvent => {
    //updating states
    setEvents([...data, newEvent]);
    //initializing some variables
    const StartDateObj = moment(newEvent?.start);
    const StartDateHours = StartDateObj.format('HH:mm:ss');
    const EndDateObj = moment(newEvent?.end);
    const EndDateHours = EndDateObj.format('HH:mm:ss');
    setValue('date', StartDateObj.format('YYYY-MM-DD'));
    setValue(
      'start_time',
      timeOptions.find(el => el.value == StartDateHours),
    );
    setValue(
      'end_time',
      timeOptions.find(el => el.value == EndDateHours),
    );
    setActiveTab('first');
  };
  const handleOnAllDayChange = e => {
    setMeeting(old => ({...old, isAllDay: e.target.checked}));
    handleEventTimeChange(e.target.checked);
  };
  const handleEventTimeChange = (isAllDay = false) => {
    //getting field values
    const {start_time, end_time, date} = getValues();
    //updating state
    setNewEvent({
      title: 'New Meeting',
      id: createEventId(),
      start: moment(`${date}T${start_time?.value}`).toISOString(),
      end: moment(`${date}T${end_time?.value}`).toISOString(),
      editable: true,
      className: 'newEvent',
      backgroundColor: 'orange',
      allDay: isAllDay,
    });
  };
  const fetchMeeting = () => {
    //showing up loader
    Block.circle('#form', 'Loading...');
    Axios.fetch(`maxproject/meeting/${params.meeting_id}`)
      .then(({data}) => {
        const {Meeting} = data;
        let payload = {
          title: Meeting.title,
          location: Meeting.location,
          link: Meeting.link,
          repeat: DoesNotRepeat.find(el => el.value == Meeting.repeat),
          project: {
            label: Meeting?.Project?.name,
            value: Meeting?.Project?.id,
          },
          isAllDay: Meeting.isAllDay,
          start_time: timeOptions.find(el => el.value == Meeting.start_time),
          end_time: timeOptions.find(el => el.value == Meeting.end_time),
          date: Meeting.date,
          desc: Meeting?.desc,
          guests: Meeting?.MeetingParticipants?.map(user => ({
            label: user?.User?.name,
            value: user?.user_id,
          })),
          canInvite: Meeting.canInvite,
          canModify: Meeting.canModify,
          canSeeGuests: Meeting.canSeeGuests,
        };
        setMeeting(payload);
        setTimezone(Meeting.timezone);
        reset(payload);
        Block.remove('#form');
      })
      .catch(err => {
        //removing loader
        Block.remove('#form');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchMeetings = () => {
    // getting meetings data
    Axios.fetch('maxproject/meeting/calendar').then(({data}) => {
      setData(data?.data);
    });
  };
  const handleFormSubmit = (data, e, force = false) => {
    let payload = {
      title: data.title,
      desc: data.desc,
      location: data.location,
      link: data.link,
      repeat: data.repeat?.value,
      project_id: data.project?.value,
      isAllDay: Number(data?.isAllDay) || 0,
      start_time: data.start_time.value,
      end_time: data.end_time.value,
      date: data.date,
      guests: data.guests?.map(u => u.value),
      desc: data.desc,
      canInvite: data.canInvite,
      canModify: data.canModify,
      canSeeGuests: data.canSeeGuests,
      timezone,
    };
    //clearing server errors
    setServerErrors({});
    //showing up loader
    Block.circle('#form', 'Saving...');
    //initiating network request
    const Request = params?.meeting_id
      ? Axios.put(`maxproject/meeting/${params.meeting_id}`, payload)
      : Axios.post('maxproject/meeting', payload);

    Request.then(({data}) => {
      Block.remove('#form');
      Notify.success(data.message);
      navigate('/Management/meetings');
    }).catch(err => {
      //removing loader
      Block.remove('#form');
      //checking for any error message
      if (err?.response?.data?.message)
        Notify.failure(err?.response?.data?.message);
      //checking and showing express validation errors
      if (err?.response?.status == StatusCode.ExpressErrors)
        err?.response?.data?.errors.forEach(el =>
          setServerErrors(old => ({...old, [el.param]: el.msg})),
        );
    });
  };
  const getSelectFieldsData = () => {
    const URL = `maxproject/random/meeting_select_fields`;
    Axios.fetch(URL)
      .then(({data}) => {
        let projects = data?.projects?.map(el => ({
          value: el?.id,
          label: el?.name,
        }));
        let users = data?.members.map(u => ({
          label: u.name,
          value: u.id,
        }));
        setUsers(users);
        setProjects(projects);
      })
      .catch(err => {
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const SchedulingDetails = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [elements, setElements] = useState([]);
    const [elementCount, setElementCount] = useState(0);
    const [data, setData] = useState();
    const startTimeOptions = timeOptions.filter(option =>
      moment(getValues()?.date).isSame(moment(), 'day')
        ? moment(option.value, 'HH:mm:ss').isAfter(moment())
        : true,
    );
    const endTimeOptions = timeOptions.filter(
      option => option.value > getValues()?.start_time?.value,
    );
    const renderEndTimeOptionLabel = ({value, label}) => {
      const startTime = moment(getValues()?.start_time?.value, 'HH:mm:ss');
      const endTime = moment(value, 'HH:mm:ss');
      const duration = moment.duration(endTime.diff(startTime));
      const difference = duration.asMinutes();

      let timeLabel = '';

      if (difference > 45) {
        const hours = Math.floor(difference / 60);
        const minutes = difference % 60;
        const formattedHours = hours + minutes / 60;
        timeLabel = `(${formattedHours} hr${formattedHours > 1 ? 's' : ''})`;
      } else {
        timeLabel = `(${difference} mins)`;
      }

      return (
        <div>
          <span>{label}</span>
          <span>{timeLabel}</span>
        </div>
      );
    };
    const addElement = () => {
      const newElement = {
        id: elementCount,
        text: `Element`,
      };
      setElements([...elements, newElement]);
      setElementCount(elementCount + 1);
    };
    const deleteElement = id => {
      const updatedElements = elements.filter(element => element.id !== id);
      setElements(updatedElements);
    };
    const NumberInputhandleChange = event => {
      const inputValue = parseInt(event.target.value, 10);
      if (!isNaN(inputValue)) {
        setData(Math.max(inputValue, 0)); // Ensure the value is not less than 0
      }
    };
    const ReminderNotification = [
      {
        value: 'Notification',
        label: 'Notification',
      },
      {
        value: 'Email',
        label: 'Email',
      },
    ];
    const Day = [
      {
        value: 'Minutes',
        label: 'Minutes',
      },
      {
        value: 'Hours',
        label: 'Hours',
      },
      {
        value: 'Day',
        label: 'Day',
      },
      {
        value: 'Weeks',
        label: 'Weeks',
      },
    ];
    const Time = [
      {
        value: '09:00 Am',
        label: '09:00 Am',
      },
      {
        value: '09:30 Am',
        label: '09:30 Am',
      },
      {
        value: '10:00 Am',
        label: '10:00 Am',
      },
      {
        value: '10:30 Am',
        label: '10:30 Am',
      },
    ];
    const DoesNotRepeatN = [
      {
        value: 'null',
        label: 'Does not repeat',
      },
      {
        value: 'WEEKDAY',
        label: 'Every weekday (Mon - Fri)',
      },
      {
        value: 'DAILY',
        label: 'Daily',
      },
      {
        value: 'WEEKLY',
        label: 'Weekly',
      },
      {
        value: 'MONTHLY',
        label: 'Monthly',
      },
      {
        value: 'custom',
        label: 'Custom...',
      },
    ];
    const MeetingCustomPopup = props => {
      const [inputOn, setInputOn] = useState(true);
      const [afterInput, setAfterInput] = useState(true);
      const [userTypeNew, setUserTypeNew] = useState('Day');
      const [count, setCount] = useState(1);
      const [occurrences, setOccurrences] = useState(1);
      const [days, setDays] = React.useState({
        sunday: false,
        monday: false,
        tuesday: true,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      });
      //?hooks
      useEffect(() => {
        //checking if every day is false
        if (Object.values(days).every(el => el === false)) {
          //getting today's day
          const today = moment().day();
          //updating state
          setDays(old => ({
            ...old,
            [moment().day(today).format('dddd').toLowerCase()]: true,
          }));
        }
      }, [days]);

      //functions
      const handleIncOccurency = () => {
        setOccurrences(old => old + 1);
      };
      const handleDecOccurency = () => {
        if (occurrences > 1) setOccurrences(old => old - 1);
      };
      const handleIncInterval = () => {
        setCount(old => old + 1);
      };
      const handleDecInterval = () => {
        if (count > 1) setCount(old => old - 1);
      };
      const UserChangeLabelNew = props => {
        return (
          <div className="view_change_label">
            {props.icon}
            <span>{props.title}</span>
          </div>
        );
      };
      const Intervals = [
        {
          value: 'Day',
          label: 'Day',
        },
        {
          value: 'Week',
          label: <UserChangeLabelNew title="Week" />,
        },
        {
          value: 'Month',
          label: <UserChangeLabelNew title="Month" />,
        },
        {
          value: 'Year',
          label: 'Year',
        },
      ];
      //
      const Month = [
        {
          value: 'Monthly on day 6',
          label: 'Monthly on day 6',
        },
        {
          value: 'Monthly on first day',
          label: 'Monthly on first day',
        },
      ];
      const onIntervalChange = ({target: {value}}) => {
        setCount(value);
      };
      const onIntervalBlur = ({target: {value}}) => {
        if (value > 0) setCount(value);
        else setCount(1);
      };
      const onOccurencyChange = ({target: {value}}) => {
        setOccurrences(value);
      };
      const onOccurencyBlur = ({target: {value}}) => {
        if (value > 0) setOccurrences(value);
        else setOccurrences(1);
      };
      return (
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Body>
            <div className="meeting_custom_popup_top_div">
              <h3>Custom recurrence</h3>
              <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
                <div className="col-12">
                  <div className="meeting_custom_popup_repeat_every_div">
                    <p>Repeat every</p>
                    <input
                      type="number"
                      value={count}
                      min={1}
                      onChange={onIntervalChange}
                      onBlur={onIntervalBlur}
                      className="count_input form-control"
                    />
                    <div className="count_input_btn_div">
                      <Tooltip title="Increment" placement="top">
                        <button className="mb-1" onClick={handleIncInterval}>
                          <AiFillCaretUp />
                        </button>
                      </Tooltip>
                      <Tooltip title="Decrement" placement="bottom">
                        <button className="mt-1" onClick={handleDecInterval}>
                          <AiFillCaretDown />
                        </button>
                      </Tooltip>
                    </div>
                    <div className="all_user_page all_user_page_new mb-0">
                      <Select
                        options={Intervals}
                        defaultValue={Intervals[0]}
                        isSearchable={false}
                        onChange={({value}) => {
                          setUserTypeNew(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {
                  {
                    Week: (
                      <div className="col-12">
                        <div className="meeting_toggle_btn_div">
                          <ToggleButton
                            value="check"
                            selected={days.sunday}
                            onChange={() =>
                              setDays(old => ({...old, sunday: !old.sunday}))
                            }>
                            s
                          </ToggleButton>
                          <ToggleButton
                            value="check"
                            selected={days.monday}
                            onChange={() =>
                              setDays(old => ({...old, monday: !old.monday}))
                            }>
                            m
                          </ToggleButton>
                          <ToggleButton
                            value="check"
                            selected={days.tuesday}
                            onChange={() =>
                              setDays(old => ({...old, tuesday: !old.tuesday}))
                            }>
                            t
                          </ToggleButton>
                          <ToggleButton
                            value="check"
                            selected={days.wednesday}
                            onChange={() =>
                              setDays(old => ({
                                ...old,
                                wednesday: !old.wednesday,
                              }))
                            }>
                            w
                          </ToggleButton>
                          <ToggleButton
                            value="check"
                            selected={days.thursday}
                            onChange={() =>
                              setDays(old => ({
                                ...old,
                                thursday: !old.thursday,
                              }))
                            }>
                            t
                          </ToggleButton>
                          <ToggleButton
                            value="check"
                            selected={days.friday}
                            onChange={() =>
                              setDays(old => ({
                                ...old,
                                friday: !old.friday,
                              }))
                            }>
                            f
                          </ToggleButton>
                          <ToggleButton
                            value="check"
                            selected={days.saturday}
                            onChange={() =>
                              setDays(old => ({
                                ...old,
                                saturday: !old.saturday,
                              }))
                            }>
                            s
                          </ToggleButton>
                        </div>
                      </div>
                    ),
                    Monthh: (
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="all_user_page all_user_page_new mb-0 w-auto ">
                          <Select
                            options={Month}
                            defaultValue={Month[0]}
                            isSearchable={false}
                            onChange={() => setModalShow(true)}
                          />
                        </div>
                      </div>
                    ),
                  }[userTypeNew]
                }
                <div className="col-12">
                  <div className="meeting_popup_end_div">
                    <p>Ends</p>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="Never"
                        name="radio-buttons-group">
                        <FormControlLabel
                          value="Never"
                          control={<Radio />}
                          label="Never"
                          onChange={() => {
                            setInputOn(true);
                            setAfterInput(true);
                          }}
                        />
                        <div className="on">
                          <FormControlLabel
                            value="On"
                            control={<Radio />}
                            label="On"
                            onChange={() => {
                              setInputOn();
                              setAfterInput(true);
                            }}
                          />
                          <input
                            className={`${
                              inputOn
                                ? 'date_picker date_picker2'
                                : 'date_picker'
                            }`}
                            type="date"
                            disabled={inputOn}
                          />
                        </div>
                        <div className="After">
                          <FormControlLabel
                            value="After"
                            control={<Radio />}
                            label="After"
                            onChange={() => {
                              setAfterInput();
                              setInputOn(true);
                            }}
                          />
                          <div
                            className={`${
                              afterInput
                                ? 'After_inner_div After_inner_div2'
                                : 'After_inner_div'
                            }`}>
                            <input
                              type="number"
                              value={occurrences}
                              disabled={afterInput}
                              onChange={onOccurencyChange}
                              onBlur={onOccurencyBlur}
                              className="After_input form-control"
                            />
                            <p
                              className={`${
                                afterInput ? 'text_p text_p2' : 'text_p'
                              }`}>
                              occurrences
                            </p>
                            {afterInput ? (
                              <div className="count_input_btn_div">
                                <button
                                  className="mb-1"
                                  style={{cursor: 'no-drop'}}>
                                  <AiFillCaretUp />
                                </button>
                                <button
                                  className="mt-1"
                                  style={{cursor: 'no-drop'}}>
                                  <AiFillCaretDown />
                                </button>
                              </div>
                            ) : (
                              <div className="count_input_btn_div">
                                <Tooltip title="Increment" placement="top">
                                  <button
                                    className="mb-1"
                                    onClick={handleIncOccurency}>
                                    <AiFillCaretUp />
                                  </button>
                                </Tooltip>
                                <Tooltip title="Decrement" placement="bottom">
                                  <button
                                    className="mt-1"
                                    onClick={handleDecOccurency}>
                                    <AiFillCaretDown />
                                  </button>
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="meeting_custom_popup_top_div_btn">
                <Button
                  onClick={props.onHide}
                  variant="text"
                  style={{color: 'black', textTransform: 'capitalize'}}>
                  Cancel
                </Button>
                <Button
                  onClick={props.onHide}
                  variant="text"
                  style={{color: '#0F62FE', textTransform: 'capitalize'}}>
                  Done
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    };
    const handleCustomOption = optionValue => {
      if (optionValue.value !== undefined && optionValue.value == 'custom') {
        setModalShow(true);
      }
    };
    return (
      <form id="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <MeetingCustomPopup
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
            <div className="dashbaord_white_card">
              <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
                <div className="col-12 scheduling_details_title">
                  <PageInnerTitle name="Event Details" />
                </div>
                <div className="col-12">
                  <div className="scheduling_details_div">
                    <InputBox
                      type="text"
                      placeholder="Title"
                      register={() =>
                        register('title', {
                          required: 'Please enter meeting title',
                          value: meeting?.title,
                        })
                      }
                      errorMessage={
                        errors.title?.message || serverErrors?.title
                      }
                    />
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <div className="scheduling_details_div">
                    <InputBox
                      type="date"
                      register={() =>
                        register('date', {
                          required: 'Please enter meeting date',
                          value: meeting?.date,
                          onChange: handleEventTimeChange,
                        })
                      }
                      errorMessage={errors.date?.message || serverErrors?.date}
                    />
                  </div>
                </div>
                <div
                  className="col-xxl-5 col-xl-5 col-lg-5 col-md-8 col-sm-12 col-12"
                  style={meeting?.isAllDay ? {display: 'none'} : null}>
                  <div className="scheduling_details_time_select_div">
                    <div
                      className="all_user_page all_user_page_new mb-0 dashboard_top_week_Select"
                      style={{width: '200px'}}>
                      <Controller
                        name="start_time"
                        control={control}
                        rules={{
                          required: meeting?.isAllDay
                            ? false
                            : 'Please select start time',
                        }}
                        render={({field}) => (
                          <Select
                            {...field}
                            options={startTimeOptions}
                            onChange={el => {
                              field.onChange(el);
                              //getting form values
                              const {start_time, end_time} = getValues();
                              //checking if start time is grater than end time
                              if (
                                moment(el.value, 'HH:mm:ss') >=
                                moment(getValues()?.end_time?.value, 'HH:mm:ss')
                              ) {
                                // Find the index of the selected time in the start time array
                                const selectedIndex = timeOptions.findIndex(
                                  option => option.value === start_time?.value,
                                );
                                //checking if index available
                                if (timeOptions[selectedIndex + 2])
                                  // Update the selected time in the second dropdown to the next available option
                                  setData(
                                    'end_time',
                                    timeOptions[selectedIndex + 2],
                                  );
                                else if (timeOptions[selectedIndex + 1]);
                                // Update the selected time in the second dropdown to the next available option
                                setData(
                                  'end_time',
                                  timeOptions[selectedIndex + 1],
                                );
                              }

                              handleEventTimeChange();
                            }}
                            defaultValue={meeting?.start_time}
                            isSearchable={false}
                          />
                        )}
                      />
                      <small className="error_message">
                        {errors.start_time?.message || serverErrors?.start_time}
                      </small>
                    </div>
                    <p className="scheduling_details_time_select_p mx-1">to</p>
                    <div
                      className="all_user_page all_user_page_new mb-0 dashboard_top_week_Select"
                      style={{width: '300px'}}>
                      <Controller
                        name="end_time"
                        control={control}
                        rules={{
                          required: meeting?.isAllDay
                            ? false
                            : 'Please select end time',
                        }}
                        render={({field}) => (
                          <Select
                            {...field}
                            onChange={el => {
                              field.onChange(el);
                              handleEventTimeChange();
                            }}
                            options={endTimeOptions}
                            getOptionLabel={renderEndTimeOptionLabel}
                            defaultValue={meeting?.end_time}
                            isSearchable={false}
                          />
                        )}
                      />
                      <small className="error_message">
                        {errors.end_time?.message || serverErrors?.end_time}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="scheduling_details_time_switch_div">
                    <FormGroup>
                      <Controller
                        name="isAllDay"
                        control={control}
                        render={({field}) => (
                          <Android12Switch
                            defaultValue={false}
                            checked={meeting?.isAllDay}
                            onChange={el => {
                              field.onChange(el);
                              handleOnAllDayChange(el);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                    <p>All Day</p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="all_user_page all_user_page_new mb-0 w-100">
                        <Controller
                          name="repeat"
                          control={control}
                          rules={{}}
                          render={({field}) => (
                            <Select
                              {...field}
                              onChange={el => field.onChange(el)}
                              options={DoesNotRepeat}
                              defaultValue={meeting?.repeat || DoesNotRepeat[0]}
                              isSearchable={false}
                            />
                          )}
                        />
                      </div>
                      <div className="all_user_page all_user_page_new dashboard_top_week_Select mb-0 w-100 mt-4">
                        <Select
                          options={DoesNotRepeatN}
                          defaultValue={DoesNotRepeatN[0]}
                          isSearchable={false}
                          // menuIsOpen
                          onChange={option => {
                            handleCustomOption(option);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="all_user_page all_user_page_new mb-0 w-100">
                        <Controller
                          name="project"
                          control={control}
                          rules={{}}
                          render={({field}) => (
                            <Select
                              {...field}
                              onChange={el => field.onChange(el)}
                              options={projects}
                              placeholder="Select project"
                              defaultValue={meeting?.project}
                              isSearchable={true}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="scheduling_details_Location_div">
                    <AddLinkIcon />
                    <InputBox
                      type="text"
                      placeholder="Meeting Link"
                      register={() =>
                        register('link', {
                          value: meeting?.link,
                          required: 'Please enter meeting link',
                        })
                      }
                      errorMessage={errors.link?.message || serverErrors?.link}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="scheduling_details_Location_div">
                    <LocationOnOutlinedIcon />
                    <InputBox
                      type="text"
                      placeholder="Location"
                      register={() =>
                        register('location', {
                          value: meeting?.location,
                        })
                      }
                      errorMessage={
                        errors.location?.message || serverErrors?.location
                      }
                    />
                  </div>
                </div>
                <div className="col-12">
                  {elements.map(element => (
                    <div
                      className="sehedule_notification_top_div"
                      key={element.id}>
                      <div className="all_user_page all_user_page_new my_m">
                        <Select
                          options={ReminderNotification}
                          defaultValue={ReminderNotification[0]}
                          isSearchable={false}
                        />
                      </div>
                      <div className="sehedule_notification_number_input_div my_m">
                        <input
                          type="number"
                          class="form-control"
                          value={data}
                          onChange={NumberInputhandleChange}
                          min="0"
                        />
                      </div>
                      <div
                        className="all_user_page all_user_page_new my_m"
                        style={{width: '123px'}}>
                        <Select
                          options={Day}
                          defaultValue={Day[0]}
                          isSearchable={false}
                        />
                      </div>
                      <div
                        className="sehedule_notification_time_div my_m"
                        style={meeting?.isAllDay ? {display: 'flex'} : null}>
                        <p>before at</p>
                        <div
                          className="all_user_page all_user_page_new  "
                          style={{width: '130px'}}>
                          <Select
                            options={Time}
                            defaultValue={Time[0]}
                            isSearchable={false}
                          />
                        </div>
                      </div>
                      <div className="sehedule_notification_delete_btn my_m">
                        <span onClick={() => deleteElement(element.id)}>
                          <AiOutlineClose />
                        </span>
                      </div>
                    </div>
                  ))}

                  {/* <div className="add_reminder_notification_btn_div">
                    <p onClick={addElement} className="management_btn_full">
                      Add notification
                    </p>
                  </div> */}
                </div>
                <div className="col-12">
                  <div className="join_meeting_editor">
                    <NewEditor
                      placeholder="Description"
                      value={getValues()?.desc}
                      onChange={text => {
                        setValue('desc', text);
                      }}
                      // onBlur={(range, source, e) => {
                      //   setMeeting(old => ({...old, desc: e.getHTML()}));
                      // }}
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 mt-5">
                  <div className=" d-flex align-items-center justify-content-center">
                    <div className=" me-2">
                      <OutlineBtn link={`/Management/meetings`} name="Cancel" />
                    </div>
                    <div className=" ms-2">
                      <button
                        className="management_btn_full2"
                        type="submit"
                        form={'form'}>
                        {params?.meeting_id ? 'Update' : 'Save'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="dashbaord_white_card">
              <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
                <div className="col-12 scheduling_details_title">
                  <PageInnerTitle name="Guests" />
                </div>
                <div className="col-12">
                  <div className="dashboard_top_week_Select drop_box drop_box2 scheduling_details_div">
                    <Controller
                      name="guests"
                      control={control}
                      rules={{
                        required: 'Please select meeting guests',
                      }}
                      render={({field}) => (
                        <Select
                          {...field}
                          isMulti
                          defaultValue={meeting?.guests}
                          onChange={el => field.onChange(el)}
                          options={users}
                          placeholder={field.value || 'Select guests'}
                        />
                      )}
                    />
                    <small className="error_message">
                      {errors.guests?.message || serverErrors?.guests}
                    </small>
                  </div>
                </div>
                <div className="col-12">
                  <p className="scheduling_details_radio_title">
                    Guest permissions
                  </p>
                  <FormGroup>
                    <Controller
                      name="canModify"
                      control={control}
                      defaultValue={false}
                      render={({field}) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={field.value}
                              onChange={e => field.onChange(e.target.checked)}
                              onBlur={field.onBlur}
                            />
                          }
                          label="Modify Event"
                        />
                      )}
                    />
                    <Controller
                      name="canInvite"
                      control={control}
                      defaultValue={false}
                      render={({field}) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={field.value}
                              onChange={e => field.onChange(e.target.checked)}
                              onBlur={field.onBlur}
                            />
                          }
                          label="Invite Others"
                        />
                      )}
                    />
                    <Controller
                      name="canSeeGuests"
                      control={control}
                      defaultValue={false}
                      render={({field}) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={field.value}
                              onChange={e => field.onChange(e.target.checked)}
                              onBlur={field.onBlur}
                            />
                          }
                          label="See Guests List"
                        />
                      )}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };
  return (
    <div className="custom_container">
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 header_my">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 ">
          <PageAllTopTitle title="New Meeting" />
        </div>
      </div>
      <div className="project_list_tabs_main_div">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="first"
          activeKey={activeTab}>
          <div className="project_list_tabs_inner_div">
            <div className=" mb-4">
              <div className="row">
                <div className="col-12">
                  <div className="time_zone_div">
                    <Nav variant="pills" className="flex">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          onClick={() => setActiveTab('first')}>
                          Scheduling Details
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="FindTime"
                          onClick={() => setActiveTab('FindTime')}>
                          Find a Time
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <div>
                      <Timezone timezone={timezone} setTimezone={setTimezone} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Tab.Content>
            <Tab.Pane eventKey="first">
              <SchedulingDetails />
            </Tab.Pane>
            <Tab.Pane eventKey="FindTime">
              <MyCalendar
                events={events}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                onNewEventChangeCb={onNewEventChangeCb}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};
const Timezone = ({timezone, setTimezone}) => {
  const [timezones, setTimezones] = React.useState([]);
  //hooks
  useEffect(() => {
    setTimezones(getTimezoneList());
  }, []);

  const handleChange = event => {
    setTimezone(event.target.value);
  };

  function getTimezoneList() {
    const timezones = moment.tz.names();
    const formattedTimezoneList = timezones.map(tz => {
      const offsetMinutes = moment.tz(tz).utcOffset();
      const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
      const offsetSign = offsetMinutes >= 0 ? '+' : '-';
      const offsetFormatted = `${offsetSign}${offsetHours
        .toString()
        .padStart(2, '0')}:${(Math.abs(offsetMinutes) % 60)
        .toString()
        .padStart(2, '0')}`;

      return {
        timezoneString: `(GMT${offsetFormatted}) ${tz}`,
        timezone: tz,
      };
    });
    return formattedTimezoneList;
    const sortedTimezoneList = formattedTimezoneList.sort((a, b) =>
      a.timezone.localeCompare(b.timezone),
    );
    return sortedTimezoneList.map(item => item.timezoneString);
  }

  return (
    <div className="time_zone_inner_div">
      <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
        <InputLabel className=" text-capitalize" id="time-zones-list">
          time zone
        </InputLabel>
        <Selects
          labelId="time-zones-list"
          id="demo-simple-select-standard"
          value={timezone}
          onChange={handleChange}
          defaultValue={timezone}
          label="time zone">
          {timezones.map((el, index) => (
            <MenuItem key={index} value={el.timezone}>
              {el.timezoneString}
            </MenuItem>
          ))}
        </Selects>
      </FormControl>
    </div>
  );
};
