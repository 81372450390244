import {useDispatch, useSelector} from 'react-redux';
import './MyTimesheet.scss';
import React from 'react';
import {useNavigate} from 'react-router-dom';
// list
import BreakIcon from '../../../../../pics/Management/BreakIcon.svg';
import {
  DeviceCameraVideoIcon,
  FilterIcon,
  ProjectIcon,
  StopwatchIcon,
  TasklistIcon,
  XIcon,
} from '@primer/octicons-react';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';
import Switch from '@mui/material/Switch';
// list
import {TimesheetProfileCard} from '../../../../common/NewComponents/TimesheetProfileCard/TimesheetProfileCard';
import {MyDatePicker} from '../../../../common/NewComponents/MyDatePicker/MyDatePicker';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import {NewSelect} from '../../../../common/NewComponents/NewSelect/NewSelect';
import {SearchBox} from '../../../../common/NewComponents/SearchBox/SearchBox';
import {NewOutlinedButtonWithIcon} from '../../../../common/NewComponents/NewButtons/Buttons';
import {FormGroup, Pagination, Stack, Typography} from '@mui/material';
import {useState} from 'react';
import moment from 'moment';
import {Block} from 'notiflix';
import {useEffect} from 'react';
import {useNonInitialEffect} from '../../../../../utils/hooks/non-initial-effect.tsx';
import Axios from './../../../../../config/api';
import {
  formatDuration,
  getQueryParam,
  setQueryParam,
} from '../../../../../utils/functions.js';
import {getUser} from '../../../../../redux/slices/userSlice.js';
import {setGlobal} from '../../../../../redux/slices/globalSlice.js';

export const MyTimesheet = () => {
  //?hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {global} = useSelector(state => state);
  const user = useSelector(getUser);
  const storData = useSelector(state => state);
  //?states
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(getQueryParam('page') || 1);
  const [showAlltimesheet, setShowAlltimesheet] = useState(false);
  const [hideEmptyActivities, setHideEmptyActivities] = useState(
    global?.hideEmptyActivities || false,
  );
  const [totalWorkingTime, setTotalWorkingTime] = useState('');
  const [filters, setFilters] = useState({projects: [], meetings: []});
  const [selectedFilters, setSelectedFilters] = useState({
    projects: [],
    meetings: [],
  });
  const [varianceData, setVarianceData] = useState({
    variance: '',
    varianceSummary: '',
  });
  const [dateRange, setDateRange] = useState(
    global?.timesheetDateRange || [
      moment().subtract(7, 'days').toDate(),
      moment().toDate(),
    ],
  );
  const [data, setData] = useState({
    meetings: [],
    breaks_data: {},
    projects_data: [],
    other_tasks_data: [],
    project: {name: '', logo: ''},
  });
  const [userCardData, setUserCardData] = useState({
    totalWorkingTime: '',
    totalWorkingTimeSummary: '',
    variance: '',
    varianceSummary: '',
  });
  useNonInitialEffect(() => {
    // Timer ID for the delayed fetch
    let timerId;
    // Clear previous timer, if any
    clearTimeout(timerId);
    // Set a new timer for the delayed fetch
    timerId = setTimeout(fetchTimesheet, 500);
    // Clean up timer on component unmount
    return () => clearTimeout(timerId);
  }, [query]);

  //hooks
  useEffect(async () => {
    fetchTimesheet();
    return () => {};
  }, []);

  useEffect(async () => {
    if (dateRange[1]) fetchTimesheet();
    return () => {};
  }, [dateRange, hideEmptyActivities]);

  useEffect(() => {
    setQueryParam('page', page);
  }, [page]);

  const onApplyFilters = () => {
    fetchTimesheet(true);
  };

  const fetchTimesheet = (applyFilters = false) => {
    Block.circle('.loading', 'Loading');
    const q = new URLSearchParams({
      query,
      start_date: moment(dateRange[0]).format('YYYY-MM-DD'),
      end_date: moment(dateRange[1]).format('YYYY-MM-DD'),
      projects: JSON.stringify(selectedFilters.projects.map(p => p.value)),
      meetings: JSON.stringify(selectedFilters.meetings.map(m => m.value)),
    });
    Axios.fetch(`maxproject/timesheet?${q.toString()}`)
      .then(({data}) => {
        Block.remove('.loading');
        setData({
          breaks_data: data?.breaks_data,
          other_tasks_data: data?.other_tasks_data,
          meetings: data?.meetings_data?.filter(Meeting => {
            if (!hideEmptyActivities) return true;
            if (Object.keys(Meeting?.timers).length) return true;
            return false;
          }),
          projects_data: data?.projects_data?.filter(Project => {
            if (!hideEmptyActivities) return true;
            if (Object.keys(Project?.timers).length) return true;
            return false;
          }),
        });
        //updating permissions
        setShowAlltimesheet(data?.allTimesheetPermission);
        //updating filters data
        setFilters(data?.filters);
        //preparing variance data
        const totalTasks = data?.variance_data?.tasks?.totalTasks;
        const completedTasks = data?.variance_data?.tasks?.completdTasks;
        //updating variance state
        setVarianceData({
          variance: Number(data?.variance_data?.variance),
          varianceSummary: `${completedTasks} task${
            completedTasks > 1 ? 's' : ''
          } completed out of ${totalTasks}`,
        });
      })
      .catch(err => {
        Block.remove('.loading');
      });
  };

  const handleSearch = e => {
    setQuery(e.target.value);
  };
  return (
    <>
      <div
        className="my_timesheet_main_div loading"
        data-theme={storData.global.isMode}>
        <div className="my_timesheet_nav_div">
          <button
            onClick={() => navigate('/Management/my_timesheet')}
            style={{marginLeft: '1rem'}}
            className="active">
            My Timesheet <div></div>
          </button>
          {showAlltimesheet ? (
            <button
              onClick={() => navigate('/Management/all_timesheet')}
              style={{marginLeft: '1rem'}}>
              all Timesheet
            </button>
          ) : null}
        </div>
        <div className="my_timesheet_inner_div">
          <div className="row g-3">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <MyDatePicker dateRange={dateRange} onChange={setDateRange} />
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 search_box_page_div">
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                style={{marginRight: 10}}>
                <Typography className="approval_request_input_switch_text">
                  Hide Empty Activities
                </Typography>
                <AntSwitch
                  inputProps={{'aria-label': 'ant design'}}
                  checked={hideEmptyActivities}
                  onChange={() => {
                    setHideEmptyActivities(!hideEmptyActivities);
                    setPage(1);
                    dispatch(
                      setGlobal({
                        hideEmptyActivities: !hideEmptyActivities,
                      }),
                    );
                  }}
                />
              </Stack>
              <SearchBox
                // Data={SearchBoxData}
                placeholder="Search here"
                value={query}
                onChange={handleSearch}
              />
              <NewFilters
                filters={filters}
                onApplyFilters={onApplyFilters}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
              />
            </div>
            <div className="col-12">
              <TimesheetProfileCard
                {...user}
                {...userCardData}
                dateRange={dateRange}
                totalWorkingTime={totalWorkingTime}
                varianceData={varianceData}
              />
            </div>
            <div className="col-12">
              <TimesheetList
                page={page}
                setPage={setPage}
                data={data}
                date_range={dateRange}
                searchQuery={query}
                setTotalWorkingTime={setTotalWorkingTime}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TimesheetList = ({
  data = {
    meetings: [],
    dates_data: [],
    projects_data: [],
    other_tasks_data: [],
    breaks_data: {break_timers: {}},
    project: {name: '', logo: ''},
  },
  searchQuery = '',
  date_range = [],
  setTotalWorkingTime = () => {},
  page,
  setPage = () => {},
}) => {
  //?states
  const combinedData = [
    ...(searchQuery ? [] : [{type: 'BREAK'}, {type: 'OTHER_TASK'}]),
    ...data?.meetings,
    ...data?.projects_data,
  ];
  //? hooks
  const navigate = useNavigate();
  //? Variables
  // Calculate the total number of days between the start and end dates
  const daysDiff = moment(date_range[1]).diff(date_range[0], 'days');
  // Generate the array of dates
  const Dates = Array.from({length: daysDiff + 1}, (_, i) => {
    return moment(date_range[0]).clone().add(i, 'days').toISOString();
  });
  const TotalWorkingTimeByDate = calculateTotalDuration(data);
  setTotalWorkingTime(TotalWorkingTimeByDate?.totalWorkingTime || '-');
  const totalRows = 1 + data?.meetings.length + data?.projects_data.length;
  //? Functions
  const handleOpenMeeting = Meeting => {
    if (Meeting.isDeleted) return false;
    navigate('/Management/meeting/' + Meeting.id);
  };
  const handleOpenProjectTimeline = projectId => {
    navigate('/Management/my_timesheet_Detail/project/' + projectId);
  };
  const handleTaskPagination = (e, page) => {
    setPage(page);
  };

  //? Components
  const TableThDate = ({dateString = null}) => {
    if (!dateString)
      return (
        <th scope="col" className="table_th_inner_main_div">
          <div className={`table_th_inner_div`}>
            <span>-</span>
          </div>
        </th>
      );

    const date = moment(dateString);
    const formattedDate = date.format('YYYY-MM-DD');
    const isActive = date.isSame(moment(), 'day');
    return (
      <th scope="col" className="table_th_inner_main_div">
        <div className={`table_th_inner_div ${isActive ? 'active_class' : ''}`}>
          <span>{date.format('ddd, MMM D')}</span>
          <p>{TotalWorkingTimeByDate?.dates[formattedDate] || '-'}</p>
          <div
            className="line"
            style={{display: isActive ? 'block' : 'none'}}></div>
        </div>
      </th>
    );
  };
  const BreakCell = ({Break}) => {
    return (
      <div
        style={{cursor: 'pointer'}}
        className="timesheet_list_project_name_div top_border">
        <span className="sr_no">{1}</span>
        <span className="task_icon">
          <img src={BreakIcon} alt="icon" />
        </span>
        <h3>Break</h3>
        {data?.breaks_data?.isBreakActive ? (
          <BootstrapTooltip placement="top">
            <span className="time_icon">
              <StopwatchIcon />
            </span>
          </BootstrapTooltip>
        ) : null}
      </div>
    );
  };
  const OtherTaskCell = () => {
    return (
      <div
        onClick={() => navigate('/Management/my_timesheet_Detail/other_tasks')}
        style={{cursor: 'pointer'}}
        className="timesheet_list_project_name_div top_border">
        <span className="sr_no">{2}</span>
        <span
          className="task_icon"
          style={{
            marginLeft: '2rem',
          }}>
          <TasklistIcon />
        </span>
        <h3>Other Tasks</h3>
        {data?.other_tasks_data?.isOtherTimerActive ? (
          <BootstrapTooltip placement="top" title={data?.other_tasks_data?.runningActivityName}>
            <span className="time_icon">
              <StopwatchIcon />
            </span>
          </BootstrapTooltip>
        ) : null}
      </div>
    );
  };
  const MeetingCell = ({Meeting, idx}) => {
    return (
      <div
        style={{
          cursor: 'pointer',
          ...(Meeting.isDeleted ? {opacity: '0.5', cursor: 'not-allowed'} : {}),
        }}
        onClick={() => handleOpenMeeting(Meeting)}
        key={idx}
        className="timesheet_list_project_name_div top_border">
        <span className="sr_no">{idx}</span>
        <span className="task_icon" style={{marginLeft: '2rem'}}>
          <DeviceCameraVideoIcon size={16} />
        </span>
        <h3>
          {Meeting?.meetingName.length > 15
            ? `${Meeting?.meetingName.substring(0, 15)}...`
            : Meeting?.meetingName}
        </h3>
        {Meeting?.meetingTimer ? (
          <BootstrapTooltip title={Meeting?.meetingName} placement="top">
            <span className="time_icon">
              <StopwatchIcon />
            </span>
          </BootstrapTooltip>
        ) : null}
      </div>
    );
  };
  const ProjectCell = ({Project, idx}) => {
    return (
      <div
        style={{cursor: 'pointer'}}
        onClick={() => handleOpenProjectTimeline(Project.id)}
        key={idx}
        className="timesheet_list_project_name_div top_border">
        <span className="sr_no">{idx}</span>
        <span className="task_icon">
          <ImageWithFallbackComp
            src={Project?.logo}
            alt="icon"
            ErrorComponent={() => (
              <div style={{marginLeft: '2rem', marginRight: 5}}>
                <ProjectIcon size={15} />
              </div>
            )}
          />
        </span>
        <h3>
          {Project?.name.length > 15
            ? `${Project?.name.substring(0, 15)}...`
            : Project?.name}
        </h3>
        {Project?.isTimerActive ? (
          <BootstrapTooltip title={Project?.runningActivityName} placement="top">
            <span className="time_icon">
              <StopwatchIcon />
            </span>
          </BootstrapTooltip>
        ) : null}
      </div>
    );
  };
  const TimerRow = Task => {
    const TimerTd = ({idx, day}) => {
      //getting current column date and formatting
      const currentDate = moment(day).format('YYYY-MM-DD');
      return (
        <td key={idx}>
          <div className="text_box">
            {Task?.timers[currentDate]?.duration || '-'}
          </div>
        </td>
      );
    };
    return (
      <tr className="table_tr_inner_div">
        {Dates?.map((day, idx) => (
          <TimerTd day={day} idx={idx} />
        ))}
      </tr>
    );
  };
  const BreakTimerRow = () => {
    const TimerTd = ({idx, day}) => {
      //getting current column date and formatting
      const currentDate = moment(day).format('YYYY-MM-DD');
      return (
        <td key={idx}>
          <div className="text_box">
            {data?.breaks_data?.break_timers?.[currentDate]?.duration || '-'}
          </div>
        </td>
      );
    };
    return (
      <tr className="table_tr_inner_div">
        {Dates?.map((day, idx) => (
          <TimerTd day={day} idx={idx} />
        ))}
      </tr>
    );
  };
  const OtherTimerRow = () => {
    const TimerTd = ({idx, day}) => {
      //getting current column date and formatting
      const currentDate = moment(day).format('YYYY-MM-DD');
      return (
        <td key={idx}>
          <div className="text_box">
            {data?.other_tasks_data?.timers?.[currentDate]?.duration || '-'}
          </div>
        </td>
      );
    };
    return (
      <tr className="table_tr_inner_div">
        {Dates?.map((day, idx) => (
          <TimerTd day={day} idx={idx} />
        ))}
      </tr>
    );
  };
  const RowTotalDurationCell = Item => {
    return (
      <div className="end_text_box_div top_border">
        <span className="end_text_box">{Item?.totalWorkingTime || '-'}</span>
      </div>
    );
  };
  const BreakRowTotalDurationCell = Break => {
    return (
      <div className="end_text_box_div top_border">
        <span className="end_text_box">{data?.breaks_data?.totalDuration}</span>
      </div>
    );
  };
  const OtherTimerTotalDurationCell = () => {
    return (
      <div className="end_text_box_div top_border">
        <span className="end_text_box">
          {data?.other_tasks_data?.totalWorkingTime}
        </span>
      </div>
    );
  };
  const getCell = (item, i) => {
    let idx = (page - 1) * 10 + i + 1;
    switch (item.type) {
      case 'BREAK':
        return <BreakCell />;
      case 'OTHER_TASK':
        return <OtherTaskCell />;
      case 'MEETING':
        return <MeetingCell idx={idx} Meeting={item} />;
      case 'PROJECT':
        return <ProjectCell idx={idx} Project={item} />;
      default:
        return null;
    }
  };
  const getDayTimerCellByItemType = (item, i) => {
    switch (item.type) {
      case 'BREAK':
        return <BreakTimerRow />;
      case 'OTHER_TASK':
        return <OtherTimerRow />;
      default:
        return <TimerRow idx={i} {...item} />;
    }
  };
  const getRowTotalCellByItemType = (item, i) => {
    switch (item.type) {
      case 'BREAK':
        return <BreakRowTotalDurationCell />;
      case 'OTHER_TASK':
        return <OtherTimerTotalDurationCell />;
      default:
        return <RowTotalDurationCell key={i} {...item} />;
    }
  };
  return (
    <>
      <div className="timesheet_list_top_main_div">
        <div className="timesheet_list_top_div">
          {/* ---------------------------------------------------------------------------------------------------- */}
          <div className="row m-0">
            <div className="col-3 p-0">
              {/* ProjectName */}
              <div className="timesheet_list_top_title_div">
                <span>S.No</span>
                <p>
                  Activities (
                  {data?.meetings?.length +
                    data?.projects_data?.length +
                    (searchQuery ? 0 : 1)}
                  )
                </p>
              </div>
              {combinedData
                ?.slice((page - 1) * 10, page * 10)
                ?.map((item, i) => getCell(item, i))}
            </div>
            <div className="col-7 p-0">
              <div className="timesheet_list_table_div">
                <table class="table">
                  <thead>
                    <tr>
                      {Dates?.map(Date => (
                        <TableThDate key={1} dateString={Date} />
                      ))}
                      {Dates.length >= 7
                        ? []
                        : Array(7 - Dates.length)
                            .fill(0)
                            ?.map(Date => <TableThDate />)}
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      ...(searchQuery
                        ? []
                        : [{type: 'BREAK'}, {type: 'OTHER_TASK'}]),
                      ...data?.meetings,
                      ...data?.projects_data,
                    ]
                      ?.slice((page - 1) * 10, page * 10)
                      ?.map(getDayTimerCellByItemType)}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-2 p-0">
              <div className="timesheet_list_top_Total_title_div">
                <span>Total</span>
                <p>{TotalWorkingTimeByDate?.totalWorkingTime || '-'}</p>
              </div>
              {[
                ...(searchQuery ? [] : [{type: 'BREAK'}, {type: 'OTHER_TASK'}]),
                ...data?.meetings,
                ...data?.projects_data,
              ]
                ?.slice((page - 1) * 10, page * 10)
                ?.map(getRowTotalCellByItemType)}
            </div>
          </div>
          {totalRows > 10 ? (
            <div className="page_pagination_list_div">
              <div className="page_pagination_main_number_div">
                <Pagination
                  onChange={handleTaskPagination}
                  count={Math.ceil(totalRows / 10)}
                  defaultPage={Number(page)}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
const NewFilters = ({
  filters = {projects: [], meetings: []},
  selectedFilters = {projects: [], meetings: []},
  setSelectedFilters = () => {},
  onApplyFilters = () => {},
}) => {
  //hooks

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = open => {
    setIsOpen(open);
  };
  const storData = useSelector(state => state);
  return (
    <React.Fragment>
      <NewOutlinedButtonWithIcon
        icon={<FilterIcon />}
        onClick={() => toggleDrawer(true)}
        title="Filter"
      />
      <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={() => toggleDrawer(false)}
        className="new_filters_one_main_div_top">
        <div
          className="new_filters_one_main_div"
          data-theme={storData.global.isMode}>
          <div className="top_title_and_close_btn">
            <h3>Filter</h3>
            <span onClick={() => toggleDrawer(false)}>
              <XIcon />
            </span>
          </div>
          <div className="inner_div">
            <div className="row g-2">
              <div className="col-12 ">
                <NewSelect
                isMulti={true}
                  label="Projects"
                  data={filters.projects}
                  placeholder="Select projects"
                  selected={selectedFilters.projects}
                  setSelected={projects =>
                    setSelectedFilters(old => ({...old, projects}))
                  }
                  // ErrorMessage="Please select employee name"
                />
              </div>
              <div className="col-12">
                <NewSelect
                isMulti={true}
                  label="Meetings"
                  data={filters.meetings}
                  placeholder="Select meetings"
                  selected={selectedFilters.meetings}
                  setSelected={meetings =>
                    setSelectedFilters(old => ({...old, meetings}))
                  }
                  // ErrorMessage="Please select project"
                />
              </div>
              <div className="col-12 new_filters_bottom_btn">
                <Button
                  onClick={() => {
                    onApplyFilters();
                    toggleDrawer(false);
                  }}
                  variant="contained"
                  className="btn_1">
                  Apply
                </Button>
                <Button
                  onClick={() => toggleDrawer(false)}
                  variant="outlined"
                  className="btn_2">
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};
function calculateTotalDuration(data) {
  const {
    meetings = [],
    projects_data = [],
    other_tasks_data = [],
    breaks_data = {break_timers: {}},
  } = data;

  const dateDurations = {};
  let overallTotalSeconds = 0;

  const addDuration = (date, seconds) => {
    // console.table(date, seconds);
    if (!dateDurations[date]) {
      dateDurations[date] = 0;
    }
    dateDurations[date] += seconds;
    overallTotalSeconds += seconds;
  };
  if (meetings?.length)
    meetings?.forEach(meeting => {
      Object.entries(meeting.timers).forEach(([date, timer]) => {
        addDuration(date, timer.seconds);
      });
    });

  if (other_tasks_data?.timers && Object.keys(other_tasks_data?.timers).length)
    Object.entries(other_tasks_data?.timers)?.forEach(([date, timer]) => {
      addDuration(date, timer.seconds);
    });

  if (projects_data?.length)
    projects_data?.forEach(project => {
      Object.entries(project?.timers).forEach(([date, timer]) => {
        addDuration(date, timer.seconds);
      });
    });
  if (breaks_data?.break_timers)
    Object.entries(breaks_data?.break_timers).forEach(([date, timer]) => {
      addDuration(date, timer.seconds);
    });

  const dates = {};
  Object.entries(dateDurations).forEach(([date, seconds]) => {
    dates[date] = formatDuration(seconds, true);
  });

  return {dates, totalWorkingTime: formatDuration(overallTotalSeconds)};
}
const BootstrapTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export const ImageWithFallbackComp = ({
  src = '',
  ErrorComponent = () => <></>,
  alt = '',
}) => {
  const [isError, setIsError] = useState(false);

  if (isError) return <ErrorComponent />;

  return <img src={src} onError={() => setIsError(true)} alt={alt} />;
};
const AntSwitch = styled(Switch)(({theme}) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
