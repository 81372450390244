import { PostUser } from "../../../../../common/components/PostUser/PostUser";
import { Post } from "../../../../../common/components/post/Post";
export const Announcement = () => {
  return (
    <>
      <Post />
      <PostUser />
      <PostUser />
      <PostUser />
    </>
  );
};
