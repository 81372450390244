import {Nav, Tab} from 'react-bootstrap';
import React, {useRef} from 'react';
import {
  FullBtn,
  OutlineBtn,
} from '../../../../common/ManagementComponents/Btn/Btn';
import {PageAllTopTitle} from '../../../../common/ManagementComponents/PageAllTopTitle';
import {IoClose, IoSearchOutline} from 'react-icons/io5';
import IconButton from '@mui/material/IconButton';
import {BiDotsHorizontalRounded} from 'react-icons/bi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {NavLink, useLocation, useNavigate, useParams} from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Img from '../../../../common/components/img/Img';
import {AiFillCaretDown, AiOutlineClose} from 'react-icons/ai';
import Modall from 'react-bootstrap/Modal';
import NoResultsSVG from '../../../../../pics/empty_state_search_not_found.svg';
import ReactHtmlParser from 'react-html-parser';
import {InputBox} from '../../../../common/ManagementComponents/form/Form';
import Select from 'react-select';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Editor} from 'react-draft-wysiwyg';
import DemoPDFIcon from '../../../../../pics/Management/DemoPDF.png';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import {FaFilter, FaRegComment, FaRegEye, FaUserPlus} from 'react-icons/fa';
import {IoCloseSharp} from 'react-icons/io5';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Accordion from 'react-bootstrap/Accordion';
import {UserParticipants} from '../../../../common/ManagementComponents/Participants';
import PdfIcon from '../../../../../pics/Management/new.jpg';
import {DocumentsAttachments} from '../../../../common/ManagementComponents/DocumentsAttachments';
import {BsUpload} from 'react-icons/bs';
import {useState} from 'react';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {useEffect} from 'react';
import Axios from './../../../../../config/api';
import {Block, Confirm, Notify} from 'notiflix';
import {useForm, Controller} from 'react-hook-form';
import {StatusCode, getApprovalFileUrl} from '../../../../../constants';
import FilePreview from '../../../../common/ManagementComponents/FilePreview';
import {useNonInitialEffect} from '../../../../../utils/hooks/non-initial-effect.tsx';
import {Avatar, TextField} from '@mui/material';
import moment from 'moment';
import CommentsPdfIcon from '../../../../../pics/pdf.svg';
import CommentsPdfIcon2 from '../../../../../pics/word.svg';
import CommentsPdfIcon3 from '../../../../../pics/powerpoint.svg';
import CommentsPdfIcon4 from '../../../../../pics/excel.svg';
import CommentsPdfIcon5 from '../../../../../pics/file.png';
import ApprovalIcon from '../../../../../pics/approval_icon.svg';
import {useSelector} from 'react-redux';
import {OutlineBtnWithIcon} from '../../../../common/ManagementComponents/Btn/Btn';
import {getUser} from '../../../../../redux/slices/userSlice.js';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import UploadFileIcon from '../../../../../pics/Management/upload_file.svg';
import {
  MdFileDownload,
  MdOutlineApproval,
  MdOutlineMessage,
} from 'react-icons/md';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';
import DemoImg from '../../../../../pics/Management/tsc.png';
import DemoPdf from '../../../../../pics/Management/DemoPDF.pdf';
import CommantIcon from '../../../../../pics/Management/commantIcon.svg';
import SubTaskIcon from '../../../../../pics/Management/task_alt.png';
import {Modal, ButtonToolbar} from 'rsuite';
import {
  IoIosCloseCircleOutline,
  IoMdAttach,
  IoMdClose,
  IoMdCopy,
} from 'react-icons/io';
import {TbCalendarPlus} from 'react-icons/tb';
import Accordionn from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {NewEditor} from '../../../../common/NewComponents/NewEditor/NewEditor.jsx';

const BootstrapTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const ReassignReasonLabel = props => {
  return (
    <div className="view_change_label">
      {props?.icon}
      <span>{props?.title}</span>
    </div>
  );
};

//? -->Data<--
const ReassignReasonData = [
  {
    value: 'Lack the expected quality',
    label: 'Lack the expected quality',
  },
  {
    value: 'Cross-check for errors',
    label: 'Cross-check for errors',
  },
  {
    value: 'Client Dissatisfaction',
    label: 'Client Dissatisfaction',
  },
  {
    value: 'Other',
    label: <ReassignReasonLabel title="Other" />,
  },
];

export const Approvals = () => {
  //initializing hooks
  const params = useParams();
  const {state} = useLocation();
  //initializing some states
  const [approvals, setApprovals] = useState({received: [], sent: []});
  const [statusFilters, setStatusFilters] = useState([]);
  const [priorityFilters, setPriorityFilters] = useState([]);
  const [query, setQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    projects: [],
    statuses: [],
    priorities: [],
    users: [],
  });
  //? -->Hooks<--

  useEffect(() => {
    fetchApprovals();
    fetchStatuses();
    fetchFilters();
    //other functionality
    if (state && state.taskId) {
      //showing modal
      setShowModal(true);
    }
    return () => {};
  }, []);

  useNonInitialEffect(() => {
    // Timer ID for the delayed fetch
    let timerId;
    // Clear previous timer, if any
    clearTimeout(timerId);
    // Set a new timer for the delayed fetch
    timerId = setTimeout(fetchApprovals, 500);
    // Clean up timer on component unmount
    return () => clearTimeout(timerId);
  }, [query]);

  //? -->Functions<--

  const fetchApprovals = (applyFilters = true) => {
    Block.circle('.loading');
    let selectedFilters = {};
    if (applyFilters)
      Object.keys(filters).forEach(key => {
        selectedFilters[key] = filters[key]
          .filter(f => f?.isSelected)
          .map(f => f.id);
      });
    const q = new URLSearchParams(selectedFilters);
    Axios.fetch(`maxproject/approval?query=${query}&${q.toString()}`)
      .then(({data}) => {
        Block.remove('.loading');
        setApprovals({
          received: data?.approvals?.received,
          sent: data?.approvals?.sent,
        });
      })
      .catch(err => {
        Block.remove('.loading');
      });
  };
  const fetchStatuses = () => {
    Axios.fetch(`maxproject/random/statuses/approval`)
      .then(({data}) => {
        setPriorityFilters(data?.priority_statuses);
        setStatusFilters(data?.statuses);
      })
      .catch(err => {
        Block.remove('.loading');
      });
  };
  const fetchFilters = () => {
    Axios.fetch(`maxproject/approval/filters`)
      .then(({data}) => {
        setFilters(old => ({
          ...old,
          users: data?.filters?.users,
          projects: data?.filters?.projects,
          statuses: data?.filters?.statuses,
          priorities: data?.filters?.priorities,
        }));
      })
      .catch(err => {});
  };
  const handleSearch = e => {
    setQuery(e.target.value);
  };

  //? -->COMPONENTS<--

  const RequestApprovalModal = props => {
    const SelectType = [
      {value: 'internal', label: 'Internal Employee'},
      {value: 'client', label: 'Client'},
      {value: 'other', label: 'Other'},
    ];
    //react-form-hook
    const {
      register,
      handleSubmit,
      formState: {errors},
      control,
      getValues,
      setValue,
    } = useForm({
      defaultValues: {
        type: SelectType[0],
      },
    });

    const [projects, setProjects] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [subTasks, setSubTasks] = useState([]);
    const [taskPriority, setTaskPriority] = useState({});
    const [approval, setApproval] = useState({});
    const [serverErrors, setServerErrors] = useState({});
    const [approvers, setApprovers] = useState([]);
    const [attachedFilesPreview, setAttachedFilesPreview] = useState([]);
    const [uploadedAttachments, setUploadedAttachments] = useState([]);
    const [showTask, setShowTask] = useState(true);
    const [approvalType, setApprovalType] = useState('internal');
    const [requireSwitch, setShowRequireSwitch] = useState(false);
    const [approversSeries, setApproversSeries] = useState([]);
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
      if (props?.show) getSelectFieldsData();
      //if user is coming from task detail page and want to create approval
      if (state && state.taskId) {
        //showing loader
        Block.circle('.modal_body_new', 'Loading...');
        //updating form values
        setApprovalType(state?.taskType);
        //setting type
        switch (state?.taskType) {
          case 'internal':
            setValue('type', SelectType[0]);
            break;
          case 'other':
            setValue('type', SelectType[2]);
            break;
          case 'client':
            setValue('type', SelectType[1]);
            break;
        }
        setValue('task', {
          value: state?.taskId,
          label: state?.taskName,
        });
        //getting sub tasks and marking them as checked
        onTaskChange({value: state?.taskId}, () => {
          Block.remove('.modal_body_new');
        });
      }
    }, []);

    const getSelectFieldsData = () => {
      const URL = `maxproject/random/new_approval_select_fields`;
      Axios.fetch(URL)
        .then(({data}) => {
          let projects = data?.projects?.map(el => ({
            value: el?.id,
            label: el?.name,
          }));
          let users = data?.members.map(u => ({
            label: u.name,
            value: u.id,
          }));
          setApprovers(users);
          setProjects([...projects]);
          //if user is coming from task detail page and want to create approval
          if (state && state?.projectId) {
            //finding project from id
            let project = projects.find(p => p.value == state?.projectId);
            //setting default project
            setValue('project', project);
          }
        })
        .catch(err => {
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };
    const getProjectTasks = projectId => {
      if (projectId === null) {
        //hiding task field and clearing tasks
        setTasks([]);
        return setShowTask(false);
      }
      const taskType = getValues('type')?.value || SelectType[0].value;
      //showing task field
      setShowTask(true);
      const URL = `maxproject/task/select_list_type?project_id=${projectId}&task_type=${taskType}`;
      Axios.fetch(URL)
        .then(({data}) => {
          let tasks = data?.tasks.map(u => ({
            label: u.name,
            value: u.id,
          }));
          setTasks(tasks);
        })
        .catch(err => {
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };
    const onAttachmentChange = e => {
      let files = e.target.files;
      if (files) {
        let previews = [];
        Array.from(files).forEach(file =>
          previews.push({
            name: file.name,
            url:
              file.type == 'image/jpeg' || file.type == 'image/png'
                ? URL.createObjectURL(file)
                : PdfIcon,
          }),
        );
        setAttachedFilesPreview(previews);
      }
    };
    const handleFormSubmit = (data, e, force = false) => {
      if (requireSwitch && approversSeries.some(el => el === null)) {
        return setShowErrors(true);
      }
      //clearing server errors
      setServerErrors({});
      //creating new formdata
      let formData = new FormData();
      //preparing payload
      let payload = {
        title: data.title,
        project_id: data.project?.value,
        priority_id: taskPriority?.id,
        task_id: data.task?.value || null,
        type: data.type?.value,
        assignedOrder: requireSwitch ? 1 : 0,
        approvers: JSON.stringify(
          (requireSwitch ? approversSeries : data.approvers)?.map(u => u.value),
        ),
        subTasks: JSON.stringify(
          subTasks.filter(st => st.isChecked).map(st => st.id),
        ),
        desc: approval.desc,
      };
      //inserting payload in formdata
      Object.keys(payload).forEach(k => formData.append(k, payload[k]));
      //checking if user uploaded any attachments
      if (data?.attachments?.length)
        Array.from(data?.attachments).forEach(attachment =>
          formData.append('attachments[]', attachment),
        );
      //showing up loader
      Block.circle('#form', 'Saving...');
      //initiating network request
      const Request = params?.approval_id
        ? Axios.put(`maxproject/approval/${params.approval_id}`, formData, {
            'Content-Type': 'multipart/form-data',
          })
        : Axios.post('maxproject/approval', formData, {
            'Content-Type': 'multipart/form-data',
          });
      Request.then(({data}) => {
        Block.remove('#form');
        Notify.success(data.message);
        fetchApprovals();
        props?.onHide();
      }).catch(err => {
        //removing loader
        Block.remove('#form');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
        //checking and showing express validation errors
        if (err?.response?.status == StatusCode.ExpressErrors)
          err?.response?.data?.errors.forEach(el =>
            setServerErrors(old => ({...old, [el.param]: el.msg})),
          );
      });
    };
    const handleDeleteAttachment = (i, file) => {
      Confirm.show(
        `Delete ${file.original_name}`,
        `${file.original_name} will be permanently deleted and can't be recovered.`,
        'Delete',
        'Cancel',
        () => {
          //request to delete file from server
          Axios.delete(`maxproject/attachment/${file.id}`).then(({data}) => {
            Notify.success(data.message);
            setUploadedAttachments(old => {
              let arr = old.slice();
              arr.splice(i, 1);
              return arr;
            });
          });
        },
        () => {},
        {},
      );
    };
    const SwitchHandleChange = () => {
      setShowRequireSwitch(!requireSwitch);
    };
    const AntSwitch = styled(Switch)(({theme}) => ({
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
      '&:active': {
        '& .MuiSwitch-thumb': {
          width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: 'translateX(9px)',
        },
      },
      '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
          transform: 'translateX(12px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor:
              theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
          },
        },
      },
      '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
          duration: 200,
        }),
      },
      '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255,255,255,.35)'
            : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
      },
    }));
    const addElement = () => {
      setApproversSeries([...approversSeries, null]);
    };
    const deleteElement = idx => {
      setApproversSeries(old => {
        let arr = old.slice();
        arr.splice(idx, 1);
        return arr;
      });
    };
    const onApprovalTypeChange = (el, field) => {
      field.onChange(el);
      setApprovalType(el.value);
      if (el.value == 'other') setValue('project', null);
      setValue('task', null);
      getProjectTasks(getValues('project')?.value);
      setSubTasks([]);
    };
    const onProjectChange = (el, field) => {
      field.onChange(el);
      setValue('task', null);
      //getting project tasks
      getProjectTasks(el.value);
      setSubTasks([]);
    };
    const onTaskChange = (el, cb = () => {}) => {
      //getting task id
      const task_id = el.value;
      //getting sub tasks
      const URL = `maxproject/task/sub_tasks_select_list_for_approval/${task_id}`;
      //initializing network request
      Axios.fetch(URL)
        .then(({data}) => {
          //updating sub tasks with isChecked property
          let subTasks = data?.subTasks.map(st => ({
            ...st,
            isChecked: state?.subTasks.includes(st.id),
          }));
          //setting sub tasks
          setSubTasks(subTasks);
          setTaskPriority(data?.taskPriority);
          cb();
        })
        .catch(err => {
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };
    const SubTaskList = () => {
      const [isMarkedAll, setisMarkedAll] = useState(false);

      useEffect(() => {
        //updating isMarkedAll state
        setisMarkedAll(
          subTasks?.length > 0 && subTasks?.every(subTask => subTask.isChecked),
        );
      }, []);

      const handleMarkAllChange = event => {
        setisMarkedAll(!isMarkedAll);
        setSubTasks(old => {
          let arr = old.slice();
          arr.forEach(subTask => {
            subTask.isChecked = !isMarkedAll;
          });
          return arr;
        });
      };
      const handleSubTaskChange = (event, idx) => {
        setSubTasks(old => {
          let arr = old.slice();
          arr[idx].isChecked = event.target.checked;
          return arr;
        });
      };
      return (
        <div>
          <FormControlLabel
            label="All"
            control={
              <Checkbox
                checked={isMarkedAll}
                indeterminate={!isMarkedAll}
                onChange={handleMarkAllChange}
              />
            }
          />
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            {subTasks?.map((subTask, i) => (
              <FormControlLabel
                label={subTask.name}
                control={
                  <Checkbox
                    checked={subTask?.isChecked}
                    onChange={e => handleSubTaskChange(e, i)}
                  />
                }
              />
            ))}
          </Box>
        </div>
      );
    };
    return (
      <Modall
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="modal_body_new">
          <form id="form" onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
              <div className="col-12">
                <div className="approval_request_title">
                  <h3>Approval request</h3>
                  <AiOutlineClose onClick={props?.onHide} />
                </div>
              </div>
              <div className="col-12 approval_request_input">
                <InputBox
                  type="text"
                  placeholder="Title"
                  register={() =>
                    register('title', {
                      required: 'Approval title is required',
                      value: approval?.title,
                    })
                  }
                  errorMessage={errors.title?.message || serverErrors?.title}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <Controller
                  name="type"
                  control={control}
                  rules={{
                    required: 'Please select type',
                  }}
                  render={({field}) => (
                    <Select
                      {...field}
                      options={SelectType}
                      placeholder="Select Type"
                      isDisabled={state?.taskId}
                      onChange={el => onApprovalTypeChange(el, field)}
                    />
                  )}
                />
                <small className="error_message">
                  {errors.type?.message || serverErrors?.type}
                </small>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="dashboard_top_week_Select drop_box">
                  <Controller
                    name="project"
                    control={control}
                    rules={{
                      required:
                        approvalType == 'other'
                          ? false
                          : 'Please select a project',
                    }}
                    render={({field}) => (
                      <Select
                        {...field}
                        options={projects}
                        isDisabled={approvalType == 'other' || state?.taskId}
                        placeholder="Select Project"
                        defaultValue={approval?.project || state?.projectId}
                        isSearchable={true}
                        onChange={el => onProjectChange(el, field)}
                      />
                    )}
                  />
                  <small className="error_message">
                    {errors.project?.message || serverErrors?.project}
                  </small>
                </div>
              </div>
              <div
                className="col-12"
                style={{
                  display: showTask ? 'unset' : 'none',
                }}>
                <div className="dashboard_top_week_Select drop_box">
                  <Controller
                    name="task"
                    control={control}
                    rules={{
                      required: showTask ? 'Please select a task' : false,
                    }}
                    render={({field}) => (
                      <Select
                        {...field}
                        options={tasks}
                        placeholder="Select Task"
                        isSearchable={true}
                        isDisabled={state?.taskId}
                        onChange={el => {
                          onTaskChange(el);
                          field.onChange(el);
                        }}
                        defaultValue={approval?.task}
                      />
                    )}
                  />
                  <small className="error_message">
                    {errors.task?.message || serverErrors?.task}
                  </small>
                </div>
              </div>
              {subTasks.length ? (
                <div className="col-12">
                  <div className="sub_task_filed_approvls_tab">
                    <Accordion
                      alwaysOpen
                      defaultActiveKey={state?.subTasks?.length ? '4' : null}>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>Select subtasks</Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <SubTaskList />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              ) : null}
              <div className="col-12">
                <FormGroup>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <AntSwitch
                      inputProps={{'aria-label': 'ant design'}}
                      checked={requireSwitch}
                      onChange={SwitchHandleChange}
                    />
                    <Typography className="approval_request_input_switch_text">
                      Require a response from all users in the assigned order
                    </Typography>
                  </Stack>
                </FormGroup>
              </div>
              <div className="col-12">
                <div>
                  {requireSwitch ? (
                    <div>
                      {approversSeries.map((element, i) => (
                        <div
                          className="row mb-3  "
                          style={{position: 'relative '}}
                          key={i}>
                          <div className="col-1">
                            <p className="approval_request_input_add_number">
                              {i + 1}
                            </p>
                          </div>
                          <div className="col-10 dashboard_top_week_Select drop_box">
                            <Select
                              onChange={el => {
                                setApproversSeries(old => {
                                  let arr = old.slice();
                                  arr[i] = el;
                                  return arr;
                                });
                              }}
                              value={element}
                              options={approvers}
                              placeholder="Select Approver"
                            />
                          </div>
                          <div className="col-1">
                            <div className="approval_request_input_remove_btn ">
                              <AiOutlineClose
                                onClick={() => deleteElement(i)}
                              />
                            </div>
                          </div>
                          <div className="col-1"></div>
                          <div className="col-11">
                            {!approversSeries[i] && showErrors ? (
                              <small className="error_message">
                                Please select a approver
                              </small>
                            ) : null}
                          </div>
                        </div>
                      ))}
                      <div className="">
                        <p
                          onClick={addElement}
                          className="approval_request_input_add_btn">
                          <FaUserPlus />
                          Add additional recipient
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12">
                      <div className="dashboard_top_week_Select drop_box drop_box2 scheduling_details_div">
                        <Controller
                          name="approvers"
                          control={control}
                          rules={{
                            required: requireSwitch
                              ? false
                              : 'Please select approvers',
                          }}
                          render={({field}) => (
                            <Select
                              {...field}
                              isSearchable={true}
                              onChange={el => field.onChange(el)}
                              defaultValue={approval?.approvers}
                              isMulti
                              options={approvers}
                              placeholder="select approvers"
                            />
                          )}
                        />
                        <small className="error_message">
                          {errors.approvers?.message || serverErrors?.approvers}
                        </small>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="received_list_modal_disabled_input">
                  <InputBox
                    disabled
                    type="text"
                    defaultValue={taskPriority.name}
                    className=" opacity-25"
                    label="priority"
                  />
                </div>
              </div>
              <div className="col-12">
                <NewEditor
                  placeholder="Desicription"
                  value={approval?.desc}
                  onChange={text => setApproval(old => ({...old, desc: text}))}
                />
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-sm-9 col-9">
                    <div className="brief_summery_div ">
                      <h3>Attachments:</h3>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-3 col-3">
                    <div className="documents_app_btn">
                      <input
                        type="file"
                        style={{display: 'none'}}
                        multiple={true}
                        id="attachemnt_input"
                        hidden
                        {...register('attachments', {
                          required: false,
                          onChange: onAttachmentChange,
                        })}
                      />
                      <label
                        className="management_btn_full2"
                        htmlFor="attachemnt_input">
                        <span>Upload Document</span>
                        <BsUpload />
                      </label>
                    </div>
                    {/* <div className="new_uplode_btn_div">
                      <AttachmentsMenu />
                    </div> */}
                  </div>
                  <small className="error_message">
                    {errors.attachments?.message || serverErrors?.attachments}
                  </small>
                </div>
                <div className="doc_img_main_div">
                  <div className="row g-3 mt-2">
                    {attachedFilesPreview.map((val, i) => {
                      return (
                        <FilePreview
                          cols="col-4"
                          key={i}
                          name={val.name}
                          url={val.url}
                        />
                      );
                    })}
                  </div>
                  <div className="row g-3 mt-2">
                    {uploadedAttachments.map((val, i) => {
                      return (
                        <DocumentsAttachments
                          cols="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6"
                          key={i}
                          original_name={val.original_name}
                          url={getApprovalFileUrl(val.name)}
                          handleDelete={() => handleDeleteAttachment(i, val)}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="approval_request_btn">
                  <OutlineBtn onClick={props?.onHide} name="Cancel" />
                  <button
                    className="management_btn_full2"
                    style={{width: 'auto'}}
                    type="submit"
                    form={'form'}>
                    Send
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modall>
    );
  };
  const PageRightFilters = () => {
    const PriorityInner = props => (
      <div
        className="Priority_filter_inner"
        style={{backgroundColor: props.backgroundColor}}>
        <span style={{color: props.color}}>{props.title}</span>
      </div>
    );
    const RequestedAndRejected = props => (
      <div style={{color: props.color}}>{props.title}</div>
    );
    const RequestedBy = props => (
      <div className="Requested_by_div">
        <img src={props.UserPhoto} alt="icon" />
        {props.title}
      </div>
    );
    const onFilterChange = (filter, idx, checked) => {
      setFilters(old => {
        let arr = old[filter].slice();
        arr[idx]['isSelected'] = checked;
        return {...old, [filter]: arr};
      });
    };
    const handleClearAllFilters = () => {
      setFilters(old => {
        let filters = {};
        Object.keys(old).forEach(key => {
          filters[key] = filters[key].map(filter => ({
            ...filter,
            isSelected: false,
          }));
        });
        return filters;
      });
      fetchApprovals(false);
    };
    return (
      <>
        <OutlineBtnWithIcon
          onClick={() => setShowFilters(true)}
          name="All Filters"
          icon={<FaFilter />}
        />

        <Drawer anchor="right" open={showFilters}>
          <Box role="presentation" className="filter_new_top_div">
            <IoCloseSharp onClick={() => setShowFilters(false)} />
            <div className="row mt-3 mb-4">
              <div className="col-6">
                <p className="filter_title_1">All Filters</p>
              </div>
              <div className="col-6 text-end" onClick={handleClearAllFilters}>
                <p className="filter_title_2">Clear All</p>
              </div>
            </div>
            <div className="filter_new_top_div_inner">
              <div className="header_input_div">
                <IoSearchOutline />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Filter"
                />
              </div>
              <Accordion alwaysOpen>
                {filters?.projects.length ? (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Project</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <FormGroup>
                          {filters?.projects.map((project, i) => {
                            return (
                              <FormControlLabel
                                onChange={e =>
                                  onFilterChange(
                                    'projects',
                                    i,
                                    e.target.checked,
                                  )
                                }
                                checked={project?.isSelected}
                                control={<Checkbox />}
                                label={project.name}
                              />
                            );
                          })}
                        </FormGroup>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : null}
                {filters?.priorities.length ? (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Priority</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <FormGroup>
                          {filters?.priorities.map((priority, i) => (
                            <FormControlLabel
                              key={priority.id}
                              control={<Checkbox />}
                              onChange={e =>
                                onFilterChange(
                                  'priorities',
                                  i,
                                  e.target.checked,
                                )
                              }
                              checked={priority?.isSelected}
                              label={
                                <PriorityInner
                                  color={priority.text_color}
                                  backgroundColor={priority.bg_color}
                                  title={priority.name}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : null}
                {filters?.statuses.length ? (
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Status</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <FormGroup>
                          {filters?.statuses.map((status, i) => (
                            <FormControlLabel
                              control={<Checkbox />}
                              onChange={e =>
                                onFilterChange('statuses', i, e.target.checked)
                              }
                              checked={status?.isSelected}
                              label={
                                <RequestedAndRejected
                                  title={status.name}
                                  color={status.text_color}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : null}
                {filters?.users.length ? (
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Created By</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <FormGroup>
                          {filters?.users.map((user, i) => (
                            <FormControlLabel
                              control={<Checkbox />}
                              onChange={e =>
                                onFilterChange('users', i, e.target.checked)
                              }
                              checked={user?.isSelected}
                              label={
                                <RequestedBy
                                  title={user.name}
                                  UserPhoto={user.avatar}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : null}
              </Accordion>
            </div>
            <div className=" mt-4 d-flex justify-content-center align-items-center">
              <div className=" me-3" onClick={() => fetchApprovals(true)}>
                <FullBtn name="Apply" />
              </div>
              <div className="add_project_modal_btn_one">
                <OutlineBtn
                  onClick={() => setShowFilters(false)}
                  name="Close"
                />
              </div>
            </div>
          </Box>
        </Drawer>
      </>
    );
  };
  return (
    <>
      <RequestApprovalModal
        show={showModal}
        onHide={() => setShowModal(false)}
      />
      <div className="custom_container">
        <div className="row g-3 header_my">
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-1 col-sm-12 col-12 order-md-1 order-2">
            <PageAllTopTitle title="Approvals" />
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 order-md-2 order-1">
            <div className="header_input_div">
              <IoSearchOutline />
              <input
                type="text"
                className="form-control"
                value={query}
                onChange={handleSearch}
                placeholder="Search any approval"
              />
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 header_btn order-md-3 order-3">
            <div className="approval_btn">
              <FullBtn
                onClick={() => setShowModal(true)}
                name="Request Approval"
              />
            </div>
          </div>
        </div>
        <div className="project_list_tabs_main_div">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <div className="project_tab_mb">
              <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
                <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                  <Nav variant="pills" className="flex">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Received</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Sent">Sent</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 filter_btn_top_new ">
                  <PageRightFilters />
                </div>
              </div>
            </div>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <ReceivedList
                  statusFilters={statusFilters}
                  priorityFilters={priorityFilters}
                  approvals={approvals?.received}
                  refreshApprovalsList={fetchApprovals}
                  setApprovals={data =>
                    setApprovals(old => ({...old, received: data}))
                  }
                />
              </Tab.Pane>
              <Tab.Pane eventKey="Sent">
                <SentList
                  statusFilters={statusFilters}
                  priorityFilters={priorityFilters}
                  approvals={approvals?.sent}
                  refreshApprovalsList={fetchApprovals}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};
const AttachmentsMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //
  const inputRef = useRef();

  const FilehandleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    // Do something with the file
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'end',
        }}>
        <Tooltip title="Add Attachments">
          <Button
            variant="text "
            onClick={handleClick}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            className="new_uplode_btn"
            style={{textTransform: 'capitalize', color: '#1A7FDD'}}>
            <BsUpload /> <span>Add Attachments</span>
          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
        {/* <MenuItem onClick={handleClose} className="uplode_menu_item">
          <img src={cloudIcon} alt="icon" />
          OneDrive
        </MenuItem> */}
        <MenuItem onClick={FilehandleClick} className="uplode_menu_item">
          <img src={UploadFileIcon} alt="icon" />
          Upload from my computer
        </MenuItem>
      </Menu>
      <input
        type="file"
        ref={inputRef}
        onChange={handleFileChange}
        style={{display: 'none'}}
      />
    </React.Fragment>
  );
};
const ReceivedList = ({approvals, refreshApprovalsList = () => {}}) => {
  const user = useSelector(getUser);
  const [page, setPage] = useState(1);
  const [approval, setApproval] = useState({});
  const [approvalId, setApprovalId] = useState(null);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [isApprovedByUser, setIsApprovedByUser] = useState(false);
  const [isActionTakenByUser, setIsActionTakenByUser] = useState(false);
  const [Task, setTask] = useState({});
  const [reassignModalShow, setReassignModalShow] = useState(false);

  //? --> Hooks <--
  useEffect(() => {
    if (approvalId) getApproavalData();
  }, [approvalId]);

  //? --> Functions <--
  const getApproavalData = () => {
    //showing loader
    Block.circle('.received_table', 'Loading...');
    const URL = `maxproject/approval/received_approval/${approvalId}`;
    Axios.fetch(URL)
      .then(({data}) => {
        setShowApprovalModal(true);
        const ApprovalUser = data?.approval?.ApprovalUsers?.find(
          u => u?.User?.id == user.id,
        );
        setIsActionTakenByUser(ApprovalUser.status != 'PENDING');
        //getting task owner
        let TaskOwner = data?.approval?.Task?.TaskMembers.find(
          member => member.isOwner,
        );
        setTask(data?.approval?.Task || data?.approval?.ClientTask);
        setApproval({
          ...data?.approval,
          TaskOwner: TaskOwner?.User,
        });
        //removing loader
        Block.remove('.received_table');
      })
      .catch(err => {
        //removing loader
        Block.remove('.received_table');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handlePagination = (e, page) => {
    setPage(page);
  };
  const handleModalHide = () => {
    setShowApprovalModal(false);
    setApprovalId(null);
    setApproval({});
  };
  //? --> Components <--
  const Docs = ({data}) => {
    return (
      <div className="add_new_subtask_Uploaded_mai_div">
        {data?.map(doc => (
          <div className="item">
            <div className="add_new_subtask_Uploaded_img_div">
              <img
                src={doc.type == 'application/pdf' ? DemoPDFIcon : doc.url}
                alt="Uploaded photo"
              />
              <p>
                {doc.original_name.length > 15
                  ? doc.original_name?.substr(0, 15) + '...'
                  : doc.original_name}
              </p>

              <div className="add_new_subtask_Uploaded_img_div_overlay">
                <div className="modal_img_view_btn_div">
                  <a
                    className="modal_img_view_btn me-1"
                    href={doc.url}
                    download>
                    <MdFileDownload />
                  </a>
                  <ModalImgView
                    title={doc.original_name}
                    downloadItem={doc.url}
                    modalUi={
                      <img
                        className="sub_task_modul_img_div"
                        src={doc.url}
                        alt="img"
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  const ApprovalUsersTimeline = () => (
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 py-3">
      <div className="dashbaord_white_card">
        <p className="send_list_page_detai_approval_status_title">
          Approval status: <span>{approval?.status}</span>
        </p>
        <div className="received_list_modal_timeline_uper_div">
          <ul className=" approval_request_details_feedback_ul">
            <li>
              <img
                className="feedback_modal_ul_icon"
                src={approval?.RequestedUser?.avatar}
                alt={approval?.RequestedUser?.name}
              />
              <div className="feedback_modal_ul_main_div">
                <p className="feedback_modal_ul_name mb-1">
                  {approval?.RequestedUser?.name}
                  <span>({approval?.RequestedUser?.Designation?.name})</span>
                </p>
                <span
                  className="feedback_modal_ul_requested "
                  style={{
                    backgroundColor: '#e8f2fc',
                    borderColor: '#1a7fdd',
                  }}>
                  Requested By
                </span>

                <p className="feedback_modal_ul_time">
                  <TbCalendarPlus />
                  {moment(approval?.createdAt).calendar()}
                </p>
              </div>
            </li>
            {approval?.ApprovalUsers?.map(ApprovalUser => (
              <li>
                <img
                  className="feedback_modal_ul_icon"
                  src={ApprovalUser?.User?.avatar}
                  alt={ApprovalUser?.User?.name}
                />
                <div className="feedback_modal_ul_main_div">
                  <p className="feedback_modal_ul_name mb-1">
                    {ApprovalUser?.User?.name}
                    <span>({ApprovalUser?.User?.Designation?.name})</span>
                  </p>
                  <span
                    className="feedback_modal_ul_requested "
                    style={{
                      backgroundColor:
                        ApprovalUser?.status == 'APPROVED'
                          ? '#dfe9dc'
                          : '#e8f2fc',
                      borderColor:
                        ApprovalUser?.status == 'APPROVED'
                          ? '#3ca318'
                          : '#1a7fdd',
                    }}>
                    {ApprovalUser?.status}
                  </span>
                  {ApprovalUser?.comment || ApprovalUser?.reason ? (
                    <>
                      <p className="feedback_modal_ul_commant mt-2">
                        <img src={CommantIcon} alt="icon" />
                        <span className="w-100">
                          {ApprovalUser?.comment
                            ? ReactHtmlParser(ApprovalUser?.comment)
                            : ApprovalUser?.reason}
                        </span>
                      </p>
                      {/* <div style={{marginLeft: '20px'}}>
                          <Docs data={[]} />
                        </div> */}
                    </>
                  ) : null}

                  <p className="feedback_modal_ul_time">
                    <TbCalendarPlus />
                    {moment(ApprovalUser?.updatedAt).calendar()}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
  const ModalImgView = props => {
    const [open, setOpen] = React.useState(false);
    const [size, setSize] = React.useState();
    const handleOpen = value => {
      setSize(value);
      setOpen(true);
    };
    const handleClose = () => setOpen(false);

    return (
      <>
        <ButtonToolbar>
          <span
            className="modal_img_view_btn p-0"
            size="lg"
            onClick={() => handleOpen('lg')}>
            <FaRegEye />
          </span>
        </ButtonToolbar>
        <Modal size={size} open={open} onClose={handleClose}>
          <Modal.Body
            className="sub_task_modul_body_div"
            style={{maxHeight: '35rem'}}>
            <p className="sub_task_modul_title_div">{props.title}</p>
            <hr />
            <IoMdClose
              className="sub_task_modul_top_close_btn_div"
              onClick={handleClose}
            />
            {/* ui */}
            {props.modalUi}
            {/* ui end */}
          </Modal.Body>
          <Modal.Footer>
            <div className="mt-3 d-flex align-items-center justify-content-end approval_modal_btn">
              <div className="me-2">
                <OutlineBtn onClick={handleClose} name="Cancel" />
              </div>
              <div className=" ms-2">
                <a
                  className="management_btn_full2 sub_task_modul_download_btn"
                  href={props.downloadItem}
                  download>
                  download
                </a>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  const ApprovalModal = () => {
    const [comment, setComment] = useState(null);
    const [reassignReason, setReassignReason] = useState('');
    const [showCommentError, setShowCommentError] = useState(false);
    const [rejectionReasonError, setRejectionReasonError] = useState(false);

    const handleApprove = () => {
      const payload = {
        comment,
        reason: reassignReason,
      };
      //request to delete file from server
      Axios.post(`maxproject/approval/approve/${approvalId}`, payload)
        .then(({data}) => {
          Notify.success(data.message);
          setIsActionTakenByUser(true);
          setShowApprovalModal(false);
          refreshApprovalsList();
        })
        .catch(err => {
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };
    const handleRejection = () => {
      // Reset errors
      setRejectionReasonError(false);
      setShowCommentError(false);
      // Check if a reassign reason has been selected
      if (!reassignReason || reassignReason.trim() === '') {
        // Set error state to true to show the error message
        setRejectionReasonError(true);
        return;
      }
      // Check if the selected reason is 'Other' and comment is empty
      if (reassignReason === 'Other' && !comment) {
        setShowCommentError(true);
        return;
      }

      Confirm.show(
        `Reject?`,
        `This action is undonable!`,
        'Reject',
        'Cancel',
        () => {
          const payload = {
            comment,
            reason: reassignReason,
          };
          //request to delete file from server
          Axios.post(`maxproject/approval/reject/${approvalId}`, payload)
            .then(({data}) => {
              Notify.success(data.message);
              setIsActionTakenByUser(true);
              setShowApprovalModal(false);
              refreshApprovalsList();
            })
            .catch(err => {
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        },
      );
    };
    const NewSubTasks = () => {
      //? -->States<--
      const [page, setPage] = useState(1);
      const [subTasks, setSubTasks] = useState(
        approval?.ApprovalSubTasks || [],
      );
      const [selectedFile, setSelectedFile] = useState(null);
      const [checkboxSwitch, setShowCheckboxSwitch] = useState(false);
      const [feedbackShow, setFeedbackShow] = useState(false);

      //? -->Functions<--
      const handlePagination = (e, page) => {
        setPage(page);
      };

      //? -->Components<--
      const ModalImgView = props => {
        const [open, setOpen] = React.useState(false);
        const [size, setSize] = React.useState();
        const handleOpen = value => {
          setSize(value);
          setOpen(true);
        };
        const handleClose = () => setOpen(false);

        return (
          <>
            <ButtonToolbar>
              <span
                className="modal_img_view_btn"
                size="lg"
                onClick={() => handleOpen('lg')}>
                <FaRegEye />
              </span>
            </ButtonToolbar>
            <Modal size={size} open={open} onClose={handleClose}>
              <Modal.Body
                className="sub_task_modul_body_div"
                style={{maxHeight: '35rem'}}>
                <p className="sub_task_modul_title_div">{props.title}</p>
                <hr />
                <IoMdClose
                  className="sub_task_modul_top_close_btn_div"
                  onClick={handleClose}
                />
                {/* ui */}
                {props.modalUi}
                {/* ui end */}
              </Modal.Body>
              <Modal.Footer>
                <div className="mt-3 d-flex align-items-center justify-content-end approval_modal_btn">
                  <div className="me-2">
                    <OutlineBtn onClick={handleClose} name="Cancel" />
                  </div>
                  <div className=" ms-2">
                    <a
                      className="management_btn_full2 sub_task_modul_download_btn"
                      href={props.downloadItem}
                      download>
                      download
                    </a>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        );
      };
      const SubTask = ({subTaskData, idx}) => {
        //? -->States<--
        const [subTask, setSubTask] = useState(subTaskData || {});
        const [addNewComment, setAddNewComment] = useState(false);
        const [comments, setComments] = useState(
          subTask?.ApprovalSubTaskComments || [],
        );
        //? -->Functions<--
        const handleApprove = () => {
          //showing up loader
          Block.circle('#sub_task_' + idx, 'Loading...');
          //initializing network request
          Axios.post(`maxproject/approval/approve_sub_task/${subTask?.id}`)
            .then(({data}) => {
              Block.remove('#sub_task_' + idx);
              //updating sub task
              setSubTask(old => ({...old, status: 'APPROVED'}));
              Notify.success(data.message);
            })
            .catch(err => {
              //removing loader
              Block.remove('#sub_task_' + idx);
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        const handleReject = () => {
          //showing up loader
          Block.circle('#sub_task_' + idx, 'Loading...');
          //initializing network request
          Axios.post(`maxproject/approval/reject_sub_task/${subTask?.id}`)
            .then(({data}) => {
              Block.remove('#sub_task_' + idx);
              //updating sub task
              setSubTask(old => ({...old, status: 'REJECTED'}));
              Notify.success(data.message);
            })
            .catch(err => {
              //removing loader
              Block.remove('#sub_task_' + idx);
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        //? -->Components<--
        const SubTaskComment = ({comment, idx}) => {
          return (
            <div key={idx} style={{marginLeft: '1rem'}}>
              <div className="sub_task_approval_request_details_commants_view_div">
                <img src={CommantIcon} alt="icon" />
                <p className="sub_task_approval_request_details_commants_view">
                  {comment?.comment}
                </p>
              </div>
              <Docs data={comment?.ApprovalSubTaskCommentAttachments || []} />
            </div>
          );
        };
        const NewComment = () => {
          const [comment, setComment] = useState('');
          const [newDocs, setNewDocs] = useState([]);

          //? -->Functions<--

          const handleFileChange = e => {
            let files = e.target.files;
            setNewDocs(files);
          };
          const handleRemoveNewDoc = fileToRemove => {
            const filesArray = Array.from(newDocs);
            const updatedFilesArray = filesArray.filter(
              file => file !== fileToRemove,
            );
            setNewDocs(updatedFilesArray);
          };

          const handleAddSubTaskComment = e => {
            e.preventDefault();
            //getting values
            const {comment} = e.target;
            //showing up loader
            Block.circle('.new_sub_task_comment', 'Loading...');
            //preparing form data
            let formdata = new FormData();
            //adding form data
            formdata.append('comment', comment.value);
            //adding attachments
            if (newDocs)
              Array.from(newDocs).forEach(file =>
                formdata.append('attachments[]', file),
              );
            //initializing network request
            Axios.post(
              `maxproject/approval/sub_task_comment/${subTask?.id}`,
              formdata,
            )
              .then(({data}) => {
                Block.remove('.new_sub_task_comment');
                Notify.success(data.message);
                setComments(data?.comments);
                setAddNewComment(false);
              })
              .catch(err => {
                //removing loader
                Block.remove('.new_sub_task_comment');
                //checking for any error message
                if (err?.response?.data?.message)
                  Notify.failure(err?.response?.data?.message);
              });
          };

          //? -->Components<--

          const NewDocs = () => {
            return Array.from(newDocs).map((file, i) => (
              <div className="item item_new4">
                <div className="add_new_subtask_Uploaded_img_div">
                  <img src={getFileIcon(file, true)} alt="Uploaded doc" />
                  <p>
                    {file.name.length > 15
                      ? file.name?.substr(0, 15) + '...'
                      : file.name}
                  </p>
                  <span
                    className="close_btn"
                    onClick={() => handleRemoveNewDoc(file)}>
                    <IoClose />
                  </span>
                  <div className="add_new_subtask_Uploaded_img_div_overlay">
                    <div className="modal_img_view_btn_div">
                      <ModalImgView
                        title={file.name}
                        downloadItem={getFileIcon(file, true)}
                        modalUi={
                          <img
                            className="sub_task_modul_img_div"
                            src={getFileIcon(file, true)}
                            alt="img"
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ));
          };

          return (
            <div style={{marginLeft: '1rem'}} className="new_sub_task_comment">
              <form onSubmit={handleAddSubTaskComment}>
                <div className="mt-3">
                  <TextField
                    id="fullWidth"
                    label="Comments here"
                    placeholder="Write your comments here"
                    name="comment"
                    required
                    multiline
                    fullWidth
                  />
                </div>
                <div className="add_new_subtask_Uploaded_mai_div">
                  <NewDocs />
                </div>
                <div className="add_new_subtask_top_ui_btn_div">
                  <div className="task_detail_main_btn_div">
                    <input
                      type="file"
                      multiple
                      onChange={handleFileChange}
                      id="subTaskFile2"
                      hidden
                    />
                    <BootstrapTooltip title="Attachment" placement="top">
                      <label htmlFor="subTaskFile2">
                        <IoMdAttach />
                      </label>
                    </BootstrapTooltip>
                  </div>
                  <Button
                    onClick={() => setAddNewComment(false)}
                    className="btn1"
                    variant="outlined">
                    Cancel
                  </Button>
                  <Button type="submit" className="btn2" variant="contained">
                    Save
                  </Button>
                </div>
              </form>
            </div>
          );
        };
        return (
          <div key={idx} className="sub_task_scroll_new" id={'sub_task_' + idx}>
            <div className="row m-0 ">
              {/* item view */}
              <div className="col-12 p-0">
                <div className="row m-0 task_detail_top_main_div">
                  <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-8 col-sm-8 col-8 p-0 p-0">
                    <div className="task_detail_top_mani_div">
                      <div className="task_detail_chakbox">
                        {/* <FormControlLabel
                          control={<Checkbox onChange={setShowCheckboxSwitch} />}
                          className="my_checkbox"
                        /> */}
                        {/* <img
                          className="task_icon my_checkbox_icon"
                          src={SubTaskIcon}
                          alt="icon"
                        /> */}
                        <img
                          className="task_icon"
                          src={SubTaskIcon}
                          alt="icon"
                        />
                      </div>
                      <div className="task_detail_top_inner_div">
                        <div className="row m-0">
                          <div className="col-6 p-0 task_detail_text_div_top_col">
                            <div className="task_detail_text_div ms-0">
                              <div>
                                <p>{subTask?.SubTask?.name}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 p-0 ">
                            <div className=" task_date_div_rel">
                              <div className="task_date_div_rel2 task_detail_main_btn_div">
                                {isActionTakenByUser ? null : (
                                  <BootstrapTooltip
                                    title="Add comment"
                                    placement="top">
                                    <label
                                      className="show_btn"
                                      onClick={setAddNewComment}>
                                      <MdOutlineMessage />
                                    </label>
                                  </BootstrapTooltip>
                                )}
                                {subTask?.status == 'PENDING' &&
                                !isActionTakenByUser ? (
                                  <>
                                    <BootstrapTooltip
                                      title="Reject"
                                      placement="top">
                                      <label
                                        className="show_btn ms-0"
                                        onClick={() => {
                                          Confirm.show(
                                            `Reject?`,
                                            `This action is undonable!`,
                                            'Cancel',
                                            'Reject',
                                            () => {},
                                            handleReject,
                                          );
                                        }}>
                                        <IoIosCloseCircleOutline />
                                      </label>
                                    </BootstrapTooltip>
                                    <BootstrapTooltip
                                      title="Approval"
                                      placement="top">
                                      <label
                                        className="show_btn ms-0"
                                        onClick={() => {
                                          Confirm.show(
                                            `Approval?`,
                                            `This action is undonable!`,
                                            'Cancel',
                                            'Approval',
                                            () => {},
                                            handleApprove,
                                          );
                                        }}>
                                        <MdOutlineApproval />
                                      </label>
                                    </BootstrapTooltip>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 p-0">
                            <Docs
                              data={subTask?.SubTask?.SubTaskAttachments || []}
                            />

                            {addNewComment ? <NewComment /> : null}

                            {comments?.map((Comment, i) => (
                              <SubTaskComment comment={Comment} idx={i} />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 task_date_div">
                    <p>{moment(subTask?.SubTask?.start_date).calendar()}</p>
                  </div>
                  <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 task_date_div">
                    <p>{moment(subTask?.SubTask?.end_date).calendar()}</p>
                  </div>
                </div>
              </div>
              {/* item view end */}
            </div>
          </div>
        );
      };
      return (
        <>
          <div className="row ">
            <div className="col-12">
              <div className="row mt-0 g-3">
                <div className="col-xxl-8 col-xl-8 col-lg-8 col-lg-8 col-md-8 col-sm-12 col-12 d-flex align-items-center">
                  <div className="add_new_subtask_menu_div_top">
                    <span className="add_new_subtask_ui_title mt-0 me-3">
                      Subtasks
                    </span>
                  </div>
                </div>
                {checkboxSwitch ? (
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-lg-4 col-md-4 col-sm-12 approval_request_details_subtask_top_btn_div">
                    <label
                      style={{
                        backgroundColor: 'rgb(26 127 221 / 10%)',
                        border: '1px solid #1A7FDD',
                      }}
                      onClick={() => {
                        setFeedbackShow(true);
                      }}>
                      <MdOutlineMessage style={{color: '#1A7FDD'}} />
                      Feedback?
                    </label>
                    <label
                      style={{
                        backgroundColor: 'rgb(57 194 34 / 10%)',
                        border: '1px solid #39C222',
                      }}
                      onClick={() => {
                        Confirm.show(
                          `Approve?`,
                          `This action is undonable!`,
                          'Cancel',
                          'Approve',
                          () => {},
                          () => {},
                        );
                      }}>
                      <MdOutlineApproval style={{color: '#3BA317'}} /> Approve
                    </label>
                    <label
                      style={{
                        backgroundColor: '#rgb(255 62 72 / 10%)',
                        border: '1px solid #FF3E48',
                      }}
                      onClick={() => {
                        Confirm.show(
                          `Reject?`,
                          `This action is undonable!`,
                          'Cancel',
                          'Reject',
                          () => {},
                          () => {},
                        );
                      }}>
                      <IoIosCloseCircleOutline style={{color: '#FF3E48'}} />
                      Reject
                    </label>
                  </div>
                ) : null}
              </div>
              {/*subtask data view ui */}
              <div className="task_scrol_div1">
                <div className="add_new_subtask_top_ui_div p-0 task_scrol_div2">
                  {/* item header */}
                  <div className="row">
                    <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-8 col-8">
                      <div className=" new_task_detail_top_title_one">
                        {/* <FormControlLabel
                          control={<Checkbox onChange={setShowCheckboxSwitch} />}
                        /> */}
                        <span style={{marginLeft: 50}} className="small">
                          Subtask Name
                        </span>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="new_task_detail_top_title_two">
                        <span className="small">Start date</span>
                      </div>
                    </div>
                    <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2">
                      <div className="new_task_detail_top_title_two task_date_div_rel_new">
                        <span className="small">End date</span>
                      </div>
                    </div>
                  </div>
                  {/* item header end */}
                  {subTasks
                    ?.slice((page - 1) * 5, 5 * page)
                    ?.map((subTask, i) => (
                      <SubTask subTaskData={subTask} idx={i} />
                    ))}
                </div>
                {subTasks?.length > 5 ? (
                  <Stack spacing={2} className=" align-items-end mt-2">
                    <Pagination
                      onChange={handlePagination}
                      count={Math.ceil(subTasks?.length / 5)}
                    />
                  </Stack>
                ) : null}
              </div>
              {/*subtask data view ui end */}
            </div>
          </div>
        </>
      );
    };
    const handleShowReassignModal = () => {
      setReassignModalShow(true);
      setShowApprovalModal(false);
    };
    return (
      <Modall
        show={showApprovalModal}
        onHide={handleModalHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body id="approvalModal" className="modal_body_new">
          <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
            <div className="col-12">
              <div className="approval_request_title">
                <ul className="approval_modal_top_title_div">
                  <li>
                    <img src={ApprovalIcon} alt="icon" />
                  </li>
                  <li>
                    <p>Approval request</p>
                    <span>Approval request details</span>
                  </li>
                  <li>
                    <div>{approval?.status}</div>
                  </li>
                </ul>
                <AiOutlineClose onClick={handleModalHide} />
              </div>
              <hr />
            </div>
            <div className="col-12 mt-0">
              <Accordionn className="approval_modal_sub_title_Description_top_div2 mt-0">
                <div className="approval_modal_sub_title_Description_div">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon className="pm_icon" />}
                    aria-controls="panel1-content"
                    id="panel1-header"></AccordionSummary>
                  <div className="ms-1 approval_modal_sub_title_Description_new mt-0 mb-0">
                    Approval Detail
                  </div>
                </div>
                <AccordionDetails>
                  <div className="row rec_list_tb">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 py-3 rec_list_mid">
                      <div className="received_list_modal_top_div">
                        <h3>{approval?.title}</h3>

                        <div className="approval_modal_sub_title_div2">
                          <ul>
                            <li className="li_first">Date</li>
                            <li>: {moment(approval?.createdAt).calendar()}</li>
                          </ul>
                          <ul>
                            <li className="li_first">Approval requested by</li>
                            <li>: {approval?.RequestedUser?.name}</li>
                          </ul>
                          <ul>
                            <li className="li_first">Type</li>
                            <li>: {approval?.type}</li>
                          </ul>
                          <ul>
                            <li className="li_first">Project</li>
                            <li>: {approval?.Project?.name || 'N/A'}</li>
                          </ul>
                          <ul>
                            <li className="li_first">Task:</li>
                            <li>: {Task?.name}</li>
                          </ul>

                          <ul>
                            <li className="li_first">Task start date</li>
                            <li>: {moment(Task?.start_date).calendar()}</li>
                          </ul>
                          <ul>
                            <li className="li_first">Task end date</li>
                            <li>: {moment(Task?.end_date).calendar()}</li>
                          </ul>
                          <ul>
                            <li className="li_first">Assign to</li>
                            <li className=" d-flex">
                              <small style={{marginRight: '5px'}}>:</small>
                              <div className="Participants_main_div_inner">
                                <UserParticipants
                                  data={Task?.TaskMembers || []}
                                />
                              </div>
                            </li>
                          </ul>
                          {approval?.Task ? (
                            <ul>
                              <li className="li_first">Owner</li>
                              <li className=" d-flex">
                                <small style={{marginRight: '5px'}}>:</small>
                                <Avatar
                                  alt={approval?.TaskOwner?.name}
                                  src={approval?.TaskOwner?.avatar}
                                  sx={{width: 24, height: 24}}
                                />
                              </li>
                            </ul>
                          ) : null}
                          <ul>
                            <li className="li_first">Priority</li>
                            <li>: {approval?.Priority?.name}</li>
                          </ul>
                          {/* <ul>
                            <li className="li_first">Impact</li>
                            <li>
                              :
                              <span
                                style={{
                                  backgroundColor: '#F3D32C',
                                  color: 'white',
                                }}>
                                low
                              </span>
                            </li>
                          </ul>
                          <ul>
                            <li className="li_first">Impact Category</li>
                            <li>: Lorem ipsum</li>
                          </ul>
                          <ul>
                            <li className="li_first">Impact Sub category</li>
                            <li>: Lorem ipsum</li>
                          </ul> */}
                        </div>
                        <h4>Description:</h4>
                        <div style={{wordBreak: 'break-all'}}>
                          {ReactHtmlParser(approval?.desc)}
                        </div>
                        <h4 className="mt-3">Attachments:</h4>
                        <div className="doc_img_main_div">
                          <div className="row g-3 mt-2">
                            <Docs data={approval?.ApprovalAttachments || []} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <ApprovalUsersTimeline />
                  </div>
                </AccordionDetails>
              </Accordionn>
            </div>
            <div className="col-12 mt-1">
              <Accordionn className="approval_modal_sub_title_Description_top_div2 mt-0">
                <div className="approval_modal_sub_title_Description_div">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon className="pm_icon" />}
                    aria-controls="panel1-content"
                    id="panel1-header"></AccordionSummary>
                  <div className="ms-1 approval_modal_sub_title_Description_new mt-0 mb-0">
                    Subtasks
                  </div>
                </div>
                <AccordionDetails>
                  <NewSubTasks />
                </AccordionDetails>
              </Accordionn>
            </div>

            {isApprovedByUser ? null : (
              <>
                <div className="col-12">
                  <NewEditor
                    value={comment}
                    onChange={text => setComment(text)}
                    placeholder="Desicription "
                  />

                  {showCommentError && (
                    <small className="error_message">
                      Please enter a comment
                    </small>
                  )}
                </div>

                {/* <div className="col-12">
                  {
                    {
                      Other: (
                        <>
                          <textarea
                            class="form-control"
                            rows="4"
                            value={comment}
                            onChange={e => setComment(e.target.value)}
                            placeholder="Type a message..."></textarea>
                          {commentError && (
                            <small className="error_message">
                              Please enter a comment for your reason.
                            </small>
                          )}
                        </>
                      ),
                    }[reassignReason]
                  }
                </div> */}
                <div className="col-6">
                  <div className="row">
                    <div className="col-9">
                      <div className="all_user_page all_user_page_new mb-0 w-100">
                        <Select
                          options={ReassignReasonData}
                          isSearchable={false}
                          placeholder="Select Reassign/Reject Reason"
                          onChange={({value}) => {
                            setReassignReason(value);
                            setRejectionReasonError(false);
                          }}
                        />
                        {rejectionReasonError && (
                          <small className="error_message">
                            Please select a reason
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="approval_request_btn3">
                        <OutlineBtn
                          onClick={handleShowReassignModal}
                          name="Reassign"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="approval_request_btn2">
                    <OutlineBtn onClick={handleRejection} name="Reject" />
                    <FullBtn
                      onClick={() => {
                        Confirm.show(
                          `Approve?`,
                          `This action is undonable!`,
                          'Cancel',
                          'Approve',
                          () => {},
                          handleApprove,
                        );
                      }}
                      name="Approve"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modall>
    );
  };
  const ReassignModal = props => {
    //? --> States <--
    const [users, setUsers] = useState([]);
    const [reAssignUser, setReAssignUser] = useState(null);
    const [comment, setComment] = useState(null);
    const [reassignReason, setReassignReason] = useState('');
    const [showCommentError, setShowCommentError] = useState(false);
    const [rejectionReasonError, setRejectionReasonError] = useState(false);

    //? -->Hooks<--

    useEffect(() => {
      if (reassignModalShow) fetchTeamMembers();
    }, [reassignModalShow]);

    //? --> Functions <--
    const fetchTeamMembers = () => {
      const URL = `maxproject/team/members`;
      Axios.fetch(URL)
        .then(({data}) => {
          let reassignUsers = data.members
            .filter(u => u.id != user.id)
            .map(u => ({
              label: u.name,
              value: u.id,
            }));
          setUsers(reassignUsers);
        })
        .catch(err => {
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };
    const handleReassign = () => {
      //request to delete file from server
      Axios.post(`maxproject/approval/reassign/${approvalId}`, {
        assignedUserId: reAssignUser.value,
        reason: reassignReason,
        comment,
      })
        .then(({data}) => {
          Notify.success(data.message);
          setReassignModalShow(false);
          setIsActionTakenByUser(true);
        })
        .catch(err => {
          setReassignModalShow(true);
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };
    const handleReassignValidation = () => {
      // Reset errors
      setRejectionReasonError(false);
      setShowCommentError(false);
      // Check if a reassign reason has been selected
      if (!reassignReason || reassignReason.trim() === '') {
        // Set error state to true to show the error message
        setRejectionReasonError(true);
        return;
      }
      // Check if the selected reason is 'Other' and comment is empty
      if (reassignReason === 'Other' && !comment) {
        setShowCommentError(true);
        return;
      }

      handleReassign();
    };
    const handleClose = () => {
      setReassignModalShow(false);
      setShowApprovalModal(true);
    };
    return (
      <Modall
        show={reassignModalShow}
        onHide={() => setReassignModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="modal_body_new">
          <div className="delete_modal">
            <div className="row g-3">
              <div className="col-12">
                <div className="approval_request_title">
                  <h3>Approval Request Details</h3>
                  <AiOutlineClose onClick={props?.onHide} />
                  <hr />
                </div>
              </div>
              <Editor
                editorState={comment}
                onEditorStateChange={e => setComment(e)}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                placeholder="Desicription "
              />
              {showCommentError && (
                <small className="error_message">Please enter a comment</small>
              )}
              <div className="col-12">
                <div className="all_user_page all_user_page_new mb-0 w-100">
                  <Select
                    options={ReassignReasonData}
                    isSearchable={false}
                    placeholder="Select Task Reassign Reason"
                    onChange={({value}) => {
                      setReassignReason(value);
                      setRejectionReasonError(false);
                    }}
                  />
                  {rejectionReasonError && (
                    <small className="error_message">
                      Please select a reason
                    </small>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="all_user_page all_user_page_new mb-0 w-100">
                  <label className="reassign_my_label_new">Reassign to</label>
                  <Select
                    options={users}
                    isSearchable={true}
                    placeholder="Select a user"
                    onChange={item => setReAssignUser(item)}
                  />
                </div>
              </div>
            </div>
            <div className="row reassign_btn_div">
              <div className="col-6 reassign_btn1_div">
                <Button
                  onClick={handleClose}
                  variant="text"
                  startIcon={<ChevronLeftIcon />}>
                  back
                </Button>
              </div>
              <div className="col-6 reassign_btn2_div">
                <Button
                  onClick={handleReassignValidation}
                  variant="contained"
                  disabled={!reAssignUser}>
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modall>
    );
  };
  const ApprovalTr = props => {
    const ReceivedListDropDown = () => {
      const [anchorEl, setAnchorEl] = useState(null);
      const open = Boolean(anchorEl);
      const handleClick = event => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
      const ITEM_HEIGHT = 30;
      return (
        <>
          <div className="chat_option">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              className=" m-0 p-0">
              <BiDotsHorizontalRounded />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '15ch',
                },
              }}>
              <MenuItem onClick={() => setApprovalId(props.id)}>
                <div className="chat_option_name_div">Approve</div>
              </MenuItem>
              <MenuItem onClick={() => setApprovalId(props.id)}>
                <div className="chat_option_name_div">Reject</div>
              </MenuItem>
            </Menu>
          </div>
        </>
      );
    };
    const navigate = useNavigate();
    function PageLink() {
      navigate('/Management/received_approval/' + props.id);
    }
    return (
      <tr
        className="progress_all_tabel_tr_mor"
        id={`received_approval_${props?.id}`}>
        <td>
          <div className="Participants_main_div_tr ">{props?.idx + 1}</div>
        </td>
        <td>
          <div
            className="Participants_main_div_tr list_link_title"
            onClick={PageLink}>
            {props?.title?.length > 30
              ? props?.title.substr(0, 30) + '...'
              : props?.title}
          </div>
        </td>
        <td className="project_list_name">
          <div
            className="project_list_priority_div"
            style={{backgroundColor: props?.Priority?.bg_color}}>
            <span className="project_list_priority">
              {props?.Priority?.name}
            </span>
          </div>
        </td>
        <td>
          <div
            className="Participants_main_div_tr"
            // style={{color: props?.StatusColor}}
          >
            {props?.status}
          </div>
        </td>
        <td>
          <div className="Participants_main_div_tr">
            {props?.Project ? (
              <NavLink to={`/Management/project_details/${props?.Project?.id}`}>
                {props?.Project?.name}
              </NavLink>
            ) : (
              'N/A'
            )}
          </div>
        </td>
        <td className="Participants_main_div">
          <div className="Participants_main_div_inner">
            <Avatar
              alt={props?.RequestedUser?.name}
              src={props?.RequestedUser?.avatar}
              sx={{width: 24, height: 24}}
            />
            <span style={{marginLeft: 5}}>{props?.RequestedUser?.name}</span>
          </div>
        </td>
        <td>
          <div className="Participants_main_div_tr">
            {moment(props?.createdAt).calendar()}
          </div>
        </td>
        <td>
          <div className="mt-1">
            <div className="progress_all_option_icon">
              {props?.status == 'PENDING' ? <ReceivedListDropDown /> : 'N/A'}
            </div>
          </div>
        </td>
      </tr>
    );
  };
  if (!approvals.length) return <NoDataView />;
  return (
    <>
      <ApprovalModal />
      <ReassignModal />
      <div className="dashbaord_white_card">
        <div>
          <table className="table table-hover text-capitalize progress_all_tabel_tabel received_table">
            <thead>
              <tr className="progress_all_tabel_tr">
                <th scope="col">S. No.</th>
                <th scope="col">Request Title</th>
                <th scope="col">Priority</th>
                <th scope="col">Status</th>
                <th scope="col">Project Name</th>
                <th scope="col">Requested By</th>
                <th scope="col">date</th>
                <th scope="col">action</th>
              </tr>
            </thead>
            <tbody>
              {approvals?.slice((page - 1) * 10, 10 * page)?.map((val, i) => (
                <ApprovalTr idx={i} key={i} {...val} arr={val} />
              ))}
            </tbody>
          </table>
        </div>
        {approvals?.length > 10 ? (
          <div className="projec_tabelt_list_pagination_div">
            <Stack spacing={2}>
              <Pagination
                onChange={handlePagination}
                count={Math.ceil(approvals?.length / 10)}
              />
            </Stack>
          </div>
        ) : null}
      </div>
    </>
  );
};
const SentList = ({
  approvals,
  setApprovals,
  refreshApprovalsList = () => {},
}) => {
  //states
  const [page, setPage] = useState(1);
  const [approval, setApproval] = useState({});
  const [approvalId, setApprovalId] = useState(null);
  const [editApprovalId, setEditApprovalId] = useState(null);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [reassignModalShow, setReassignModalShow] = useState(false);

  //? --> Hooks <--
  useEffect(() => {
    if (approvalId) getApproavalData();
  }, [approvalId]);

  useEffect(() => {
    if (editApprovalId) setShowEditModal(true);
  }, [editApprovalId]);

  //? -->COMPONENTS<--
  const EditApprovalModal = props => {
    //react-form-hook
    const {
      register,
      handleSubmit,
      formState: {errors},
      control,
      reset,
    } = useForm();
    const [projects, setProjects] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [approval, setApproval] = useState({});
    const [serverErrors, setServerErrors] = useState({});
    const [approvers, setApprovers] = useState([]);
    const [attachedFilesPreview, setAttachedFilesPreview] = useState([]);
    const [uploadedAttachments, setUploadedAttachments] = useState([]);
    const [showTask, setShowTask] = useState(true);
    const [requireSwitch, setShowRequireSwitch] = useState(false);
    const [elementCount, setElementCount] = useState(0);
    const [elements, setElements] = useState([]);
    useEffect(() => {
      if (showEditModal) {
        getApprovalData();
        getSelectFieldsData();
      }
    }, []);
    const getApprovalData = () => {
      //showing up loader
      Block.circle('#editModal', 'Loading...');
      const URL = `maxproject/approval/sent_approval/${editApprovalId}`;
      Axios.fetch(URL)
        .then(({data}) => {
          let payload = {
            title: data?.approval?.title,
            project: {
              label: data?.approval?.Project?.name,
              value: data?.approval?.Project?.id,
            },
            task: {
              label: data?.approval?.Task?.name,
              value: data?.approval?.Task?.id,
            },
            priority: {
              label: data?.approval?.Priority?.name,
              value: data?.approval?.Priority?.id,
            },
            approvers: data?.approval?.ApprovalUsers?.map(user => ({
              label: user?.User?.name,
              value: user?.User?.id,
            })),
            desc: data?.approval?.desc,
          };
          if (!data?.approval?.Project) {
            payload.project = {label: 'Other', value: null};
            payload.task = null;
            setShowTask(false);
          }
          setApproval(payload);
          setUploadedAttachments(data?.approval?.ApprovalAttachments);
          reset(payload);
          Block.remove('#editModal');
        })
        .catch(err => {
          console.log(err);
          Block.remove('#editModal');
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };
    const getSelectFieldsData = () => {
      const URL = `maxproject/random/new_approval_select_fields`;
      Axios.fetch(URL)
        .then(({data}) => {
          let projects = data?.projects?.map(el => ({
            value: el?.id,
            label: el?.name,
          }));
          let users = data?.members.map(u => ({
            label: u.name,
            value: u.id,
          }));
          let priorities = data?.priorities.map(u => ({
            label: u.name,
            value: u.id,
          }));
          setApprovers(users);
          setProjects([...projects, {label: 'Other', value: null}]);
          setPriorities(priorities);
        })
        .catch(err => {
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };
    const getProjectTasks = projectId => {
      if (projectId === null) {
        //hiding task field and clearing tasks
        setTasks([]);
        return setShowTask(false);
      }
      //showing task field
      setShowTask(true);
      const URL = `maxproject/task/select_list_type?project_id=${projectId}`;
      Axios.fetch(URL)
        .then(({data}) => {
          let tasks = data?.tasks.map(u => ({
            label: u.name,
            value: u.id,
          }));
          setTasks(tasks);
        })
        .catch(err => {
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };
    const onAttachmentChange = e => {
      let files = e.target.files;
      if (files) {
        let previews = [];
        Array.from(files).forEach(file =>
          previews.push({
            name: file.name,
            url:
              file.type == 'image/jpeg' || file.type == 'image/png'
                ? URL.createObjectURL(file)
                : PdfIcon,
          }),
        );
        setAttachedFilesPreview(previews);
      }
    };
    const handleFormSubmit = (data, e, force = false) => {
      //clearing server errors
      setServerErrors({});
      //creating new formdata
      let formData = new FormData();
      //preparing payload
      let payload = {
        title: data.title,
        project_id: data.project?.value,
        task_id: data.task?.value,
        priority_id: data.priority?.value,
        approvers: JSON.stringify(data.approvers?.map(u => u.value)),
        desc: approval.desc,
      };
      //inserting payload in formdata
      Object.keys(payload).forEach(k => formData.append(k, payload[k]));
      //checking if user uploaded any attachments
      if (data?.attachments.length)
        Array.from(data.attachments).forEach(attachment =>
          formData.append('attachments[]', attachment),
        );
      //showing up loader
      Block.circle('#editModal', 'Saving...');
      //initiating network request
      Axios.put(`maxproject/approval/${editApprovalId}`, formData, {
        'Content-Type': 'multipart/form-data',
      })
        .then(({data}) => {
          Block.remove('#editModal');
          Notify.success(data.message);
          onModalHide();
          refreshApprovalsList();
        })
        .catch(err => {
          //removing loader
          Block.remove('#editModal');
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
          //checking and showing express validation errors
          if (err?.response?.status == StatusCode.ExpressErrors)
            err?.response?.data?.errors.forEach(el =>
              setServerErrors(old => ({...old, [el.param]: el.msg})),
            );
        });
    };
    const handleDeleteAttachment = (i, file) => {
      Confirm.show(
        `Delete ${file.original_name}`,
        `${file.original_name} will be permanently deleted and can't be recovered.`,
        'Delete',
        'Cancel',
        () => {
          //request to delete file from server
          Axios.delete(`maxproject/approval/attachment/${file.id}`).then(
            ({data}) => {
              Notify.success(data.message);
              setUploadedAttachments(old => {
                let arr = old.slice();
                arr.splice(i, 1);
                return arr;
              });
            },
          );
        },
        () => {},
        {},
      );
    };
    const onModalHide = () => {
      setShowEditModal(false);
      setEditApprovalId(null);
    };
    const SwitchHandleChange = () => {
      setShowRequireSwitch(!requireSwitch);
    };
    const SelectType = [
      {value: 'Internal Employee', label: 'Internal Employee'},
      {value: 'Client', label: 'Client'},
      {value: 'Other', label: 'Other'},
    ];
    const UserUi = props => {
      return (
        <div className="assign_to_modal_ui">
          <Avatar
            alt={props.userName}
            src={props.userImg}
            sx={{width: 24, height: 24}}
          />
          <span>{props.userName}</span>
        </div>
      );
    };
    const Users = [
      {
        value: 'sunny charkhwal',
        label: (
          <UserUi
            userName="sunny charkhwal"
            userImg="https://mui.com/static/images/avatar/2.jpg"
          />
        ),
      },
      {
        value: 'kamal',
        label: (
          <UserUi
            userName="kamal "
            userImg="https://mui.com/static/images/avatar/3.jpg"
          />
        ),
      },
      {
        value: 'sunny',
        label: (
          <UserUi
            userName="sunny"
            userImg="https://mui.com/static/images/avatar/4.jpg"
          />
        ),
      },
    ];
    const AntSwitch = styled(Switch)(({theme}) => ({
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
      '&:active': {
        '& .MuiSwitch-thumb': {
          width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: 'translateX(9px)',
        },
      },
      '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
          transform: 'translateX(12px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor:
              theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
          },
        },
      },
      '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
          duration: 200,
        }),
      },
      '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255,255,255,.35)'
            : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
      },
    }));
    const addElement = () => {
      const newElement = {
        id: elementCount,
        text: `Element`,
      };
      setElements([...elements, newElement]);
      setElementCount(elementCount + 1);
    };
    const deleteElement = id => {
      const updatedElements = elements.filter(element => element.id !== id);
      setElements(updatedElements);
    };
    return (
      <Modall
        show={showEditModal}
        onHide={onModalHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body id="editModal" className="modal_body_new">
          <form id="form" onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
              <div className="col-12">
                <div className="approval_request_title">
                  <h3>Edit Approval request</h3>
                  <AiOutlineClose onClick={props?.onHide} />
                </div>
              </div>
              <div className="col-12 approval_request_input">
                <InputBox
                  type="text"
                  placeholder="Title"
                  register={() =>
                    register('title', {
                      required: 'Approval title is required',
                      value: approval?.title,
                    })
                  }
                  errorMessage={errors.title?.message || serverErrors?.title}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <Select
                  options={SelectType}
                  placeholder="Select Type"
                  // defaultValue={project[0]}
                  isSearchable={true}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="dashboard_top_week_Select drop_box">
                  <Controller
                    name="project"
                    control={control}
                    rules={{
                      required: 'Please select a project',
                    }}
                    render={({field}) => (
                      <Select
                        {...field}
                        options={projects}
                        placeholder="Select Project"
                        defaultValue={approval?.project}
                        isSearchable={true}
                        onChange={el => {
                          field.onChange(el);
                          //getting project tasks
                          getProjectTasks(el.value);
                        }}
                      />
                    )}
                  />
                  <small className="error_message">
                    {errors.project?.message || serverErrors?.project}
                  </small>
                </div>
              </div>
              <div
                className="col-12"
                style={{
                  display: showTask ? 'unset' : 'none',
                }}>
                <div className="dashboard_top_week_Select drop_box">
                  <Controller
                    name="task"
                    control={control}
                    rules={{
                      required: showTask ? 'Please select a task' : false,
                    }}
                    render={({field}) => (
                      <Select
                        {...field}
                        options={tasks}
                        placeholder="Select Task"
                        isSearchable={true}
                        onChange={el => field.onChange(el)}
                        defaultValue={approval?.task}
                      />
                    )}
                  />
                  <small className="error_message">
                    {errors.task?.message || serverErrors?.task}
                  </small>
                </div>
              </div>
              {/* <div className="col-12">
                <div className="dashboard_top_week_Select drop_box">
                  <Controller
                    name="priority"
                    control={control}
                    rules={{
                      required: 'Please select a priority',
                    }}
                    render={({field}) => (
                      <Select
                        {...field}
                        options={priorities}
                        placeholder="Select Priority"
                        isSearchable={false}
                        onChange={el => field.onChange(el)}
                        defaultValue={approval?.priority}
                      />
                    )}
                  />
                  <small className="error_message">
                    {errors.priority?.message || serverErrors?.priority}
                  </small>
                </div>
              </div> */}
              <div className="col-12">
                <FormGroup>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <AntSwitch
                      inputProps={{'aria-label': 'ant design'}}
                      checked={requireSwitch}
                      onChange={SwitchHandleChange}
                    />
                    <Typography className="approval_request_input_switch_text">
                      Require a response from all users in the assigned order
                    </Typography>
                  </Stack>
                </FormGroup>
              </div>
              <div className="col-12">
                <div>
                  {requireSwitch ? (
                    <>
                      <div>
                        {elements.map(element => (
                          <div
                            className="row mb-3  "
                            style={{position: 'relative '}}
                            key={element.id}>
                            <div className="col-1">
                              <p className="approval_request_input_add_number">
                                1
                              </p>
                            </div>
                            <div className="col-10 dashboard_top_week_Select drop_box">
                              <Select
                                // isMulti
                                // menuIsOpen
                                options={Users}
                                placeholder="Select Users"
                              />
                            </div>
                            <div className="col-1">
                              <div className="approval_request_input_remove_btn ">
                                <AiOutlineClose
                                  onClick={() => deleteElement(element.id)}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="">
                          <p
                            onClick={addElement}
                            className="approval_request_input_add_btn">
                            <FaUserPlus />
                            Add additional recipient
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="dashboard_top_week_Select drop_box">
                        <Select
                          isMulti
                          // menuIsOpen
                          options={Users}
                          placeholder="Select Users"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="received_list_modal_disabled_input">
                  <InputBox
                    type="text"
                    defaultValue="Medium"
                    disabled
                    className=" opacity-25"
                    label="priority"
                  />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="received_list_modal_disabled_input">
                  <InputBox
                    type="text"
                    defaultValue="low"
                    disabled
                    className=" opacity-25"
                    label="Impact"
                  />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="received_list_modal_disabled_input">
                  <InputBox
                    type="text"
                    defaultValue="auto selected"
                    disabled
                    className=" opacity-25"
                    label="Impact category"
                  />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="received_list_modal_disabled_input">
                  <InputBox
                    type="text"
                    defaultValue="auto selected"
                    disabled
                    className=" opacity-25"
                    label="Impact sub category"
                  />
                </div>
              </div>
              <div className="col-12">
                <NewEditor
                  value={approval?.desc}
                  onChange={text => setApproval(old => ({...old, desc: text}))}
                  placeholder="Desicription "
                />
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-sm-9 col-9">
                    <div className="brief_summery_div ">
                      <h3>Attachments:</h3>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-3 col-3">
                    {/* <div className="documents_app_btn">
                      <input
                        type="file"
                        style={{display: 'none'}}
                        multiple={true}
                        id="attachemnt_input"
                        hidden
                        {...register('attachments', {
                          required: false,
                          onChange: onAttachmentChange,
                        })}
                      />
                      <label
                        className="management_btn_full2"
                        htmlFor="attachemnt_input">
                        <span>Upload Document</span>
                        <BsUpload />
                      </label>
                    </div> */}
                    <div className="new_uplode_btn_div">
                      <AttachmentsMenu />
                    </div>
                  </div>
                  <small className="error_message">
                    {errors.attachments?.message || serverErrors?.attachments}
                  </small>
                </div>
                <div className="doc_img_main_div">
                  <div className="row g-3 mt-2">
                    {attachedFilesPreview.map((val, i) => {
                      return (
                        <FilePreview
                          cols="col-4"
                          key={i}
                          name={val.name}
                          url={val.url}
                        />
                      );
                    })}
                  </div>
                  <div className="row g-3 mt-2">
                    {uploadedAttachments.map((val, i) => {
                      return (
                        <DocumentsAttachments
                          cols="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6"
                          key={i}
                          original_name={val.original_name}
                          url={getFileIcon(val, false)}
                          downloadUrl={getApprovalFileUrl(val.name)}
                          handleDelete={() => handleDeleteAttachment(i, val)}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="dashboard_top_week_Select drop_box drop_box2 scheduling_details_div">
                  <Controller
                    name="approvers"
                    control={control}
                    rules={{
                      required: 'Please select approvers',
                    }}
                    render={({field}) => (
                      <Select
                        {...field}
                        isSearchable={true}
                        onChange={el => field.onChange(el)}
                        defaultValue={approval?.approvers}
                        isMulti
                        options={approvers}
                        placeholder="select approvers"
                      />
                    )}
                  />
                  <small className="error_message">
                    {errors.approvers?.message || serverErrors?.approvers}
                  </small>
                </div>
              </div>
              <div className="col-12">
                <div className="approval_request_btn">
                  <a
                    onClick={onModalHide}
                    style={{cursor: 'pointer'}}
                    className="management_btn_outline">
                    Cancel
                  </a>
                  <button
                    className="management_btn_full2 w-auto"
                    type="submit"
                    form={'form'}>
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modall>
    );
  };
  const ApprovalModal = () => {
    const NewSubTasks = () => {
      const ModalImgView = props => {
        const [open, setOpen] = React.useState(false);
        const [size, setSize] = React.useState();
        const handleOpen = value => {
          setSize(value);
          setOpen(true);
        };
        const handleClose = () => setOpen(false);

        return (
          <>
            <ButtonToolbar>
              <span
                className="modal_img_view_btn"
                size="lg"
                onClick={() => handleOpen('lg')}>
                <FaRegEye />
              </span>
            </ButtonToolbar>
            <Modal size={size} open={open} onClose={handleClose}>
              <Modal.Body
                className="sub_task_modul_body_div"
                style={{maxHeight: '35rem'}}>
                <p className="sub_task_modul_title_div">{props.title}</p>
                <hr />
                <IoMdClose
                  className="sub_task_modul_top_close_btn_div"
                  onClick={handleClose}
                />
                {/* ui */}
                {props.modalUi}
                {/* ui end */}
              </Modal.Body>
              <Modal.Footer>
                <div className="mt-3 d-flex align-items-center justify-content-end approval_modal_btn">
                  <div className="me-2">
                    <OutlineBtn onClick={handleClose} name="Cancel" />
                  </div>
                  <div className=" ms-2">
                    <a
                      className="management_btn_full2 sub_task_modul_download_btn"
                      href={props.downloadItem}
                      download>
                      download
                    </a>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        );
      };
      const ModalViewFeedback = props => {
        const [open, setOpen] = React.useState(false);
        const [size, setSize] = React.useState();
        const handleOpen = value => {
          setSize(value);
          setOpen(true);
        };
        const handleClose = () => setOpen(false);

        return (
          <>
            <ButtonToolbar>
              <BootstrapTooltip title="View feedback" placement="top">
                <label
                  size="sm"
                  className="show_btn ms-0"
                  onClick={() => handleOpen('sm')}>
                  <MdOutlineMessage />
                </label>
              </BootstrapTooltip>
            </ButtonToolbar>
            <Modal size={size} open={open} onClose={handleClose}>
              <Modal.Body
                className="sub_task_modul_body_div"
                style={{maxHeight: '35rem'}}>
                <p className="sub_task_modul_title_div">
                  Final Status: Approved
                </p>
                <hr />
                <IoMdClose
                  className="sub_task_modul_top_close_btn_div"
                  onClick={handleClose}
                />
                {/* ui */}
                <ul className="feedback_modal_ul">
                  <li>
                    <img
                      className="feedback_modal_ul_icon"
                      src="https://i.pravatar.cc/150?img=12"
                      alt="ok"
                    />
                    <div className="feedback_modal_ul_main_div">
                      <span
                        className="feedback_modal_ul_requested"
                        style={{
                          backgroundColor: '#e8f2fc',
                          borderColor: '#1a7fdd',
                        }}>
                        Requested
                      </span>
                      <p className="feedback_modal_ul_name">
                        Deependra Bhaskar
                        <span>(Design Team Lead)</span>
                      </p>
                      <p className="feedback_modal_ul_commant">
                        <img src={CommantIcon} alt="icon" />
                        <span>
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Corrupti eum laudantium ad quo eligendi tempore
                          totam consectetur, voluptate est fugit iure sed
                          recusandae temporibus optio reprehenderit? Modi
                          tempore molestiae voluptatem.
                        </span>
                        <IoMdCopy className="feedback_modal_ul_commant_coyp_btn" />
                      </p>
                      <div>
                        <div className="add_new_subtask_Uploaded_mai_div">
                          <div className="item">
                            <div className="add_new_subtask_Uploaded_img_div">
                              <img src={DemoImg} alt="Uploaded photo" />
                              <p>
                                {'Get a specific image by adding'.length > 15
                                  ? 'Get a specific image by adding'?.substr(
                                      0,
                                      15,
                                    ) + '...'
                                  : 'Get a specific image by adding'}
                              </p>

                              <div className="add_new_subtask_Uploaded_img_div_overlay">
                                <div className="modal_img_view_btn_div">
                                  <a
                                    className="modal_img_view_btn me-1"
                                    href={DemoImg}
                                    download>
                                    <MdFileDownload />
                                  </a>
                                  <ModalImgView
                                    title="photo demo title"
                                    downloadItem={DemoImg}
                                    modalUi={
                                      <>
                                        <img
                                          className="sub_task_modul_img_div"
                                          src={DemoImg}
                                          alt="img"
                                        />
                                      </>
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="item">
                            <div className="add_new_subtask_Uploaded_img_div">
                              <img
                                src="https://img.freepik.com/premium-vector/open-folder-icon-with-pdf-file-inside-folder-with-documents-blue-background-flat-design-vector-illustration_476325-459.jpg?w=1060"
                                alt="Uploaded photo"
                              />
                              <p>
                                {'pdf title'.length > 15
                                  ? 'pdf title'?.substr(0, 15) + '...'
                                  : 'pdf title'}
                              </p>

                              <div className="add_new_subtask_Uploaded_img_div_overlay">
                                <div className="modal_img_view_btn_div">
                                  <a
                                    className="modal_img_view_btn me-1"
                                    href={DemoImg}
                                    download>
                                    <MdFileDownload />
                                  </a>
                                  <ModalImgView
                                    title="pdf demo title"
                                    downloadItem={DemoImg}
                                    modalUi={
                                      <>
                                        <object
                                          data={DemoPdf}
                                          style={{
                                            width: '100%',
                                            height: '35rem',
                                          }}></object>
                                      </>
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="feedback_modal_ul_time">
                        <TbCalendarPlus />
                        11/24/2023, 12:24:00 PM
                      </p>
                    </div>
                  </li>
                  <li>
                    <img
                      className="feedback_modal_ul_icon"
                      src="https://i.pravatar.cc/150?img=12"
                      alt="ok"
                    />
                    <div className="feedback_modal_ul_main_div">
                      <span
                        className="feedback_modal_ul_requested"
                        style={{
                          backgroundColor: '#e8f2fc',
                          borderColor: '#39C222',
                        }}>
                        Approved
                      </span>
                      <p className="feedback_modal_ul_name">
                        Deependra Bhaskar
                        <span>(Design Team Lead)</span>
                      </p>
                      <p className="feedback_modal_ul_commant">
                        <img src={CommantIcon} alt="icon" />
                        <span>
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Corrupti eum laudantium ad quo eligendi tempore
                          totam consectetur, voluptate est fugit iure sed
                          recusandae temporibus optio reprehenderit? Modi
                          tempore molestiae voluptatem.
                        </span>
                        <IoMdCopy className="feedback_modal_ul_commant_coyp_btn" />
                      </p>
                      <div>
                        <div className="add_new_subtask_Uploaded_mai_div">
                          <div className="item">
                            <div className="add_new_subtask_Uploaded_img_div">
                              <img src={DemoImg} alt="Uploaded photo" />
                              <p>
                                {'Get a specific image by adding'.length > 15
                                  ? 'Get a specific image by adding'?.substr(
                                      0,
                                      15,
                                    ) + '...'
                                  : 'Get a specific image by adding'}
                              </p>

                              <div className="add_new_subtask_Uploaded_img_div_overlay">
                                <div className="modal_img_view_btn_div">
                                  <a
                                    className="modal_img_view_btn me-1"
                                    href={DemoImg}
                                    download>
                                    <MdFileDownload />
                                  </a>
                                  <ModalImgView
                                    title="photo demo title"
                                    downloadItem={DemoImg}
                                    modalUi={
                                      <>
                                        <img
                                          className="sub_task_modul_img_div"
                                          src={DemoImg}
                                          alt="img"
                                        />
                                      </>
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="item">
                            <div className="add_new_subtask_Uploaded_img_div">
                              <img
                                src="https://img.freepik.com/premium-vector/open-folder-icon-with-pdf-file-inside-folder-with-documents-blue-background-flat-design-vector-illustration_476325-459.jpg?w=1060"
                                alt="Uploaded photo"
                              />
                              <p>
                                {'pdf title'.length > 15
                                  ? 'pdf title'?.substr(0, 15) + '...'
                                  : 'pdf title'}
                              </p>

                              <div className="add_new_subtask_Uploaded_img_div_overlay">
                                <div className="modal_img_view_btn_div">
                                  <a
                                    className="modal_img_view_btn me-1"
                                    href={DemoImg}
                                    download>
                                    <MdFileDownload />
                                  </a>
                                  <ModalImgView
                                    title="pdf demo title"
                                    downloadItem={DemoImg}
                                    modalUi={
                                      <>
                                        <object
                                          data={DemoPdf}
                                          style={{
                                            width: '100%',
                                            height: '35rem',
                                          }}></object>
                                      </>
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="feedback_modal_ul_time">
                        <TbCalendarPlus />
                        11/24/2023, 12:24:00 PM
                      </p>
                    </div>
                  </li>
                </ul>
                {/* ui end */}
              </Modal.Body>
            </Modal>
          </>
        );
      };
      return (
        <>
          <div className="row ">
            <div className="col-12">
              <div className="row mt-0 g-3">
                <div className="col-xxl-8 col-xl-8 col-lg-8 col-lg-8 col-md-8 col-sm-12 col-12">
                  <div className="add_new_subtask_menu_div_top">
                    <span className="add_new_subtask_ui_title mt-0 me-3">
                      Subtasks
                    </span>
                  </div>
                </div>
              </div>
              {/*subtask data view ui */}
              <div className="task_scrol_div1">
                <div className="add_new_subtask_top_ui_div p-0 task_scrol_div2">
                  {/* item header */}
                  <div className="row">
                    <div className="col-6">
                      <div className=" new_task_detail_top_title_one">
                        <span className="small">Subtask Name</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="new_task_detail_top_title_two">
                        <span className="small">Duration</span>
                      </div>
                    </div>
                  </div>
                  {/* item header end */}
                  <div className="sub_task_scroll_new">
                    <div className="row m-0 ">
                      {/* item view */}
                      <div className="col-12 p-0 ">
                        <div
                          className="row m-0 task_detail_top_main_div"
                          style={{paddingLeft: '30px'}}>
                          <div className="col-12 p-0">
                            <div className="row m-0">
                              <div className="col-6 p-0 task_detail_text_div_top_col">
                                <div className="task_detail_text_div">
                                  <span>
                                    <img
                                      className="task_icon"
                                      src={SubTaskIcon}
                                      alt="icon"
                                    />
                                  </span>
                                  <div>
                                    <p>This is the second dummy subtask</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0 ">
                                <div className="task_detail_main_btn_div">
                                  <ModalViewFeedback />
                                  <p className="add_new_subtask_date_picker_show_div">
                                    <TbCalendarPlus />
                                    Jan 24 - Feb 3
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="add_new_subtask_Uploaded_mai_div">
                                <div className="item item_new3">
                                  <div className="add_new_subtask_Uploaded_img_div">
                                    <img src={DemoImg} alt="Uploaded photo" />
                                    <p>
                                      {'Get a specific image by adding'.length >
                                      15
                                        ? 'Get a specific image by adding'?.substr(
                                            0,
                                            15,
                                          ) + '...'
                                        : 'Get a specific image by adding'}
                                    </p>

                                    <div className="add_new_subtask_Uploaded_img_div_overlay">
                                      <div className="modal_img_view_btn_div">
                                        <a
                                          className="modal_img_view_btn me-1"
                                          href={DemoImg}
                                          download>
                                          <MdFileDownload />
                                        </a>
                                        <ModalImgView
                                          title="photo demo title"
                                          downloadItem={DemoImg}
                                          modalUi={
                                            <>
                                              <img
                                                className="sub_task_modul_img_div"
                                                src={DemoImg}
                                                alt="img"
                                              />
                                            </>
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="item item_new3">
                                  <div className="add_new_subtask_Uploaded_img_div">
                                    <img
                                      src="https://img.freepik.com/premium-vector/open-folder-icon-with-pdf-file-inside-folder-with-documents-blue-background-flat-design-vector-illustration_476325-459.jpg?w=1060"
                                      alt="Uploaded photo"
                                    />
                                    <p>
                                      {'pdf title'.length > 15
                                        ? 'pdf title'?.substr(0, 15) + '...'
                                        : 'pdf title'}
                                    </p>

                                    <div className="add_new_subtask_Uploaded_img_div_overlay">
                                      <div className="modal_img_view_btn_div">
                                        <a
                                          className="modal_img_view_btn me-1"
                                          href={DemoImg}
                                          download>
                                          <MdFileDownload />
                                        </a>
                                        <ModalImgView
                                          title="pdf demo title"
                                          downloadItem={DemoImg}
                                          modalUi={
                                            <>
                                              <object
                                                data={DemoPdf}
                                                style={{
                                                  width: '100%',
                                                  height: '35rem',
                                                }}></object>
                                            </>
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* item view end */}
                    </div>
                  </div>
                </div>
                <Stack spacing={2} className=" align-items-end mt-2">
                  <Pagination count={10} />
                </Stack>
              </div>
              {/*subtask data view ui end */}
            </div>
          </div>
        </>
      );
    };
    const BootstrapTooltip = styled(({className, ...props}) => (
      <Tooltip {...props} arrow classes={{popper: className}} />
    ))(({theme}) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
      },
    }));
    const Participants = [
      {
        name: 'sunny charkhwal',
        Designation: 'react js devloper',
        img: 'https://mui.com/static/images/avatar/7.jpg',
      },
      {
        name: 'sunny charkhwal',
        Designation: 'react js devloper',
        img: 'https://mui.com/static/images/avatar/7.jpg',
      },
      {
        name: 'sunny charkhwal',
        Designation: 'react js devloper',
        img: 'https://mui.com/static/images/avatar/7.jpg',
      },
      {
        name: 'sunny charkhwal',
        Designation: 'react js devloper',
        img: 'https://mui.com/static/images/avatar/7.jpg',
      },
      {
        name: 'sunny charkhwal',
        Designation: 'react js devloper',
        img: 'https://mui.com/static/images/avatar/7.jpg',
      },
    ];
    const ModalImgView = props => {
      const [open, setOpen] = React.useState(false);
      const [size, setSize] = React.useState();
      const handleOpen = value => {
        setSize(value);
        setOpen(true);
      };
      const handleClose = () => setOpen(false);

      return (
        <>
          <ButtonToolbar>
            <span
              className="modal_img_view_btn"
              size="lg"
              onClick={() => handleOpen('lg')}>
              <FaRegEye />
            </span>
          </ButtonToolbar>
          <Modal size={size} open={open} onClose={handleClose}>
            <Modal.Body
              className="sub_task_modul_body_div"
              style={{maxHeight: '35rem'}}>
              <p className="sub_task_modul_title_div">{props.title}</p>
              <hr />
              <IoMdClose
                className="sub_task_modul_top_close_btn_div"
                onClick={handleClose}
              />
              {/* ui */}
              {props.modalUi}
              {/* ui end */}
            </Modal.Body>
            <Modal.Footer>
              <div className="mt-3 d-flex align-items-center justify-content-end approval_modal_btn">
                <div className="me-2">
                  <OutlineBtn onClick={handleClose} name="Cancel" />
                </div>
                <div className=" ms-2">
                  <a
                    className="management_btn_full2 sub_task_modul_download_btn"
                    href={props.downloadItem}
                    download>
                    download
                  </a>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      );
    };
    return (
      <Modall
        show={showApprovalModal}
        onHide={handleModalHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="modal_body_new">
          <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
            <div className="col-12">
              <div className="approval_request_title">
                <ul className="approval_modal_top_title_div">
                  <li>
                    <img
                      src="http://localhost:3000/static/media/ap.b1f2388215dc8374d7f43ac194bba632.svg"
                      alt="icon"
                    />
                  </li>
                  <li>
                    <p>Approval request</p>
                    <span>Approval request details</span>
                  </li>
                  <li>
                    <div>Approved</div>
                  </li>
                </ul>
                <AiOutlineClose onClick={handleModalHide} />
              </div>
            </div>
            <div className="col-12">
              <div className="row rec_list_tb">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 py-3 rec_list_mid">
                  <div className="received_list_modal_top_div">
                    <h3>{approval?.title}</h3>

                    <div className="approval_modal_sub_title_div2">
                      <ul>
                        <li className="li_first">Date</li>
                        <li>: {moment(approval?.createdAt).calendar()}</li>
                      </ul>
                      <ul>
                        <li className="li_first">Created By</li>
                        <li>: {approval?.User?.name}</li>
                      </ul>
                      <ul>
                        <li className="li_first">Type</li>
                        <li>: Client</li>
                      </ul>
                      <ul>
                        <li className="li_first">Project</li>
                        <li>: Lorem ipsum</li>
                      </ul>
                      {approval?.Task ? (
                        <ul>
                          <li className="li_first">Task</li>
                          <li>: {approval?.Task?.name}</li>
                        </ul>
                      ) : null}
                      <ul>
                        <li className="li_first">Task start date</li>
                        <li>: Lorem ipsum</li>
                      </ul>
                      <ul>
                        <li className="li_first">Task end date</li>
                        <li>: Lorem ipsum</li>
                      </ul>
                      <ul>
                        <li className="li_first">Assign to</li>
                        <li className=" d-flex">
                          <small style={{marginRight: '5px'}}>:</small>
                          <div className="Participants_main_div_inner">
                            <UserParticipants data={Participants} />
                          </div>
                        </li>
                      </ul>

                      <ul>
                        <li className="li_first">Owner</li>
                        <li className=" d-flex">
                          <small style={{marginRight: '5px'}}>:</small>
                          <Avatar
                            alt="sunny"
                            src="https://maxcrm.projectmaxlence.com.au/uploads/users/profile_images/sunny.png"
                            sx={{width: 24, height: 24}}
                          />
                        </li>
                      </ul>
                      <ul>
                        <li className="li_first">Priority</li>
                        <li>: Low/Medium/High</li>
                      </ul>
                      <ul>
                        <li className="li_first">Impact</li>
                        <li>
                          :
                          <span
                            style={{
                              backgroundColor: '#F3D32C',
                              color: 'white',
                            }}>
                            low
                          </span>
                        </li>
                      </ul>
                      <ul>
                        <li className="li_first">Impact Category</li>
                        <li>: Lorem ipsum</li>
                      </ul>
                      <ul>
                        <li className="li_first">Impact Sub category</li>
                        <li>: Lorem ipsum</li>
                      </ul>
                    </div>
                    <h4>Description:</h4>
                    <div style={{wordBreak: 'break-all'}}>
                      {ReactHtmlParser(approval?.desc)}
                    </div>
                    {approval?.ApprovalAttachments?.length ? (
                      <>
                        <h4 className="mt-3">Attachments:</h4>
                        <div className="doc_img_main_div">
                          <div className="row g-3 mt-2">
                            {approval?.ApprovalAttachments?.map((val, i) => {
                              return (
                                <DocumentsAttachments
                                  key={i}
                                  cols="col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-6 col-6"
                                  original_name={val?.original_name}
                                  url={getFileIcon(val, false)}
                                  downloadUrl={getApprovalFileUrl(val.name)}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 py-3">
                  <div className="received_list_modal_top_div">
                    <h3>Status</h3>
                  </div>
                  <div className="received_list_modal_timeline_uper_div">
                    <ul className=" approval_request_details_feedback_ul">
                      <li>
                        <img
                          className="feedback_modal_ul_icon"
                          src="https://i.pravatar.cc/150?img=12"
                          alt="ok"
                        />
                        <div className="feedback_modal_ul_main_div">
                          <span
                            className="feedback_modal_ul_requested"
                            style={{
                              backgroundColor: '#e8f2fc',
                              borderColor: '#1a7fdd',
                            }}>
                            Requested
                          </span>
                          <p className="feedback_modal_ul_name">
                            Deependra Bhaskar
                            <span>(Design Team Lead)</span>
                          </p>
                          <p className="feedback_modal_ul_commant">
                            <img src={CommantIcon} alt="icon" />
                            <span>
                              Lorem ipsum dolor sit amet consectetur,
                              adipisicing elit. Corrupti eum laudantium ad quo
                              eligendi tempore totam consectetur, voluptate est
                              fugit iure sed recusandae temporibus optio
                              reprehenderit? Modi tempore molestiae voluptatem.
                            </span>
                            <IoMdCopy className="feedback_modal_ul_commant_coyp_btn" />
                          </p>
                          <div>
                            <div className="add_new_subtask_Uploaded_mai_div">
                              <div className="item">
                                <div className="add_new_subtask_Uploaded_img_div">
                                  <img src={DemoImg} alt="Uploaded photo" />
                                  <p>
                                    {'Get a specific image by adding'.length >
                                    15
                                      ? 'Get a specific image by adding'?.substr(
                                          0,
                                          15,
                                        ) + '...'
                                      : 'Get a specific image by adding'}
                                  </p>

                                  <div className="add_new_subtask_Uploaded_img_div_overlay">
                                    <div className="modal_img_view_btn_div">
                                      <a
                                        className="modal_img_view_btn me-1"
                                        href={DemoImg}
                                        download>
                                        <MdFileDownload />
                                      </a>
                                      <ModalImgView
                                        title="photo demo title"
                                        downloadItem={DemoImg}
                                        modalUi={
                                          <>
                                            <img
                                              className="sub_task_modul_img_div"
                                              src={DemoImg}
                                              alt="img"
                                            />
                                          </>
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="item">
                                <div className="add_new_subtask_Uploaded_img_div">
                                  <img
                                    src="https://img.freepik.com/premium-vector/open-folder-icon-with-pdf-file-inside-folder-with-documents-blue-background-flat-design-vector-illustration_476325-459.jpg?w=1060"
                                    alt="Uploaded photo"
                                  />
                                  <p>
                                    {'pdf title'.length > 15
                                      ? 'pdf title'?.substr(0, 15) + '...'
                                      : 'pdf title'}
                                  </p>

                                  <div className="add_new_subtask_Uploaded_img_div_overlay">
                                    <div className="modal_img_view_btn_div">
                                      <a
                                        className="modal_img_view_btn me-1"
                                        href={DemoImg}
                                        download>
                                        <MdFileDownload />
                                      </a>
                                      <ModalImgView
                                        title="pdf demo title"
                                        downloadItem={DemoImg}
                                        modalUi={
                                          <>
                                            <object
                                              data={DemoPdf}
                                              style={{
                                                width: '100%',
                                                height: '35rem',
                                              }}></object>
                                          </>
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="feedback_modal_ul_time">
                            <TbCalendarPlus />
                            11/24/2023, 12:24:00 PM
                          </p>
                        </div>
                      </li>
                      <li>
                        <img
                          className="feedback_modal_ul_icon"
                          src="https://i.pravatar.cc/150?img=12"
                          alt="ok"
                        />
                        <div className="feedback_modal_ul_main_div">
                          <span
                            className="feedback_modal_ul_requested"
                            style={{
                              backgroundColor: '#e8f2fc',
                              borderColor: '#1a7fdd',
                            }}>
                            Requested
                          </span>
                          <p className="feedback_modal_ul_name">
                            Deependra Bhaskar
                            <span>(Design Team Lead)</span>
                          </p>
                          <p className="feedback_modal_ul_commant">
                            <img src={CommantIcon} alt="icon" />
                            <span>
                              Lorem ipsum dolor sit amet consectetur,
                              adipisicing elit. Corrupti eum laudantium ad quo
                              eligendi tempore totam consectetur, voluptate est
                              fugit iure sed recusandae temporibus optio
                              reprehenderit? Modi tempore molestiae voluptatem.
                            </span>
                            <IoMdCopy className="feedback_modal_ul_commant_coyp_btn" />
                          </p>
                          <div>
                            <div className="add_new_subtask_Uploaded_mai_div">
                              <div className="item">
                                <div className="add_new_subtask_Uploaded_img_div">
                                  <img src={DemoImg} alt="Uploaded photo" />
                                  <p>
                                    {'Get a specific image by adding'.length >
                                    15
                                      ? 'Get a specific image by adding'?.substr(
                                          0,
                                          15,
                                        ) + '...'
                                      : 'Get a specific image by adding'}
                                  </p>

                                  <div className="add_new_subtask_Uploaded_img_div_overlay">
                                    <div className="modal_img_view_btn_div">
                                      <a
                                        className="modal_img_view_btn me-1"
                                        href={DemoImg}
                                        download>
                                        <MdFileDownload />
                                      </a>
                                      <ModalImgView
                                        title="photo demo title"
                                        downloadItem={DemoImg}
                                        modalUi={
                                          <>
                                            <img
                                              className="sub_task_modul_img_div"
                                              src={DemoImg}
                                              alt="img"
                                            />
                                          </>
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="item">
                                <div className="add_new_subtask_Uploaded_img_div">
                                  <img
                                    src="https://img.freepik.com/premium-vector/open-folder-icon-with-pdf-file-inside-folder-with-documents-blue-background-flat-design-vector-illustration_476325-459.jpg?w=1060"
                                    alt="Uploaded photo"
                                  />
                                  <p>
                                    {'pdf title'.length > 15
                                      ? 'pdf title'?.substr(0, 15) + '...'
                                      : 'pdf title'}
                                  </p>

                                  <div className="add_new_subtask_Uploaded_img_div_overlay">
                                    <div className="modal_img_view_btn_div">
                                      <a
                                        className="modal_img_view_btn me-1"
                                        href={DemoImg}
                                        download>
                                        <MdFileDownload />
                                      </a>
                                      <ModalImgView
                                        title="pdf demo title"
                                        downloadItem={DemoImg}
                                        modalUi={
                                          <>
                                            <object
                                              data={DemoPdf}
                                              style={{
                                                width: '100%',
                                                height: '35rem',
                                              }}></object>
                                          </>
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="feedback_modal_ul_time">
                            <TbCalendarPlus />
                            11/24/2023, 12:24:00 PM
                          </p>
                        </div>
                      </li>
                    </ul>
                    {/* <ul className="received_list_modal_timeline">
                      <li>
                        <img
                          className="received_list_modal_timeline_title_img"
                          src={Ch2}
                          alt="ok"
                        />
                        <h3>
                          <span>Requested</span>
                          <br />
                          {approval?.User?.name} <span>(UI/UX Designer)</span>
                          <br />
                          <small className="received_list_modal_date_time">
                            11/24/2023, 12:24:00 PM
                          </small>
                        </h3>
                        <div className="received_list_modal_timeline_comm">
                          <img src={Line} alt="img" />
                          <p>
                            {approval?.User?.name} Submitted the request <br />
                            <span>
                              {moment(approval?.createdAt).calendar()}
                            </span>
                          </p>
                        </div>
                      </li>
                      {approval?.ApprovalUsers?.map((user, i) => {
                        return (
                          <li key={i}>
                            <img
                              className="received_list_modal_timeline_title_img"
                              src={user.status == 'APPROVED' ? Ch2 : Ch}
                              alt="ok"
                            />
                            <h3>
                              <span style={{textTransform: 'capitalize'}}>
                                {user.status}
                              </span>
                              <br />
                              {user?.User?.name} <span>(UI/UX Designer)</span>
                            </h3>
                            <div
                              className="received_list_modal_timeline_comm"
                              style={{display: 'block'}}>
                              <img src={Line} alt="img" />
                              {user?.comment ? (
                                <p>
                                  {user?.comment} <br />
                                  <span>
                                    {moment(user?.commentDate).calendar()}
                                  </span>
                                </p>
                              ) : null}
                            </div>
                          </li>
                        );
                      })}
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <Accordionn className="approval_modal_sub_title_Description_top_div2 mt-0">
                <div className="approval_modal_sub_title_Description_div">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon className="pm_icon" />}
                    aria-controls="panel1-content"
                    id="panel1-header"></AccordionSummary>
                  <div className="ms-1 approval_modal_sub_title_Description_new mt-0 mb-0">
                    Subtasks
                  </div>
                </div>
                <AccordionDetails>
                  <NewSubTasks />
                </AccordionDetails>
              </Accordionn>
            </div>
            {approval?.status == 'PENDING' ? (
              <div className="col-12">
                <div className="approval_request_btn2 justify-content-center">
                  <OutlineBtn
                    onClick={() => handleCancelRequest(approval.id)}
                    name="Cancel request"
                  />
                  <FullBtn
                    onClick={() => handleFollowUpClick(approval.id)}
                    name="Follow Up"
                  />
                </div>
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modall>
    );
  };
  //? --> Functions <--
  const handleEditApproval = approvalId => {
    setEditApprovalId(approvalId);
  };
  const getApproavalData = () => {
    //showing up loader
    Block.circle('.sent_table', 'Loading...');
    const URL = `maxproject/approval/sent_approval/${approvalId}`;
    Axios.fetch(URL)
      .then(({data}) => {
        setApproval(data?.approval);
        setShowApprovalModal(true);
        //hiding loader
        Block.remove('.sent_table');
      })
      .catch(err => {
        //hiding loader
        Block.remove('.sent_table');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handlePagination = (e, page) => {
    setPage(page);
  };
  const handleRejection = (id, i) => {
    Block.dots(`#sent_approval_${id}`);
    //initiatalizing network request
    Axios.put('maxproject/approval/reject/' + id)
      .then(({data}) => {
        Block.remove(`#sent_approval_${id}`);
        Notify.success(data?.message);
        //updating current approval state
        let arr = approvals.slice();
        arr[i].isRejected = 1;
        setApprovals(arr);
      })
      .catch(err => {
        Block.remove(`#sent_approval_${id}`);
      });
  };
  const handleModalHide = () => {
    setShowApprovalModal(false);
    setApprovalId(null);
    setApproval({});
  };
  const handleFollowUpClick = (approvalId, showModal = true) => {
    setShowApprovalModal(false);
    Confirm.show(
      `Ask for follow up?`,
      `This action is undonable!`,
      'Follow up',
      'Cancel',
      () => {
        //request to delete file from server
        Axios.post(`maxproject/approval/followup/${approvalId}`)
          .then(({data}) => {
            handleModalHide();
            Notify.success(data.message);
          })
          .catch(err => {
            setShowApprovalModal(true);
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      },
      () => {
        setShowApprovalModal(showModal);
      },
      {},
    );
  };
  const handleCancelRequest = (approvalId, showModal = true) => {
    setShowApprovalModal(false);
    Confirm.show(
      `Cancel request?`,
      `This action is undonable!`,
      'Yes',
      'No',
      () => {
        //request to delete file from server
        Axios.delete(`maxproject/approval/${approvalId}`)
          .then(({data}) => {
            handleModalHide();
            refreshApprovalsList();
            Notify.success(data.message);
          })
          .catch(err => {
            setShowApprovalModal(true);
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      },
      () => {
        setShowApprovalModal(showModal);
      },
      {},
    );
  };
  const handleResendApproval = approvalId => {
    Confirm.show(
      `Resend approval request?`,
      `This action is undonable!`,
      'Resend',
      'Cancel',
      () => {
        //request to delete file from server
        Axios.post(`maxproject/approval/resend/${approvalId}`)
          .then(({data}) => {
            refreshApprovalsList();
            Notify.success(data.message);
          })
          .catch(err => {
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      },
      () => {},
      {},
    );
  };
  const ApprovalTr = props => {
    const SentListDropDown = () => {
      const [anchorEl, setAnchorEl] = useState(null);
      const open = Boolean(anchorEl);
      const handleClick = event => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
      const ITEM_HEIGHT = 30;

      return (
        <div className="chat_option">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            className=" m-0 p-0">
            <BiDotsHorizontalRounded />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '15ch',
              },
            }}>
            {props?.status == 'PENDING' ? (
              <>
                <MenuItem onClick={() => handleFollowUpClick(props.id, false)}>
                  <div className="chat_option_name_div">Follow Up</div>
                </MenuItem>
                <MenuItem onClick={() => handleCancelRequest(props.id, false)}>
                  <div className="chat_option_name_div">Cancel</div>
                </MenuItem>
              </>
            ) : null}
            {props?.status == 'REJECTED' ? (
              <MenuItem onClick={() => handleResendApproval(props.id)}>
                <div className="chat_option_name_div">Resend Approval</div>
              </MenuItem>
            ) : null}
            {/* <MenuItem onClick={() => handleEditApproval(props.id)}>
              <div className="chat_option_name_div">edit</div>
            </MenuItem> */}
          </Menu>
        </div>
      );
    };
    const navigate = useNavigate();
    function PageLink() {
      navigate('/Management/sent_approval/' + props.id);
    }
    return (
      <tr
        className="progress_all_tabel_tr_mor"
        id={`sent_approval_${props?.id}`}>
        <td>
          <div className="Participants_main_div_tr ">{props?.idx + 1}</div>
        </td>
        <td>
          {/* /Management/send_lists/ */}
          <div
            className="Participants_main_div_tr list_link_title"
            onClick={PageLink}>
            {props?.title?.length > 30
              ? props?.title.substr(0, 30) + '...'
              : props?.title}
          </div>
        </td>
        <td className="project_list_name">
          <div
            className="project_list_priority_div"
            style={{backgroundColor: props?.Priority?.bg_color}}>
            <span className="project_list_priority">
              {props?.Priority?.name}
            </span>
          </div>
        </td>
        <td>
          <div
            className="Participants_main_div_tr"
            // style={{color: props?.StatusColor}}
          >
            {props?.status}
          </div>
        </td>
        <td>
          <div className="Participants_main_div_tr">
            {props?.Project ? (
              <NavLink to={`/Management/project_details/${props?.Project?.id}`}>
                {props?.Project?.name}
              </NavLink>
            ) : (
              'N/A'
            )}
          </div>
        </td>
        <td className="Participants_main_div">
          <div className="Participants_main_div_inner">
            <UserParticipants data={props?.ApprovalUsers} />
          </div>
        </td>
        <td>
          <div className="Participants_main_div_tr">
            {moment(props?.createdAt).calendar()}
          </div>
        </td>
        <td>
          <div className="mt-1">
            <div className="progress_all_option_icon">
              <SentListDropDown />
            </div>
          </div>
        </td>
      </tr>
    );
  };
  if (!approvals.length) return <NoDataView />;
  return (
    <>
      <EditApprovalModal />
      <ApprovalModal />
      {/* <ReassignModal /> */}
      <div className="dashbaord_white_card approval_list" id="approval_list">
        <div>
          <table className="table table-hover text-capitalize progress_all_tabel_tabel sent_table">
            <thead>
              <tr className="progress_all_tabel_tr">
                <th scope="col">S. No.</th>
                <th scope="col">Request Title</th>
                <th scope="col">Priority</th>
                <th scope="col">Status</th>
                <th scope="col">Project Name</th>
                <th scope="col">Sent to</th>
                <th scope="col">date</th>
                <th scope="col">action</th>
              </tr>
            </thead>
            <tbody>
              {approvals?.slice((page - 1) * 10, 10 * page)?.map((val, i) => (
                <ApprovalTr idx={i} key={i} {...val} arr={val} />
              ))}
            </tbody>
          </table>
        </div>
        {approvals?.length > 10 ? (
          <div className="projec_tabelt_list_pagination_div">
            <Stack spacing={2}>
              <Pagination
                onChange={handlePagination}
                count={Math.ceil(approvals?.length / 10)}
              />
            </Stack>
          </div>
        ) : null}
      </div>
    </>
  );
};
const NoDataView = () => {
  return (
    <div className="row">
      <div className="col-12">
        <div
          style={{
            minHeight: '30rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '2rem',
          }}>
          <Img
            backgroundImage={NoResultsSVG}
            style={{
              height: 250,
              width: 250,
            }}
            className="project_summary_card_icon"
          />
          <span className="mb-3 w-50">
            Sorry, We couldn't find any details that match your filter criteria.
            Please consider clearing your filter or refining your criteria for
            better results.
          </span>
        </div>
      </div>
    </div>
  );
};
const getFileIcon = (file, crateImageObject = true) => {
  switch (file.type) {
    case 'image/jpeg':
      return crateImageObject
        ? URL.createObjectURL(file)
        : getApprovalFileUrl(file.name);
    case 'image/png':
      return crateImageObject
        ? URL.createObjectURL(file)
        : getApprovalFileUrl(file.name);
    case 'application/pdf':
      return CommentsPdfIcon;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return CommentsPdfIcon2;
    case 'application/ppx':
      return CommentsPdfIcon3;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return CommentsPdfIcon4;
    case 'application/zip':
      return CommentsPdfIcon5;
    default:
      return CommentsPdfIcon5;
  }
};
