import moment from 'moment';
import {toHoursAndMinutes} from '../../../../../../../constants/helpers';

const UserBreks = props => (
  <div className="user_breks_row">
    <div className="row m-0">
      <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
        <img src={props.icon} height={20} width={20} />
      </div>
      <div className="col-6">
        <p>{props.title}</p>
        <small style={{color: 'gray', fontSize:10}}>
          {moment(props.createdAt).calendar()}
        </small>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
        <p>{toHoursAndMinutes(props.time)}</p>
      </div>
    </div>
  </div>
);
export default UserBreks;
