import {PageAllTopTitle} from '../../../../common/ManagementComponents/PageAllTopTitle';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {BsArrowLeft} from 'react-icons/bs';
import {BiTimeFive} from 'react-icons/bi';
import {AiTwotoneCopy} from 'react-icons/ai';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SnoozeIcon from '@mui/icons-material/Snooze';
import LinkIcon from '@mui/icons-material/Link';
import AvatarGroup from '@mui/material/AvatarGroup';
import {
  MdDone,
  MdOutlineEdit,
  MdOutlineSupervisedUserCircle,
} from 'react-icons/md';
import {UserParticipants} from '../../../../common/ManagementComponents/Participants';
import {useParams} from 'react-router-dom';
import {Block, Notify, Report} from 'notiflix';
import ReactHtmlParser from 'react-html-parser';
import Axios from './../../../../../config/api';
import moment from 'moment';
//
import React, {useState, useRef, useEffect, useCallback} from 'react';
import {FaPlus, FaCircleNotch} from 'react-icons/fa';
import {GoDotFill} from 'react-icons/go';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import {BsFillCircleFill} from 'react-icons/bs';
import {AiFillCaretDown} from 'react-icons/ai';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useNavigate} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Avatar from '@mui/material/Avatar';
import {Confirm} from 'notiflix';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MarkCompletedIcon from '../../../../../pics/Management/met.svg';
import {TbNotes} from 'react-icons/tb';
import {RiDeleteBin6Line} from 'react-icons/ri';
//
import {FullBtn} from '../../../../common/ManagementComponents/Btn/Btn';
import AddTaskIcon from '../../../../../pics/Management/add_task.svg';
import WarningNote from '../../../../../pics/Management/warning 1.svg';
import {calDuration} from '../../../../../utils/functions';
import {useSelector} from 'react-redux';
import {getUser} from '../../../../../redux/slices/userSlice';

//
export const JoinMeeting = props => {
  const interval = useRef(null);
  const scrollContainerRef = useRef(null);
  const Navigate = useNavigate();
  const user = useSelector(getUser);
  //getting params
  const {meeting_id} = useParams();
  const [copied, setCopied] = useState(false);
  const [meeting, setMeeting] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const [markCompletedShow, setMarkCompletedShow] = useState(false);
  const [assignToModalShow, setAssignToModalShow] = useState(false);
  const [hasNotes, setHasNotes] = useState(false);
  //hooks
  useEffect(() => {
    fetchMembers();
    if (meeting_id) fetchMeeting();
  }, [meeting_id]);

  // useEffect(() => {
  //   if (timers?.status)
  //     interval.current = setInterval(
  //       () => setTimers(old => ({...old, duration: old.duration})),
  //       1000,
  //     );
  //   else clearTimeout(interval.current);
  //   return () => {
  //     clearTimeout(interval.current);
  //   };
  // }, [timers?.status]);

  const fetchMeeting = () => {
    //showing up loader
    Block.circle('#form', 'Loading...');
    Axios.fetch(`maxproject/meeting/${meeting_id}`)
      .then(({data}) => {
        // Iterate over MeetingMembers
        data?.Meeting?.MeetingParticipants.forEach(member => {
          // Calculate the sum of durations for MeetingTimers of each member
          const totalDuration = member.MeetingTimers.reduce(
            (sum, timer) => sum + timer.duration,
            0,
          );
          // Assign the total duration to a new property in each member object
          member.totalDuration = totalDuration;
          member.lastTimer = member.MeetingTimers?.[0] || null;
        });
        //getting user timer from teammembers array
        const userTimer = data?.Meeting?.MeetingParticipants?.find(
          member => member.user_id == user?.id,
        );
        console.log(userTimer);
        // Assign the total duration to a new property in meeting data object
        setMeeting({...data?.Meeting, userTimer: data?.UserTimer});
        Block.remove('#form');
      })
      .catch(err => {
        //removing loader
        Block.remove('#form');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchMembers = () => {
    Axios.fetch(`maxproject/random/members`).then(({data}) => {
      setAllUsers(data.members);
    });
  };
  const handleFileUpload = event => {
    console.log(event.target.files[0].name);
  };
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(meeting?.link)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
      })
      .catch(err => console.error('Copy failed:', err));
  };
  const LongText = ({content, limit}) => {
    const toShow = content?.substring(0, limit) + '...';
    return <div style={{wordBreak: 'break-all'}}>{toShow}</div>;
  };
  const scrollToDown = () => {
    // Scroll to the bottom of the container
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  };
  const handleJoinMeeting = () => {
    //showing up loader
    Block.circle('#form', 'Joining Meeting...');
    Axios.fetch(`maxproject/meeting/join/${meeting_id}`)
      .then(({data}) => {
        Block.remove('#form');
        window.open(data.link, '_blank');
      })
      .catch(err => {
        Block.remove('#form');
      });
  };
  const handleMarkMeetingAsComplete = () => {
    if (hasNotes) handleProcessMeetingMark();
    else setMarkCompletedShow(true);
  };
  const handleProcessMeetingMark = (reason = '') => {
    //showing up loader
    Block.circle('#form', 'Please wait...');
    //initiating network request
    Axios.put(`maxproject/meeting/${meeting_id}/mark_complete`, {reason})
      .then(({data}) => {
        //removing loader
        Block.remove('#form');
        //hiding modal
        setMarkCompletedShow(false);
        //updating state
        setMeeting(old => ({...old, isCompleted: true}));
      })
      .catch(err => {
        //removing loader
        Block.remove('#form');
      });
  };
  //? Components
  const TimerComponets = () => {
    const interval = useRef(null);
    //initializing timer
    const [timer, setTimer] = useState(meeting?.userTimer || {});

    useEffect(() => {
      if (timer?.status)
        interval.current = setInterval(
          () => setTimer(old => ({...old, duration: old.duration})),
          1000,
        );
      else clearTimeout(interval.current);
      return () => {
        clearTimeout(interval.current);
      };
    }, [timer?.status]);

    const handleTimerStatus = e => {
      //getting checked status
      let checked = e.target.checked;
      //showing up loader
      Block.circle('#form', 'Updating status...');
      //preparing payload
      let payload = {status: Number(checked)};
      //initializing network request
      Axios.put(`maxproject/meeting/timer_status/${meeting_id}`, payload)
        .then(({data}) => {
          Block.remove('#form');
          //checking for any error message
          if (data?.status == -1)
            Report.info('Attention', data.message, 'Okay');
          else {
            //showing a success message
            Notify.success(`Meeting ${checked ? 'started' : 'paused'}`, {
              position: 'right-bottom',
            });
            // Assign the total duration to a new property in each member object
            const lastTimer = data?.MeetingTimers?.[0] || null;
            // Assign the total duration to a new property in meeting data object
            setMeeting(old => ({
              ...old,
              userTimer: data?.MeetingTimer,
            }));
            // setTimers(old => {
            //   return data?.MeetingTimers
            //     ? {...data?.MeetingTimers, status: checked}
            //     : {...old.MeetingTimers, status: checked};
            // });
          }
        })
        .catch(err => {
          //removing loader
          Block.remove('#form');
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };
    return (
      <div className="timer_switch_div_top">
        <div className="timer_switch_div">
          <label
            style={{
              ...(meeting?.Status?.isCompleted || timer?.isCompleted
                ? {filter: 'opacity(0.5)'}
                : {}),
            }}
            className="switch">
            <input
              disabled={meeting?.Status?.isCompleted || timer?.isCompleted}
              checked={Boolean(timer?.status)}
              onChange={e => handleTimerStatus(e)}
              type="checkbox"
              className="switch_input"
              readOnly></input>
            <span className="slider round"></span>
          </label>
          <p>{timer?.status ? 'Pause' : 'Start'}</p>
          {/* <MdAvTimer /> */}
          {timer?.status ? (
            <AccessTimeIcon fontSize="medium" style={{color: 'black'}} />
          ) : (
            <SnoozeIcon
              color="black"
              fontSize="medium"
              style={{color: 'black'}}
            />
          )}
          <span style={{color: timer?.status ? 'blue' : 'green'}}>
            {calDuration(timer, timer?.duration)}
          </span>
        </div>
      </div>
    );
  };
  return (
    <div
      id="form"
      className="custom_container"
      ref={scrollContainerRef}
      style={{
        height: '45rem',
        overflowY: 'auto', // Enable vertical scrolling
        // border: '1px solid #ccc',
        scrollBehavior: 'smooth',
      }}>
      <MarkCompletedModal
        show={markCompletedShow}
        onHide={() => setMarkCompletedShow(false)}
        handleProcessMeetingMark={handleProcessMeetingMark}
      />
      <AssignToModal
        show={assignToModalShow}
        onHide={() => setAssignToModalShow(false)}
        setMeeting={setMeeting}
        meeting={meeting}
        allUsers={allUsers}
        btnName="save"
        title="Add/Remove attendee"
      />
      <div className="row">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <PageAllTopTitle link={-1} icon={<BsArrowLeft />} title="Back" />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 edit_btn_meeting_div">
          <TimerComponets />
          {/* use your dynamic link */}
          <Button
            onClick={() => Navigate('/Management/update_meeting/' + meeting_id)}
            variant="text"
            className="edit_btn_meeting ms-3">
            <MdOutlineEdit />
          </Button>
          {/* use your dynamic link */}
          <Button
            onClick={() => {
              Confirm.show(
                `Delete?`,
                `This action is undonable!`,
                'Cancel',
                'Delete',
                () => {},
                () => {},
              );
            }}
            variant="text"
            className="edit_btn_meeting">
            <RiDeleteBin6Line />
          </Button>
        </div>
      </div>

      <div className="dashbaord_white_card">
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
          <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
              <div className="col-12">
                <div>
                  <h3 className="meeting_detail_new_title">{meeting?.title}</h3>
                  <div>
                    <p className="meeting_detail_new_sub_title">Project name</p>
                    <div className="meeting_detail_new_Project_title">
                      {meeting?.Project?.name}
                    </div>
                  </div>
                  <div>
                    <p className="meeting_detail_new_sub_title mb-1">
                      Description
                    </p>
                    <div className="meeting_detail_new_desc">
                      {ReactHtmlParser(meeting?.desc)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 join_meeting_side_line_div">
            <div>
              <div className="join_meeting_side_line"></div>
            </div>
            <div className="join_meeting_side_link_div mt-0">
              <div className="management_btn_top_div mb-4">
                <button
                  onClick={scrollToDown}
                  className="meeting_detail_new_add_notes_btn">
                  <TbNotes />
                  Add notes
                </button>
                {/* This button will be disabled when the meeting is complete */}
                <a
                  className="management_btn_full mobile_btn"
                  style={{
                    color: '#0F62FE',
                    backgroundColor: 'transparent',
                    border: '1px solid #0F62FE',
                  }}
                  onClick={handleJoinMeeting}>
                  Join Meeting
                </a>
                {/* This button will be disabled when the meeting is complete */}
                <div className="completed_btn_mob">
                  {/* When the meeting is complete, this button will not appear. */}
                  {meeting?.Status?.isCompleted ? null : (
                    <button
                      className="management_btn_full"
                      style={{
                        color: '#FF3E48',
                        backgroundColor: 'transparent',
                        border: '1px solid #FF3E48',
                      }}
                      onClick={handleMarkMeetingAsComplete}>
                      Mark completed
                    </button>
                  )}
                  {/* When the meeting is complete, this button will not appear. */}
                  {/* This button will appear when the meeting is complete */}
                  {/* <button className="management_btn_full completed_btn">
                  <FaRegCheckCircle />
                  completed
                </button> */}
                  {/* This button will appear when the meeting is complete */}
                </div>
              </div>
              <div>
                <p className="join_meeting_side_link_div_p">Meeting Link :</p>
                {/* This link will be disabled when the meeting is marked complete */}
                <div
                  style={{color: copied ? '#0f62fe' : ''}}
                  onClick={handleCopyClick}
                  className="join_meeting_side_inner_link">
                  <LinkIcon />
                  <LongText content={meeting?.link} limit={30} />
                  <button>{copied ? <MdDone /> : <AiTwotoneCopy />}</button>
                </div>
                {/* This link will be disabled when the meeting is marked complete */}
                <div className=" join_meeting_time mb-3">
                  <p>
                    <BiTimeFive />
                    11:00:00 - 11:30:00
                  </p>
                  <div className="join_meeting_sub_time_div">
                    <p className="join_meeting_sub_time mb-0 ">
                      <span>Recurrence : </span> Every weekday (Mon - Fri)
                    </p>
                    <p className="join_meeting_sub_time mt-0">
                      <span>Ends : </span> 21/12/2024
                    </p>
                  </div>
                </div>
                <div className="notes_details_bottom_assigned_div ">
                  <div className="row w-100 position-relative">
                    <div className="col-6 d-flex">
                      <p>Attendee</p>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                      <p
                        className="attendee_add_btn"
                        onClick={setAssignToModalShow}>
                        Add/Remove <span>attendee</span>
                      </p>
                    </div>
                  </div>
                  <div className="Participants_main_div_inner">
                    <MeetingUsersDropdown
                      data={meeting?.MeetingParticipants || []}
                    />
                  </div>
                </div>
                <ul className="join_meeting_side_user_created_by">
                  <li>
                    <h3>Created By :</h3>
                  </li>
                  <li>
                    <p>{meeting?.User?.name}</p>
                    <span>{moment(meeting?.createdAt).calendar()}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <NotesDetails
          meeting_note_id={meeting?.MeetingNote?.id}
          setHasNotes={setHasNotes}
        />
      </div>
    </div>
  );
};
const NotesDetails = ({meeting_note_id, setHasNotes = () => {}}) => {
  //getting params
  const {meeting_id} = useParams();

  const [data, setData] = useState({});
  const [meetingNoteId, setMeetingNoteId] = useState(meeting_note_id);
  const [priorities, setPriorities] = useState([]);

  useEffect(() => {
    setMeetingNoteId(meeting_note_id);
  }, [meeting_note_id]);

  useEffect(() => {
    if (meetingNoteId) fetchNotes();
  }, [meetingNoteId]);

  useEffect(() => {
    fetchPriorities();
    if (meetingNoteId) fetchNotes();
  }, []);

  const fetchPriorities = () => {
    Axios.fetch(`maxproject/random/statuses/meeting_note`).then(({data}) => {
      setPriorities(data?.priority_statuses);
    });
  };
  const fetchNotes = async () => {
    //showing loader
    Block.circle('.custom_container');
    Axios.fetch(`maxproject/meeting_note/notes/${meetingNoteId}`)
      .then(({data}) => {
        setHasNotes(Boolean(data?.Notes?.length));
        //removing loader
        Block.remove('.custom_container');
        setData({
          ...data,
          MeetingNoteParticipants: [
            ...data.MeetingNoteParticipants,
            {User: data?.User},
          ],
        });
      })
      .catch(err => {
        //removing loader
        Block.remove('.custom_container');
      });
  };

  const Notes = () => {
    const navigate = useNavigate();
    const [note, setNote] = useState('');
    const [activeNoteId, setActiveNoteId] = useState(null);
    const [noteParticipant, setNoteParticipant] = useState([]);
    const [showAssignModal, setShowAssignModal] = useState(false);

    // Memoize the setNewNote function to prevent unnecessary re-renders
    const memoizedSetNewNote = useCallback(
      e => {
        setNote(e.target.value);
      },
      [], // Dependency array is empty as setNewNote does not depend on any variable
    );

    const handleSaveNote = e => {
      if (e.key === 'Enter') {
        Block.dots('.notes_details_add');
        Axios.post(
          `maxproject/meeting_note/note/${
            meetingNoteId ? meetingNoteId : meeting_id
          }?type=${meetingNoteId ? 'meetingNoteId' : 'meetingId'}`,
          {
            title: note,
          },
        )
          .then(({data}) => {
            if (meetingNoteId)
              setData(old => {
                let arr = old.Notes.slice();
                arr.unshift(data.note);
                return {...old, Notes: arr};
              });
            else {
              setMeetingNoteId(data?.data?.id);
              setData({
                ...data.data,
                MeetingNoteParticipants: [
                  ...data?.data.MeetingNoteParticipants,
                  {User: data?.data?.User},
                ],
              });
            }
            setNote('');
            Block.remove('.notes_details_add');
          })
          .catch(err => {
            Block.remove('.notes_details_add');
          });
      }
    };

    const AssignToModal = props => {
      const [newNoteParticipants, setNewNoteParticipants] = useState([]);

      const handleUpdateNoteParticipants = () => {
        const users = newNoteParticipants.map(val => val.value);
        Block.dots('.note_participants_modal');
        Axios.put(`maxproject/meeting_note/note_participant/` + activeNoteId, {
          users,
        })
          .then(({data}) => {
            //showing message
            Notify.success('Note participants updated successfully');
            setData(old => {
              let arr = old.Notes.slice();
              let index = arr.findIndex(val => val.id === activeNoteId);
              arr[index] = data.note;
              return {...old, Notes: arr};
            });
            setNoteParticipant([]);
            setNewNoteParticipants([]);
            setActiveNoteId(null);
            Block.remove('.note_participants_modal');
            setShowAssignModal(false);
          })
          .catch(err => {
            Block.remove('.note_participants_modal');
          });
      };
      const UserUi = props => {
        return (
          <div className="assign_to_modal_ui">
            <Avatar
              alt={props.userName}
              src={props.userImg}
              sx={{width: 24, height: 24}}
            />
            <span>{props.userName}</span>
          </div>
        );
      };
      const NoteUsers = noteParticipant.map(val => ({
        value: val.User.id,
        label: <UserUi userName={val.User.name} userImg={val.User.avatar} />,
      }));
      const AllUsers = data?.MeetingNoteParticipants?.map(val => ({
        value: val.User.id,
        label: <UserUi userName={val.User.name} userImg={val.User.avatar} />,
      }));
      return (
        <Modal
          show={showAssignModal}
          onHide={() => setShowAssignModal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Assign</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row note_participants_modal">
              <div className="col-12">
                <div>
                  <Select
                    isMulti
                    isSearchable={true}
                    defaultValue={NoteUsers}
                    options={AllUsers}
                    onChange={val => setNewNoteParticipants(val)}
                    placeholder="Select members"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className=" text-center mt-5">
                  <FullBtn
                    onClick={handleUpdateNoteParticipants}
                    name="Assign"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    };

    const Note = ({note, index}) => {
      const [noteTitle, setNoteTitle] = useState(note?.title);
      const [priority, setPriority] = useState(note?.Priority);
      const [isEditing, setIsEditing] = useState(false);

      const handleForwardNote = () => {
        Block.dots('#note-' + note.id);
        Axios.fetch(`maxproject/meeting_note/forward_note/` + note?.id)
          .then(({data}) => {
            Notify.success(data.message);
            Block.remove('#note-' + note.id);
          })
          .catch(err => {
            Block.remove('#note-' + note.id);
          });
      };
      const processDeleteNote = () => {
        Block.dots('#note-' + note.id);
        Axios.delete(`maxproject/meeting_note/note/` + note?.id)
          .then(({data}) => {
            setData(old => {
              let arr = old.Notes.slice();
              arr.splice(index, 1);
              return {
                ...old,
                Notes: arr,
              };
            });
            Notify.success(data?.message);
            Block.remove('#note-' + note.id);
          })
          .catch(err => {
            Block.remove('#note-' + note.id);
          });
      };
      const handleDeleteNote = () => {
        Confirm.show(
          `Delete?`,
          `This action is undonable!`,
          'Delete',
          'Cancel',
          () => processDeleteNote(),
          // () => processDeleteNote(),
        );
      };
      const handleUpdateNote = e => {
        if (e.key === 'Enter') {
          Block.dots('#note-' + note.id);
          Axios.put(`maxproject/meeting_note/note_title/` + note?.id, {
            title: e.target.value,
          })
            .then(({data}) => {
              setNoteTitle(e.target.value);
              setIsEditing(false);
              Block.remove('#note-' + note.id);
            })
            .catch(err => {
              Block.remove('#note-' + note.id);
            });
        } else if (e.key === 'Escape') setIsEditing(false);
      };
      const BoxMenu = () => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = event => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        return (
          <React.Fragment>
            <Tooltip title="Menu" position="bottom">
              <MoreVertIcon
                onClick={handleClick}
                aria-controls={open ? 'account-menu' : undefined}
                className="new_note_box_menu_div2"
              />
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
              <MenuItem onClick={() => setIsEditing(true)}>Edit</MenuItem>
              <MenuItem onClick={handleDeleteNote}>Delete</MenuItem>
              <MenuItem
                disabled={!note?.NoteParticipants?.length}
                onClick={handleForwardNote}>
                Forward
              </MenuItem>
            </Menu>
          </React.Fragment>
        );
      };
      const PriorityDropdown = () => {
        const [priorityDropdown, setPriorityDropdown] = React.useState(null);
        const open = Boolean(priorityDropdown);
        const handleClick = event => {
          setPriorityDropdown(event.currentTarget);
        };
        const handleClose = () => {
          setPriorityDropdown(null);
        };
        const handlePriorityChange = priority => {
          Block.circle('.projects_table');
          Axios.put(`maxproject/meeting_note/${note.id}/priority`, {
            priority_id: priority.id,
          })
            .then(({data}) => {
              Block.remove('.projects_table');
              setPriority(priority);
              setPriorityDropdown(null);
            })
            .catch(err => {
              Block.remove('.projects_table');
            });
        };
        return (
          <React.Fragment>
            <Box>
              <Tooltip arrow>
                <div
                  onClick={handleClick}
                  className="notes_details_add_todo_dropdown">
                  <FaCircleNotch className="btn_icon" />
                  {priority ? (
                    <p
                      className="Priority_dropdown "
                      style={{backgroundColor: priority?.bg_color}}>
                      <span style={{color: priority?.color}}>
                        {priority?.name}
                        <AiFillCaretDown />
                      </span>
                    </p>
                  ) : (
                    <p
                      className="Priority_dropdown "
                      style={{backgroundColor: 'transparent'}}>
                      <span style={{color: '#1A7FDD'}}>
                        Priority
                        <AiFillCaretDown />
                      </span>
                    </p>
                  )}
                </div>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={priorityDropdown}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
              <Divider />
              {priorities?.map(p => (
                <MenuItem onClick={() => handlePriorityChange(p)}>
                  <p className="Priority_dropdown_item">
                    <BsFillCircleFill style={{color: p.bg_color}} />
                    {p.name}
                  </p>
                </MenuItem>
              ))}
            </Menu>
          </React.Fragment>
        );
      };
      return (
        <div id={'note-' + note.id} key={index} className="col-12">
          <div className=" join_meeting_time mb-3">
            <p>
              <BiTimeFive />
              {moment(note?.createdAt).calendar()}
            </p>
          </div>
          {isEditing ? (
            <div className="notes_details_add">
              <input
                type="text"
                class="form-control"
                placeholder="Edit note"
                defaultValue={noteTitle}
                onKeyDown={handleUpdateNote}
              />
            </div>
          ) : (
            <div className="notes_details_add_todo">
              <span className="notes_details_add_todo_dot">
                <GoDotFill />
              </span>
              <div>
                <p className="notes_details_add_todo_text">{noteTitle}</p>
                <div className="notes_details_bottom_btn_div">
                  <div className="notes_details_bottom_btn_div_inner mb_btn_1">
                    <p
                      className="notes_details_bottom_btn"
                      onClick={() =>
                        navigate('/Management/create_task', {
                          state: {
                            meetingNote: {
                              meetingNoteId,
                              noteTitle,
                            },
                          },
                        })
                      }>
                      <img src={AddTaskIcon} alt="icon" />
                      Add to task
                    </p>
                  </div>
                  <div className="mb_btn_2">
                    <PriorityDropdown />
                  </div>
                  <p
                    className="notes_details_bottom_btn notes_details_bottom_btn_div_inner mb_btn_3"
                    onClick={() => {
                      setShowAssignModal(true);
                      setNoteParticipant(note?.NoteParticipants);
                      setActiveNoteId(note?.id);
                    }}>
                    <MdOutlineSupervisedUserCircle
                      size={18}
                      className="btn_icon"
                      style={{marginRight: 3}}
                    />
                    Assign
                  </p>
                  {note?.NoteParticipants?.length ? (
                    <div className="notes_details_bottom_assigned_div mb_btn_4">
                      <p>Assigned to</p>
                      <div className="Participants_main_div_inner">
                        <UserParticipants data={note?.NoteParticipants} />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <BoxMenu />
            </div>
          )}
        </div>
      );
    };
    return (
      <div>
        <AssignToModal />
        <div className="notes_details_add">
          <FaPlus />
          <input
            type="text"
            class="form-control"
            placeholder="Add note"
            value={note}
            onChange={memoizedSetNewNote}
            onKeyDown={handleSaveNote}
          />
        </div>
        {data?.Notes?.length ? (
          <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
            {data?.Notes?.map((note, index) => (
              <Note note={note} index={index} />
            ))}
          </div>
        ) : (
          <div className="warning_note_div ">
            <h3>
              <img src={WarningNote} alt="Warning Note icon" /> No Notes!
            </h3>
            <p>There are no notes yet.</p>
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <div className="dashbaord_white_card">
        <div>
          <PageAllTopTitle title="Notes" />
        </div>
        <Notes />
      </div>
    </>
  );
};
const MarkCompletedModal = props => {
  const handleFormSubmit = e => {
    e.preventDefault();
    props?.handleProcessMeetingMark(e.target.reason.value);
  };
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body>
        <div className="mark_completed_modal_div">
          <img src={MarkCompletedIcon} alt="icon" />
          <h3>Action required</h3>
          <p>
            The meeting you are about to complete does not contain any notes.
            Are you sure you want to complete it?
          </p>
          <form onSubmit={handleFormSubmit}>
            <TextField
              id="outlined-multiline-flexible"
              label="Give a reason"
              multiline
              maxRows={4}
              className="w-100 mark_completed_modal_fild"
              required
              name="reason"
            />
            <div className="mark_completed_modal_btn_div">
              <Button
                onClick={props.onHide}
                variant="text"
                style={{color: '#252525'}}>
                Cancel
              </Button>
              <Button type="submit" variant="text" style={{color: '#FF3E48'}}>
                Complete
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};
const AssignToModal = props => {
  const UserUi = props => {
    return (
      <div className="assign_to_modal_ui">
        <Avatar
          alt={props.userName}
          src={props.userImg}
          sx={{width: 24, height: 24}}
        />
        <span>{props.userName}</span>
      </div>
    );
  };
  const AllUsers = props?.allUsers?.map(User => ({
    value: User?.id,
    label: <UserUi userName={User?.name} userImg={User?.avatar} />,
  }));
  //states
  const [meetingUsers, setMeetingUsers] = useState([]);
  //useEffect hooks
  useEffect(() => {
    if (props?.meeting?.MeetingParticipants?.length) {
      const users = props?.meeting?.MeetingParticipants?.map(val => ({
        value: val?.user_id,
        label: (
          <UserUi userName={val?.User?.name} userImg={val?.User?.avatar} />
        ),
      }));
      setMeetingUsers(users);
    }
  }, [props?.meeting?.MeetingParticipants]);
  //functions
  const handleUpdateAttandee = () => {
    const users = meetingUsers.map(User => User.value);
    Block.dots('.note_participants_modal');
    Axios.put(`maxproject/meeting/meeting_participant/` + props?.meeting?.id, {
      users,
    })
      .then(({data}) => {
        //showing message
        Notify.success('Attendee updated successfully');
        props?.setMeeting(old => {
          return {
            ...old,
            MeetingParticipants: data.MeetingParticipant,
          };
        });
        Block.remove('.note_participants_modal');
        props.onHide();
      })
      .catch(err => {
        Block.remove('.note_participants_modal');
      });
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <div>
              <Select
                isMulti
                options={AllUsers}
                isSearchable={true}
                defaultValue={meetingUsers}
                placeholder="Select members"
                onChange={val => setMeetingUsers(val)}
              />
            </div>
          </div>
          <div className="col-12">
            <div className=" text-center mt-5">
              <FullBtn onClick={handleUpdateAttandee} name={props.btnName} />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export const MeetingUsersDropdown = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  //functions
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ParticipantsModal = props => {
    const ParticipantsApp = props => {
      //state
      const [totalDuration, setTotalDuration] = useState(0);
      const [timer, setTimer] = useState(null);
      //hooks
      useEffect(() => {
        // Calculate the sum of durations for MeetingTimers of each member
        const totalDuration = props.MeetingTimers.reduce(
          (sum, timer) => sum + timer.duration,
          0,
        );
        setTotalDuration(totalDuration);
        // Assign the total duration to a new property in each member object
        setTimer(props.MeetingTimers?.[0]);
      }, []);
      return (
        <div className="col-12">
          <div className="user_card_div_inner">
            <Avatar
              alt={props?.User?.name}
              src={props?.User?.avatar}
              sx={{width: 15, height: 15}}
            />
            <div className="user_card_div_inner_timer">
              <p>{props?.User?.name}</p>
              <h5>{props?.User?.Designation?.name}</h5>
            </div>
            <div className="user_card_div_inner_timer_timer_div">
              {timer?.isCompleted ? (
                <>
                  <p style={{color: 'green'}}>
                    Completed on{' '}
                    {moment(timer.updatedAt).format('Do MMM, HH:mm')}
                  </p>
                  <div>
                    <span
                      style={{
                        color: timer?.status ? 'blue' : 'green',
                      }}>
                      {calDuration(timer, totalDuration)}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  {timer ? (
                    <p style={{color: '#008B0E'}}>
                      {timer?.status ? 'in Meeting' : 'Paused'}
                    </p>
                  ) : (
                    <p style={{color: 'red'}}>Not Joined Yet</p>
                  )}
                  {timer ? (
                    <div>
                      {timer?.status ? (
                        <AccessTimeIcon
                          fontSize="medium"
                          style={{color: 'black'}}
                        />
                      ) : (
                        <SnoozeIcon
                          color="black"
                          fontSize="medium"
                          style={{color: 'black'}}
                        />
                      )}
                      <span
                        style={{
                          color: timer?.status ? 'blue' : 'green',
                        }}>
                        {calDuration(timer, totalDuration)}
                      </span>
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      );
    };
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#ddc8c8',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
        <div className="user_card_div_title">
          <h3>Participants</h3>
        </div>
        <div className="user_card_div">
          <div className="row g-2 ">
            {props.data.map((val, i) => {
              return <ParticipantsApp key={i} {...val} />;
            })}
          </div>
        </div>
      </Menu>
    );
  };
  return (
    <>
      <ParticipantsModal data={props.data} />
      <div className="Participants_icon_div">
        <AvatarGroup onClick={handleClick} max={5}>
          {props.data.map((val, i) => (
            <Avatar key={i} alt={val?.User?.name} src={val?.User?.avatar} />
          ))}
        </AvatarGroup>
        <AiFillCaretDown onClick={handleClick} className="Participants_icon" />
      </div>
    </>
  );
};
