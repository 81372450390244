import * as React from 'react';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Select from 'react-select';
import {NavLink} from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useState} from 'react';
import {AiFillCaretDown, AiOutlinePlus} from 'react-icons/ai';
import ReactHtmlParser from 'react-html-parser';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {Block} from 'notiflix/build/notiflix-block-aio';
import Axios from './../../../config/api';
import moment from 'moment';
import {IoClose} from 'react-icons/io5';
import {
  MdOutlineModeEditOutline,
  MdOutlineComment,
  MdOutlineTaskAlt,
  MdFileDownload,
  MdOutlineMessage,
  MdOutlineApproval,
} from 'react-icons/md';
import {BiDotsHorizontal} from 'react-icons/bi';
import {FiChevronRight} from 'react-icons/fi';
import {TbCalendarPlus, TbFileDescription} from 'react-icons/tb';
import {ImAttachment} from 'react-icons/im';
import {TbSubtask} from 'react-icons/tb';
import {RiDeleteBinLine} from 'react-icons/ri';
import {GoIssueOpened} from 'react-icons/go';
import {HiOutlineDownload} from 'react-icons/hi';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SnoozeIcon from '@mui/icons-material/Snooze';
import {
  IoIosAttach,
  IoMdAttach,
  IoMdCheckmark,
  IoMdClose,
  IoMdCopy,
} from 'react-icons/io';
import {FaCheck, FaRegEye} from 'react-icons/fa6';
import {
  Avatar,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  ListItemIcon,
  TextField,
  Button,
  Stack,
  Pagination,
} from '@mui/material';
import Modall from '@mui/material/Modal';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import {Confirm} from 'notiflix/build/notiflix-confirm-aio';
import {useDispatch, useSelector} from 'react-redux';
import {FiEdit} from 'react-icons/fi';
import {OutlineBtn, FullBtn} from '../../common/ManagementComponents/Btn/Btn';
import {getUser} from '../../../redux/slices/userSlice';
import {DocumentsAttachments} from '../../common/ManagementComponents/DocumentsAttachments';
import {UserParticipants2} from '../../common/ManagementComponents/tasks/View';
import CommentsPdfIcon from '../../../pics/pdf.svg';
import CommentsPdfIcon2 from '../../../pics/word.svg';
import CommentsPdfIcon3 from '../../../pics/powerpoint.svg';
import CommentsPdfIcon4 from '../../../pics/excel.svg';
import CommentsPdfIcon5 from '../../../pics/file.png';
import {getGlobal, setGlobal} from '../../../redux/slices/globalSlice';
import draftToHtml from 'draftjs-to-html';
import {convertToRaw} from 'draft-js';
import {calDuration} from '../../../utils/functions';
import {useRef} from 'react';
import {Report} from 'notiflix';
//
import {Input} from 'rsuite';
import {MdOutlineDelete, MdOutlineModeEdit} from 'react-icons/md';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';
import TaskIcon from '../../../pics/Management/add_task2.svg';
import DemoImg from '../../../pics/Management/tsc.png';
import DemoPdf from '../../../pics/Management/DemoPDF.pdf';
import DemoPDFIcon from '../../../pics/Management/DemoPDF.png';
import CommantIcon from '../../../pics/Management/commantIcon.svg';
import RequestedIcon from '../../../pics/Management/RequestedIcon.svg';
import Approvedicon from '../../../pics/Management/Approvedicon.svg';
import Rejectedicon from '../../../pics/Management/Rejectedicon.svg';
import {Modal, ButtonToolbar, Placeholder} from 'rsuite';
import {useNonInitialEffect} from '../../../utils/hooks/non-initial-effect.tsx';
import {NewEditor} from '../../common/NewComponents/NewEditor/NewEditor.jsx';

const BootstrapTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const subtaskApprovalStatuses = [
  {status: 'NEW', icon: TaskIcon, label: 'New'},
  {status: 'PENDING', icon: RequestedIcon, label: 'Requested'},
  {status: 'APPROVED', icon: Approvedicon, label: 'Approved'},
  {status: 'REJECTED', icon: Rejectedicon, label: 'Rejected'},
];

export default props => {
  //?hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  //?states
  const {taskId, refreshTasks} = useSelector(getGlobal);
  const [taskData, setTaskData] = useState({});
  const [isTaskMember, setIsTaskMember] = useState(false);
  const [delay, setDelay] = useState(null);
  const [blockingTask, setBlockingTask] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [editDesc, setEditDesc] = useState(false);
  const [newTaskDesc, setNewTaskDesc] = useState(null);
  const handleOpenTeamModal = () => setShowTeamModal(true);
  const handleCloseTeamModal = () => setShowTeamModal(false);

  useEffect(() => {
    if (taskId) fetchTaskDetail();
    return () => {};
  }, []);

  useEffect(() => {
    setDelay(moment().diff(taskData?.end_date, 'days'));
  }, [taskData]);

  const fetchTaskDetail = () => {
    //showing up loader
    Block.circle('.task_detail', 'Loading...');
    Axios.fetch(`maxproject/task/internal_task_detail/${taskId}`)
      .then(({data}) => {
        Block.remove('.task_detail');
        // Iterate over TaskMembers
        data?.task?.TaskMembers.forEach(member => {
          // Calculate the sum of durations for TaskTimers of each member
          const totalDuration = member.TaskTimers.reduce(
            (sum, timer) => sum + timer.duration,
            0,
          );
          // Assign the total duration to a new property in each member object
          member.totalDuration = totalDuration;
          member.lastTimer = member.TaskTimers?.[0] || null;
        });
        //getting user timer from teammembers array
        const userTimer = data?.task?.TaskMembers?.find(
          member => member.user_id == user?.id,
        );
        //checking if user a task member
        const isTaskMember = data?.task?.TaskMembers?.some(
          member => member.user_id == user?.id,
        );
        //checking if user is task owner
        const TaskOwner = data?.task?.TaskMembers?.find(
          member => member.isOwner,
        );
        //updating states
        setIsTaskMember(isTaskMember);
        setTaskData({...data.task, userTime: userTimer, TaskOwner});
        setBlockingTask(data?.BlockingTask);
        setTaskList(data?.TasksList);
        setNewTaskDesc(data.task?.desc);
      })
      .catch(err => {
        //removing loader
        Block.remove('.task_detail');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const getTaskProgress = () => {
    if (!taskData) return 0;
    let totalIssues = taskData?.TaskIssues?.length;
    let totalSubTasks = taskData?.SubTasks?.length;

    let totalCompletedSubTasks = taskData?.SubTasks?.filter(
      task => task.completed_on,
    )?.length;
    let totalCompletedIssues = taskData?.TaskIssues?.filter(
      issue => issue.completed_on,
    )?.length;

    return totalIssues +
      totalSubTasks +
      totalCompletedSubTasks +
      totalCompletedIssues >
      0
      ? Math.round(
          ((totalCompletedSubTasks + totalCompletedIssues) /
            (totalIssues + totalSubTasks)) *
            100 *
            100,
        ) / 100
      : 0;
  };
  const handleTaskCompleteStatus = e => {
    //getting current completing status
    let isCompleted = taskData?.userTime?.lastTimer?.isCompleted;
    //showing up loader
    Block.circle('.task_detail', 'Updating status...');
    //preparing payload
    let payload = {isCompleted: Number(!isCompleted)};
    //initializing network request
    Axios.put(
      `maxproject/task/internal_task/complete_status/${taskId}`,
      payload,
    )
      .then(({data}) => {
        Block.remove('.task_detail');
        Notify.success(data.message, {position: 'right-bottom'});
        // Calculate the sum of durations for TaskTimers of each member
        const totalDuration = data.UserTimers.reduce(
          (sum, timer) => sum + timer.duration,
          0,
        );
        // Assign the total duration to a new property in each member object
        const lastTimer = data.UserTimers?.[0] || null;
        setTaskData(old => ({
          ...old,
          userTime: {TaskTimers: data.UserTimers, totalDuration, lastTimer},
        }));
        //updating tasks
        dispatch(setGlobal({refreshTasks: !refreshTasks}));
      })
      .catch(err => {
        //removing loader
        Block.remove('.task_detail');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const onTaskAttachmentsChange = e => {
    if (!e.target.files) return;
    //showning loader
    Block.circle('.task_detail', 'Uploading attachments...');
    //preparint form data
    let formdata = new FormData();
    Array.from(e.target.files).forEach(file =>
      formdata.append('attachments[]', file),
    );
    //initializing network
    Axios.post(
      'maxproject/task/internal_task/add_task_attachments/' + taskId,
      formdata,
    )
      .then(({data}) => {
        //removing loader
        Notify.success(data.message);
        Block.remove('.task_detail');
        setTaskData(old => ({...old, TaskAttachments: data.TaskAttachments}));
      })
      .catch(err => {
        //removing loader
        Block.remove('.task_detail');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleDeleteAttachment = (i, file) => {
    Confirm.show(
      `Delete ${file.original_name}`,
      `${file.original_name} will be permanently deleted and can't be recovered.`,
      'Delete',
      'Cancel',
      () => {
        //request to delete file from server
        Axios.delete(
          `maxproject/task/internal_task/attachment/${file.id}`,
        ).then(({data}) => {
          Notify.success(data.message);
          setTaskData(old => {
            let TaskAttachments = old.TaskAttachments.slice();
            TaskAttachments.splice(i, 1);
            return {...old, TaskAttachments};
          });
        });
      },
      () => {},
      {},
    );
  };
  const handleTaskDependencyStatus = e => {
    //getting checked status
    let checked = e.target.checked;
    //showing up loader
    Block.circle('.task_detail', 'Updating status...');
    //preparing payload
    let payload = {dependency: Number(checked)};
    //initializing network request
    Axios.put(
      `maxproject/task/internal_task/dependency_status/${taskId}`,
      payload,
    )
      .then(({data}) => {
        Block.remove('.task_detail');
        setTaskData(old => ({...old, dependency: checked}));
      })
      .catch(err => {
        //removing loader
        Block.remove('.task_detail');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const onTaskBlockageChange = payload => {
    //showing up loader
    Block.circle('.task_detail', 'Updating status...');
    //initializing network request
    Axios.put(
      `maxproject/task/internal_task/blockage_status/${taskId}`,
      payload,
    )
      .then(({data}) => {
        Block.remove('.task_detail');
        setTaskData(old => ({...old, payload}));
      })
      .catch(err => {
        //removing loader
        Block.remove('.task_detail');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleEditDesc = () => {
    setEditDesc(true);
  };
  const handleUpdateDesc = () => {
    Block.circle('.task_desc', 'Saving...');
    //initializing network request
    Axios.put(`maxproject/task/internal_task/desc/${taskId}`, {
      desc: newTaskDesc,
    })
      .then(({data}) => {
        //removing loader
        Block.remove('.task_desc');
        //showing success message
        Notify.success(data.message);
        //removing editor
        setEditDesc(false);
        //updating task state
        setTaskData(old => ({
          ...old,
          desc: newTaskDesc,
        }));
      })
      .catch(err => {
        //removing loader
        Block.remove('.task_desc');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleCancelUpdateDesc = () => {
    setEditDesc(false);
  };
  //? Components
  const TimerComponets = () => {
    const interval = useRef(null);
    //initializing timer
    const [timer, setTimer] = useState(taskData?.userTime?.lastTimer || {});

    useEffect(() => {
      if (timer?.status)
        interval.current = setInterval(
          () => setTimer(old => ({...old, duration: old.duration})),
          1000,
        );
      else clearTimeout(interval.current);
      return () => {
        clearTimeout(interval.current);
      };
    }, [timer?.status]);

    const handleTaskTimerStatus = e => {
      //getting checked status
      let checked = e.target.checked;
      //showing up loader
      Block.circle('.task_detail', 'Updating status...');
      //preparing payload
      let payload = {status: Number(checked)};
      //initializing network request
      Axios.put(`maxproject/task/internal_task/timer_status/${taskId}`, payload)
        .then(({data}) => {
          Block.remove('.task_detail');
          //checking for any error message
          if (data?.status == -1)
            Report.info('Attention', data.message, 'Okay');
          else {
            //showing a success message
            Notify.success(`Task ${checked ? 'started' : 'paused'}`, {
              position: 'right-bottom',
            });
            // Calculate the sum of durations for TaskTimers of each member
            const totalDuration = data.UserTimers.reduce(
              (sum, timer) => sum + timer.duration,
              0,
            );
            // Assign the total duration to a new property in each member object
            const lastTimer = data.UserTimers?.[0] || null;
            setTaskData(old => ({
              ...old,
              userTime: {TaskTimers: data.UserTimers, totalDuration, lastTimer},
            }));
            //updating tasks
            dispatch(setGlobal({refreshTasks: !refreshTasks}));
          }
        })
        .catch(err => {
          //removing loader
          Block.remove('.task_detail');
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };

    return (
      <div className="timer_switch_div_top">
        <div className="timer_switch_div">
          <label
            style={{
              ...(taskData?.isCompleted ||
              taskData?.userTime?.lastTimer?.isCompleted
                ? {filter: 'opacity(0.5)'}
                : {}),
            }}
            className="switch">
            <input
              disabled={
                !isTaskMember ||
                taskData?.isCompleted ||
                taskData?.userTime?.lastTimer?.isCompleted
              }
              checked={Boolean(timer?.status)}
              onChange={e => handleTaskTimerStatus(e)}
              type="checkbox"
              className="switch_input"
              readOnly></input>
            <span className="slider round"></span>
          </label>
          <p>{timer?.status ? 'Pause' : 'Start'}</p>
          {/* <MdAvTimer /> */}
          {timer?.status ? (
            <AccessTimeIcon fontSize="medium" style={{color: 'black'}} />
          ) : (
            <SnoozeIcon
              color="black"
              fontSize="medium"
              style={{color: 'black'}}
            />
          )}
          <span style={{color: timer?.status ? 'blue' : 'green'}}>
            {calDuration(timer, taskData?.userTime?.totalDuration)}
          </span>
        </div>
      </div>
    );
  };
  const TeamMembersUiModal = () => {
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      border: '0px solid #000',
      boxShadow: 24,
      p: 0,
    };
    const TeamMembersDataUi = props => (
      <div className="team_members_data_ui_div">
        <img
          src={
            props.userIcon ||
            'https://source.unsplash.com/random/500x500/?user,icon'
          }
          alt="img"
        />
        {props.name}
      </div>
    );
    const TeamMembersData = [
      {
        value: '1',
        label: <TeamMembersDataUi name="sunny charkhwal" />,
      },
      {
        value: '2',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face2"
          />
        ),
      },
      {
        value: '3',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face3"
          />
        ),
      },
      {
        value: '4',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face4"
          />
        ),
      },
      {
        value: '5',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face5"
          />
        ),
      },
      {
        value: '6',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face6"
          />
        ),
      },
      {
        value: '7',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face7"
          />
        ),
      },
      {
        value: '8',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face8"
          />
        ),
      },
      {
        value: '9',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face9"
          />
        ),
      },
      {
        value: '10',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face10"
          />
        ),
      },
    ];
    const TeamMembersShowSearchResultData = [
      {
        Img: '',
        userName: 'kamal',
      },
      {
        Img: 'https://source.unsplash.com/random/500x500/?face,photo',
        userName: 'sunny charkhwal',
      },
      {
        Img: 'https://source.unsplash.com/random/500x500/?face,photo',
        userName: 'sunny charkhwal',
      },
    ];
    return (
      <Modal
        open={showTeamModal}
        onClose={handleCloseTeamModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} className="team_members_ui_modal">
          <div className="team_members_ui_modal_title">
            <h3>Team Members</h3>
            <IoClose onClick={handleCloseTeamModal} />
          </div>
          <div className="team_members_ui_modal_body">
            <div className=" mb-4">
              <Select
                // menuIsOpen={true}
                options={TeamMembersData}
                placeholder="Team Members"
                isMulti
              />
            </div>
            <div
              className="
            _scorll">
              <div className="row g-3">
                {TeamMembersShowSearchResultData.map((val, i) => (
                  <div className="col-12" key={i}>
                    <div className="team_members_data_ui_div">
                      <img
                        src={
                          val.Img ||
                          'https://source.unsplash.com/random/500x500/?girl'
                        }
                        alt="img"
                      />
                      {val.userName}
                      <span>Remove</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };
  // Header Menu
  const HeaderMenu = () => {
    const [taskRightDrawerUiHeaderMenu, setTaskRightDrawerUiHeaderMenu] =
      useState(null);
    const open = Boolean(taskRightDrawerUiHeaderMenu);
    const handleClick = event => {
      setTaskRightDrawerUiHeaderMenu(event.currentTarget);
    };
    const handleClose = () => {
      setTaskRightDrawerUiHeaderMenu(null);
    };
    const navigate = useNavigate();

    const confirmDeleteTask = () => {
      Confirm.show(
        'Delete Task',
        "This action can't be undone",
        'Yes',
        'No',
        () => {
          handleDeleteTask(taskId);
        },
      );
    };

    return (
      <div>
        <input
          type="file"
          name="attachments"
          style={{display: 'none'}}
          multiple={true}
          id="attachemnt_input"
          onChange={onTaskAttachmentsChange}
        />
        <Box onClick={handleClick}>
          <BiDotsHorizontal className="menu_svg" />
        </Box>
        <Menu
          anchorEl={taskRightDrawerUiHeaderMenu}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{horizontal: 'right', vertical: 'top'}}
          anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
          <MenuItem
            onClick={() => navigate(`/Management/update_task/${taskId}`)}>
            <ListItemIcon>
              <EditOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem onClick={confirmDeleteTask}>
            <ListItemIcon>
              <DeleteOutlineIcon fontSize="small" />
            </ListItemIcon>
            Delete
          </MenuItem>
          <MenuItem>
            <label htmlFor="attachemnt_input">
              <ListItemIcon>
                <AttachFileIcon fontSize="small" />
              </ListItemIcon>
              Attach Document
            </label>
          </MenuItem>
          {/* <MenuItem onClick={handleOpenTeamModal}>
              <ListItemIcon>
                <Groups3Icon fontSize="small" />
              </ListItemIcon>
              Team Members
            </MenuItem> */}
        </Menu>
        <TeamMembersUiModal />
      </div>
    );
  };
  const TaskTabs = () => {
    const SubTasks = ({taskMembers = []}) => {
      const [Users, setUsers] = useState([]);
      const [page, setPage] = useState(1);
      const [subTaskName, setSubTaskName] = useState('');
      const [isMarkedAll, setIsMarkedAll] = useState(false);
      const [selectedUser, setSelectedUser] = useState(null);
      const [subTasks, setSubTasks] = useState(taskData?.SubTasks || []);
      const [selectedSubTasksCount, setSelectedSubTasksCount] = useState(0);
      const [subtaskApprovalStatus, setSubtaskApprovalStatus] = useState({
        status: null,
        icon: null,
        label: 'Select Type',
      });
      //hooks
      useEffect(() => {
        setUsers(old => {
          return taskMembers.map(m => ({
            value: m.user_id,
            label: <UserUi userName={m.User.name} userImg={m.User.avatar} />,
          }));
        });
      }, []);
      useNonInitialEffect(() => {
        fetchSubTasks();
      }, [subtaskApprovalStatus, selectedUser]);

      const fetchSubTasks = () => {
        //updating state
        setSelectedSubTasksCount(0);
        setIsMarkedAll(false);
        //showing up loader
        Block.dots('.sub-tasks-table', 'Loading...');
        //preparing url parameters
        const params = new URLSearchParams();
        if (subtaskApprovalStatus.status)
          params.append('approval_status', subtaskApprovalStatus.status);
        if (selectedUser) params.append('created_by', selectedUser.value);
        //preparing url
        const URL = `maxproject/sub_task/sub_tasks/${taskId}?${params.toString()}`;
        //initializing network request
        Axios.fetch(URL)
          .then(({data}) => {
            Block.remove('.sub-tasks-table');
            setSubTasks(data?.subTasks);
          })
          .catch(err => {
            //removing loader
            Block.remove('.sub-tasks-table');
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      };

      const handleUsersFilterChange = e => {
        //updating state
        setSelectedUser(e);
        setSelectedSubTasksCount(0);
        setIsMarkedAll(false);
      };
      //functions
      const handleDelete = (id, idx) => {
        //showing up loader
        Block.circle('.sub_tasks', 'Loading...');
        //initializing network request
        Axios.delete(`maxproject/sub_task/${id}`)
          .then(({data}) => {
            Block.remove('.sub_tasks');
            Notify.success(data.message);
            setSubTasks(old => {
              let newData = old.slice();
              newData.splice(idx, 1);
              return newData;
            });
          })
          .catch(err => {
            //removing loader
            Block.remove('.sub_tasks');
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      };
      const handleSelectAll = e => {
        const isChecked = e.target.checked;
        setIsMarkedAll(!isMarkedAll);
        setSubTasks(old => {
          let arr = old.slice().map(sub => ({
            ...sub,
            ...(sub.created_by == user.id ? {isMarked: isChecked} : {}),
          }));
          return arr;
        });
        setSelectedSubTasksCount(isChecked ? subTasks.length : 0);
      };
      const handleChangeCheckedStatus = (checked, idx) => {
        setSelectedSubTasksCount(old => (checked ? old + 1 : old - 1));
        setSubTasks(old => {
          let arr = old.slice();
          arr[idx] = {...arr[idx], isMarked: checked};
          return arr;
        });
      };
      const handleBatchMarkComplete = () => {
        const proceedChange = () => {
          //getting marked sub tasks id's
          const markedItems = subTasks
            .filter(sub => sub.isMarked)
            .map(sub => sub.id);
          //showing up loader
          Block.circle('.sub_tasks', 'Loading...');
          Axios.post(`maxproject/sub_task/mark_batch_as_complete/${taskId}`, {
            sub_tasks: markedItems,
            task_id: taskId,
          })
            .then(({data}) => {
              Block.remove('.sub_tasks');
              Notify.success(data.message);
              setSubTasks(data.SubTasks);
              setSelectedSubTasksCount(0);
              setIsMarkedAll(false);
            })
            .catch(err => {
              //removing loader
              Block.remove('.sub_tasks');
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        Confirm.show(
          `Mark as completed?`,
          "This action can't be undone",
          'Yes',
          'No',
          proceedChange,
        );
      };
      const handleBatchApprovalRequest = () => {
        //getting marked sub tasks id's
        const markedItems = subTasks
          .filter(sub => sub.isMarked)
          .map(sub => sub.id);
        //navigating with params
        navigate(`/Management/approvals`, {
          state: {
            subTasks: markedItems,
            taskId,
            projectId: taskData.project_id,
            taskType: taskData?.project_id ? 'internal' : 'other',
            taskName: taskData.name,
          },
        });
        //closing task modal
        dispatch(setGlobal({showTask: false, taskId: null}));
      };
      const handleBatchDelete = () => {
        //getting marked sub tasks id's
        const markedItems = subTasks
          .filter(sub => sub.isMarked)
          .map(sub => sub.id);
        //showing up loader
        Block.circle('.sub_tasks', 'Loading...');
        Axios.post(`maxproject/sub_task/delete_batch/${taskId}`, {
          sub_tasks: markedItems,
          task_id: taskId,
        })
          .then(({data}) => {
            Block.remove('.sub_tasks');
            Notify.success(data.message);
            setSubTasks(data.SubTasks);
            setSelectedSubTasksCount(0);
            setIsMarkedAll(false);
          })
          .catch(err => {
            //removing loader
            Block.remove('.sub_tasks');
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      };
      //components
      const ModalImgView = props => {
        const [open, setOpen] = React.useState(false);
        const [size, setSize] = React.useState();
        const handleOpen = value => {
          setSize(value);
          setOpen(true);
        };
        const handleClose = () => setOpen(false);

        return (
          <>
            <ButtonToolbar>
              <span
                className="modal_img_view_btn"
                size="lg"
                onClick={() => handleOpen('lg')}>
                <FaRegEye />
              </span>
            </ButtonToolbar>
            <Modal size={size} open={open} onClose={handleClose}>
              <Modal.Body
                className="sub_task_modul_body_div"
                style={{maxHeight: '35rem'}}>
                <p className="sub_task_modul_title_div">{props.title}</p>
                <hr />
                <IoMdClose
                  className="sub_task_modul_top_close_btn_div"
                  onClick={handleClose}
                />
                {/* ui */}
                {props.modalUi}
                {/* ui end */}
              </Modal.Body>
              <Modal.Footer>
                <div className="mt-3 d-flex align-items-center justify-content-end approval_modal_btn">
                  <div className="me-2">
                    <OutlineBtn onClick={handleClose} name="Close" />
                  </div>
                  <div className=" ms-2">
                    <a
                      className="management_btn_full2 sub_task_modul_download_btn"
                      href={props.downloadItem}
                      download>
                      download
                    </a>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        );
      };
      const ModalViewFeedback = props => {
        const [open, setOpen] = React.useState(false);
        const [size, setSize] = React.useState();
        const handleOpen = value => {
          setSize(value);
          setOpen(true);
        };
        const handleClose = () => setOpen(false);

        return (
          <>
            <ButtonToolbar>
              <BootstrapTooltip title="View feedback" placement="top">
                <label
                  size="sm"
                  className="show_btn ms-0"
                  onClick={() => handleOpen('sm')}>
                  <MdOutlineMessage />
                </label>
              </BootstrapTooltip>
            </ButtonToolbar>
            <Modal size={size} open={open} onClose={handleClose}>
              <Modal.Body
                className="sub_task_modul_body_div"
                style={{maxHeight: '35rem'}}>
                <p className="sub_task_modul_title_div">
                  Final Status: Approved
                </p>
                <hr />
                <IoMdClose
                  className="sub_task_modul_top_close_btn_div"
                  onClick={handleClose}
                />
                {/* ui */}
                <ul className="feedback_modal_ul">
                  <li>
                    <img
                      className="feedback_modal_ul_icon"
                      src="https://i.pravatar.cc/150?img=12"
                      alt="ok"
                    />
                    <div className="feedback_modal_ul_main_div">
                      <p className="feedback_modal_ul_name mb-2">
                        Deependra Bhaskar
                        <span>(Design Team Lead)</span>
                      </p>
                      <span
                        className="feedback_modal_ul_requested"
                        style={{
                          backgroundColor: '#e8f2fc',
                          borderColor: '#1a7fdd',
                        }}>
                        Requested
                      </span>
                      <p className="feedback_modal_ul_commant">
                        <img src={CommantIcon} alt="icon" />
                        <span>
                          Lorem ipsum dolor sit amet consectetur, adipisicing
                          elit. Corrupti eum laudantium ad quo eligendi tempore
                          totam consectetur, voluptate est fugit iure sed
                          recusandae temporibus optio reprehenderit? Modi
                          tempore molestiae voluptatem.
                        </span>
                      </p>
                      <div style={{marginLeft: '20px'}}>
                        <div className="add_new_subtask_Uploaded_mai_div">
                          <div className="item">
                            <div className="add_new_subtask_Uploaded_img_div">
                              <img src={DemoImg} alt="Uploaded photo" />
                              <p>
                                {'Get a specific image by adding'.length > 15
                                  ? 'Get a specific image by adding'?.substr(
                                      0,
                                      15,
                                    ) + '...'
                                  : 'Get a specific image by adding'}
                              </p>

                              {true ? (
                                <span className="close_btn">
                                  <IoClose />
                                </span>
                              ) : null}
                              <div className="add_new_subtask_Uploaded_img_div_overlay">
                                <div className="modal_img_view_btn_div">
                                  <a
                                    className="modal_img_view_btn me-1"
                                    href={DemoImg}
                                    download>
                                    <MdFileDownload />
                                  </a>
                                  <ModalImgView
                                    title="photo demo title"
                                    downloadItem={DemoImg}
                                    modalUi={
                                      <>
                                        <img
                                          className="sub_task_modul_img_div"
                                          src={DemoImg}
                                          alt="img"
                                        />
                                      </>
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="item">
                            <div className="add_new_subtask_Uploaded_img_div">
                              <img src={DemoPDFIcon} alt="pdf icon" />
                              <p>
                                {'pdf title'.length > 15
                                  ? 'pdf title'?.substr(0, 15) + '...'
                                  : 'pdf title'}
                              </p>

                              {true ? (
                                <span className="close_btn">
                                  <IoClose />
                                </span>
                              ) : null}
                              <div className="add_new_subtask_Uploaded_img_div_overlay">
                                <div className="modal_img_view_btn_div">
                                  <a
                                    className="modal_img_view_btn me-1"
                                    href={DemoImg}
                                    download>
                                    <MdFileDownload />
                                  </a>
                                  <ModalImgView
                                    title="pdf demo title"
                                    downloadItem={DemoImg}
                                    modalUi={
                                      <>
                                        <object
                                          data={DemoPdf}
                                          style={{
                                            width: '100%',
                                            height: '35rem',
                                          }}></object>
                                      </>
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="feedback_modal_ul_time">
                        <TbCalendarPlus />
                        11/24/2023, 12:24:00 PM
                      </p>
                    </div>
                  </li>
                </ul>
                {/* ui end */}
              </Modal.Body>
            </Modal>
          </>
        );
      };
      const RequestedMenu = () => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = event => setAnchorEl(event.currentTarget);
        const handleClose = () => setAnchorEl(null);
        const handleChange = item => {
          setSubtaskApprovalStatus(item);
          setAnchorEl(null);
        };
        return (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
              }}>
              <div
                onClick={handleClick}
                size="small"
                style={{marginRight: '16px'}}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                className="notes_details_add_todo_dropdown add_new_subtask_requested_menu_dropdown">
                <p
                  className="Priority_dropdown "
                  style={{
                    backgroundColor: '#fefbea',
                    border: '1px solid #F3D32C',
                  }}>
                  {subtaskApprovalStatus.icon ? (
                    <img
                      className="task_icon"
                      src={subtaskApprovalStatus.icon}
                      alt="icon"
                    />
                  ) : null}
                  <span style={{color: 'black'}}>
                    {subtaskApprovalStatus.label}
                    <AiFillCaretDown />
                  </span>
                </p>
              </div>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
              {subtaskApprovalStatuses.map(item => (
                <MenuItem
                  key={item.status}
                  selected={subtaskApprovalStatus.status === item.status}
                  onClick={() => handleChange(item)}>
                  <p className="Priority_dropdown_item add_new_subtask_requested_menu_item">
                    <img className="task_icon" src={item.icon} alt="icon" />
                    {item.label}
                  </p>
                </MenuItem>
              ))}
            </Menu>
          </>
        );
      };
      const UserUi = props => {
        return (
          <div className="assign_to_modal_ui">
            <Avatar
              alt={props.userName}
              src={props.userImg}
              sx={{width: 24, height: 24}}
            />
            <span>{props.userName}</span>
          </div>
        );
      };
      const DeleteModalInner = props => {
        const [open, setOpen] = React.useState(false);
        const [size, setSize] = React.useState();
        const handleOpen = value => {
          setSize(value);
          setOpen(true);
        };
        const handleClose = () => setOpen(false);

        return (
          <>
            <ButtonToolbar>
              <BootstrapTooltip
                size="xs"
                onClick={() => handleOpen('xs')}
                title="Delete"
                placement="top">
                <label className="me-0 show_btn sub_task_delete_btn">
                  <MdOutlineDelete />
                </label>
              </BootstrapTooltip>
            </ButtonToolbar>
            <Modal
              size={size}
              open={open}
              onClose={handleClose}
              className="delete_modal_sub_task">
              <Modal.Body>
                <div className="delete_modal">
                  <ul>
                    <li>
                      <h3>Delete?</h3>
                      <p>{props?.title}</p>
                    </li>
                  </ul>
                  <div className="delete_modal_btn_div">
                    <ul>
                      <li className="btn_one">
                        <OutlineBtn onClick={handleClose} name="Cancel" />
                      </li>
                      <li className="btn_two">
                        <FullBtn
                          onClick={() => handleDelete(props.id, props.idx)}
                          name="Delete"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </>
        );
      };
      const DeleteModalOuter = props => {
        const [open, setOpen] = React.useState(false);
        const [size, setSize] = React.useState();
        const handleOpen = value => {
          setSize(value);
          setOpen(true);
        };
        const handleClose = () => setOpen(false);

        return (
          <>
            <ButtonToolbar>
              <BootstrapTooltip
                size="xs"
                onClick={() => handleOpen('xs')}
                title="Delete"
                placement="top">
                <label className="sub_task_delete_btn">
                  <MdOutlineDelete />
                </label>
              </BootstrapTooltip>
            </ButtonToolbar>
            <Modal
              size={size}
              open={open}
              onClose={handleClose}
              className="delete_modal_sub_task">
              <Modal.Body>
                <div className="delete_modal">
                  <ul>
                    <li>
                      <h3>Delete?</h3>
                      <p>This action can't be undone.</p>
                    </li>
                  </ul>
                  <div className="delete_modal_btn_div">
                    <ul>
                      <li className="btn_one">
                        <OutlineBtn onClick={handleClose} name="Cancel" />
                      </li>
                      <li className="btn_two">
                        <FullBtn onClick={handleBatchDelete} name="Delete" />
                      </li>
                    </ul>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </>
        );
      };
      const Header = () => (
        <div className="row">
          <div className="col-6">
            <div className=" new_task_detail_top_title_one">
              <FormControlLabel
                control={
                  <Checkbox checked={isMarkedAll} onChange={handleSelectAll} />
                }
              />
              <span className="small">Subtask Name</span>
            </div>
          </div>
          <div className="col-6">
            <div className="new_task_detail_top_title_two">
              <span className="small">Duration</span>
            </div>
          </div>
        </div>
      );
      const Actions = () => (
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 add_new_subtask_top_btn_div">
          <DeleteModalOuter />
          <BootstrapTooltip title="Mark Complete" placement="top">
            <label onClick={handleBatchMarkComplete}>
              <IoMdCheckmark />
            </label>
          </BootstrapTooltip>
          <BootstrapTooltip title="Send for approval" placement="top">
            <label onClick={handleBatchApprovalRequest}>
              <MdOutlineApproval />
            </label>
          </BootstrapTooltip>
        </div>
      );
      const Filters = () => (
        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
          <div className="add_new_subtask_menu_div_top">
            <span className="add_new_subtask_ui_title mt-0 me-3">Subtasks</span>
            <div className="add_new_subtask_menu_div_top2">
              <RequestedMenu />
              <div className="dashboard_top_week_Select drop_box new_sub_task_select">
                <Select
                  options={Users}
                  placeholder="Select User"
                  onChange={handleUsersFilterChange}
                  value={selectedUser}
                />
              </div>
            </div>
          </div>
        </div>
      );
      const NewSubTask = () => {
        const [showAddSubTasks, setShowAddSubTasks] = useState(false);
        const [newDocs, setNewDocs] = useState([]);
        const [startDate, setStartDate] = useState(moment());
        const [endDate, setEndDate] = useState(null);
        //hooks
        useEffect(() => {
          //if start date is greater than end date
          if (endDate && startDate.isAfter(endDate)) setEndDate(null);
        }, [startDate]);

        const handleAddNewSubTask = e => {
          e.preventDefault();
          //getting values
          const {name, start_date, end_date} = e.target;
          //showing up loader
          Block.circle('#new_sub_task', 'Saving...');
          //preparing form data
          let formdata = new FormData();
          //adding form data
          formdata.append('name', name.value);
          formdata.append('task_id', taskId);
          formdata.append('start_date', moment(start_date.value));
          formdata.append('end_date', moment(end_date.value));
          //adding attachments
          if (newDocs)
            Array.from(newDocs).forEach(file =>
              formdata.append('attachments[]', file),
            );
          //initializing network request
          Axios.post(`maxproject/sub_task`, formdata)
            .then(({data}) => {
              Block.remove('#new_sub_task');
              Notify.success(data.message);
              setTaskData(old => ({...old, SubTasks: data?.subTasks}));
              setSubTasks(data?.subTasks);
            })
            .catch(err => {
              //removing loader
              Block.remove('#new_sub_task');
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        const handleRemoveNewDoc = fileToRemove => {
          const filesArray = Array.from(newDocs);
          const updatedFilesArray = filesArray.filter(
            file => file !== fileToRemove,
          );
          setNewDocs(updatedFilesArray);
        };
        const handleFileChange = e => {
          let files = e.target.files;
          setNewDocs(old => [...old, ...files]);
        };
        return (
          <div
            id="new_sub_task"
            className="add_new_subtask_top_ui_div add_new_subtask_main_ui_new p-0">
            <div className="row m-0">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="add_new_subtask_data_top_title_ui">
                  Subtask Name
                </div>
              </div>

              <div className="col-3 d-none d-xxl-block d-xl-block d-lg-block d-md-block">
                <div className="new_start_date">Start date</div>
              </div>
              <div className="col-3 d-none d-xxl-block d-xl-block d-lg-block d-md-block">
                <div className="end_start_date">End date</div>
              </div>
            </div>
            <div
              style={{
                padding: ' 15px 0 15px 0',
                borderTop: '1px solid #e5e5ea',
              }}>
              <form onSubmit={handleAddNewSubTask}>
                <div className="row m-0 ">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="add_new_subtask_main_ui create_task_date_piker_new2">
                      <div className="add_new_subtask_main_ui_input_div create_task_date_piker_input">
                        <span>
                          <MdOutlineTaskAlt />
                        </span>
                        <Input
                          name="name"
                          required
                          placeholder="Subtask name"
                          onClick={setShowAddSubTasks}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <div className="create_task_date_piker_new create_task_date_piker_new2">
                      <div>
                        <label className="all_page_my_label_new">
                          Start Date*
                        </label>
                        <input
                          name="start_date"
                          required
                          type="date"
                          min={moment().format('YYYY-MM-DD')}
                          max={moment(taskData?.end_date).format('YYYY-MM-DD')}
                          className="form-control intput_box"
                          value={startDate.format('YYYY-MM-DD')}
                          onChange={e => setStartDate(moment(e.target.value))}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <div className="create_task_date_piker_new create_task_date_piker_new2">
                      <div>
                        <label className="all_page_my_label_new">
                          End Date*
                        </label>
                        <input
                          name="end_date"
                          required
                          type="date"
                          min={
                            moment(startDate).format('YYYY-MM-DD') ||
                            moment().format('YYYY-MM-DD')
                          }
                          max={moment(taskData?.end_date).format('YYYY-MM-DD')}
                          className="form-control intput_box"
                          value={endDate ? endDate.format('YYYY-MM-DD') : ''}
                          onChange={e => setEndDate(moment(e.target.value))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {showAddSubTasks ? (
                  <div style={{padding: '0 15px'}}>
                    <div
                      className="add_new_subtask_Uploaded_mai_div"
                      style={{paddingLeft: '24px'}}>
                      {Array.from(newDocs).map((file, i) => (
                        <div className="item">
                          <div className="add_new_subtask_Uploaded_img_div">
                            <img
                              src={getFileIcon(file, true)}
                              alt="Uploaded doc"
                            />
                            <p>
                              {file.name.length > 15
                                ? file.name?.substr(0, 15) + '...'
                                : file.name}
                            </p>
                            <span
                              className="close_btn"
                              onClick={() => handleRemoveNewDoc(file)}>
                              <IoClose />
                            </span>
                            <div className="add_new_subtask_Uploaded_img_div_overlay">
                              <div className="modal_img_view_btn_div">
                                <ModalImgView
                                  title={file.name}
                                  downloadItem={getFileIcon(file, true)}
                                  modalUi={
                                    <img
                                      className="sub_task_modul_img_div"
                                      src={getFileIcon(file, true)}
                                      alt="img"
                                    />
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="add_new_subtask_top_ui_btn_div">
                      <div className="add_new_subtask_uploader_div">
                        <input
                          type="file"
                          onChange={handleFileChange}
                          id="subTaskFile"
                          hidden
                          multiple
                        />
                        <BootstrapTooltip title="Attachment" placement="top">
                          <label htmlFor="subTaskFile" className=" d-flex">
                            <IoMdAttach />
                          </label>
                        </BootstrapTooltip>
                      </div>
                      <Button
                        onClick={() => {
                          setShowAddSubTasks(false);
                        }}
                        className="btn1"
                        variant="outlined">
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn2"
                        variant="contained">
                        Save
                      </Button>
                    </div>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        );
      };
      const SubTask = ({sub, idx}) => {
        const [isEditing, setIsEditing] = useState(false);
        const [name, setName] = useState(sub.name);
        const [docs, setDocs] = useState(sub.SubTaskAttachments || []);
        const [newDocs, setNewDocs] = useState([]);
        const [startDate, setStartDate] = useState(
          sub?.start_date ? moment(sub?.start_date).format('YYYY-MM-DD') : null,
        );
        const [endDate, setEndDate] = useState(
          sub?.start_date ? moment(sub?.end_date).format('YYYY-MM-DD') : null,
        );
        const handeUpdateSubTask = e => {
          if (e.key && (e.key !== 'Enter' || !name.length)) return;
          //showing up loader
          Block.circle('.sub_tasks', 'Loading...');
          //preparing form data
          let formdata = new FormData();
          //adding form data
          formdata.append('name', name);
          formdata.append('task_id', taskId);
          if (startDate) formdata.append('start_date', moment(startDate));
          if (endDate) formdata.append('end_date', moment(endDate));
          //adding attachments
          Array.from(newDocs).forEach(file =>
            formdata.append('attachments[]', file),
          );
          //initializing network request
          Axios.put(`maxproject/sub_task/${sub.id}`, formdata)
            .then(({data}) => {
              Block.remove('.sub_tasks');
              Notify.success(data.message);

              setSubTasks(old => {
                let newData = old.slice();
                newData[idx] = data?.SubTask;
                return newData;
              });
            })
            .catch(err => {
              //removing loader
              Block.remove('.sub_tasks');
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        const handleFileChange = e => {
          let files = e.target.files;
          setNewDocs(old => [...old, ...files]);
        };
        const handleRemoveNewDoc = fileToRemove => {
          const filesArray = Array.from(newDocs);
          const updatedFilesArray = filesArray.filter(
            file => file !== fileToRemove,
          );
          setNewDocs(updatedFilesArray);
        };
        const handleRemoveDoc = doc => {
          Confirm.show(
            `Delete ${doc.original_name}`,
            `${doc.original_name} will be permanently deleted and can't be recovered.`,
            'Delete',
            'Cancel',
            () => {
              //request to delete file from server
              Axios.delete(`maxproject/sub_task/attachment/${doc.id}`, {
                data: {task_id: taskId},
              }).then(({data}) => {
                Notify.success(data.message);
                setDocs(old => {
                  let arr = old.slice();
                  const newArr = arr.filter(d => d.id !== doc.id);
                  return newArr;
                });
              });
            },
            () => {},
            {},
          );
        };
        const handleMarkComplete = () => {
          const proceedChange = () => {
            //showing up loader
            Block.circle('.sub_tasks', 'Loading...');
            Axios.put(`maxproject/sub_task/${sub.id}/update_complete_status`, {
              isCompleted: 1,
              task_id: taskId,
            })
              .then(({data}) => {
                Block.remove('.sub_tasks');
                // Notify.success(data.message);
                setSubTasks(old => {
                  let newData = old.slice();
                  newData[idx].isCompleted = true;
                  newData[idx].completed_on = new Date();
                  return newData;
                });
                if (data?.taskTimerPaused) {
                  // Calculate the sum of durations for TaskTimers of each member
                  const totalDuration = data.UserTimers.reduce(
                    (sum, timer) => sum + timer.duration,
                    0,
                  );
                  // Assign the total duration to a new property in each member object
                  const lastTimer = data.UserTimers?.[0] || null;
                  setTaskData(old => ({
                    ...old,
                    userTime: {
                      TaskTimers: data.UserTimers,
                      totalDuration,
                      lastTimer,
                    },
                  }));
                  //updating tasks
                  dispatch(setGlobal({refreshTasks: !refreshTasks}));
                }
              })
              .catch(err => {
                //removing loader
                Block.remove('.sub_tasks');
                //checking for any error message
                if (err?.response?.data?.message)
                  Notify.failure(err?.response?.data?.message);
              });
          };
          Confirm.show(
            `Mark as completed?`,
            "This action can't be undone",
            'Yes',
            'No',
            proceedChange,
          );
        };
        const handleSendForApproval = () => {
          //navigating with params
          navigate(`/Management/approvals`, {
            state: {
              taskId,
              subTasks: [sub.id],
              projectId: taskData?.project_id,
              taskType: taskData?.project_id ? 'internal' : 'other',
              taskName: taskData.name,
            },
          });
          //closing task modal
          dispatch(setGlobal({showTask: false, taskId: null}));
        };
        //Components
        const Docs = () => {
          return (
            <div className="add_new_subtask_Uploaded_mai_div mb-3">
              {docs.map(doc => (
                <div className="item">
                  <div className="add_new_subtask_Uploaded_img_div">
                    <img src={getFileIcon(doc)} alt="Uploaded photo" />
                    <p>
                      {doc.original_name.length > 10
                        ? doc.original_name?.substr(0, 10) + '...'
                        : doc.original_name}
                    </p>

                    {isEditing ? (
                      <span
                        className="close_btn"
                        onClick={() => handleRemoveDoc(doc)}>
                        <IoClose />
                      </span>
                    ) : null}
                    <div className="add_new_subtask_Uploaded_img_div_overlay">
                      <div className="modal_img_view_btn_div">
                        <a
                          className="modal_img_view_btn me-1"
                          href={getFileIcon(doc)}
                          download>
                          <MdFileDownload />
                        </a>
                        <ModalImgView
                          title={doc.original_name}
                          downloadItem={getFileIcon(doc)}
                          modalUi={
                            doc.type === 'application/pdf' ? (
                              <object
                                data={doc.url}
                                style={{
                                  width: '100%',
                                  height: '35rem',
                                }}></object>
                            ) : (
                              <img
                                className="sub_task_modul_img_div"
                                src={getFileIcon(doc)}
                                alt="img"
                              />
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {isEditing
                ? Array.from(newDocs).map((file, i) => (
                    <div className="item">
                      <div className="add_new_subtask_Uploaded_img_div">
                        <img src={getFileIcon(file, true)} alt="Uploaded doc" />
                        <p>
                          {file.name.length > 10
                            ? file.name?.substr(0, 10) + '...'
                            : file.name}
                        </p>
                        {isEditing ? (
                          <span
                            className="close_btn"
                            onClick={() => handleRemoveNewDoc(file)}>
                            <IoClose />
                          </span>
                        ) : null}
                        <div className="add_new_subtask_Uploaded_img_div_overlay">
                          <div className="modal_img_view_btn_div">
                            <ModalImgView
                              title={file.name}
                              downloadItem={getFileIcon(file, true)}
                              modalUi={
                                <img
                                  className="sub_task_modul_img_div"
                                  src={getFileIcon(file, true)}
                                  alt="img"
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          );
        };
        const SubTaskComment = ({comment, idx}) => {
          return (
            <div key={idx} style={{marginLeft: '1rem'}}>
              <div className="sub_task_approval_request_details_commants_view_div">
                <img src={CommantIcon} alt="icon" />
                <p className="sub_task_approval_request_details_commants_view">
                  {comment?.comment}
                </p>
              </div>
              <Docs data={comment?.ApprovalSubTaskCommentAttachments || []} />
            </div>
          );
        };
        const ModalViewFeedback = () => {
          const [open, setOpen] = useState(false);
          const [size, setSize] = useState();
          const [comments, setComments] = useState([]);
          const [isDataLoaded, setIsDataLoaded] = useState(false);
          //hooks
          useEffect(() => {
            if (!open || isDataLoaded) return false;
            //showing loader
            Block.circle('.feedbacks_modal', 'Loading...');
            //getting comments
            Axios.fetch(`maxproject/sub_task/${sub.id}/feedbacks`)
              .then(({data}) => {
                //removing loader
                Block.remove('.feedbacks_modal');
                setComments(data?.Comments);
                setIsDataLoaded(true);
              })
              .catch(err => {
                Block.remove('.feedbacks_modal');
                //checking for any error message
                if (err?.response?.data?.message)
                  Notify.failure(err?.response?.data?.message);
              });
          }, [open]);

          const handleOpen = value => {
            setSize(value);
            setOpen(true);
          };
          const handleClose = () => setOpen(false);

          return (
            <>
              <ButtonToolbar>
                <BootstrapTooltip title="View feedback" placement="top">
                  <label
                    size="sm"
                    className="show_btn ms-0"
                    onClick={() => handleOpen('sm')}>
                    <MdOutlineMessage />
                  </label>
                </BootstrapTooltip>
              </ButtonToolbar>
              <Modal size={size} open={open} onClose={handleClose}>
                <Modal.Body
                  className="sub_task_modul_body_div feedbacks_modal"
                  style={{maxHeight: '35rem'}}>
                  <p className="sub_task_modul_title_div">
                    Final Status: {sub?.approvalStatus}
                  </p>
                  <hr />
                  {comments?.map((Comment, i) => (
                    <>
                      <IoMdClose
                        className="sub_task_modul_top_close_btn_div"
                        onClick={handleClose}
                      />
                      {/* ui */}
                      <ul className="feedback_modal_ul">
                        <li>
                          <img
                            className="feedback_modal_ul_icon"
                            src={Comment?.User?.avatar}
                            alt={Comment?.User?.name}
                          />
                          <div className="feedback_modal_ul_main_div">
                            <p className="feedback_modal_ul_name mb-2">
                              {Comment?.User?.name}
                              <span>({Comment?.User?.Designation?.name})</span>
                            </p>
                            {Comment?.type == 'STATUS_CHANGE' ? (
                              <span
                                className="feedback_modal_ul_requested"
                                style={{
                                  backgroundColor: getStatusBackgroundColor(
                                    Comment?.status,
                                  ),
                                  borderColor: getStatusBorderColor(
                                    Comment?.status,
                                  ),
                                }}>
                                {Comment?.status}
                              </span>
                            ) : (
                              <SubTaskComment comment={Comment} idx={i} />
                            )}
                            <p className="feedback_modal_ul_time">
                              <TbCalendarPlus />
                              {moment(Comment?.createdAt).calendar()}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </>
                  ))}

                  {/* ui end */}
                </Modal.Body>
              </Modal>
            </>
          );
        };
        return (
          <div className="col-12 p-0">
            <div
              className="row m-0 task_detail_top_main_div new_task_detail_div"
              // style={{backgroundColor: getSubTaskBgColor(sub?.approvalStatus)}}
            >
              {isEditing ? null : sub?.isMarked ? (
                <div className="col-1 col-1 p-0 task_detail_chakbox_new">
                  <FormControlLabel
                    className="sub_task_checkbox"
                    style={{display: 'block'}}
                    control={
                      <Checkbox
                        checked={sub?.isMarked}
                        onChange={e =>
                          handleChangeCheckedStatus(e.target.checked, idx)
                        }
                      />
                    }
                  />
                </div>
              ) : (
                <div className="col-1 col-1 p-0 task_detail_chakbox_new">
                  <FormControlLabel
                    className="sub_task_checkbox"
                    control={
                      <Checkbox
                        disabled={sub?.created_by !== user?.id}
                        checked={sub?.isMarked}
                        onChange={e =>
                          handleChangeCheckedStatus(e.target.checked, idx)
                        }
                      />
                    }
                  />
                  <img
                    className="task_icon"
                    src={getSubTaskIcon(sub?.approvalStatus)}
                    alt="icon"
                  />
                </div>
              )}
              <div className={isEditing ? 'col-12 p-0 ' : 'col-11 p-0'}>
                <div className="row m-0">
                  <div
                    className={
                      isEditing
                        ? 'col-12 p-0 task_detail_text_div_top_col'
                        : 'col-6 p-0 task_detail_text_div_top_col'
                    }>
                    <div
                      className={
                        isEditing
                          ? 'task_detail_text_div ms-0 w-100'
                          : 'task_detail_text_div'
                      }>
                      <div className="w-100">
                        {isEditing ? (
                          <div className="inner_edite_input w-100">
                            <span>
                              <img
                                className="task_icon"
                                src={TaskIcon}
                                alt="icon"
                              />
                            </span>
                            <Input
                              value={name}
                              onChange={value => setName(value)}
                              className="w-100"
                              placeholder="Subtask name"
                            />
                          </div>
                        ) : (
                          <p>
                            {sub.completed_on ? (
                              <del>{sub?.name}</del>
                            ) : (
                              sub?.name
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 p-0 ">
                    <div className="task_detail_main_btn_div">
                      {isEditing ? null : (
                        <>
                          {sub.completed_on ? null : (
                            <>
                              {sub?.created_by == user?.id ? (
                                <>
                                  <DeleteModalInner
                                    title={sub.name}
                                    id={sub.id}
                                    idx={idx}
                                  />
                                  <BootstrapTooltip
                                    title="Edit"
                                    placement="top">
                                    <label
                                      className="show_btn"
                                      onClick={setIsEditing}>
                                      <MdOutlineModeEdit />
                                    </label>
                                  </BootstrapTooltip>
                                  <BootstrapTooltip
                                    title="Mark complete"
                                    placement="top">
                                    <label
                                      className="show_btn ms-0"
                                      onClick={handleMarkComplete}>
                                      <IoMdCheckmark />
                                    </label>
                                  </BootstrapTooltip>
                                </>
                              ) : null}
                            </>
                          )}
                          <ModalViewFeedback />
                          {(sub?.approvalStatus &&
                            sub?.approvalStatus !== 'REJECTED') ||
                          sub?.created_by !== user?.id ? null : (
                            <BootstrapTooltip
                              title="Send for approval"
                              placement="top">
                              <label
                                className="show_btn ms-0"
                                onClick={handleSendForApproval}>
                                <MdOutlineApproval />
                              </label>
                            </BootstrapTooltip>
                          )}
                        </>
                      )}
                      {isEditing ? null : (
                        <p className="add_new_subtask_date_picker_show_div">
                          {sub?.start_date && sub?.end_date ? (
                            <>
                              <TbCalendarPlus />
                              {moment(sub?.start_date).format('MMM D')} -{' '}
                              {moment(sub?.end_date).format('MMM D')}
                            </>
                          ) : null}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {isEditing ? (
                  <div className="row g-3" style={{marginTop: '-10px'}}>
                    <div className="col-6">
                      <label className="all_page_my_label_new">
                        Start Date*
                      </label>
                      <input
                        className="form-control intput_box"
                        type="date"
                        min={moment(taskData?.startDate).format('YYYY-MM-DD')}
                        max={moment(endDate || taskData?.end_date).format(
                          'YYYY-MM-DD',
                        )}
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="col-6">
                      <label className="all_page_my_label_new">End Date*</label>
                      <input
                        className="form-control intput_box"
                        type="date"
                        value={endDate}
                        min={moment(startDate || taskData?.startDate).format(
                          'YYYY-MM-DD',
                        )}
                        max={moment(taskData?.end_date).format('YYYY-MM-DD')}
                        onChange={e => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                ) : null}
                <Docs />
                {isEditing ? (
                  <div className="add_new_subtask_top_ui_btn_div">
                    <div className="task_detail_main_btn_div">
                      <input
                        type="file"
                        onChange={handleFileChange}
                        multiple
                        id="subTaskFile2"
                        hidden
                      />
                      <BootstrapTooltip title="Attachment" placement="top">
                        <label htmlFor="subTaskFile2">
                          <IoMdAttach />
                        </label>
                      </BootstrapTooltip>
                    </div>
                    <Button
                      className="btn1"
                      variant="outlined"
                      onClick={() => {
                        setIsEditing(false);
                      }}>
                      Cancel
                    </Button>
                    <Button
                      onClick={handeUpdateSubTask}
                      className="btn2"
                      variant="contained">
                      Save
                    </Button>
                  </div>
                ) : null}
              </div>
              {!isEditing ? (
                <span
                  style={{
                    color: 'black',
                    fontSize: 12,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  Created by
                  <div className="task_details_user_avatar">
                    <Avatar
                      alt={sub.CreatedBy?.name}
                      src={sub.CreatedBy?.avatar}
                      sx={{width: 15, height: 15}}
                    />
                    <span>{sub.CreatedBy?.name}</span>
                  </div>
                  ,
                  <span style={{marginLeft: '1rem'}}>
                    {moment(sub?.createdAt).format('llll')}
                  </span>
                </span>
              ) : null}
              {Boolean(sub.completed_on) ? (
                <small
                  style={{
                    color: 'black',
                    fontSize: 12,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  Completed on ({moment(sub?.completed_on).format('llll')})
                </small>
              ) : null}
            </div>
          </div>
        );
      };
      return (
        <div className="row">
          <div className="col-12">
            {/* subtask new add ui */}
            {isTaskMember ? <NewSubTask /> : null}
            {/* subtask new add ui end */}
          </div>
          <div className="col-12 sub-tasks-table">
            <div className="row g-3 mt-3">
              <Filters />
              {selectedSubTasksCount ? <Actions /> : null}
            </div>
            {/*subtask data view ui */}
            {subTasks.length ? (
              <div className="task_scrol_div1">
                <div className="add_new_subtask_top_ui_div p-0 task_scrol_div2">
                  <Header />
                  <div className="row m-0">
                    <div className="col-12 p-0">
                      <div className="task_scrol_div4">
                        {subTasks
                          ?.slice((page - 1) * 5, 5 * page)
                          ?.map((sub, idx) => (
                            <SubTask sub={sub} idx={idx + (page - 1) * 5} />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {/*subtask data view ui end */}
            {subTasks?.length > 5 ? (
              <div className="projec_tabelt_list_pagination_div">
                <Stack spacing={2}>
                  <Pagination
                    onChange={(e, page) => setPage(page)}
                    count={Math.ceil(subTasks?.length / 5)}
                  />
                </Stack>
              </div>
            ) : null}
          </div>
        </div>
      );
    };
    const Issues = () => {
      const [issueName, setIssueName] = useState('');
      const [issues, setIssues] = useState(taskData?.TaskIssues || []);

      const handleIssueChange = (checked, subTaskId, idx) => {
        const proceedChange = () => {
          //showing up loader
          Block.circle('.issues', 'Loading...');
          Axios.put(
            `maxproject/task_issue/${subTaskId}/update_complete_status`,
            {
              isCompleted: checked ? 1 : 0,
              task_id: taskId,
            },
          )
            .then(({data}) => {
              Block.remove('.issues');
              // Notify.success(data.message);
              setIssues(old => {
                let newData = old.slice();
                newData[idx] = data.data;
                return newData;
              });
            })
            .catch(err => {
              //removing loader
              Block.remove('.issues');
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };

        Confirm.show(
          'Mark as completed?',
          "This action can't be undone",
          'Yes',
          'No',
          proceedChange,
        );
      };
      const handleAddNewIssue = e => {
        if (e.key && (e.key !== 'Enter' || !issueName.length)) return;
        //showing up loader
        Block.circle('.issues', 'Loading...');
        //preparing payload
        const payload = {
          name: issueName,
          task_id: taskId,
        };
        //initializing network request
        Axios.post(`maxproject/task_issue/${taskId}`, payload)
          .then(({data}) => {
            Block.remove('.issues');
            Notify.success(data.message);
            setIssueName('');
            setIssues(old => {
              let newData = old.slice();
              newData.push(data.issue);
              return newData;
            });
          })
          .catch(err => {
            //removing loader
            Block.remove('.issues');
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      };
      const handleDelete = (id, idx) => {
        const proceedDelete = () => {
          //showing up loader
          Block.circle('.issues', 'Loading...');
          //initializing network request
          Axios.delete(`maxproject/task_issue/${id}`, {data: {task_id: taskId}})
            .then(({data}) => {
              Block.remove('.issues');
              Notify.success(data.message);
              setIssues(old => {
                let newData = old.slice();
                newData.splice(idx, 1);
                return newData;
              });
            })
            .catch(err => {
              //removing loader
              Block.remove('.issues');
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        Confirm.show(
          'Confirm Delete',
          "This action can't be undone",
          'Delete',
          'Cancel',
          proceedDelete,
        );
      };
      const Issue = ({issue, idx}) => {
        const [isEditing, setIsEditing] = useState(false);
        const [name, setName] = useState(issue.name);
        const handleUpdateIssue = e => {
          if (e.key && (e.key !== 'Enter' || !name.length)) return;
          //showing up loader
          Block.circle('.issues', 'Loading...');
          //preparing payload
          const payload = {
            name,
            task_id: taskId,
          };
          //initializing network request
          Axios.put(`maxproject/task_issue/${issue.id}`, payload)
            .then(({data}) => {
              Block.remove('.issues');
              Notify.success(data.message);

              setIssues(old => {
                let newData = old.slice();
                newData[idx].name = name;
                return newData;
              });
            })
            .catch(err => {
              //removing loader
              Block.remove('.issues');
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        return (
          <ul className="issues_top_new_div" key={idx}>
            <li>
              {isEditing ? (
                <div className="">
                  <input
                    className="form-control intput_box my-2"
                    type="text"
                    value={name}
                    style={{height: 30}}
                    onChange={e => setName(e.target.value)}
                    onKeyDown={handleUpdateIssue}
                    placeholder="Update issue"></input>
                </div>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      readOnly
                      disabled={Boolean(issue.completed_on) || !isTaskMember}
                      onChange={e =>
                        handleIssueChange(e.target.checked, issue?.id, idx)
                      }
                      checked={Boolean(issue?.completed_on)}
                    />
                  }
                  label={
                    Boolean(issue?.completed_on) ? (
                      <strike style={{color: 'gray'}}>{issue?.name}</strike>
                    ) : (
                      issue?.name
                    )
                  }
                />
              )}
            </li>
            <li>
              <div
                className="issues_top_new_btn_div"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                {Boolean(issue.completed_on) ? null : isEditing ? (
                  <>
                    <span
                      onClick={handleUpdateIssue}
                      style={{
                        marginLeft: 10,
                        color: '#1a7fdd',
                        cursor: 'pointer',
                      }}>
                      Save
                    </span>
                    <span
                      onClick={() => setIsEditing(false)}
                      style={{
                        marginLeft: 10,
                        color: '#1a7fdd',
                        cursor: 'pointer',
                      }}>
                      Cancel
                    </span>
                  </>
                ) : isTaskMember ? (
                  <>
                    <button id className="me-1">
                      <FiEdit onClick={() => setIsEditing(!isEditing)} />
                    </button>
                    <button className="ms-1">
                      <RiDeleteBinLine
                        onClick={() => handleDelete(issue.id, idx)}
                      />
                    </button>
                  </>
                ) : null}
                {Boolean(issue.completed_on) ? (
                  <i>
                    <small
                      style={{
                        color: 'gray',
                        fontSize: 12,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      completed on (
                      {moment(issue?.completed_on).format('DD MMM')}) by
                      <div className="task_details_user_avatar">
                        <Avatar
                          alt={issue?.CompletedBy?.name}
                          src={issue?.CompletedBy?.avatar}
                          sx={{width: 15, height: 15}}
                        />
                        <span>{issue?.CompletedBy?.name}</span>
                      </div>
                    </small>
                  </i>
                ) : null}
                {!Boolean(issue.completed_on) && !isEditing ? (
                  <i>
                    <span
                      style={{
                        color: 'gray',
                        fontSize: 12,
                        marginLeft: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      added on {moment(issue?.createdAt).format('DD MMM')}{' '}
                      {issue?.CreatedBy ? (
                        <>
                          by
                          <div className="task_details_user_avatar">
                            <Avatar
                              alt={issue.CreatedBy?.name}
                              src={issue.CreatedBy?.avatar}
                              sx={{width: 15, height: 15}}
                            />
                            <span>{issue.CreatedBy?.name}</span>
                          </div>
                        </>
                      ) : null}
                    </span>
                  </i>
                ) : null}
              </div>
            </li>
          </ul>
        );
      };
      return (
        <div className="task_right_drawer_ui_body_description issues">
          <FormGroup>
            {issues?.map((issue, idx) => (
              <Issue issue={issue} idx={idx} />
            ))}
          </FormGroup>
          {isTaskMember ? (
            <>
              <div className="task_right_drawer_ui_body_Subtasks_input_div">
                <input
                  className="form-control intput_box"
                  type="text"
                  value={issueName}
                  onChange={e => setIssueName(e.target.value)}
                  onKeyDown={handleAddNewIssue}
                  placeholder="Create new issue"></input>
                {/* <RiDeleteBinLine /> */}
              </div>
              <button
                disabled={!issueName.length}
                onClick={handleAddNewIssue}
                className="text_button mt-3">
                <AiOutlinePlus />
                Add New Issue
              </button>
            </>
          ) : null}
        </div>
      );
    };
    const Comments = () => {
      const [comments, setComments] = useState(taskData?.TaskComments || []);
      const handleDeleteComment = (commentId, idx) => {
        const processDeleteComment = () => {
          //blocking comment section
          Block.dots('#comment-' + commentId);
          //initializing network request
          Axios.delete(`maxproject/task_comment/${commentId}`)
            .then(({data}) => {
              //removing loader
              Block.remove('#comment-' + commentId);
              //removing comment from state
              setComments(old => {
                let arr = old.slice();
                arr.splice(idx, 1);
                return arr;
              });
              //showinng popup
              Notify.success(data.message);
            })
            .catch(err => {
              //removing loader
              Block.remove('#comment-' + commentId);
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        Confirm.show(
          'Delete Comment',
          "This action can't be undone",
          'Yes',
          'No',
          processDeleteComment,
        );
      };
      const FilePreview = ({name, url, handleRemoveFile}) => (
        <div className="col-12">
          <div className="comments_typing_Attach_row">
            <div
              className="comments_typing_Attach_icon"
              style={{
                backgroundImage: `url(${url})`,
              }}></div>
            <p>{name}</p>
            <RiDeleteBinLine onClick={handleRemoveFile} />
          </div>
        </div>
      );
      const EditView = ({idx, comment, setIsEditing}) => {
        const [text, setText] = useState(comment.comment);

        const handleUpdateComment = () => {
          //blocking comment section
          Block.dots('#comment-' + comment.id);
          //initializing network request
          Axios.put(`maxproject/task_comment/${taskId}`, {
            comment: text,
            comment_id: comment.id,
          })
            .then(({data}) => {
              //removing loader
              Block.remove('#comment-' + comment.id);
              //removing editor
              setIsEditing(false);
              //updating comment from state
              setComments(old => {
                let arr = old.slice();
                arr[idx].comment = text;
                return arr;
              });
              //showinng popup
              Notify.success(data.message);
            })
            .catch(err => {
              //removing loader
              Block.remove('#comment-' + comment.id);
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        return (
          <>
            <form
              method="post"
              encType="multipart/form-data"
              className="comments_typing_div pb-3 pt-md-3 pt-2 new_comment"
              // onSubmit={handleNewComment}
            >
              <div className=" w-100">
                <div className="comments_typing_inner_div">
                  <div className="p-2">
                    <NewEditor
                      value={text}
                      placeholder="Enter your comment..."
                      onChange={text => setText(text)}
                    />
                  </div>
                </div>
              </div>
            </form>
            <div style={{marginBottom: '1rem'}}>
              <span
                onClick={handleUpdateComment}
                style={{
                  marginLeft: 10,
                  color: '#1a7fdd',
                  cursor: 'pointer',
                }}>
                Save
              </span>
              <span
                onClick={() => setIsEditing(false)}
                style={{
                  marginLeft: 10,
                  color: '#1a7fdd',
                  cursor: 'pointer',
                }}>
                Cancel
              </span>
            </div>
          </>
        );
      };

      const Comment = ({idx, comment, children}) => {
        const [isEditing, setIsEditing] = useState(false);

        return (
          <div className="comments_menu_top_div" id={`comment-${comment.id}`}>
            <ul className="comments_user_ui">
              <li>
                <img src={comment?.User?.avatar} alt="user" />
              </li>
              <li className="comments_menu_outer_div">{comment?.User?.name}</li>
            </ul>
            <div className="comments_img_inner">
              {isEditing ? (
                <EditView
                  idx={idx}
                  comment={comment}
                  setIsEditing={setIsEditing}
                />
              ) : (
                <div>
                  {children}
                  <div>
                    <ul className="comments_menu_time_div">
                      <li className="comments_menu_time">
                        {moment(comment?.createdAt).format('DD MMM')}
                        {user.id == comment?.user_id ? (
                          <span className="comments_menu_time_div_inner">
                            <div className="d-flex">
                              <button
                                className="comments_menu_btn"
                                onClick={() => setIsEditing(true)}>
                                Edit
                              </button>
                              <button
                                className="comments_menu_btn"
                                onClick={() =>
                                  handleDeleteComment(comment.id, idx)
                                }>
                                Delete
                              </button>
                            </div>
                          </span>
                        ) : null}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      };
      const CommentText = ({text}) => {
        return (
          <div className="comments_user_ui_text">{ReactHtmlParser(text)}</div>
        );
      };
      const CommentDocs = ({docs = []}) => (
        <div className="comments_doc_div">
          {docs.map(doc =>
            doc.type == 'image/jpeg' || doc.type == 'image/png' ? (
              <div className="comments_img_div">
                <div
                  className="comments_img"
                  style={{backgroundImage: `url('${doc.url}')`}}></div>
                <div className="comments_img_name">{doc.original_name}</div>
                <div className="comments_img_name_download_div">
                  <div className="comments_img_name_download">
                    <NavLink to={doc.url} target="_blank" download>
                      <span>
                        <HiOutlineDownload />
                      </span>
                    </NavLink>
                  </div>
                </div>
              </div>
            ) : (
              <div className="new_comments_doc_div">
                <div className="new_comments_doc_img_top_div">
                  <div className="new_comments_doc_img_div">
                    <div
                      className="new_comments_doc_div_img"
                      style={{
                        backgroundImage: `url(${getFileIcon(doc)})`,
                      }}></div>
                  </div>
                  <div className="new_comments_doc_text_div">
                    <p>{doc.original_name}</p>
                  </div>
                  <div className="new_comments_doc_download_div">
                    <NavLink to={doc.url} target="_blank" download>
                      <span>
                        <HiOutlineDownload />
                      </span>
                    </NavLink>
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      );
      const NewComment = () => {
        const [showCommentOptions, setShowCommentOptions] = useState(false);
        const [fileInput, setFileInput] = useState([]);
        const [comment, setComment] = useState('');
        const handleNewComment = e => {
          e.preventDefault();
          //blocking comment section
          Block.circle('.new_comment');
          //preparing form data
          let formdata = new FormData();
          //adding form data
          formdata.append('comment', comment);
          //adding attachments
          Array.from(fileInput).forEach(file =>
            formdata.append('attachments[]', file),
          );
          //initializing network request
          Axios.post(`maxproject/task_comment/${taskId}`, formdata)
            .then(({data}) => {
              //removing loader
              Block.remove('.new_comment');
              //reseting fields
              setComment('');
              setFileInput([]);
              setShowCommentOptions(false);
              setComments(old => [...old, data?.newComment]);
              //showinng popup
              Notify.success(data.message);
            })
            .catch(err => {
              //removing loader
              Block.remove('.new_comment');
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        function removeFileFromList(fileToRemove) {
          const filesArray = Array.from(fileInput);
          const updatedFilesArray = filesArray.filter(
            file => file !== fileToRemove,
          );
          setFileInput(updatedFilesArray);
        }
        const onAttachmentChange = e => {
          let files = e.target.files;
          setFileInput(files);
        };
        const CommentOptions = () => (
          <div className="comments_typing_btn_div">
            {Array.from(fileInput).length ? (
              <div className="comments_typing_Attach_div">
                <p>Attachments</p>
                <div className="comments_typing_Attach_scroll">
                  <div className="row">
                    {Array.from(fileInput).map((file, i) => (
                      <FilePreview
                        key={i}
                        name={file.name}
                        url={getFileIcon(file)}
                        handleRemoveFile={() => removeFileFromList(file)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="comments_typing_btn_top_div">
              <div className="row align-items-center">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                  <label
                    htmlFor="comment_attachemnt_input"
                    className="comments_typing_btn_attach">
                    <IoIosAttach />
                    Attach Files
                  </label>
                  <input
                    type="file"
                    name="attachments"
                    style={{display: 'none'}}
                    multiple={true}
                    id="comment_attachemnt_input"
                    onChange={onAttachmentChange}
                  />
                </div>
                <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                  <div className="comments_typing_btn_inner_div">
                    <FullBtn name="Add Comment" />
                    <OutlineBtn
                      onClick={() => setShowCommentOptions(false)}
                      name="Cancel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        return (
          <form
            method="post"
            encType="multipart/form-data"
            className="comments_typing_div pb-3 pt-md-3 pt-2 new_comment"
            onSubmit={handleNewComment}>
            <div
              className="comments_img"
              style={{backgroundImage: `url(${user.avatar})`}}></div>
            <div className=" w-100">
              <div className="comments_typing_inner_div">
                <NewEditor
                  value={comment}
                  placeholder="Enter you comment..."
                  onChange={text => setComment(text)}
                  onFocus={() => setShowCommentOptions(true)}
                />
              </div>
              {showCommentOptions ? <CommentOptions /> : null}
            </div>
          </form>
        );
      };
      return (
        <div className="comments">
          {comments.map((comment, i) => (
            <Comment key={i} idx={i} comment={comment}>
              <CommentText text={comment?.comment} />
              <CommentDocs docs={comment?.TaskCommentAttachments} />
            </Comment>
          ))}
          <NewComment />
        </div>
      );
    };
    return (
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="task_right_drawer_ui_body_tab">
          <Nav variant="pills" className="d-flex">
            <Nav.Item>
              <Nav.Link eventKey="first">
                <TbSubtask />
                Subtasks
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
                <GoIssueOpened />
                Issues
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Comments">
                <MdOutlineComment />
                Comments
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className=" mt-3">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <SubTasks taskMembers={taskData?.TaskMembers} />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <Issues />
            </Tab.Pane>
            <Tab.Pane eventKey="Comments">
              <Comments />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  };

  return (
    <div className="task_right_drawer_ui_main_div task_detail">
      <div className="task_right_drawer_ui_header_div">
        <IoClose
          className="close_svg"
          onClick={() => dispatch(setGlobal({showTask: false, taskId: null}))}
        />
        <div className="task_right_drawer_ui_inner_div">
          <button
            disabled={!isTaskMember}
            onClick={handleTaskCompleteStatus}
            className={
              taskData?.userTime?.lastTimer?.isCompleted
                ? 'task_details_complete_btn task_details_complete_btn2'
                : 'task_details_complete_btn'
            }>
            {taskData?.userTime?.lastTimer?.isCompleted ? (
              <>
                <FaCheck />
                <span>completed</span>
              </>
            ) : (
              <>
                <FaCheck />
                <span>Mark complete</span>
              </>
            )}
          </button>
          <TimerComponets />
          {isTaskMember ? (
            <>
              <NavLink
                onClick={() =>
                  dispatch(setGlobal({showTask: false, taskId: null}))
                }
                to={`/Management/${
                  taskData?.Project ? 'update_task' : 'update_other_task'
                }/${taskId}`}>
                <MdOutlineModeEditOutline />
              </NavLink>
              <HeaderMenu />
            </>
          ) : null}
        </div>
      </div>
      <div className="task_right_drawer_ui_body_div">
        {taskData?.Project ? (
          <div className="task_right_drawer_ui_body_nav_div">
            <p>{taskData?.Project?.name}</p>
            <span>
              <FiChevronRight />
            </span>
            <p>{taskData?.Department?.name}</p>
            <span>
              <FiChevronRight />
            </span>
            <p>{taskData?.ProjectService?.Service?.name}</p>
            <span>
              <FiChevronRight />
            </span>
            <p>{taskData?.Milestone?.title}</p>
          </div>
        ) : null}
        <div className="task_right_drawer_ui_body_project_title">
          <p>{taskData?.name}</p>
          <div className=" d-flex mt-1">
            <div
              className="task_right_drawer_ui_body_user_add"
              style={{marginRight: 0}}>
              Added by <b>{taskData?.User?.name}</b> <span>on</span>
            </div>
            <div className="task_right_drawer_ui_body_user_add_date">
              {moment(taskData?.createdAt).format('DD MMM')}
            </div>
            {taskData?.completedOn ? (
              <>
                <div
                  className="task_right_drawer_ui_body_user_add"
                  style={{marginLeft: '1rem', marginRight: '6px'}}>
                  Completed on
                </div>
                <div className="task_right_drawer_ui_body_user_add_date">
                  {moment(taskData?.completedOn).format('DD MMM')}
                </div>
              </>
            ) : null}
          </div>
        </div>
        <ul className="task_right_drawer_ui_body_created">
          <li className="li_1">
            <p>Created By</p>
            <div className="team_members_data_ui_div">
              <img src={taskData?.User?.avatar} alt="img" />
              <p
                className="mb-0 ms-2 "
                style={{fontSize: '16px', color: 'black'}}>
                {taskData?.User?.name}
              </p>
            </div>
          </li>
          {taskData?.TaskOwner ? (
            <li className="li_1">
              <p>Owner</p>
              <div className="team_members_data_ui_div">
                <img src={taskData?.TaskOwner?.User?.avatar} alt="img" />
                <p
                  className="mb-0 ms-2 "
                  style={{fontSize: '16px', color: 'black'}}>
                  {taskData?.TaskOwner?.User?.name}
                </p>
              </div>
            </li>
          ) : null}
          <li className=" ms-md-3 ms-0 mt-md-0 mt-3">
            <p>Assigned To</p>
            <div className="team_members_data_ui_div">
              <UserParticipants2 data={taskData?.TaskMembers || []} />
            </div>
          </li>
        </ul>
        <ul className="task_right_drawer_ui_body_created2">
          <li>
            <p>Start Date</p>
            <span>{moment(taskData?.start_date).format('DD MMM')}</span>
          </li>
          <li className=" ms-md-5 ms-0 mt-md-0 mt-3">
            <p>Due Date</p>
            <span>{moment(taskData?.end_date).format('DD MMM')}</span>
          </li>
          <li className=" ms-md-5 ms-0 mt-md-0 mt-3">
            <p>Progress</p>
            <span>
              <b>
                {taskData?.isCompleted ? 100 : getTaskProgress()}% Completed
              </b>
            </span>
          </li>
          <li className=" ms-md-5 ms-0 mt-md-0 mt-3">
            <p>Priority</p>
            <p
              style={{backgroundColor: taskData?.Priority?.bg_color}}
              className="task_right_drawer_ui_body_Priority ">
              <span style={{color: taskData?.Priority?.text_color}}>
                {taskData?.Priority?.name}
              </span>
            </p>
          </li>
          <li className=" ms-md-5 ms-0 mt-md-0 mt-3">
            <p>Delay</p>
            <span style={{color: 'red'}}>
              <b>
                {delay > 0 && !taskData?.isCompleted
                  ? `${delay} day${delay > 1 ? 's' : ''}`
                  : '- -'}
              </b>
            </span>
          </li>
        </ul>
        <div className="profile_switch_top">
          <div className="task_right_drawer_ui_body_Dependency">
            <p>Dependency</p>
            <label className="switch">
              <input
                type="checkbox"
                className="switch_input"
                readOnly
                disabled={!isTaskMember}
                checked={Boolean(taskData?.dependency)}
                onChange={e => handleTaskDependencyStatus(e)}></input>
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        {taskData?.dependency ? (
          <div className="task_right_drawer_ui_body_Dependency_show">
            <FullBtn
              name="Create Dependent Task"
              link="/Management/create_task"
            />
            <div className="task_right_drawer_ui_body_Dependency_show_text">
              <div className="task_right_drawer_ui_body_Dependency_select">
                <p>Blocked By</p>
                <select
                  onChange={e =>
                    onTaskBlockageChange({blockedByTask: e.target.value})
                  }
                  class="form-select">
                  <option value={0}>Select Task</option>
                  {taskList?.map(task => (
                    <option
                      selected={taskData?.blockedByTask == task.id}
                      value={task.id}>
                      {task.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="task_right_drawer_ui_body_Dependency_select">
                <p>Blocking</p>
                <select
                  onChange={e =>
                    onTaskBlockageChange({blockingTask: e.target.value})
                  }
                  class="form-select">
                  <option value={0}>Select Task</option>
                  {taskList?.map(task => (
                    <option
                      selected={taskData?.blockingTask == task.id}
                      value={task.id}>
                      {task.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        ) : null}
        {blockingTask.length ? (
          <div className="task_right_drawer_ui_body_Dependency_select">
            <p>Blocked By Tasks:</p>
            {blockingTask
              ?.map(task => <i>{task.name}</i>)
              .reduce((prev, curr) => [prev, ', ', curr])}
          </div>
        ) : null}
        <hr />
        <div className="task_right_drawer_ui_body_description">
          <h3>
            <TbFileDescription /> Description{' '}
            {editDesc || !isTaskMember ? null : (
              <EditOutlinedIcon
                style={{marginLeft: 10, cursor: 'pointer'}}
                onClick={handleEditDesc}
              />
            )}
          </h3>
          {editDesc ? (
            <div className="task_desc">
              <NewEditor
                placeholder="Describe the details of the task/ Write about the task in brief"
                value={newTaskDesc}
                onChange={desc => setNewTaskDesc(desc)}
              />

              <Button
                onClick={handleUpdateDesc}
                style={{marginTop: 8}}
                variant="outlined">
                Save
              </Button>
              <Button
                onClick={handleCancelUpdateDesc}
                style={{marginTop: 8, marginLeft: 6}}>
                Cancel
              </Button>
            </div>
          ) : (
            ReactHtmlParser(taskData?.desc)
          )}
          <hr />
        </div>

        {taskData?.TaskAttachments?.length ? (
          <div className="task_right_drawer_ui_body_description">
            <h3>
              <ImAttachment /> Attachments
            </h3>
            <div className="row g-2">
              {taskData?.TaskAttachments?.map((val, i) => (
                <DocumentsAttachments
                  cols="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6"
                  key={i}
                  original_name={val.original_name}
                  downloadUrl={val.url}
                  url={getFileIcon(val)}
                  handleDelete={() => handleDeleteAttachment(i, val)}
                />
              ))}
            </div>
            <hr />
          </div>
        ) : null}

        <TaskTabs />
      </div>
    </div>
  );
};
const getFileIcon = (file, crateImageObject = false) => {
  switch (file.type) {
    case 'image/jpeg':
      return crateImageObject ? URL.createObjectURL(file) : file.url;
    case 'image/png':
      return crateImageObject ? URL.createObjectURL(file) : file.url;
    case 'application/pdf':
      return CommentsPdfIcon;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return CommentsPdfIcon2;
    case 'application/ppx':
      return CommentsPdfIcon3;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return CommentsPdfIcon4;
    case 'application/zip':
      return CommentsPdfIcon5;
    default:
      return CommentsPdfIcon5;
  }
};
const handleDeleteTask = taskId => {
  Block.circle('.delete_modal');
  Axios.delete('/maxproject/task/internal_task/' + taskId)
    .then(({data}) => {
      Block.remove('.delete_modal');
      Notify.success(data.message);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    })
    .catch(err => {
      //removing loader
      Block.remove('.delete_modal');
      //checking for any error message
      if (err?.response?.data?.message)
        Notify.failure(err?.response?.data?.message);
    });
};
const getSubTaskIcon = status => {
  switch (status) {
    case 'PENDING':
      return RequestedIcon;
    case 'APPROVED':
      return Approvedicon;
    case 'REJECTED':
      return Rejectedicon;
    default:
      return TaskIcon;
  }
};
const getSubTaskBgColor = status => {
  switch (status) {
    case 'PENDING':
      return 'white';
    case 'APPROVED':
      return '#A5D6A7';
    case 'REJECTED':
      return '#FFCDD2';
    default:
      return 'white';
  }
};
const getStatusBorderColor = status => {
  switch (status) {
    case 'APPROVED':
      return '#3ca318';
    case 'REJECTED':
      return '#ff000073';
    case 'REASSIGNED':
      return '#1a7fdd';
    default:
      return '#1a7fdd';
  }
};
const getStatusBackgroundColor = status => {
  switch (status) {
    case 'APPROVED':
      return '#dfe9dc';
    case 'REJECTED':
      return '#ff000073';
    case 'REASSIGNED':
      return '#e8f2fc';
    default:
      return '#e8f2fc';
  }
};
