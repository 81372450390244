import './TimesheetProfileCard.scss';
import NoImagesFound from '../../../../pics/Management/noimagesfound.png';
import {useEffect} from 'react';
import {Block} from 'notiflix';
import Axios from './../../../../config/api';
import {useState} from 'react';
import moment from 'moment';

export const ProjectOverviewCard = props => {
  //states
  const [data, setData] = useState({});
  const [varianceData, setVarianceData] = useState({});
  //hooks
  useEffect(() => {
    fetchProjectOverviewData();
  }, [props?.projectId, props?.startDate, props?.endDate]);

  useEffect(() => {
    return () => {
      props?.setOverallProjectTime('Nil');
    };
  }, []);

  //functions
  const fetchProjectOverviewData = () => {
    //showing loader
    Block.circle('.projectOverviewCard');
    const q = new URLSearchParams({
      start_date: moment(props?.startDate).format('YYYY-MM-DD'),
      end_date: moment(props?.endDate).format('YYYY-MM-DD'),
    });
    //initializing network request
    Axios.fetch(
      `maxproject/timesheet/project_overview/${
        props?.projectId
      }?${q.toString()}`,
    )
      .then(({data}) => {
        Block.remove('.projectOverviewCard');
        setData(data?.projectData);
        setVarianceData(data?.variance_data);
      })
      .catch(err => {
        Block.remove('.projectOverviewCard');
      });
  };

  return (
    <div className="timesheet_profile_card_div projectOverviewCard">
      <div className="row g-2">
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 inner_card_div_1">
          <div>
            {data?.logo ? (
              <img src={data?.logo} alt={data?.name} />
            ) : (
              <img src={NoImagesFound} alt={data?.name} />
            )}
            <h3>{data?.name}</h3>
          </div>
          {/* <span>extra data</span> */}
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 inner_card_div_2">
          <div>
            <p>Total working time</p>
            <h3>{props?.overallProjectTime}</h3>
            <small>{props?.totalWorkingTimeSummary}</small>
          </div>
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 inner_card_div_2">
          <div>
            <p>Variance</p>
            <h3
              style={{
                color: 'black',
              }}>
              {varianceData?.variance
                ? varianceData?.variance > 0
                  ? `(+) Positive (${varianceData?.variance}%)`
                  : `(-) Negative (${varianceData?.variance}%)`
                : 'N/A'}
            </h3>
            {varianceData?.variance ? (
              <small>{`${varianceData?.tasks?.completdTasks} tasks completed out of ${varianceData?.tasks?.totalTasks}`}</small>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
