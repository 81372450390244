import {useState} from 'react';
import Select, {components} from 'react-select';
import {AlertFillIcon} from '@primer/octicons-react';
import {FaCaretDown} from 'react-icons/fa';
import './NewSelect.scss';
export const NewSelect = props => {
  const handleChange = selectedOption => {
    props?.setSelected(selectedOption);
  };
  const getFormatedV2 = (label, userInput) => {
    // Highlight letters
    return label
      ?.split(' ')
      .map(word =>
        word
          .split('')
          .map(c =>
            userInput.toLowerCase().includes(c.toLowerCase()) ? (
              <b style={{color: '#3656F4'}}>{c}</b>
            ) : (
              c
            ),
          ),
      );
  };
  const Option = props => {
    const userInput = props?.selectProps?.inputValue || '';
    const label = props?.data?.label || '';
    return (
      <components.Option {...props}>
        <div>
          {userInput?.length
            ? label?.split(' ').length
              ? getFormatedV2(label, userInput)
              : label
            : label}
        </div>
      </components.Option>
    );
  };
  const filterOption = (option, inputValue) => {
    const {label, value} = option;
    const otherKey = props.data.filter(
      opt => opt.label === label && opt.label.includes(inputValue),
    );
    return label.includes(inputValue) || otherKey.length > 0;
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <FaCaretDown />
      </components.DropdownIndicator>
    );
  };
  return (
    <>
      <div className="new_select_box">
        <label className="label">{props.label}</label>
        <Select
          options={props.data}
          isDisabled={props?.disabled}
          components={{DropdownIndicator, Option}}
          placeholder={props.placeholder}
          isMulti={props?.isMulti}
          // menuIsOpen
          // filterOption={filterOption}
          onChange={newValue => handleChange(newValue)}
          value={props?.selected}
        />
        {props.ErrorMessage ? (
          <small className="small">
            <span>
              <AlertFillIcon />
            </span>
            {props.ErrorMessage}
          </small>
        ) : null}
      </div>
    </>
  );
};
