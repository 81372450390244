import {useState, useEffect, useRef} from 'react';

import NavProfile from '../../../../../common/components/NavProfile/NavProfile';
import useClickOutside from '../../../../CustomHooks/ClickOutside';
import styles from './MyProfile.module.scss';
import UserProfile from '../../../../../common/components/img/Img';
import {AiTwotoneSetting} from 'react-icons/ai';
import {NavLink, useNavigate} from 'react-router-dom';
import SwitchInput from '../../../../../common/components/input/Input';
import {BiChevronDown} from 'react-icons/bi';
import UserBreks from './subPages/UserBreaks';
import UserInput from './subPages/UserInput';
import ProfileList from './subPages/ProfileList';
import ClearableSelect from 'react-select/creatable';
import {getUser, clearUser} from '../../../../../../redux/slices/userSlice';
import {setGlobal} from '../../../../../../redux/slices/globalSlice';
import {useSelector, useDispatch} from 'react-redux';
import {MemoryClient} from '../../../../../../utils';
import {Confirm} from 'notiflix/build/notiflix-confirm-aio';
import Axios from './../../../../../../config/api';
import {Block} from 'notiflix/build/notiflix-block-aio';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {getIconUrl} from '../../../../../../constants/helpers';
import moment from 'moment';
const MyProfile = () => {
  const Navigate = useNavigate();
  const [userBreks, setUserBreks] = useState(true);
  const [isOnline, setIsOnline] = useState(true);
  const [duration, setDuration] = useState(0);
  const [isProfileOpen, setisProfileOpen] = useState(false);
  const [newBreak, setNewBreak] = useState({type: null, time: '', options: []});
  const [userBreaks, setUserBreaks] = useState([]);
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const interval = useRef(null);

  useEffect(() => {
    fetchUserDropdown();
    return () => {};
  }, []);

  const fetchUserDropdown = () => {
    Axios.fetch('maxproject/user/navbar').then(({data}) => {
      let breakOptions = data.breakOptions.map(b => ({
        value: b.id,
        label: b.title,
      }));
      setNewBreak(old => ({...old, options: breakOptions}));
      setUserBreaks(data.userBreaks);
      setIsOnline(Boolean(data.isOnline));
      setDuration(Number(data.duration));
    });
  };
  const handleNewBreak = () => {
    Block.circle('.new_break');
    //preparing payload
    let payload = {duration: newBreak.time, breakType: newBreak.type};
    //initiating network request
    Axios.post('maxproject/user/break', payload).then(({data}) => {
      Block.remove('.new_break');
      setNewBreak(old => ({...old, type: null, time: 0}));
      setUserBreaks(data?.userBreaks);
      Notify.success(data.message);
    });
  };
  let domNode = useClickOutside(() => {
    setisProfileOpen(false);
  });
  useEffect(() => {
    if (isOnline)
      interval.current = setInterval(() => {
        setDuration(old => old + 1);
      }, 1000);
    else clearTimeout(interval.current);
    return () => {};
  }, [isOnline]);

  const toggleOnlineStatus = () => {
    Block.circle('.online_status');

    Axios.put('maxproject/user/online_status', {status: !isOnline}).then(
      ({data}) => {
        Block.remove('.online_status');
        setIsOnline(!isOnline);
        setDuration(Number(data.duration));
      },
    );
  };
  const openCloseBreaksDropdown = () => {
    setUserBreks(!userBreks);
  };
  const handleBreakTypeChange = value => {
    setNewBreak(old => ({...old, type: value}));
  };
  const handleBreakTimeChange = e => {
    setNewBreak(old => ({...old, time: e.target.value}));
  };
  const handleLogout = () => {
    Confirm.show(
      'Logout',
      'Do you really want to logout?',
      'Yes',
      'No',
      () => {
        MemoryClient.clearAll();
        dispatch(clearUser());
        dispatch(setGlobal({isLoggedIn: false}));
        Navigate('/');
      },
      () => {},
      {
        borderRadius: '5px',
        okButtonBackground: '#0f62fe',
        okButtonColor: 'white',
      },
    );
  };
  const ProfileListData = [
    {
      color: '#DA1E28',
      title: 'Maxlence HR Tool',
      date: '--',
    },
    {
      color: '#0043CE',
      title: 'Maxlence Tool',
      date: '--',
    },
    {
      color: '#24A148',
      title: 'Maxlence',
      date: '--',
    },
    {
      color: '#0043CE',
      title: 'Maxlence Tool',
      date: '--',
    },
  ];
  return (
    <div ref={domNode} className={styles.avatar_container}>
      <NavProfile
        onClick={() => {
          setisProfileOpen(!isProfileOpen);
        }}
      />
      <div
        className={`${styles.menu} ${isProfileOpen ? styles.menu_active : ''}`}>
        <div className=" position-relative">
          <div className="user_profile_top_card_div">
            <div className="row m-0">
              <div className="col-3">
                <div className="user_profile_img_div">
                  <UserProfile
                    className="user_profile_img"
                    backgroundImage={user.avatar}
                  />
                </div>
              </div>
              <div className="col-9">
                <div className="user_profile_imfo">
                  <p className="user_profile_name">{user.name}</p>
                  <p className="user_profile_work">{user.designation}</p>
                  <p className="user_profile_work">Employee ID: # {user.id}</p>
                  <NavLink to="#" className="user_profile_icon_div">
                    <AiTwotoneSetting className="user_profile_icon" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="profile_switch_main_div shadow-sm">
              <div className="row">
                <div className="col-6">
                  <div className="profile_switch_top">
                    <div>
                      <label className="switch">
                        <SwitchInput
                          readOnly
                          checked={isOnline}
                          type="checkbox"
                          className="switch_input"
                        />
                        <span
                          className="slider round"
                          onClick={toggleOnlineStatus}></span>
                      </label>
                    </div>
                    <div className="profile_switch_onlin_div">
                      <p className={`${isOnline ? 'offline2' : ' online2'}`}>
                        {isOnline ? 'Online' : 'Offline'}
                      </p>
                      {isOnline ? (
                        <span
                          className="user_breks_menu"
                          onClick={openCloseBreaksDropdown}>
                          <BiChevronDown />
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="profile_switch_time_div">
                    <p className={`${isOnline ? 'offline' : ' online'}`}>
                      {moment.utc(duration * 1000).format('HH:mm:ss')}
                    </p>
                  </div>
                </div>
              </div>
              {isOnline ? (
                <div
                  className={`${userBreks ? 'online3' : 'offline3'} new_break`}>
                  <div className="user_breks_main_div">
                    {userBreaks.map((val, i) => (
                      <UserBreks
                        key={i}
                        icon={getIconUrl(val?.BreakType?.icon || 'break.png')}
                        title={val.title || val?.BreakType?.title}
                        time={val.duration}
                        createdAt={val.createdAt}
                      />
                    ))}
                  </div>
                  <div className="user_input_main_div shadow-sm">
                    <div className="row m-0">
                      <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                        <span>Break Type</span>
                        <div style={{marginTop: 5}}>
                          <ClearableSelect
                            value={newBreak.type}
                            options={newBreak.options}
                            onChange={handleBreakTypeChange}
                            placeholder="Select break"
                            isClearable
                          />
                        </div>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                        <UserInput
                          min={0}
                          max={60}
                          value={newBreak.time}
                          onChange={handleBreakTimeChange}
                          title="time"
                          type="number"
                          className="form-control"
                          placeholder="Enter break time"
                        />
                      </div>
                      {newBreak.time > 0 && newBreak.type? (
                        <div className="col-12 text-center mt-3">
                          <button
                            onClick={handleNewBreak}
                            className="btn btn-primary">
                            Save
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {userBreks ? (
              <div className="profile_switch_main_div shadow-sm">
                <div className="profile_list_top_div">
                  {ProfileListData.map((val, i) => {
                    return (
                      <ProfileList
                        key={i}
                        color={val.color}
                        title={val.title}
                        date={val.date}
                      />
                    );
                  })}
                </div>
              </div>
            ) : null}

            <div className="logout_btn_div">
              <button onClick={handleLogout} className="logout_btn">
                log out
              </button>
            </div>
            {/* <div className=" text-center user_profile_bottom_link">
              <div className="row m-0">
                <div className="col-6">
                  <div className="user_profile_page_link">
                    <NavLink to="/">Privacy policy</NavLink>
                  </div>
                </div>
                <div className="col-6">
                  <div className="user_profile_page_link2">
                    <NavLink to="/">Terms and condition</NavLink>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
