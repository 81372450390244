import React, {useEffect} from 'react';
import Select from 'react-select';
import {useState} from 'react';
import TasksIcon from '../../../../../../pics/Management/TasksIcon.svg';
import ClientsIcon from '../../../../../../pics/Management/Clientsicon.svg';
import ChatsIcon from '../../../../../../pics/Management/Chatsuser.png';
import {PageAllTopTitle} from '../../../../../common/ManagementComponents/PageAllTopTitle';
import {AiOutlineRight} from 'react-icons/ai';
import {Link, useSearchParams} from 'react-router-dom';
import Axios from './../../../../../../config/api';
import {Block} from 'notiflix/build/notiflix-block-aio';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {getProjectFileUrl} from '../../../../../../constants';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import {setGlobal} from '../../../../../../redux/slices/globalSlice';

export const SearchResult = props => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchType, setSearchType] = useState('All');
  const [searchData, setSearchData] = useState({
    projects: [],
    tasks: [],
    clients: [],
  });

  const fetchSearchResults = () => {
    //showing up loader
    Block.circle('.search_results');
    //initializing network request
    Axios.fetch(`maxproject/search?${searchParams.toString()}`)
      .then(({data}) => {
        Block.remove('.search_results');
        //updating search data
        setSearchData({
          projects: data?.Projects,
          tasks: data?.Tasks,
          clients: data?.Clients,
        });
      })
      .catch(err => {
        //removing loader
        Block.remove('.search_results');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
    searchParams.toString();
  };

  useEffect(fetchSearchResults, [searchParams]);

  const UserChangeLabel = props => {
    return (
      <div className="view_change_label">
        {props.icon}
        <span>{props.title}</span>
      </div>
    );
  };
  const UserChange = [
    {
      value: 'All',
      label: <UserChangeLabel title="All" />,
    },
    {
      value: 'Projects',
      label: <UserChangeLabel title="Projects" />,
    },
    {
      value: 'Tasks',
      label: <UserChangeLabel title="Tasks" />,
    },
    {
      value: 'Clients',
      label: <UserChangeLabel title="Clients" />,
    },
    {
      value: 'Chats',
      label: <UserChangeLabel title="Chats" />,
    },
  ];
  return (
    <div className="custom_container">
      <PageAllTopTitle title="Search" />
      <div className="row g-3 ">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 order-md-1 order-2 ">
          <div className="all_user_page all_user_page_new ">
            <Select
              options={UserChange}
              defaultValue={UserChange[0]}
              isSearchable={false}
              onChange={({value}) => {
                setSearchType(value);
              }}
            />
          </div>
        </div>
      </div>
      {
        {
          All: (
            <>
              <ProjectsSearchResult data={searchData.projects} />
              <TasksSearchResult data={searchData.tasks} />
              <ClientsSearchResult data={searchData.clients} />
            </>
          ),
          Projects: <ProjectsSearchResult data={searchData.projects} />,
          Tasks: <TasksSearchResult data={searchData.tasks} />,
          Clients: <ClientsSearchResult data={searchData.clients} />,
        }[searchType]
      }
    </div>
  );
};

const ProjectsSearchResult = ({data}) => {
  const [limit, setLimit] = useState(3);
  return (
    <>
      <div className="search_result_bass_title_div">
        <h3>Projects</h3>
      </div>
      <div className="search_result_bass_div search_results">
        <div className="row g-3">
          {data?.length ? null : <span>No results</span>}
          {data?.slice(0, limit)?.map((proj, i) => {
            return (
              <Link
                to={`/Management/project_details/${proj.id}`}
                className="col-12"
                key={i}>
                <div
                  className="search_result_data_inner_top_div search_result_item"
                  style={{backgroundColor: '#EEFCFF'}}>
                  <div className="search_result_data_inner_div">
                    <img src={getProjectFileUrl(proj?.project_image)} />
                    <div>
                      <p className="mb-1">{proj.name}</p>
                      <div className="search_result_data_id">
                        <p className="mb-1">
                          {/* <span>{proj?.brief}</span> */}
                        </p>
                        {/* <p>{proj?.scope}</p> */}
                        <br />
                        <p>
                          Last Modified on {moment(proj?.updatedAt).calendar()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        {limit > data.length ? null : (
          <button
            className="search_result_div_btn"
            onClick={() => setLimit(old => old + 5)}>
            more
          </button>
        )}
      </div>
    </>
  );
};
const TasksSearchResult = ({data}) => {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(3);
  return (
    <>
      <div className="search_result_bass_title_div">
        <h3>Tasks</h3>
      </div>
      <div className="search_result_bass_div search_results">
        <div className="row g-3">
          {data?.length ? null : <span>No results</span>}
          {data?.slice(0, limit)?.map((task, i) => {
            return (
              <div
                onClick={() =>
                  dispatch(setGlobal({taskId: task.id, showTask: true}))
                }
                className="col-12"
                key={i}>
                <div
                  className="search_result_data_inner_top_div search_result_item"
                  style={{backgroundColor: '#EEFCFF'}}>
                  <div className="search_result_data_inner_div">
                    <img src={TasksIcon} alt="icon" />
                    <div>
                      <p className="mb-1">{task?.name}</p>
                      <div className="search_result_data_id">
                        <div className="search_result_nav">
                          {task?.Project ? (
                            <div className="task_right_drawer_ui_body_nav_div">
                              <p>{task?.Project?.name}</p>
                              <span>
                                <AiOutlineRight />
                              </span>
                              <p>{task?.Department?.name}</p>
                              <span>
                                <AiOutlineRight />
                              </span>
                              <p>{task?.ProjectService?.Service?.name}</p>
                              {task?.Milestone?.title ? (
                                <>
                                  <span>
                                    <AiOutlineRight />
                                  </span>
                                  <p>{task?.Milestone?.title}</p>
                                </>
                              ) : null}
                            </div>
                          ) : null}
                          {task?.NavData?.map((task, i) => {
                            return (
                              <p className="mb-1" key={i}>
                                {task?.nav}
                                <span className="m-0">
                                  <AiOutlineRight />
                                </span>
                              </p>
                            );
                          })}
                        </div>
                        <p>
                          Last Modified on {moment(task?.updatedAt).calendar()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {limit > data.length ? null : (
          <button
            className="search_result_div_btn"
            onClick={() => setLimit(old => old + 5)}>
            more
          </button>
        )}
      </div>
    </>
  );
};
const ClientsSearchResult = ({data}) => {
  const [limit, setLimit] = useState(3);
  return (
    <>
      <div className="search_result_bass_title_div">
        <h3>Clients</h3>
      </div>
      <div className="search_result_bass_div search_results">
        <div className="row g-3">
          {data?.length ? null : <span>No results</span>}
          {data?.slice(0, limit)?.map((client, i) => {
            return (
              <Link
                to={`/Management/clients/${client.id}`}
                className="col-12"
                key={i}>
                <div
                  className="search_result_data_inner_top_div search_result_item"
                  style={{backgroundColor: '#EEFCFF'}}>
                  <div className="search_result_data_inner_div">
                    <img src={ClientsIcon} alt="icon" />
                    <div>
                      <p className="mb-1">{client?.client_name}</p>
                      <div className="search_result_data_id">
                        <p className="mb-1">
                          <span className="m-0">{client?.org_name} </span>
                        </p>
                        <p>
                          {client?.client_email} -
                          <br className=" d-block d-xxl-none d-xl-none d-lg-none d-md-none" />{' '}
                          {client?.phone}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        {limit > data.length ? null : (
          <button
            className="search_result_div_btn"
            onClick={() => setLimit(old => old + 5)}>
            more
          </button>
        )}
      </div>
    </>
  );
};
const ChatsSearchResult = () => {
  const ProjectsSearchResultData = [
    {
      icon: ChatsIcon,
      name: 'Sunny Charkhwal',
      message: 'Luno Electrical landing page completed.',
      date: '13/04/23',
      time: '11:45 AM',
    },
    {
      icon: ChatsIcon,
      name: 'Sunny Charkhwal',
      message: 'Luno Electrical landing page completed.',
      date: '13/04/23',
      time: '11:45 AM',
    },
    {
      icon: ChatsIcon,
      name: 'Sunny Charkhwal',
      message: 'Luno Electrical landing page completed.',
      date: '13/04/23',
      time: '11:45 AM',
    },
    {
      icon: ChatsIcon,
      name: 'Sunny Charkhwal',
      message: 'Luno Electrical landing page completed.',
      date: '13/04/23',
      time: '11:45 AM',
    },
  ];
  return (
    <>
      <div className="search_result_bass_title_div">
        <h3>Chats</h3>
      </div>
      <div className="search_result_bass_div">
        <div className="row g-3">
          {ProjectsSearchResultData.map((val, i) => {
            return (
              <div className="col-12" key={i}>
                <div
                  className="search_result_data_inner_top_div search_result_item"
                  style={{backgroundColor: '#EEFCFF'}}>
                  <div className="search_result_data_inner_div">
                    <img src={val.icon} alt="icon" />
                    <div>
                      <p className="mb-1">
                        {val.name} in chat
                        <br className=" d-block d-xxl-none d-xl-none d-lg-none d-md-none" />
                        <span className="chats_search_result_time_date">
                          {val.date} {val.time}
                        </span>
                      </p>
                      <div className="search_result_data_id">
                        <p className="mb-1">with you</p>
                        <p>{val.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <button className="search_result_div_btn">more</button>
      </div>
    </>
  );
};
