import { HiringIndex } from "../components/Pages/Hiring/HiringIndex";
const Hiring = () => {
  return (
    <>
      <div className="custom_container">
        <HiringIndex />
      </div>
    </>
  );
};

export default Hiring;
