import {PageAllTopTitle} from '../../../../common/ManagementComponents/PageAllTopTitle';
import {BsArrowLeft} from 'react-icons/bs';
import Axios from './../../../../../config/api';
import {useForm} from 'react-hook-form';
import {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {StatusCode} from '../../../../../constants';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {Block} from 'notiflix/build/notiflix-block-aio';
import {
  InputBox,
  SelectBox,
  TextareaBox,
} from '../../../../common/ManagementComponents/form/Form';
import {
  OutlineBtn,
  FullBtn,
} from '../../../../common/ManagementComponents/Btn/Btn';

export const ClientsProfile = () => {
  return (
    <div className="custom_container">
      <PageAllTopTitle link={-1} icon={<BsArrowLeft />} title="back" />
      <div className="custom_container_iner">
        <Form />
      </div>
    </div>
  );
};
const Form = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [serverErrors, setServerErrors] = useState({});
  const [client, setClient] = useState(null);
  const [countries, setCountries] = useState([]);
  const [countriesCodes, setCountriesCodes] = useState([]);

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm();

  useEffect(async () => {
    fetchCountries();
    if (params?.client_id) fetchClientData();

    return () => {};
  }, []);

  const fetchClientData = () => {
    //showing up loader
    Block.circle('.custom_container_iner', 'Loading...');
    Axios.fetch(`maxproject/client/${params.client_id}`)
      .then(({data}) => {
        setClient(data.client);
        reset(data.client);
        Block.remove('.custom_container_iner');
      })
      .catch(err => {
        //removing loader
        Block.remove('.custom_container_iner');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchCountries = () => {
    //showing up loader
    Block.circle('.custom_container_iner', 'Loading...');
    Axios.fetch(`maxproject/random/get_all_countries`)
      .then(({data}) => {
        let countryList = data?.countries?.map(country => ({
          optionValue: country.id,
          option: country.name,
        }));
        let countryCodeList = data?.countries?.map(country => ({
          optionValue: country.id,
          option: '+' + country.code,
        }));
        console.log(countryList, countryCodeList);
        setCountries(countryList);
        setCountriesCodes(countryCodeList);
        Block.remove('.custom_container_iner');
      })
      .catch(err => {
        //removing loader
        Block.remove('.custom_container_iner');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = data => {
    //clearing server errors
    setServerErrors({});
    //showing up loader
    Block.circle('.custom_container_iner', 'Saving...');
    //initiating network request
    const Request = params?.client_id
      ? Axios.put('maxproject/client/' + params.client_id, data)
      : Axios.post('maxproject/client', data);

    Request.then(({data}) => {
      Block.remove('.custom_container_iner');
      Notify.success(data.message);
      navigate(-1);
    }).catch(err => {
      //removing loader
      Block.remove('.custom_container_iner');
      //checking for any error message
      if (err?.response?.data?.message)
        Notify.failure(err?.response?.data?.message);
      //checking and showing express validation errors
      if (err?.response?.status == StatusCode.ExpressErrors)
        err?.response?.data?.errors.forEach(el =>
          setServerErrors(old => ({...old, [el.param]: el.msg})),
        );
    });
  };
  return (
    <div className="CompanySetup_main_div">
      <div className="CompanySetup_inner_main_div">
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
          <div className="create_task_title">
            <h3>{params?.client_id && 'Update '}Client Profile</h3>
          </div>
          <form id="form" onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <InputBox
                  defaultValue={client?.client_name}
                  register={() =>
                    register('client_name', {
                      required: 'Please enter client name',
                      value: client?.client_name,
                    })
                  }
                  type="text"
                  placeholder="Enter full name of client"
                  label="Client Name* (POC/Owner)"
                  errorMessage={
                    errors.client_name?.message || serverErrors?.client_name
                  }
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <InputBox
                  defaultValue={client?.designation}
                  register={() =>
                    register('designation', {
                      required: 'Please enter designation or role',
                    })
                  }
                  errorMessage={
                    errors.designation?.message || serverErrors?.designation
                  }
                  type="text"
                  placeholder="Designation"
                  label="Designation / role"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <InputBox
                  defaultValue={client?.org_name}
                  register={() =>
                    register('org_name', {
                      required: 'Please enter Organization Name',
                    })
                  }
                  errorMessage={
                    errors.org_name?.message || serverErrors?.org_name
                  }
                  type="text"
                  placeholder="Client's organization name if any available"
                  label="Organisation Name*"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="row">
                  <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
                    <SelectBox
                      defaultValue={client?.country_code_id}
                      register={() =>
                        register('country_code_id', {
                          required: 'Please enter country code',
                        })
                      }
                      errorMessage={
                        errors.country_code_id?.message ||
                        serverErrors?.country_code_id
                      }
                      label="country"
                      SelectBoxData={countriesCodes}
                    />
                  </div>
                  <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8">
                    <InputBox
                      defaultValue={client?.phone}
                      register={() =>
                        register('phone', {
                          required: 'Please enter your phone',
                        })
                      }
                      errorMessage={
                        errors.phone?.message || serverErrors?.phone
                      }
                      type="number"
                      placeholder=" Add contact number"
                      label=" Contact Number*"
                    />
                  </div>
                </div>
              </div>

              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <InputBox
                  defaultValue={client?.client_email}
                  register={() =>
                    register('client_email', {
                      required: "Please enter client's personal email ID",
                    })
                  }
                  errorMessage={
                    errors.client_email?.message || serverErrors?.client_email
                  }
                  type="text"
                  label="Client's Email Address*"
                  placeholder="Client's personal email ID"
                />
              </div>

              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <TextareaBox
                  defaultValue={client?.address_1}
                  register={() =>
                    register('address_1', {
                      required: 'Please enter address',
                    })
                  }
                  errorMessage={
                    errors.address_1?.message || serverErrors?.address_1
                  }
                  label="Address Line 1"
                  rows="1"
                  placeholder="Address line 1"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <TextareaBox
                  defaultValue={client?.address_2}
                  register={() => register('address_2')}
                  label="Address Line 2 (Optional)"
                  rows="1"
                  placeholder="Address line 2(Optional)"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <InputBox
                  defaultValue={client?.city}
                  register={() =>
                    register('city', {
                      required: 'Please enter city',
                    })
                  }
                  errorMessage={errors.city?.message || serverErrors?.city}
                  type="text"
                  label="City"
                  placeholder="Full Name of your city"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="dashboard_top_week_Select drop_box">
                  <SelectBox
                    defaultValue={client?.country_id}
                    register={() =>
                      register('country_id', {
                        required: 'Please select country_id',
                      })
                    }
                    errorMessage={
                      errors.country_id?.message || serverErrors?.country_id
                    }
                    label="country"
                    SelectBoxData={countries}
                  />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <InputBox
                  defaultValue={client?.abn_no}
                  register={() =>
                    register('abn_no', {
                      required: 'Please enter ABN no.',
                    })
                  }
                  errorMessage={errors.abn_no?.message || serverErrors?.abn_no}
                  type="text"
                  label="Business ABN Number*"
                  placeholder="Business ABN Number (If country is selected as AU)"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <InputBox
                  defaultValue={client?.pin_code}
                  register={() =>
                    register('pin_code', {
                      required: 'Please enter PIN code',
                    })
                  }
                  errorMessage={
                    errors.pin_code?.message || serverErrors?.pin_code
                  }
                  type="text"
                  label="ZIP /Postcode"
                  placeholder="Your local ZIP/Postcode"
                />
              </div>
              <div className="col-12 mb-3 mt-5">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="ms-2">
                    <a
                      className="me-2 management_btn_outline"
                      onClick={() => navigate(-1)}>
                      <span>Cancel</span>
                    </a>
                  </div>
                  <div className="ms-2">
                    <button
                      form="form"
                      type="submit"
                      className="management_btn_full2">
                      {params?.client_id ? 'Update' : 'Submit'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
