import React from 'react';
import {BiEdit} from 'react-icons/bi';
import Img from '../components/img/Img';
let Photo = 'https://source.unsplash.com/random/500x500/?girl';
export const ProfileImg = props => {
  return (
    <>
      <input
        onChange={props.onChange}
        {...(props.register && props.register())}
        type="file"
        style={{display: 'none'}}
        accept="image/*"
        id="dd"
        hidden
      />
      <div className="user_logo_upload_main_div">
        <small className="error_message">{props.errorMessage}</small>
        <Img backgroundImage={props.Photo} className={props.className}></Img>
        {props?.editable ? (
          <label htmlFor="dd">
            <BiEdit />
          </label>
        ) : null}
      </div>
    </>
  );
};
ProfileImg.defaultProps = {
  Photo: Photo,
  className: 'project_details_card_img',
  editable: true,
};
