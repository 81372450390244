import {PageAllTopTitle} from '../../../../../common/ManagementComponents/PageAllTopTitle';
import {BsArrowLeft} from 'react-icons/bs';
import {useState, useEffect} from 'react';
import Select from 'react-select';
import ClearableSelect from 'react-select/creatable';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {InputBox} from '../../../../../common/ManagementComponents/form/Form';
import {AiOutlineCheckCircle} from 'react-icons/ai';
import {BsPlus} from 'react-icons/bs';
import {FiCircle} from 'react-icons/fi';

import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {Block} from 'notiflix/build/notiflix-block-aio';
import {Report} from 'notiflix/build/notiflix-report-aio';
import Axios from './../../../../../../config/api';
import _ from 'underscore';
import {useForm, Controller, useFieldArray} from 'react-hook-form';

export const AssignProject = () => {
  return (
    <div className="custom_container">
      <PageAllTopTitle link={-1} icon={<BsArrowLeft />} title="back" />
      <ClientProfileStep />
    </div>
  );
};
const ClientProfileStep = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    <Step1 setCurrentStep={setCurrentStep} />,
    <Step2 setCurrentStep={setCurrentStep} />,
    <Step3 />,
  ];

  const getCurrentStepCheckbox = index => {
    if (currentStep === index) return <AiOutlineCheckCircle />;
    else if (currentStep > index) return <AiOutlineCheckCircle />;
    else return <FiCircle className="ClientProfile_normal_dot" />;
  };
  return (
    <>
      <div className="custom_container_iner">
        <div className="row"></div>
        <div className="Appraisal_progress_bar_custom_container">
          <div className=" Client_Profile_main_top_div  Client_Profile_main_top_div_new ">
            <div className="Client_Profile_main_top_div_inner">
              <progress
                className="Appraisal_progress_bar Appraisal_progress_bar_man"
                value={(100 / steps.length) * (currentStep + 1)}
                max="100"
                variant="warning">
                32%
              </progress>
            </div>

            <div className="Appraisal_progress_text_bar Appraisal_progress_text_bar_mann">
              <ul className="mb-0 Client_Profile_svg">
                <li>
                  <span>{getCurrentStepCheckbox(0)}</span>
                  <label>Team Members</label>
                </li>
                <li>
                  <span className="Client_Profile_svg_new_new">
                    {getCurrentStepCheckbox(1)}
                  </span>
                  <label className="Client_Profile_svg_new">Milestones</label>
                </li>
                <li>
                  <span className="Client_Profile_svg_last ">
                    {getCurrentStepCheckbox(2)}
                  </span>
                  <p className="Client_Profile_svg_last_text">Sprints</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="inner_mr">{steps[currentStep]}</div>
          <div>
            <div className=" Client_Profile_btn_div">
              <div>
                <button
                  className="management_btn_outline"
                  onClick={() => setCurrentStep(old => old - 1)}
                  disabled={!currentStep}>
                  Previous
                </button>
              </div>
              <div>
                <div>
                  <button
                    className="management_btn_full"
                    type="submit"
                    form={`form_${currentStep}`}>
                    {steps.length === currentStep + 1
                      ? 'Save'
                      : 'Save and Continue'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Step1 = ({setCurrentStep}) => {
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm();

  const {fields, append, remove, replace} = useFieldArray({
    control,
    name: 'services',
  });

  const [departmentUsers, setDepartmentUsers] = useState([]);

  useEffect(() => {
    if (params?.project_id) fetchProjectServices();

    return () => {};
  }, []);

  const fetchProjectServices = () => {
    //showing up loader
    Block.circle('#form_0', 'Loading...');
    const URL = `maxproject/project_service/users/${params.project_id}?includeDepartmentUsers=1&includeServiceMembers=1`;
    Axios.fetch(URL)
      .then(({data}) => {
        let services = [],
          departmentUsers = [];
        data.projectServices.forEach(d => {
          services.push({
            service: d?.Service?.name,
            serviceMembers: d.ProjectMembers?.map(m => ({
              label: m?.User?.name,
              value: m?.User?.id,
            })),
            id: d.id,
          });
          departmentUsers.push(
            d.departmentUsers?.map(u => ({label: u.name, value: u.id})),
          );
        });
        setDepartmentUsers(departmentUsers);
        replace(services);
        Block.remove('#form_0');
      })
      .catch(err => {
        //removing loader
        Block.remove('#form_0');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = data => {
    //preparing payload
    let payload = data.services.map(d => ({
      id: d.id,
      users: d.serviceMembers.map(u => u.value),
    }));
    //showing up loader
    Block.circle('#form_0', 'Saving...');
    //initiating network request
    Axios.post(`maxproject/project_service/users/${params.project_id}`, payload)
      .then(({data}) => {
        Block.remove('#form_0');
        Notify.success(data.message);
        setCurrentStep(old => old + 1);
        // navigate('/Management/project_list');
      })
      .catch(err => {
        //removing loader
        Block.remove('#form_0');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };

  const ProjectService = ({index, item}) => {
    return (
      <div className="col-12 Select_Services_row_new_inner_top">
        <div className="row Select_Services_row Select_Services_row_new">
          <div className="col-12 ">
            <div className=" Select_Services_row_new_inner">
              <p>{index}</p>
            </div>
            <p className="project_leads">{item.service}</p>
            <label className="all_page_my_label_new">Team Members*</label>
            <div className="dashboard_top_week_Select drop_box drop_box2">
              <Controller
                name={`services.${index}.serviceMembers`}
                control={control}
                rules={{
                  required: 'Please select team members',
                }}
                render={({field}) => (
                  <Select
                    {...field}
                    defaultValue={field.value}
                    onChange={el => field.onChange(el)}
                    isMulti
                    options={departmentUsers[index]}
                    placeholder="Select Team Members*"
                  />
                )}
              />
              <small className="error_message">
                {errors?.services?.[index]?.department_users?.message}
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <form id="form_0" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="row align-items-baseline">
        <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-6 col-sm-12 col-12">
          <div className="Client_Profile_title">
            <h3>Team Members</h3>
          </div>
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12">
          <NavLink
            to={`/Management/project_details/${params?.project_id}`}
            className="text_btn">
            View Project Details
          </NavLink>
        </div>
      </div>
      <div className="m_t">
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
          {fields.map((item, index) => (
            <ProjectService key={index} index={index} item={item} />
          ))}
        </div>
      </div>
    </form>
  );
};

const Step2 = ({setCurrentStep}) => {
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm();

  const {fields, append, remove, replace} = useFieldArray({
    control,
    name: 'milestones',
  });

  const [departmentUsers, setDepartmentUsers] = useState([]);

  useEffect(() => {
    if (params?.project_id) fetchProjectDepartments();

    return () => {};
  }, []);

  const fetchProjectDepartments = () => {
    //showing up loader
    Block.circle('#form_1', 'Loading...');
    const URL = `maxproject/project_service/milestone/${params.project_id}?includeServiceMembers=1`;
    Axios.fetch(URL)
      .then(({data}) => {
        let milestones = [],
          dUsers = [];
        data.milestones.forEach(d => {
          milestones.push({
            service: d.Service.name,
            service_id: d.service_id,
            milestones: d?.ProjectMilestones?.length
              ? d?.ProjectMilestones?.map(m => ({
                  milestone: {
                    label: m.title,
                    value: m.title,
                  },
                  employee: m?.User
                    ? {
                        label: m?.User?.name,
                        value: m?.User?.id,
                      }
                    : null,
                  milestone_id: m.milestone_id,
                }))
              : [
                  {
                    milestone: null,
                    employee: null,
                    milestone_id: null,
                  },
                ],
          });
          dUsers.push(
            d.ProjectMembers?.map(u => ({
              label: u.User.name,
              value: u.user_id,
            })),
          );
        });
        setDepartmentUsers(dUsers);
        replace(milestones);
        Block.remove('#form_1');
      })
      .catch(err => {
        console.log(err);
        //removing loader
        Block.remove('#form_1');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = data => {
    //preparing payload
    let payload = data.milestones; //.map(d => ({
    //   id: d.id,
    //   users: d.department_users.map(u => u.value),
    // }));
    //showing up loader
    Block.circle('#form_1', 'Saving...');
    //initiating network request
    Axios.post(
      `maxproject/project_service/milestone/${params.project_id}`,
      payload,
    )
      .then(({data}) => {
        Block.remove('#form_1');
        Notify.success(data.message);
        setCurrentStep(old => old + 1);
        // navigate('/Management/project_list');
      })
      .catch(err => {
        //removing loader
        Block.remove('#form_1');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const NestedFieldArray = ({nestIndex, control, register}) => {
    const {fields, remove, append} = useFieldArray({
      control,
      name: `milestones[${nestIndex}].milestones`,
    });
    const handleAddMilestone = () => {
      append({
        milestone: null,
        employee: null,
      });
    };
    return (
      <div className="ml-5">
        {fields.map((item, index) => {
          return (
            <div key={item.id} className="mt-3">
              <div className="col-12 ">
                <div className=" Select_Services_row_new_inner">
                  <p>{index}</p>
                </div>
                <label className="all_page_my_label_new">Milestones*</label>
                <div className="dashboard_top_week_Select drop_box drop_box2">
                  <Controller
                    name={`milestones[${nestIndex}].milestones[${index}].milestone`}
                    control={control}
                    rules={{
                      required: 'Please select milestone',
                    }}
                    render={({field}) => (
                      <ClearableSelect
                        {...field}
                        defaultValue={field.value}
                        onChange={el => field.onChange(el)}
                        placeholder="Milestone*"
                        isClearable
                      />
                    )}
                  />
                  <small className="error_message">
                    {
                      errors?.milestones?.[nestIndex]?.milestones?.[index]
                        ?.milestone?.message
                    }
                  </small>
                </div>
              </div>
              <div className="col-12  mt-2  ">
                <div className="row g-3">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <label className="all_page_my_label_new">Employee*</label>
                    <div className="dashboard_top_week_Select drop_box ">
                      <Controller
                        name={`milestones[${nestIndex}].milestones[${index}].employee`}
                        control={control}
                        rules={{
                          required: 'Please select employee',
                        }}
                        render={({field}) => (
                          <Select
                            {...field}
                            defaultValue={field.value}
                            onChange={el => field.onChange(el)}
                            options={departmentUsers[nestIndex]}
                            placeholder="Employee*"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="row">
          <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12 ">
            <div className="milestones_emp mt-3">
              <button onClick={handleAddMilestone}>
                <span>
                  <BsPlus />
                  Add Milestone
                </span>
              </button>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  };
  const Department = ({index, item}) => {
    return (
      <div className="col-12 Select_Services_row_new_inner_top">
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 Select_Services_row Select_Services_row_new">
          <div className="col-12">
            <h6>{item.service}</h6>
            <NestedFieldArray nestIndex={index} {...{control, register}} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <form id="form_1" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="row align-items-baseline">
        <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-6 col-sm-12 col-12">
          <div className="Client_Profile_title">
            <h3>Milestones</h3>
          </div>
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12">
          <NavLink to="/Management/project_details" className="text_btn">
            View Project Details
          </NavLink>
        </div>
      </div>
      <div className="m_t">
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
          {fields.map((item, index) => (
            <Department key={index} index={index} item={item} />
          ))}
        </div>
      </div>
    </form>
  );
};
const Step3 = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm();

  const {fields, append, remove, replace} = useFieldArray({
    control,
    name: 'sprints',
  });

  useEffect(() => {
    if (params?.project_id) fetchProjectServices();

    return () => {};
  }, []);

  const fetchProjectServices = () => {
    //showing up loader
    Block.circle('#form_2', 'Loading...');
    const URL = `maxproject/project_service/sprint/${params.project_id}`;
    Axios.fetch(URL)
      .then(({data}) => {
        let sprints = data?.services?.map(service => ({
          service_id: service.id,
          service: service?.Service?.name,
          start_date: service?.start_date,
          end_date: service?.end_date,
        }));

        replace(sprints);
        Block.remove('#form_2');
      })
      .catch(err => {
        console.log(err);
        //removing loader
        Block.remove('#form_2');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = data => {
    //preparing payload
    let payload = data.sprints;
    //showing up loader
    Block.circle('#form_2', 'Saving...');
    //initiating network request
    Axios.post(
      `maxproject/project_service/sprint/${params.project_id}`,
      payload,
    )
      .then(({data}) => {
        Block.remove('#form_2');
        Notify.success(data.message);
        Report.success(
          'Success',
          'You have completed all of the steps!',
          'Okay',
        );
        navigate('/Management/project_list');
      })
      .catch(err => {
        //removing loader
        Block.remove('#form_2');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const Service = ({index, item}) => {
    return (
      <div className="col-12 Select_Services_row_new_inner_top">
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 Select_Services_row Select_Services_row_new">
          <div className="col-12 ">
            <div className=" Select_Services_row_new_inner">
              <p>{index}</p>
            </div>
            <p className="project_leads">
              {index + 1}. {item.service}
            </p>
            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <InputBox
                  register={() =>
                    register(`sprints.${index}.start_date`, {
                      required: 'Please enter start date',
                      value: `sprints.${index}.start_date`,
                    })
                  }
                  type="date"
                  label="Start Date*"
                />
                <small className="error_message">
                  {errors?.sprints?.[index]?.start_date?.message}
                </small>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <InputBox
                  register={() =>
                    register(`sprints.${index}.end_date`, {
                      required: 'Please enter end date',
                      value: `sprints.${index}.end_date`,
                    })
                  }
                  type="date"
                  label="end Date*"
                />
                <small className="error_message">
                  {errors?.sprints?.[index]?.end_date?.message}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <form id="form_2" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="row align-items-baseline">
        <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-6 col-sm-12 col-12">
          <div className="Client_Profile_title">
            <h3>Sprints</h3>
          </div>
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12">
          <NavLink to="/Management/project_details" className="text_btn">
            View Project Details
          </NavLink>
        </div>
      </div>
      <div className="m_t">
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
          {fields.map((item, index) => (
            <Service key={index} index={index} item={item} />
          ))}
        </div>
      </div>
    </form>
  );
};
