import React, {useState, useRef, useEffect, useCallback} from 'react';
import {IoSearchOutline} from 'react-icons/io5';
import {FaPlus, FaCircleNotch} from 'react-icons/fa';
import {GoDotFill} from 'react-icons/go';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import {BsFillCircleFill} from 'react-icons/bs';
import {AiFillCaretDown} from 'react-icons/ai';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useNavigate, useParams} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Avatar from '@mui/material/Avatar';
import {MdOutlineEdit, MdOutlineSupervisedUserCircle} from 'react-icons/md';
import {Confirm, Notify} from 'notiflix';
import {PageAllTopTitle} from '../../../../common/ManagementComponents/PageAllTopTitle';
import {FullBtn} from '../../../../common/ManagementComponents/Btn/Btn';
import AddTaskIcon from '../../../../../pics/Management/add_task.svg';
import {UserParticipants} from '../../../../common/ManagementComponents/Participants';
import {BiTimeFive} from 'react-icons/bi';
import Axios from './../../../../../config/api';
import {Block} from 'notiflix';
import moment from 'moment';
import {useNonInitialEffect} from '../../../../../utils/hooks/non-initial-effect.tsx';
import {getUser} from '../../../../../redux/slices/userSlice.js';
import {useSelector} from 'react-redux';
import NotesIcon from '../../../../../pics/Management/Notes.svg';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';

export const NotesDetails = () => {
  const params = useParams();
  const {meeting_note_id} = params;

  const [meetingNotes, setMeetingNotes] = useState({});
  const [priorities, setPriorities] = useState([]);
  const [query, setQuery] = useState('');

  useNonInitialEffect(() => {
    // Timer ID for the delayed fetch
    let timerId;
    // Clear previous timer, if any
    clearTimeout(timerId);
    // Set a new timer for the delayed fetch
    timerId = setTimeout(fetchNotes, 500);
    // Clean up timer on component unmount
    return () => clearTimeout(timerId);
  }, [query]);

  useEffect(() => {
    fetchPriorities();
  }, []);

  useEffect(() => {
    fetchNotes();
    return () => {};
  }, [meeting_note_id]);

  const fetchNotes = async () => {
    //showing loader
    Block.circle('.custom_container');
    Axios.fetch(
      `maxproject/meeting_note/notes/${meeting_note_id}?query=${query}`,
    )
      .then(({data}) => {
        //removing loader
        Block.remove('.custom_container');
        setMeetingNotes(data);
      })
      .catch(err => {
        //removing loader
        Block.remove('.custom_container');
      });
  };
  const fetchPriorities = () => {
    Axios.fetch(`maxproject/random/statuses/meeting_note`).then(({data}) => {
      setPriorities(data?.priority_statuses);
    });
  };

  const [assignToModalShow, setAssignToModalShow] = React.useState(false);
  return (
    <>
      <div className="custom_container">
        <AssignToModal
          show={assignToModalShow}
          setMeetingNotes={setMeetingNotes}
          participant={meetingNotes?.MeetingNoteParticipants || []}
          onHide={() => setAssignToModalShow(false)}
        />
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 header_my">
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 order-md-1 order-3">
            <div className="notes_details_project_title_div">
              <img src={NotesIcon} alt="icon" />
              <p>
                {/* {meetingNotes?.Meeting?.title} */}
                {meetingNotes?.Meeting?.title.length > 35
                  ? `${meetingNotes?.Meeting?.title.substring(0, 35)}...`
                  : meetingNotes?.Meeting?.title}
              </p>
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 order-md-2 order-1">
            <div className="header_input_div">
              <IoSearchOutline />
              <input
                type="search"
                className="form-control"
                defaultValue={query}
                onChange={e => setQuery(e.target.value)}
                placeholder="Search notes here"
              />
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12  justify-content-end schedule_meeting_btn  order-md-3 order-2">
            <div className="blue_btn_top_div ">
              <FullBtn
                onClick={() => setAssignToModalShow(true)}
                name="Assign"
              />
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 order-md-4 order-4">
            <NoteTitle title={meetingNotes?.title} />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 notes_details_edit_title_div order-md-5 order-5">
            <div className="notes_details_bottom_assigned_div ">
              <p>Assigned to</p>
              <div className="Participants_main_div_inner">
                <UserParticipants
                  data={
                    meetingNotes?.MeetingNoteParticipants
                      ? [
                          ...meetingNotes?.MeetingNoteParticipants,
                          {User: meetingNotes?.User},
                        ]
                      : []
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="dashbaord_white_card">
          <Notes
            MeetingNoteParticipants={
              meetingNotes?.MeetingNoteParticipants
                ? [
                    ...meetingNotes?.MeetingNoteParticipants,
                    {User: meetingNotes?.User},
                  ]
                : []
            }
            data={meetingNotes?.Notes}
            meeting_note_id={meeting_note_id}
            projectId={meetingNotes?.project_id}
            priorities={priorities}
          />
        </div>
      </div>
    </>
  );
};
const Notes = ({
  data,
  meeting_note_id,
  MeetingNoteParticipants = [],
  priorities = [],
  projectId,
}) => {
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const [notes, setNotes] = useState(data || []);
  const [note, setNote] = useState('');
  const [page, setPage] = useState(1);

  const [activeNoteId, setActiveNoteId] = useState(null);
  const [noteParticipant, setNoteParticipant] = useState([]);
  const [showAssignModal, setShowAssignModal] = useState(false);

  useEffect(() => {
    setNotes(data);
  }, [data]);

  // Memoize the setNewNote function to prevent unnecessary re-renders
  const memoizedSetNewNote = useCallback(
    e => {
      setNote(e.target.value);
    },
    [], // Dependency array is empty as setNewNote does not depend on any variable
  );

  const handleSaveNote = e => {
    if (e.key === 'Enter') {
      Block.dots('.notes_details_add');
      Axios.post(`maxproject/meeting_note/note/` + meeting_note_id, {
        title: note,
      })
        .then(({data}) => {
          setNotes(old => {
            let arr = old.slice();
            arr.unshift(data.note);
            return arr;
          });
          setNote('');
          Block.remove('.notes_details_add');
        })
        .catch(err => {
          Block.remove('.notes_details_add');
        });
    }
  };

  const AssignToModal = props => {
    const [newNoteParticipants, setNewNoteParticipants] = useState([]);

    const handleUpdateNoteParticipants = () => {
      const users = newNoteParticipants.map(val => val.value);
      Block.dots('.note_participants_modal');
      Axios.put(`maxproject/meeting_note/note_participant/` + activeNoteId, {
        users,
      })
        .then(({data}) => {
          //showing message
          Notify.success('Note participants updated successfully');
          setNotes(old => {
            let arr = old.slice();
            let index = arr.findIndex(val => val.id === activeNoteId);
            arr[index] = data.note;
            return arr;
          });
          setNoteParticipant([]);
          setNewNoteParticipants([]);
          setActiveNoteId(null);
          Block.remove('.note_participants_modal');
          setShowAssignModal(false);
        })
        .catch(err => {
          Block.remove('.note_participants_modal');
        });
    };
    const UserUi = props => {
      return (
        <div className="assign_to_modal_ui">
          <Avatar
            alt={props.userName}
            src={props.userImg}
            sx={{width: 24, height: 24}}
          />
          <span>{props.userName}</span>
        </div>
      );
    };
    const NoteUsers = noteParticipant.map(val => ({
      value: val.User.id,
      label: <UserUi userName={val.User.name} userImg={val.User.avatar} />,
    }));
    const AllUsers = MeetingNoteParticipants.map(val => ({
      value: val.User.id,
      label: <UserUi userName={val.User.name} userImg={val.User.avatar} />,
    }));
    return (
      <Modal
        show={showAssignModal}
        onHide={() => setShowAssignModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Assign</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row note_participants_modal">
            <div className="col-12">
              <div>
                <Select
                  isMulti
                  isSearchable={true}
                  defaultValue={NoteUsers}
                  options={AllUsers}
                  onChange={val => setNewNoteParticipants(val)}
                  placeholder="Select members"
                />
              </div>
            </div>
            <div className="col-12">
              <div className=" text-center mt-5">
                <FullBtn onClick={handleUpdateNoteParticipants} name="Assign" />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const Note = ({note, index}) => {
    const [noteTitle, setNoteTitle] = useState(note?.title);
    const [priority, setPriority] = useState(note?.Priority);
    const [isEditing, setIsEditing] = useState(false);

    const handleForwardNote = () => {
      Block.dots('#note-' + note.id);
      Axios.fetch(`maxproject/meeting_note/forward_note/` + note?.id)
        .then(({data}) => {
          Notify.success(data.message);
          Block.remove('#note-' + note.id);
        })
        .catch(err => {
          Block.remove('#note-' + note.id);
        });
    };
    const processDeleteNote = () => {
      Block.dots('#note-' + note.id);
      Axios.delete(`maxproject/meeting_note/note/` + note?.id)
        .then(({data}) => {
          setNotes(old => {
            let arr = old.slice();
            arr.splice(index, 1);
            return arr;
          });
          Block.remove('#note-' + note.id);
        })
        .catch(err => {
          Block.remove('#note-' + note.id);
        });
    };
    const handleDeleteNote = () => {
      Confirm.show(
        `Delete?`,
        `This action is undonable!`,
        'Delete',
        'Cancel',
        () => processDeleteNote(),
        // () => processDeleteNote(),
      );
    };
    const handleUpdateNote = e => {
      if (e.key === 'Enter') {
        Block.dots('#note-' + note.id);
        Axios.put(`maxproject/meeting_note/note_title/` + note?.id, {
          title: e.target.value,
        })
          .then(({data}) => {
            setNoteTitle(e.target.value);
            setIsEditing(false);
            Block.remove('#note-' + note.id);
          })
          .catch(err => {
            Block.remove('#note-' + note.id);
          });
      } else if (e.key === 'Escape') setIsEditing(false);
    };
    const BoxMenu = () => {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
      const handleClick = event => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
      return (
        <React.Fragment>
          <Tooltip title="Menu" position="bottom">
            <MoreVertIcon
              onClick={handleClick}
              aria-controls={open ? 'account-menu' : undefined}
              className="new_note_box_menu_div2"
            />
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
            <MenuItem onClick={() => setIsEditing(true)}>Edit</MenuItem>
            <MenuItem
              disabled={note.user_id != user.id}
              onClick={handleDeleteNote}>
              Delete
            </MenuItem>
            <MenuItem
              disabled={!note?.NoteParticipants?.length}
              onClick={handleForwardNote}>
              Forward
            </MenuItem>
          </Menu>
        </React.Fragment>
      );
    };
    const PriorityDropdown = () => {
      const [priorityDropdown, setPriorityDropdown] = React.useState(null);
      const open = Boolean(priorityDropdown);
      const handleClick = event => {
        setPriorityDropdown(event.currentTarget);
      };
      const handleClose = () => {
        setPriorityDropdown(null);
      };
      const handlePriorityChange = priority => {
        Block.circle('.projects_table');
        Axios.put(`maxproject/meeting_note/${note.id}/priority`, {
          priority_id: priority.id,
        })
          .then(({data}) => {
            Block.remove('.projects_table');
            setPriority(priority);
            setPriorityDropdown(null);
          })
          .catch(err => {
            Block.remove('.projects_table');
          });
      };
      return (
        <React.Fragment>
          <Box>
            <Tooltip arrow>
              <div
                onClick={handleClick}
                className="notes_details_add_todo_dropdown">
                <FaCircleNotch className="btn_icon" />
                {priority ? (
                  <p
                    className="Priority_dropdown"
                    style={{
                      backgroundColor: priority?.bg_color,
                    }}>
                    <span style={{color: priority?.color}}>
                      {priority?.name}
                      <AiFillCaretDown />
                    </span>
                  </p>
                ) : (
                  <p
                    className="Priority_dropdown "
                    style={{backgroundColor: '#F0F5FE'}}>
                    <span style={{color: '#1A7FDD'}}>
                      Priority
                      <AiFillCaretDown />
                    </span>
                  </p>
                )}
              </div>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={priorityDropdown}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
            <Divider />
            {priorities?.map(p => (
              <MenuItem onClick={() => handlePriorityChange(p)}>
                <p className="Priority_dropdown_item">
                  <BsFillCircleFill style={{color: p.bg_color}} />
                  {p.name}
                </p>
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      );
    };
    return (
      <div id={'note-' + note.id} key={index} className="col-12">
        <div className=" join_meeting_time mb-3">
          <p>
            <BiTimeFive />
            {moment(note?.createdAt).calendar()}
          </p>
        </div>
        {isEditing ? (
          <div className="notes_details_add">
            <input
              type="text"
              class="form-control"
              placeholder="Edit note"
              defaultValue={noteTitle}
              onKeyDown={handleUpdateNote}
            />
          </div>
        ) : (
          <div className="notes_details_add_todo">
            <span className="notes_details_add_todo_dot">
              <GoDotFill />
            </span>
            <div>
              <p className="notes_details_add_todo_text">{noteTitle}</p>
              <div className="notes_details_bottom_btn_div">
                <div className="notes_details_bottom_btn_div_inner ">
                  <p
                    className="notes_details_bottom_btn"
                    onClick={() =>
                      navigate('/Management/create_task', {
                        state: {
                          meetingNote: {
                            meeting_note_id,
                            projectId,
                            noteTitle,
                          },
                        },
                      })
                    }>
                    <img src={AddTaskIcon} alt="icon" />
                    Add to task
                  </p>
                </div>
                <div>
                  <PriorityDropdown />
                </div>
                <div className="notes_details_bottom_btn_div_inner_top_div">
                  <p
                    className="notes_details_bottom_btn notes_details_bottom_btn_div_inner "
                    onClick={() => {
                      setShowAssignModal(true);
                      setNoteParticipant(note?.NoteParticipants);
                      setActiveNoteId(note?.id);
                    }}>
                    <MdOutlineSupervisedUserCircle
                      size={18}
                      className="btn_icon"
                      style={{marginRight: 3}}
                    />
                    Assign
                  </p>
                  {note?.NoteParticipants?.length ? (
                    <div className="notes_details_bottom_assigned_div ">
                      <p>Assigned to</p>
                      <div className="Participants_main_div_inner">
                        <UserParticipants data={note?.NoteParticipants} />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <BoxMenu />
          </div>
        )}
      </div>
    );
  };
  const handlePagination = (e, page) => {
    setPage(page);
  };
  return (
    <div>
      <AssignToModal />
      <div className="notes_details_add">
        <FaPlus />
        <input
          type="text"
          class="form-control"
          placeholder="Add note"
          value={note}
          onChange={memoizedSetNewNote}
          onKeyDown={handleSaveNote}
        />
      </div>
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
        {notes?.slice((page - 1) * 10, 10 * page)?.map((note, i) => (
          <Note note={note} index={i} />
        ))}
      </div>
      {notes?.length > 10 ? (
        <div className="projec_tabelt_list_pagination_div mt-5">
          <Stack spacing={2}>
            <Pagination
              onChange={handlePagination}
              count={Math.ceil(notes?.length / 10)}
            />
          </Stack>
        </div>
      ) : null}
    </div>
  );
};
const AssignToModal = props => {
  const params = useParams();
  const {meeting_note_id} = params;

  const [meetingNotesUsers, setMeetingNotesUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  //creating default options
  const participants = props.participant.map(val => ({
    value: val.User.id,
    label: <UserUi userName={val.User.name} userImg={val.User.avatar} />,
  }));
  //getting all users
  useEffect(() => {
    Axios.fetch(`maxproject/random/members`)
      .then(({data}) => {
        let options = data.members.map(val => ({
          value: val.id,
          label: <UserUi userName={val.name} userImg={val.avatar} />,
        }));
        setAllUsers(options);
      })
      .catch(err => {});
  }, []);

  const handleUpdateNoteParticipants = () => {
    const users = meetingNotesUsers.map(val => val.value);
    Block.dots('.meeting_notes_users');
    Axios.put(
      `maxproject/meeting_note/meeting_note_participant/` + meeting_note_id,
      {
        users,
      },
    )
      .then(({data}) => {
        //showing message
        Notify.success('Participants updated successfully');
        props.setMeetingNotes(old => ({
          ...old,
          MeetingNoteParticipants: data.MeetingNoteParticipant,
        }));
        Block.remove('.meeting_notes_users');
        props.onHide();
      })
      .catch(err => {
        Block.remove('.meeting_notes_users');
      });
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Assign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row meeting_notes_users">
          <div className="col-12">
            <div>
              <Select
                isMulti
                isSearchable={true}
                defaultValue={participants}
                onChange={val => setMeetingNotesUsers(val)}
                options={allUsers}
                placeholder="Select members"
              />
            </div>
          </div>
          <div className="col-12">
            <div className=" text-center mt-5">
              <FullBtn onClick={handleUpdateNoteParticipants} name="Assign" />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
const NoteTitle = ({title}) => {
  const params = useParams();
  const {meeting_note_id} = params;

  const [text, setText] = useState(title);

  useEffect(() => {
    setText(title);
  }, [title]);

  const handleSave = newText => {
    let oldText = text;
    //updating state
    setText(newText);
    //updating note title
    Axios.put(`maxproject/meeting_note/meeting_title/` + meeting_note_id, {
      title: newText,
    })
      .then(({data}) => {
        //showing message
        // Notify.success('Note title updated');
      })
      .catch(err => {
        //updating state
        setText(oldText);
      });
  };
  const EditableText = ({text, onSave}) => {
    const [isEditing, setEditing] = useState(false);
    const [value, setValue] = useState(text);
    const inputRef = useRef();

    useEffect(() => {
      if (isEditing) {
        inputRef.current.focus();
      }
    }, [isEditing]);

    const handleDoubleClick = () => {
      setEditing(true);
    };

    const handleBlur = () => {
      setEditing(false);
      onSave(value);
    };

    const handleChange = e => {
      setValue(e.target.value);
    };

    return (
      <div>
        {isEditing ? (
          <input
            ref={inputRef}
            type="text"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={e => {
              if (e.key === 'Enter') handleBlur();
            }}
            className="form-control"
          />
        ) : (
          <div className="notes_details_title_div">
            <p>{text}</p>
            <button onClick={handleDoubleClick}>
              <MdOutlineEdit />
            </button>
          </div>
        )}
      </div>
    );
  };
  return (
    <div>
      <EditableText text={text} onSave={handleSave} />
    </div>
  );
};
const UserUi = props => {
  return (
    <div className="assign_to_modal_ui">
      <Avatar
        alt={props.userName}
        src={props.userImg}
        sx={{width: 24, height: 24}}
      />
      <span>{props.userName}</span>
    </div>
  );
};
