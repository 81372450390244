import { configureStore } from "@reduxjs/toolkit";
import UserReducer from "./slices/userSlice";
import GlobalReducer from "./slices/globalSlice";

export default configureStore({
  reducer: {
    user: UserReducer,
    global: GlobalReducer,
  },
});
