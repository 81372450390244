import React, {useEffect} from 'react';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

import {PageAllTopTitle} from '../../../../../common/ManagementComponents/PageAllTopTitle';
import {BsArrowLeft} from 'react-icons/bs';
import {Nav, Tab} from 'react-bootstrap';
import {
  OutlineBtn,
  FullBtn,
} from '../../../../../common/ManagementComponents/Btn/Btn';
import Img from '../../../../../common/components/img/Img';
import {ProfileImg} from '../../../../../common/ManagementComponents/ProfileImg';
import NotesImg from '../../../../../../pics/Management/notes.png';
import {GrAttachment} from 'react-icons/gr';
import {IoChatbubblesOutline} from 'react-icons/io5';
import {ImHeadphones} from 'react-icons/im';
import {BiEdit, BiDotsHorizontalRounded, BiTask} from 'react-icons/bi';
import {useState} from 'react';
import {PageInnerTitle} from '../../../../../common/ManagementComponents/pageInnerTitle/Title';
import Drag from '../../../../../../pics/Management/drag.png';
import Down from '../../../../../../pics/Management/down.png';
import ReactApexChart from 'react-apexcharts';
import {Dropdown, ButtonToolbar} from 'rsuite';
import {MdOutlineWidgets} from 'react-icons/md';
import {AiOutlineDelete} from 'react-icons/ai';
import {BsFillInfoCircleFill} from 'react-icons/bs';

// mui
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {CircularStatic} from './CircularStatic';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {DocumentsAttachments} from '../../../../../common/ManagementComponents/DocumentsAttachments';
import {TasksList} from '../../../../components/pages/Tasks/Index';

import {AiFillCaretDown} from 'react-icons/ai';

import Axios from './../../../../../../config/api';
import {StatusCode, getProjectFileUrl} from '../../../../../../constants';
import {Block} from 'notiflix/build/notiflix-block-aio';
import {getMeetingTime} from '../../../../../../utils/functions';
import {getFileIcon} from '../../Approvals/ReceivedApprovalList';
// img link
let charts = [
  {
    title: 'Milestone Status',
    data: {
      series: [56, 22, 22],
      options: {
        colors: ['#0878FF', '#5AE1E2', '#E6ECF6'],
        chart: {
          type: 'pie',
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          position: 'top',
          textAnchor: 'start',
          style: {
            colors: ['#fff'],
          },
        },
        legend: {
          show: false,
        },
        bar: {
          barWidth: '10%',
        },
      },
    },
  },
  {
    title: 'Bugs/Issues',
    data: {
      series: [56, 22, 22],
      options: {
        colors: ['#0878FF', '#5AE1E2', '#E6ECF6'],
        chart: {
          type: 'pie',
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
          position: 'top',
          textAnchor: 'start',
          style: {
            colors: ['#fff'],
          },
        },
        legend: {
          show: false,
        },
        bar: {
          barWidth: '10%',
        },
      },
    },
  },
];
// index
export const ProjectDetails = () => {
  const params = useParams();
  const [projectName, setProjectName] = useState('');
  const [clientId, setClientId] = useState(null);
  return (
    <div className="custom_container">
      <PageAllTopTitle link={-1} icon={<BsArrowLeft />} title="Project" />
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
        <div className="col-12">
          <ProjectDetailsCard
            setClientId={setClientId}
            setProjectName={setProjectName}
          />
        </div>
        <div className="col-12">
          <MyTab
            projectName={projectName}
            clientId={clientId}
            project_id={params?.project_id}
          />
        </div>
      </div>
    </div>
  );
};
// index end
// top card
const ProjectDetailsCard = ({setProjectName, setClientId}) => {
  const params = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState({});
  const [members, setMembers] = useState([]);

  useEffect(() => {
    if (params?.project_id) fetchProjectInfo();
    return () => {};
  }, [params?.project_id]);

  const fetchProjectInfo = () => {
    Block.circle('.project_info_card');
    Axios.fetch(`maxproject/project/info/${params?.project_id}`)
      .then(({data}) => {
        setClientId(data?.project?.Client?.id);
        setProject(data.project);
        setProjectName(data?.project?.name);
        setMembers(data.members);
        Block.remove('.project_info_card');
      })
      .catch(err => {
        const errCode = err?.response?.status;
        Block.remove('.project_info_card');
        if (errCode == StatusCode.BadRequest) navigate(-1);
      });
  };

  return (
    <div className="dashbaord_white_card dashbaord_white_card_new project_info_card">
      <div className="row">
        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 img_dashbaord_white_card_new">
          <ProfileImg
            Photo={getProjectFileUrl(project.project_image)}
            editable={false}
            className="ProjectDetailsCard_img"
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-9 col-sm-12 col-12">
          <div className="project_details_card_name">
            <h3>
              {project?.name > 10
                ? `${project?.name.substring(0, 10)}...`
                : project?.name}
            </h3>
            <p>{project?.Client?.client_email}</p>
          </div>

          <div className="project_details_card_box_main_div">
            <div className="project_details_card_box">
              <p>{project?.due_date}</p>
              <span>Due Date</span>
            </div>
            <div className="project_details_card_box project_details_card_box_inner">
              <p>
                {project?.Tasks?.[0]?.pending_tasks}/
                {project?.Tasks?.[0]?.total_tasks}
              </p>
              <span>Open Tasks</span>
            </div>
            <div className="project_details_card_box project_details_card_box_inner">
              <p>
                {project?.Tasks?.[0]?.overdue_tasks}/
                {project?.Tasks?.[0]?.total_tasks}
              </p>
              <span>Overdue Tasks</span>
            </div>
            <div className="card_user_img">
              <UserParticipants data={members} />
            </div>
          </div>
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="circular_static_div_main_div">
            <div className="circular_static_div">
              <CircularStatic progress={project?.progress || 0} />
            </div>
            <div className="circular_static_div_text">
              <h3>Project Completion</h3>
              <p>Great job on the momentum! You're ahead of deadline!</p>
              <FullBtn link="/Management/tasks" name="View Tasks" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
// top card end
// tab
const MyTab = ({projectName, clientId, project_id}) => {
  const [permissions, setPermissions] = useState({hasDeletePermission: false});
  return (
    <div className="project_list_tabs_main_div">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="project_tab_mb">
          <Nav variant="pills" className="flex">
            <Nav.Item>
              <Nav.Link eventKey="first">Project Dashboard</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Overview">Overview</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Team">Team & Work Progress</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="comment">Comments</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Meetings">Meetings & Notes</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Tasks">Tasks</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <Tab.Content>
          <Tab.Pane eventKey="first">
            <div className="Dashboard_menu_right">
              <TabsMenu
                permissions={permissions}
                projectName={projectName}
                clientId={clientId}
                WidgetsControl={<WidgetsControl />}
              />
            </div>
            <ProjectDashboard />
          </Tab.Pane>
          <Tab.Pane eventKey="Overview">
            <div className="Dashboard_menu_right">
              <TabsMenu
                permissions={permissions}
                projectName={projectName}
                clientId={clientId}
              />
            </div>
            <Overview setPermissions={setPermissions} />
          </Tab.Pane>
          <Tab.Pane eventKey="Team">
            <div className="Dashboard_menu_right">
              <TabsMenu
                permissions={permissions}
                projectName={projectName}
                clientId={clientId}
              />
            </div>
            <TeamWorkProgress />
          </Tab.Pane>
          <Tab.Pane eventKey="comment">
            <div className="Dashboard_menu_right">
              <TabsMenu
                permissions={permissions}
                projectName={projectName}
                clientId={clientId}
              />
            </div>
            <h5>There are no new comments </h5>
          </Tab.Pane>
          <Tab.Pane eventKey="Meetings">
            <div className="Dashboard_menu_right">
              <TabsMenu
                permissions={permissions}
                projectName={projectName}
                clientId={clientId}
              />
            </div>
            <Meetings />
          </Tab.Pane>
          <Tab.Pane eventKey="Tasks">
            <div className="Dashboard_menu_right">
              <TabsMenu
                permissions={permissions}
                projectName={projectName}
                clientId={clientId}
              />
            </div>
            <TasksList project_id={Number(project_id)} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};
// tab end
// Meetings & Notes
const Meetings = () => {
  const params = useParams();
  const [meetings, setMeetings] = useState([]);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    if (params?.project_id) {
      fetchProjectMeetings();
      fetchProjectNotes();
    }
    return () => {};
  }, []);

  const fetchProjectMeetings = () => {
    Block.circle('.meetings_table');
    Axios.fetch(`maxproject/meeting/project/${params?.project_id}`)
      .then(({data}) => {
        Block.remove('.meetings_table');
        setMeetings(data?.meetings);
      })
      .catch(err => {
        Block.remove('.meetings_table');
      });
  };
  const fetchProjectNotes = () => {
    Block.circle('.notes_list');
    Axios.fetch(`maxproject/note/project/${params?.project_id}`)
      .then(({data}) => {
        Block.remove('.notes_list');
        setNotes(data?.notes);
      })
      .catch(err => {
        Block.remove('.notes_list');
      });
  };

  const handleDeleteNote = (note, i, cb) => {
    Axios.delete(`maxproject/note/${note.id}`).then(() => {
      setNotes(old => {
        let notes = old.slice();
        notes.splice(i, 1);
        return notes;
      });
      cb();
    });
  };

  return (
    <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
      <div className="col-12">
        <div className="row d-flex align-items-baseline">
          <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-9 col-sm-6 col-6">
            <PageAllTopTitle title="Scheduled Meetings" />
          </div>
          <div className="ol-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-6 col-6 text-end">
            <NavLink to="/Management/meetings" className="text_btn">
              View All Meetings
            </NavLink>
          </div>
        </div>
      </div>
      <div className="col-12 meetings_table">
        <Meeting data={meetings} />
      </div>
      <div className="col-12">
        <div className="row d-flex align-items-baseline">
          <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-9 col-sm-6 col-6">
            <PageAllTopTitle title="Recent Notes" />
          </div>
          <div className="ol-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-6 col-6 text-end">
            <NavLink to="#" className="text_btn">
              View All Notes
            </NavLink>
          </div>
        </div>
      </div>
      <div className="col-12 notes_list">
        <Notes data={notes} handleDeleteNote={handleDeleteNote} />
      </div>
    </div>
  );
};
const Meeting = ({data}) => {
  const [page, setPage] = useState(1);

  const handlePagination = (e, page) => {
    setPage(page);
  };
  const Row = props => {
    return (
      <tr className="progress_all_tabel_tr_mor">
        <td>
          <div className="Participants_main_div_tr">
            <input className="form-check-input" type="checkbox" />
          </div>
        </td>
        <td>
          <div className="Participants_main_div_tr">
            <NavLink className="link_a" to={'/Management/meeting/' + props.id}>
              {props?.title}
            </NavLink>
          </div>
        </td>
        <td className="project_list_name">
          <div className="Participants_main_div_tr">{props?.Project?.name}</div>
        </td>
        <td className="Participants_main_div">
          <div className="Participants_main_div_inner">
            <MeetingParticipants data={props?.MeetingParticipants} />
          </div>
        </td>
        <td>
          <div className="Participants_main_div_tr">{props?.User?.name}</div>
        </td>

        <td>
          <div className="Participants_main_div_tr">
            {getMeetingTime(props)}
          </div>
        </td>
        <td>
          <div className=" Participants_main_div_tr_btn ">
            <OutlineBtn
              name="Join"
              onClick={() => window.open(props.link, '_blank')}
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className="dashbaord_white_card ">
      <div>
        <table className="table table-hover text-capitalize progress_all_tabel_tabel">
          <thead>
            <tr className="progress_all_tabel_tr">
              <th scope="col">
                <input className="form-check-input" type="checkbox" />
              </th>
              <th scope="col">Meeting Title</th>
              <th scope="col">Project Name</th>
              <th scope="col">Participants</th>
              <th scope="col">Organiser</th>

              <th scope="col">Scheduled Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.slice((page - 1) * 10, 10 * page)?.map((val, i) => (
              <Row key={i} {...val} />
            ))}
          </tbody>
        </table>
      </div>
      {data?.length > 10 ? (
        <div className="projec_tabelt_list_pagination_div">
          <Stack spacing={2}>
            <Pagination
              onChange={handlePagination}
              count={Math.ceil(data?.length / 10)}
            />
          </Stack>
        </div>
      ) : null}
    </div>
  );
};

const Notes = ({data, handleDeleteNote}) => {
  const Note = props => {
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const ProjectListDropDown = () => {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
      const handleClick = event => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
      const navigate = useNavigate();

      const ITEM_HEIGHT = 30;
      return (
        <div className="chat_option">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            className=" m-0 p-0">
            <BiDotsHorizontalRounded />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '15ch',
              },
            }}>
            <MenuItem
              disabled
              onClick={() => navigate('/Management/edit_notes')}
              className="chat_option_name_div">
              Edit
            </MenuItem>
            <MenuItem
              disabled
              onClick={() => navigate('/Management/create_task')}
              className="chat_option_name_div">
              Create Task
            </MenuItem>
            <MenuItem disabled className="chat_option_name_div">
              Create Poll
            </MenuItem>
            <MenuItem
              onClick={() => setOpen(true)}
              className="chat_option_name_div">
              Delete
            </MenuItem>
          </Menu>
        </div>
      );
    };
    const DeleteModal = () => {
      return (
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}>
            <Fade in={open}>
              <Box sx={style}>
                <div className="delete_modal">
                  <ul>
                    <li>
                      <BsFillInfoCircleFill />
                    </li>
                    <li>
                      <h3>Delete {props.title}?</h3>
                      <p>
                        {props.name} will be deleted permanently and cannot be
                        recovered.
                      </p>
                    </li>
                  </ul>
                  <div className="delete_modal_btn_div">
                    <ul>
                      <li className="btn_one">
                        <OutlineBtn onClick={handleClose} name="Cancel" />
                      </li>
                      <li className="btn_two">
                        <FullBtn
                          onClick={() =>
                            props?.handleDelete(() => setOpen(false))
                          }
                          name="Delete"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      );
    };
    return (
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
        <DeleteModal />
        <div className="notes_card_top_div">
          <div className="notes_card_top_opsiton">
            <ProjectListDropDown />
          </div>
          <div>
            <h3>
              {props?.User?.name} <span>created this</span>
            </h3>
            <p>{moment(props?.createdAt).calendar()}</p>
            <div className="notes_card_img_div">
              <Img className="notes_card_img" backgroundImage={NotesImg} />
            </div>
            <h5>{props?.title}</h5>
            <ul>
              <li className=" ms-1">
                <span>
                  <GrAttachment />
                </span>
                {props?.NoteAttachments?.length}
              </li>
              <li>
                <span>
                  <IoChatbubblesOutline />
                </span>
                {props?.NoteAttachments?.length}
              </li>
              <li>
                <span>
                  <ImHeadphones />
                </span>
                {props?.NoteAttachments?.length}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
      {data.map((val, i) => (
        <Note
          key={i}
          {...val}
          handleDelete={cb => handleDeleteNote(val, i, cb)}
        />
      ))}
    </div>
  );
};
// Meetings & Notes end
// Team & Work Progress
const TeamWorkProgress = () => {
  const params = useParams();

  const [page, setPage] = useState(1);
  const [team, setTeam] = useState([]);

  useEffect(() => {
    if (params?.project_id) fetchProjectTeam();
    return () => {};
  }, []);

  const fetchProjectTeam = () => {
    Block.circle('.teams_table');
    Axios.fetch(`maxproject/team/${params?.project_id}`)
      .then(({data}) => {
        setTeam(data?.team);
        Block.remove('.teams_table');
      })
      .catch(err => {
        Block.remove('.teams_table');
      });
  };

  const handlePagination = (e, page) => {
    setPage(page);
  };

  const Row = props => {
    return (
      <tr className="progress_all_tabel_tr_mor">
        <td className="Participants_main_div">
          <NavLink className="link_a" to={'/Management/view_all_tasks'}>
            <div className="Participants_main_div_inner">
              <AvatarGroup>
                <Avatar
                  alt={props?.avatar}
                  src={props?.avatar}
                  sx={{width: 24, height: 24}}
                />
              </AvatarGroup>
              <span> {props?.name}</span>
            </div>
          </NavLink>
        </td>
        <td>
          <div className="Participants_main_div_tr">
            {props?.Designation?.name}
          </div>
        </td>
        <td>
          {Number(props?.Tasks?.[0]?.total_tasks) ? (
            <div className="Participants_main_div_tr">
              {props?.Tasks?.[0]?.pending_tasks}/
              {props?.Tasks?.[0]?.total_tasks}
            </div>
          ) : null}
        </td>
        <td className="project_list_name project_details_bar_div">
          {Number(props?.Tasks?.[0]?.total_tasks) ? (
            <div className="Participants_main_div_tr">
              <div className="row align-items-center">
                <div className="col-2">
                  <span>
                    {parseFloat(
                      (Number(props?.Tasks?.[0]?.pending_tasks) /
                        Number(props?.Tasks?.[0]?.total_tasks)) *
                        100,
                    ).toFixed(0)}
                    %
                  </span>
                </div>
                <div className="col-10">
                  <div className="d-none d-xxl-block d-xl-block d-lg-block d-md-block">
                    <div className="progress ">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width:
                            (Number(props?.Tasks?.[0]?.pending_tasks) /
                              Number(props?.Tasks?.[0]?.total_tasks)) *
                              100 +
                            '%',
                        }}
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </td>

        <td>
          <div className="Participants_main_div_tr">
            <span className="project_details_status_text">{props.Status}</span>
          </div>
        </td>
        <td>
          <div className=" Participants_main_div_tr_btn ">
            <OutlineBtn disabled name="View" />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className="dashbaord_white_card teams_table">
      <PageAllTopTitle title="Team & Work Progress" />
      <div>
        <table className="table table-hover text-capitalize team_work_progress">
          <thead>
            <tr className="progress_all_tabel_tr">
              <th scope="col">Team Member</th>
              <th scope="col">Role</th>
              <th scope="col">Tasks</th>
              <th scope="col">Progress</th>
              <th scope="col">Status</th>
              <th scope="col">action</th>
            </tr>
          </thead>
          <tbody>
            {team?.slice((page - 1) * 10, 10 * page)?.map((val, i) => (
              <Row key={i} {...val} />
            ))}
          </tbody>
        </table>
      </div>
      {team?.length > 10 ? (
        <div className="projec_tabelt_list_pagination_div">
          <Stack spacing={2}>
            <Pagination
              onChange={handlePagination}
              count={Math.ceil(team?.length / 10)}
            />
          </Stack>
        </div>
      ) : null}
    </div>
  );
};
// Team & Work Progress end
// Overview

const Overview = ({setPermissions}) => {
  const params = useParams();
  const [projectData, setProjectData] = useState({});

  useEffect(() => {
    if (params?.project_id) fetchProjectOverView();
    return () => {};
  }, []);

  const fetchProjectOverView = () => {
    Block.circle('.overview_card');
    Axios.fetch(`maxproject/project/overview/${params?.project_id}`)
      .then(({data}) => {
        Block.remove('.overview_card');
        setProjectData(data?.project);
        setPermissions({hasDeletePermission: data?.hasDeletePermission});
      })
      .catch(err => {
        Block.remove('.overview_card');
      });
  };
  return (
    <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
      <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
        <div className="dashbaord_white_card overview_card">
          <PageAllTopTitle title="Details" />
          <BasicOverviewDetails data={projectData} />
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
        <div className="dashbaord_white_card overview_card">
          <PageAllTopTitle title="Service Overview" />
          <ServiceOverview data={projectData?.ProjectServices} />
        </div>
      </div>
    </div>
  );
};
const BasicOverviewDetails = ({data}) => {
  const handleFileUpload = event => {
    console.log(event.target.files[0].name);
  };
  const BasicOverviewDetailsApp = ({title, value}) => {
    return (
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <div className="overview_basic_details_div">
          <ul>
            <li className="overview_basic_details_inner_div position-relative">
              {title}
              <label>:</label>
            </li>
            <li>
              <p>{value}</p>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div className="row g-2 all_edit_icon">
      <BasicOverviewDetailsApp title="title" value={data?.name} />
      <BasicOverviewDetailsApp
        title="Lead Source"
        value={data?.LeadSource?.name}
      />
      <BasicOverviewDetailsApp
        title="Onboarding Date"
        value={data?.onboarding_date}
      />
      <BasicOverviewDetailsApp title="Due Date" value={data?.due_date} />
      <BasicOverviewDetailsApp
        title="Benchmark"
        value={data?.Benchmark?.name}
      />

      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <div className="overview_basic_details_div ">
          <ul>
            <li className="overview_basic_details_inner_div position-relative">
              Priority
              <label>:</label>
            </li>
            <li>
              <p
                className="project_list_priority_div"
                style={{
                  background: data?.Priority?.bg_color,
                  color: data?.Priority?.text_color,
                }}>
                <span>{data?.Priority?.name}</span>
              </p>
            </li>
          </ul>
          {/* <div className="all_edit_icon_inner">
            <BiEdit />
          </div> */}
        </div>
      </div>

      <div className="col-12">
        <div className=" brief_summery_div all_edit_icon">
          <h3>Brief:</h3>
          <div>{ReactHtmlParser(data?.brief)}</div>
          {/* <div className="all_edit_icon_inner">
            <BiEdit />
          </div> */}
        </div>
      </div>
      <div className="col-12">
        <div className=" brief_summery_div all_edit_icon">
          <h3>Objective:</h3>
          {ReactHtmlParser(data?.objective)}
          {/* <div className="all_edit_icon_inner">
            <BiEdit />
          </div> */}
        </div>
      </div>
      <div className="col-12">
        <div className=" brief_summery_div all_edit_icon">
          <h3>Scope:</h3>
          {ReactHtmlParser(data?.scope)}
          {/* <div className="all_edit_icon_inner">
            <BiEdit />
          </div> */}
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-9 col-9">
            <div className="brief_summery_div ">
              <h3>Attachments:</h3>
            </div>
          </div>
          {/* <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-3 col-3">
          <div className="documents_app_btn">
            <input
              onChange={handleFileUpload}
              type="file"
              style={{display: 'none'}}
              id="dd"
              hidden
            />
            <label className="management_btn_full2" htmlFor="dd">
              <span> Upload Document</span>
              <BsUpload />
            </label>
          </div>
        </div> */}
        </div>
        <div className="doc_img_main_div">
          <div className="row g-3 mt-2">
            {data?.ProjectAttachments?.map((el, i) => (
              <DocumentsAttachments
                cols="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6"
                key={i}
                original_name={el.original_name}
                downloadUrl={getProjectFileUrl(el.name)}
                url={getFileIcon({...el, url: getProjectFileUrl(el.name)})}
                // handleDelete={handleDeleteAttachment}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ServiceOverview = ({data}) => {
  const Service = props => {
    const progress =
      Math.round(
        (props?.tasks?.completed_tasks / props?.tasks?.total_tasks) * 100 * 100,
      ) / 100 || 0;

    return (
      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-6 col-12 col-12">
        <div className="service_overview_details_div">
          <div className="service_overview_details">
            <h3>{props?.Service?.name}</h3>
            <div className="row">
              <div className="col-9">
                <h4>
                  {props?.Service?.Department?.name} - (
                  {props?.Service?.Department?.Department?.name})
                </h4>
                {props?.reporting_date ? (
                  <i style={{fontSize: 12, color: 'gray'}}>
                    Reporting Date - {props?.reporting_date}
                  </i>
                ) : null}
              </div>
              <div className="col-3 text-end">
                <h5>{props.status}</h5>
              </div>
            </div>
            <div className="text-center">
              <p>{progress}%</p>
            </div>
          </div>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: progress + '%',
              }}
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"></div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="row g-3">
      {data?.map((val, i) => (
        <Service key={i} {...val} />
      ))}
    </div>
  );
};
// Overview end
// Project Dashboard
const ProjectDashboard = () => {
  const params = useParams();
  const [services, setServices] = useState([]);
  const [teamTasks, setTeamTasks] = useState([]);
  const [teamIsseus, setTeamIssues] = useState([]);
  const [tasksChartData, setTasksChartData] = useState({
    series: [],
    labels: [],
    colors: [],
  });
  const [issueChartData, setIssueChartData] = useState({
    series: [],
    labels: [],
    colors: [],
  });
  const [subTasksChartData, setSubTasksChartData] = useState({
    series: [],
    labels: [],
    colors: [],
  });

  useEffect(() => {
    if (params?.project_id) fetchProjectInfo();
    return () => {};
  }, []);

  const fetchProjectInfo = () => {
    Block.circle('.dashboard_card');
    Axios.fetch(`maxproject/project/dashboard/${params?.project_id}`)
      .then(({data}) => {
        Block.remove('.dashboard_card');
        setServices(data?.services);
        setTeamTasks(data?.team?.tasks);
        setTeamIssues(data?.team?.issues);
        //preparing tasks chart data
        let series = [];
        let labels = [];
        let colors = [];
        data?.tasks_chart?.forEach(el => {
          series.push(el.count);
          labels.push(el?.Status?.name);
          colors.push(el?.Status?.bg_color);
        });
        setTasksChartData({series, labels, colors});
        //preparing issue chart dat
        if (data?.issue_chart?.pending + data?.issue_chart?.completed)
          setIssueChartData({
            series: [data?.issue_chart?.pending, data?.issue_chart?.completed],
            labels: ['Pending', 'Completed'],
            colors: ['#FF0000', '#00FF00'],
          });
        //preparing sub-tasks chart dat
        if (data?.sub_tasks_chart?.pending + data?.sub_tasks_chart?.completed)
          setSubTasksChartData({
            series: [
              data?.sub_tasks_chart?.pending,
              data?.sub_tasks_chart?.completed,
            ],
            labels: ['Pending', 'Completed'],
            colors: ['#FF0000', '#00FF00'],
          });
      })
      .catch(err => {
        Block.remove('.dashboard_card');
      });
  };
  return (
    <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
      <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
          <div className="col-12 dashboard_card">
            <ProjectServices services={services} />
          </div>
          <div className="col-12 dashboard_card">
            <TeamStatus teamTasks={teamTasks} teamIsseus={teamIsseus} />
          </div>
          {/* <div className="col-12 dashboard_card">
            <WeeklyDigest />
          </div>
          <div className="col-12 dashboard_card">
            <ProjectCostChart />
          </div> */}
        </div>
      </div>

      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
        <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
          <div className="col-12">
            <PieChart
              title="Task Status"
              className="dashboard_card"
              data={{
                series: tasksChartData.series,
                options: {
                  labels: tasksChartData.labels,
                  colors: tasksChartData.colors,
                  chart: {
                    type: 'pie',
                    stacked: false,
                    toolbar: {
                      show: false,
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    position: 'top',
                    textAnchor: 'start',
                    style: {
                      colors: ['#fff'],
                    },
                  },
                  legend: {
                    show: false,
                  },
                  bar: {
                    barWidth: '10%',
                  },
                  noData: {
                    text: "There's no data",
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                  },
                },
              }}
            />
          </div>
          <div className="col-12">
            <PieChart
              title="Sub-Tasks Status"
              className="dashboard_card"
              data={{
                series: subTasksChartData.series,
                options: {
                  labels: subTasksChartData.labels,
                  colors: subTasksChartData.colors,
                  chart: {
                    type: 'pie',
                    stacked: false,
                    toolbar: {
                      show: false,
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    position: 'top',
                    textAnchor: 'start',
                    style: {
                      colors: ['#fff'],
                    },
                  },
                  legend: {
                    show: false,
                  },
                  bar: {
                    barWidth: '10%',
                  },
                  noData: {
                    text: "There's no data",
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                  },
                },
              }}
            />
          </div>
          <div className="col-12">
            <PieChart
              title="Issue Status"
              className="dashboard_card"
              data={{
                series: issueChartData.series,
                options: {
                  labels: issueChartData.labels,
                  colors: issueChartData.colors,
                  chart: {
                    type: 'pie',
                    stacked: false,
                    toolbar: {
                      show: false,
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    position: 'top',
                    textAnchor: 'start',
                    style: {
                      colors: ['#fff'],
                    },
                  },
                  legend: {
                    show: false,
                  },
                  bar: {
                    barWidth: '10%',
                  },
                  noData: {
                    text: "There's no data",
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                  },
                },
              }}
            />
          </div>
          {/* {charts.map((chart, i) => (
            <div className="col-12" key={i}>
              <PieChart className="dashboard_card" {...chart} />
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
};
const ProjectServices = ({services}) => {
  const Service = props => {
    const progress =
      Math.round(
        (props?.tasks?.completed_tasks / props?.tasks?.total_tasks) * 100 * 100,
      ) / 100 || 0;
    return (
      <tr>
        <td className="project_list_name">{props?.Service?.name}</td>
        <td className="project_list_name Progress_title_details_midel">
          {props?.Status?.name}
        </td>
        <td className="project_list_name project_details_bar_div ">
          <div className="Participants_main_div_tr Progress_title_details_top">
            <div className="row align-items-center">
              <div className="col-12 Progress_title_details">
                <span>{progress}%</span>
              </div>
              <div className="col-12">
                <div className="d-none d-xxl-block d-xl-block d-lg-block d-md-block">
                  <div className="progress ">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: progress + '%',
                      }}
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="dashbaord_white_card Progress_title_details_white">
        <img className="drag_img" src={Drag} alt="drag.img" />
        <div className="row">
          <div className="col-12 ms-2">
            <PageInnerTitle name="Services" />
          </div>
        </div>
        <div className="project_details_dashboard_div">
          <table className="table project_tabel table-borderless text-capitalize">
            <thead>
              <tr className="project_list_title">
                <th scope="col">Service Name</th>
                <th scope="col">Status</th>
                <th scope="col">Progress</th>
              </tr>
            </thead>
            <tbody className="project_list_name_tr">
              {services.map((val, i) => (
                <Service key={i} {...val} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
const TeamStatus = ({teamTasks, teamIsseus}) => {
  const UserRow = ({avatar, col1, col2, col3}) => {
    return (
      <div className="col-12">
        <div className="row team_status_uper_div">
          <div className="col-2">
            <Stack direction="row" spacing={2}>
              <Avatar alt="user" src={avatar} sx={{width: 24, height: 24}} />
            </Stack>
          </div>
          <div className="col-10">
            <ul className="team_status_inner_title2">
              <li>{col1}</li>
              <li className="team_status_inner_color">{col2}</li>
              <li>{col3}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="dashbaord_white_card Progress_title_details_white">
        <img className="drag_img" src={Drag} alt="drag.img" />
        <div className="row">
          <div className="col-12 ms-2">
            <PageInnerTitle name="Team Status" />
          </div>
        </div>
        <div className="project_details_dashboard_div">
          <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="row g-4">
                <div className="col-12">
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col-10">
                      <div className="team_status_title">
                        <h3>tasks</h3>
                      </div>
                      <ul className="team_status_inner_title">
                        <li>Today's</li>
                        <li className="team_status_inner_color">Overdue</li>
                        <li>All Open</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {teamTasks?.map((task, i) => (
                  <UserRow
                    key={i}
                    avatar={task?.User?.avatar}
                    col1={task?.today}
                    col2={task?.overdue}
                    col3={task?.total_tasks}
                  />
                ))}
              </div>
            </div>
            {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="row g-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-10">
                    <div className="team_status_title">
                      <h3>Issues</h3>
                    </div>
                    <ul className="team_status_inner_title">
                      <li>Today’s</li>
                      <li className="team_status_inner_color">Overdue</li>
                      <li>All Open</li>
                    </ul>
                  </div>
                </div>
              </div>
              {teamIsseus.map((task, i) => (
                <UserRow
                  key={i}
                  avatar={task?.User?.avatar}
                  col1={task?.today}
                  col2={task?.overdue}
                  col3={task?.total_tasks}
                />
              ))}
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
const WeeklyDigest = () => {
  return (
    <>
      <div className="dashbaord_white_card Progress_title_details_white">
        <img className="drag_img" src={Drag} alt="drag.img" />
        <div className="row">
          <div className="col-12 ms-2">
            <div className="weekly_digest_title">
              <PageInnerTitle name="Weekly Digest" />
              <p>From 04/12/2022 to 10/12/2022</p>
              <img src={Down} alt="jfv" />
              <select
                className="form-select"
                aria-label="Default select example">
                <option defaultValue>Week 22</option>
                <option value="1">Week 2</option>
                <option value="2">Week 21</option>
                <option value="3">Week 20</option>
              </select>
            </div>
          </div>
          <div className="col-12">
            <WeeklyDigestChart />
          </div>
        </div>
      </div>
    </>
  );
};
const WeeklyDigestChart = () => {
  const [state] = useState({
    series: [
      {
        name: 'sales',
        data: [
          {
            x: '2019/01/01',
            y: 400,
          },
          {
            x: '2019/01/01',
            y: 500,
          },
          {
            x: '2019/01/01',
            y: 400,
          },
          {
            x: '2019/01/01',
            y: 500,
          },
          {
            x: '2019/01/01',
            y: 400,
          },
          {
            x: '2019/01/01',
            y: 500,
          },
          {
            x: '2019/01/01',
            y: 400,
          },
        ],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
      },
      legend: {
        show: false,
      },
      bar: {
        barWidth: '10%',
      },
    },
  });
  return (
    <>
      <div id="chart">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
          height={250}
        />
      </div>
    </>
  );
};
const ProjectCostChart = () => {
  const [state] = useState({
    series: [
      {
        name: 'Overtime',
        data: [50, 90, 71, 10, 11, 19, 15],
      },
      {
        name: 'Working Time',
        data: [20, 29, 37, 36, 44, 45, 70],
      },
    ],
    options: {
      colors: ['#4318FF', '#6AD2FF'],
      chart: {
        type: 'line',
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ['SEP', 'OCT', 'NOV', 'DEC', 'JAN', 'FEB'],
      },
      legend: {
        show: false,
      },
      bar: {
        barWidth: '10%',
      },
    },
  });

  return (
    <>
      <div className="dashbaord_white_card Progress_title_details_white">
        <img className="drag_img" src={Drag} alt="drag.img" />
        <div className="row">
          <div className="col-12 ms-2">
            <PageInnerTitle name="Team Status" />
          </div>
        </div>
        <div id="chart">
          <ReactApexChart
            options={state.options}
            series={state.series}
            type="line"
            height={250}
          />
        </div>
      </div>
    </>
  );
};
const PieChart = props => {
  return (
    <div
      className={`dashbaord_white_card Progress_title_details_white ${props.className}`}>
      <img className="drag_img" src={Drag} alt="drag icon" />
      <div className="row">
        <div className="col-12 ms-2">
          <PageInnerTitle name={props.title} />
        </div>
      </div>
      <div id="chart">
        <ReactApexChart {...props.data} type="pie" height={250} />
      </div>
    </div>
  );
};
// Project Dashboard end
// Widgets Control
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const TabsMenu = props => {
  const params = useParams();
  const [showProjectDeleteModal, SetShowProjectDeleteModal] = useState(false);
  const DeleteModal = () => {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showProjectDeleteModal}
        onClose={() => SetShowProjectDeleteModal(false)}
        closeAfterTransition>
        <Fade in={showProjectDeleteModal}>
          <Box sx={style}>
            <div className="delete_modal">
              <ul>
                <li>
                  <BsFillInfoCircleFill />
                </li>
                <li>
                  <h3>Delete {props.projectName}?</h3>
                  <p>
                    Are you sure you want to delete “{props.projectName}”? It
                    cannot be recovered later.
                  </p>
                </li>
              </ul>
              <div className="delete_modal_btn_div">
                <ul>
                  <li className="btn_one">
                    <OutlineBtn
                      onClick={() => SetShowProjectDeleteModal(false)}
                      name="Cancel"
                    />
                  </li>
                  <li className="btn_two">
                    <FullBtn onClick={handleDeleteProject} name="Delete" />
                  </li>
                </ul>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    );
  };
  const handleDeleteProject = () => {
    //deleting project
    Axios.delete('maxproject/project/' + params?.project_id).then(({data}) => {
      SetShowProjectDeleteModal(false);
      navigate(-1);
    });
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="chat_div_dropdown chat_div_dropdown_right">
        <ButtonToolbar>
          <Dropdown
            title={<BiDotsHorizontalRounded />}
            activeKey="e-2 "
            placement="leftStart">
            <Dropdown.Item
              disabled={!props?.clientId}
              onClick={() =>
                navigate(`/Management/assign_project/${params?.project_id}`)
              }
              className="chat_div_dropdown_inner"
              eventKey="a">
              Assign Project
              <span>
                <BiEdit />
              </span>
            </Dropdown.Item>
            <Dropdown.Item
              disabled={!props?.clientId}
              onClick={() =>
                navigate(
                  `/Management/add_project/${props?.clientId}/${params?.project_id}`,
                )
              }
              className="chat_div_dropdown_inner"
              eventKey="a">
              Edit Project
              <span>
                <BiEdit />
              </span>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => navigate('/Management/tasks')}
              className="chat_div_dropdown_inner"
              eventKey="c">
              View Tasks
              <span>
                <BiTask />
              </span>
            </Dropdown.Item>
            {props?.permissions?.hasDeletePermission ? (
              <Dropdown.Item
                onClick={() => SetShowProjectDeleteModal(true)}
                className="chat_div_dropdown_inner"
                eventKey="b">
                Delete Project
                <span>
                  <AiOutlineDelete />
                </span>
              </Dropdown.Item>
            ) : null}
            {/* <span onClick={() => SetShowProjectDeleteModal(false)}>
              {props.WidgetsControl}
            </span> */}
          </Dropdown>
        </ButtonToolbar>
      </div>
      <DeleteModal />
    </>
  );
};
const WidgetsControl = () => {
  const CustomDropdownInner = () => (
    <div className="custom_dropdown_inner">
      Widgets Control
      <span>
        <MdOutlineWidgets />
      </span>
    </div>
  );
  const CustomDropdown = ({...props}) => {
    const InnerItemData = [
      {
        InnerItemName: 'Progress',
      },
      {
        InnerItemName: 'Task Status',
      },
      {
        InnerItemName: 'Team Status',
      },
      {
        InnerItemName: 'Issue Status',
      },
      {
        InnerItemName: 'Weekly Digest',
      },
      {
        InnerItemName: 'Milestone Status',
      },
      {
        InnerItemName: 'Project Cost',
      },
      {
        InnerItemName: 'Bugs/Issues',
      },
    ];
    const InnerItemApp = props => {
      const [state, setState] = React.useState({
        gilad: false,
        jason: false,
        antoine: false,
      });

      const handleChange = event => {
        setState({
          ...state,
          [event.target.name]: event.target.checked,
        });
      };
      return (
        <Dropdown.Item className="Dashboard_menu_right_innwr_item">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={state.gilad}
                  onChange={handleChange}
                  name="gilad"
                  size="small"
                  color="warning"
                />
              }
              label={props.InnerItemName}
            />
          </FormGroup>
        </Dropdown.Item>
      );
    };
    return (
      <div className="Dashboard_menu_right_innwr">
        <Dropdown {...props}>
          {InnerItemData.map((val, i) => {
            return <InnerItemApp key={i} {...val} />;
          })}
        </Dropdown>
      </div>
    );
  };
  return (
    <div className="custom_dropdown_last">
      <CustomDropdown
        title={<CustomDropdownInner />}
        trigger="click"
        placement="topEnd"
      />
    </div>
  );
};
// Widgets Control end

const MeetingParticipants = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ParticipantsModal = props => {
    const ParticipantsApp = props => {
      return (
        <div className="col-12">
          <div className="user_card_div_inner">
            <Avatar
              alt={props?.User?.name}
              src={props?.User?.avatar}
              sx={{width: 15, height: 15}}
            />
            <p>{props?.User?.name}</p>
            <h6>{props?.User?.Designation?.name}</h6>
          </div>
        </div>
      );
    };
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#ddc8c8',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
        <div className="user_card_div_title">
          <h3>Participants</h3>
        </div>
        <div className="user_card_div">
          <div className="row g-2 ">
            {props.data.map((val, i) => {
              return <ParticipantsApp key={i} {...val} />;
            })}
          </div>
        </div>
      </Menu>
    );
  };
  return (
    <>
      <ParticipantsModal data={props.data} />
      <div className="Participants_icon_div">
        <AvatarGroup onClick={handleClick} max={5}>
          {props.data.map((val, i) => (
            <Avatar key={i} alt={val?.User?.name} src={val?.User?.avatar} />
          ))}
        </AvatarGroup>
        <AiFillCaretDown onClick={handleClick} className="Participants_icon" />
      </div>
    </>
  );
};

const UserParticipants = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ParticipantsModal = props => {
    const ParticipantsApp = props => {
      return (
        <div className="col-12">
          <div className="user_card_div_inner">
            <Avatar
              alt={props?.name}
              src={props?.avatar}
              sx={{width: 15, height: 15}}
            />
            <p>{props?.name}</p>
            <h6>{props?.Designation?.name}</h6>
          </div>
        </div>
      );
    };
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#ddc8c8',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
        <div className="user_card_div_title">
          <h3>Participants</h3>
        </div>
        <div className="user_card_div">
          <div className="row g-2 ">
            {props.data.map((val, i) => {
              return <ParticipantsApp key={i} {...val} />;
            })}
          </div>
        </div>
      </Menu>
    );
  };
  return (
    <>
      <ParticipantsModal data={props.data} />
      <div className="Participants_icon_div">
        <AvatarGroup onClick={handleClick} max={5}>
          {props.data.map((val, i) => (
            <Avatar key={i} alt={val?.name} src={val?.avatar} />
          ))}
        </AvatarGroup>
        <AiFillCaretDown onClick={handleClick} className="Participants_icon" />
      </div>
    </>
  );
};
