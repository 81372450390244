import { PayrollIndex } from "../components/Pages/Payroll/PayrollIndex";
const Payroll = () => {
  return (
    <>
      <div className="custom_container">
        <PayrollIndex />
      </div>
    </>
  );
};

export default Payroll;
