import { Tabs } from "../components/Pages/Profile/Index";
const Profile = () => {
  return (
    <>
      <div className="custom_container">
        <Tabs />
      </div>
    </>
  );
};

export default Profile;
