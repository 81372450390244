import {ENDPOINT} from './index';

export const toHoursAndMinutes = totalSeconds => {
  const totalMinutes = Math.floor(totalSeconds / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const seconds = totalSeconds % 60;
  if (totalSeconds < 60) return `${seconds}s`;
  return `${hours ? `${hours}h`: ``}${minutes > 0 ? ` ${minutes}m` : ''}${
    seconds > 0 ? ` ${seconds}s` : ''
  }`;
};

export const getIconUrl = file => `${ENDPOINT}icons/${file}`;
export const getRandomFromArray = array =>
  array[Math.floor(Math.random() * array.length)];
