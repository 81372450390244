import * as React from 'react';
import {useState, useEffect} from 'react';
import {Block} from 'notiflix/build/notiflix-block-aio';
import Axios from './../../../config/api';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationImg from './img/Img';
import NoNotificationImg from '../../../pics/no_notifications.png';
import {NavLink} from 'react-router-dom';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {setGlobal} from '../../../redux/slices/globalSlice';

const NoNotifications = () => (
  <div
    className="no_notifications_div"
    style={{width: '25rem', padding: '5rem'}}>
    <div className="no_notifications_inner">
      <NotificationImg
        className="no_notifications"
        backgroundImage={NoNotificationImg}
      />
      <h3>No notifications yet</h3>
    </div>
  </div>
);
export const NotificationOuter = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = useState([]);

  const open = Boolean(anchorEl);

  useEffect(() => {
    fetchNotifications();
    return () => {};
  }, []);

  const fetchNotifications = () => {
    Block.circle('.notifications_popup');
    Axios.fetch(`maxproject/notification?limit=5&unreadOnly=true`)
      .then(({data}) => {
        // Block.remove('.notifications_popup');
        setNotifications(data?.notifications);
      })
      .catch(err => {
        Block.remove('.notifications_popup');
      });
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const Notification = props => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleMarkNotificationaAsRead = () => {
      Axios.post(`maxproject/notification/read/${props?.id}`)
        .then(({data}) => {
          setNotifications(old => {
            let arr = old.slice();
            arr[props?.idx].isRead = 1;
            return arr;
          });
        })
        .catch(err => {});
    };

    const handleOnNotificationClick = () => {
      //marking notification as read
      if (!props?.isRead) handleMarkNotificationaAsRead();
      //getting notifaction payload
      let payload = props?.payload;
      //handling notification click
      if (typeof payload == 'string') payload = JSON.parse(payload);
      //switch case
      switch (payload?.type) {
        case 'TASK':
          return dispatch(
            setGlobal({taskId: payload?.context_id, showTask: true}),
          );
        case 'PROJECT':
          return navigate('/Management/project_details/' + payload?.context_id);
        case 'MEETING':
          return navigate('/Management/meeting/' + payload?.context_id);
        case 'RECEIVED_APPROVAL':
          return navigate(
            '/Management/received_approval/' + payload?.context_id,
          );
        case 'SENT_APPROVAL':
          return navigate('/Management/sent_approval/' + payload?.context_id);
        default:
          break;
      }
    };
    return (
      <ul
        style={{
          cursor: 'pointer',
          margin: '5px 5px',
          borderRadius: '4px',
          background: props?.isRead ? 'white' : '#f5f7ff',
          ...(props?.isRead ? {border: '0.5px solid #c8c8c8'} : {}),
        }}
        onClick={() => handleOnNotificationClick(props)}
        className="notification_outer">
        <li>
          <div className="notification_img_div">
            <NotificationImg
              className="notification_img"
              backgroundImage={props?.User?.avatar}
            />
          </div>
        </li>
        <li className="notification_outer_text">
          <div>
            <p>
              {props?.title}
              <b> {props?.body}</b>.
            </p>
            <small>{moment(props?.createdAt).calendar()}</small>
          </div>
        </li>
      </ul>
    );
  };
  return (
    <div className="notification_outer_div">
      <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        <Tooltip title="Notifications">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ml: 2}}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <Box sx={{color: 'action.active'}}>
              {notifications.length ? (
                <Badge
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: '#FF0000',
                    },
                  }}
                  variant="dot">
                  <NotificationsIcon />
                </Badge>
              ) : (
                <NotificationsIcon />
              )}
            </Box>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 15,
              height: 15,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
        <div className="notification_outer_title">Notifications</div>
        {notifications.length ? (
          <div className="notifications_popup">
            <div className="notifications_popup_inner">
              {notifications.map((val, i) => (
                <Notification idx={i} key={i} {...val} />
              ))}
            </div>
            <div className="notification_outer_link_page">
              <NavLink to="/Management/all_notifications">View all</NavLink>
            </div>
          </div>
        ) : (
          <NoNotifications />
        )}
      </Menu>
    </div>
  );
};
