import { MyAttendence } from "../components/Pages/attendence/MyAttendence";
const Attendence = () => {
  return (
    <>
      <div className="custom_container">
        <MyAttendence />
      </div>
    </>
  );
};

export default Attendence;
