import { Index } from "../components/Pages/OrgnizationChart/Index";
const OrganizationChart = () => {
  return (
    <>
      <div className="custom_container">
        <Index />
      </div>
    </>
  );
};

export default OrganizationChart;
