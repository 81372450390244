import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import {useEffect, useRef, useState} from 'react';
import ClientsIcon from '../../../../../../pics/Management/Clientsicon.svg';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {Block} from 'notiflix/build/notiflix-block-aio';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {useNonInitialEffect} from './../../../../../../utils/hooks/non-initial-effect.tsx';
import Axios from './../../../../../../config/api';
import {useDispatch} from 'react-redux';
import TasksIcon from '../../../../../../pics/Management/TasksIcon.svg';
import IconMy from '../../../../../../pics/Management/search_result_icon.svg';
import {getProjectFileUrl} from '../../../../../../constants';
import {setGlobal} from '../../../../../../redux/slices/globalSlice';
import HistoryIcon from '@mui/icons-material/History';

const SearchBar = () => {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const SearchResultsRef = useRef(null);
  const navigate = useNavigate();
  useOutsideAlerter(wrapperRef);

  const [searchParams, setSearchParams] = useSearchParams();
  const [recentSearches, setRecentSearches] = useState([]);
  const [SearchResult, setSearchResult] = useState(false);
  const [query, setQuery] = useState('');
  const [searchData, setSearchData] = useState({
    projects: [],
    tasks: [],
    clients: [],
  });

  useEffect(() => {
    let recentSearchesData = localStorage.getItem('recentSearches');
    if (recentSearchesData) recentSearchesData = JSON.parse(recentSearchesData);
    if (typeof recentSearchesData == 'string')
      recentSearchesData = JSON.parse(recentSearchesData);
    setRecentSearches(recentSearchesData);
  }, [query]);

  useNonInitialEffect(() => {
    // Timer ID for the delayed fetch
    let timerId;
    // Clear previous timer, if any
    clearTimeout(timerId);
    // Set a new timer for the delayed fetch
    timerId = setTimeout(fetchSearchResults, 500);
    // Clean up timer on component unmount
    return () => clearTimeout(timerId);
  }, [query]);

  const fetchSearchResults = () => {
    if (!query.length)
      return setSearchData({
        projects: [],
        tasks: [],
        clients: [],
      });
    //showing up loader
    Block.circle('.nav_search_results', 'Searching...');
    //initializing network request
    Axios.fetch(`maxproject/search?query=${query}&limit=3`)
      .then(({data}) => {
        Block.remove('.nav_search_results');
        //updating search data
        setSearchData({
          projects: data?.Projects,
          tasks: data?.Tasks,
          clients: data?.Clients,
        });
      })
      .catch(err => {
        //removing loader
        Block.remove('.nav_search_results');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
    searchParams.toString();
  };

  const handleSearch = e => {
    e.preventDefault();
    const query = e.target.elements.query.value;
    navigate(`/Management/search_result?query=${query}`);
  };
  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          SearchResultsRef.current &&
          !SearchResultsRef.current.contains(event.target)
        )
          setSearchResult(false);
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  const SearchResultData = () => {
    return (
      <div
        ref={SearchResultsRef}
        className="search_result_data_div nav_search_results">
        <div className="row g-2">
          {query.length ? (
            <div className="col-12">
              <div
                onClick={() => {
                  let recentSearchesData =
                    localStorage.getItem('recentSearches');
                  //checking if data not available
                  if (!recentSearchesData)
                    localStorage.setItem(
                      'recentSearches',
                      JSON.stringify([query]),
                    );
                  else {
                    recentSearchesData = JSON.parse(recentSearchesData);
                    if (typeof recentSearchesData == 'string')
                      recentSearchesData = JSON.parse(recentSearchesData);
                    if (!recentSearchesData.includes)
                      recentSearchesData.length >= 5
                        ? recentSearchesData.unshift(query)
                        : recentSearchesData.push(query);
                    localStorage.setItem(
                      'recentSearches',
                      JSON.stringify(recentSearchesData),
                    );
                  }
                  setSearchResult(false);
                  navigate(`/Management/search_result?query=${query}`);
                }}
                className="search_result_data_inner_top_div">
                <div className="search_result_data_inner_div">
                  <img src={IconMy} />
                  <p>Search "{query}"</p>
                </div>
              </div>
            </div>
          ) : (
            recentSearches?.map((item, i) => (
              <div className="col-12">
                <div
                  onClick={() => {
                    setQuery(item);
                    setSearchResult(false);
                    navigate(`/Management/search_result?query=${item}`);
                  }}
                  className="search_result_data_inner_top_div">
                  <div className="search_result_data_inner_div">
                    <HistoryIcon
                      style={{color: 'black', marginRight: 10, fontSize: 18}}
                    />
                    <p>{item}</p>
                  </div>
                </div>
              </div>
            ))
          )}
          {searchData?.projects.map((proj, i) => (
            <div
              style={i == 0 ? {borderTop: '0.5px solid #c1c1c1'} : {}}
              className="col-12"
              key={i}>
              <div
                onClick={() => {
                  setSearchResult(false);
                  navigate(`/Management/project_details/${proj.id}`);
                }}
                className="search_result_data_inner_top_div">
                <div className="search_result_data_inner_div">
                  <img src={getProjectFileUrl(proj?.project_image)} />
                  <p>{proj?.name}</p>
                </div>
              </div>
            </div>
          ))}
          {searchData?.tasks.map((task, i) => (
            <div
              style={i == 0 ? {borderTop: '0.5px solid #c1c1c1'} : {}}
              onClick={() => {
                setSearchResult(false);
                dispatch(setGlobal({taskId: task.id, showTask: true}));
              }}
              className="col-12"
              key={i}>
              <div className="search_result_data_inner_top_div">
                <div className="search_result_data_inner_div">
                  <img src={TasksIcon} />
                  <p>{task?.name}</p>
                </div>
              </div>
            </div>
          ))}
          {searchData?.clients.map((client, i) => (
            <div
              style={i == 0 ? {borderTop: '0.5px solid #c1c1c1'} : {}}
              className="col-12"
              key={i}>
              <div
                onClick={() => {
                  setSearchResult(false);
                  navigate(`/Management/clients/${client.id}`);
                }}
                className="search_result_data_inner_top_div">
                <div className="search_result_data_inner_div">
                  <img src={ClientsIcon} />
                  <p>{client?.client_name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  return (
    <div className="top_nav_man_input_div d-none d-xxl-block d-xl-block d-md-block">
      <div className="top_nav_iner_div_input">
        <span>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </span>
        <form onSubmit={handleSearch}>
          <input
            autoComplete="off"
            defaultValue={searchParams.get('query')}
            ref={wrapperRef}
            type="search"
            value={query}
            onChange={e => setQuery(e.target.value)}
            name="query"
            className="form-control"
            placeholder="What are you looking for today?"
            onFocus={() => setSearchResult(true)}
          />
        </form>
        {SearchResult ? <SearchResultData /> : null}
      </div>
    </div>
  );
};

export default SearchBar;
