import {OutlineBtnWithIcon} from '../../../../common/ManagementComponents/Btn/Btn';
import {FaExclamation, FaFilter} from 'react-icons/fa';
import {PageAllTopTitle} from '../../../../common/ManagementComponents/PageAllTopTitle';
import {
  FullBtn,
  OutlineBtn,
} from '../../../../common/ManagementComponents/Btn/Btn';
import {IoSearchOutline} from 'react-icons/io5';
import {useNavigate} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import {BiDotsHorizontalRounded} from 'react-icons/bi';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SnoozeIcon from '@mui/icons-material/Snooze';
import {BsFillCircleFill, BsFillInfoCircleFill} from 'react-icons/bs';
// mui
import * as React from 'react';
import {useState, useEffect} from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {NavLink} from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Axios from './../../../../../config/api';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {Block} from 'notiflix/build/notiflix-block-aio';
import moment from 'moment';
import {formatDuration} from '../../../../../utils/functions';
import {AiFillCaretDown} from 'react-icons/ai';
import {useNonInitialEffect} from '../../../../../utils/hooks/non-initial-effect.tsx';
import Img from '../../../../common/components/img/Img';
import NoResultsSVG from '../../../../../pics/empty_state_search_not_found.svg';
import {useSelector} from 'react-redux';
import {getUser} from '../../../../../redux/slices/userSlice';
import {PageRightFilters} from '../../../../common/ManagementComponents/PageRightFilters';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { MeetingUsersDropdown } from './JoinMeeting.jsx';
const getMeetingTime = ({date, start_time, isAllDay, timezone}) => {
  return Number(isAllDay)
    ? moment(date, 'YYYY-MM-DD').tz(timezone).calendar(moment(), {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'YYYY-MM-DD',
      })
    : // Combine date and time if it's not an all day event
      moment(`${date}T${start_time}`).tz(timezone).calendar();
};
// tabs
export const Meetings = () => {
  const user = useSelector(getUser);
  const [meetings, setMeetings] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    getMeetings();
    fetchStatuses();
    return () => {};
  }, []);

  useNonInitialEffect(() => {
    // Timer ID for the delayed fetch
    let timerId;
    // Clear previous timer, if any
    clearTimeout(timerId);
    // Set a new timer for the delayed fetch
    timerId = setTimeout(getMeetings, 500);
    // Clean up timer on component unmount
    return () => clearTimeout(timerId);
  }, [query]);

  const getMeetings = () => {
    Block.circle('.meetings_table');
    Axios.fetch(`maxproject/meeting?query=${query}`)
      .then(({data}) => {
        Block.remove('.meetings_table');
        setMeetings(data?.meetings);
      })
      .catch(err => {
        Block.remove('.meetings_table');
      });
  };
  const fetchStatuses = () => {
    Axios.fetch(`maxproject/random/statuses/meeting`)
      .then(({data}) => {
        setStatusFilters(data?.statuses);
      })
      .catch(err => {
        Block.remove('.meetings_table');
      });
  };
  const handleSearch = e => {
    setQuery(e.target.value);
  };
  const handlePagination = (e, page) => {
    setPage(page);
  };
  const MeetingsList = () => {
    const Meeting = props => {
      const [showDelete, setShowDelete] = React.useState(false);
      const [status, setStatus] = useState(props?.Status);
      // Delete Modal
      const handleOpen = () => setShowDelete(true);
      const handleClose = () => setShowDelete(false);
      // Delete Modal
      const handleDeleteMeeting = () => {
        Block.circle('.delete_modal');
        Axios.delete('/maxproject/meeting/' + props.id)
          .then(({data}) => {
            handleClose();
            setMeetings(old => {
              let arr = old.slice();
              arr.splice(props.index, 1);
              return arr;
            });
            Block.remove('.delete_modal');
            Notify.success(data.message);
          })
          .catch(err => {
            //removing loader
            Block.remove('.delete_modal');
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      };
      const DeleteModal = () => {
        const style = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        };
        return (
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showDelete}
            onClose={handleClose}
            closeAfterTransition>
            <Fade in={showDelete}>
              <Box sx={style}>
                <div className="delete_modal">
                  <ul>
                    <li>
                      <BsFillInfoCircleFill />
                    </li>
                    <li>
                      <h3>Delete "{props.title}"?</h3>
                      <p>
                        '{props.title}' will be deleted permanently and cannot
                        be recovered.
                      </p>
                    </li>
                  </ul>
                  <div className="delete_modal_btn_div">
                    <ul>
                      <li className="btn_one">
                        <OutlineBtn onClick={handleClose} name="Cancel" />
                      </li>
                      <li className="btn_two">
                        <FullBtn onClick={handleDeleteMeeting} name="Delete" />
                      </li>
                    </ul>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        );
      };
      const joinMeeting = () => {
        Block.circle('.meetings_table');
        Axios.fetch(`maxproject/meeting/join/${props.id}`)
          .then(({data}) => {
            Block.remove('.meetings_table');
            window.open(data.link, '_blank');
          })
          .catch(err => {
            Block.remove('.meetings_table');
          });
      };
      const ProjectListDropDown = () => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = event => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        const navigate = useNavigate();
        const options = [
          {
            name: 'Join Meeting',
            onClick: joinMeeting,
          },
          {
            link: '/Management/update_meeting/' + props.id,
            name: 'Modify Event',
            disabled: !(user.id == props.organiser_id || props.canModify),
          },
          {
            link: '/Management/meeting_notes/' + props.id,
            name: 'Notes',
            disabled: true,
          },
          {
            onClick: handleOpen,
            name: 'Delete Meeting',
            disabled: !(user.id == props.organiser_id || props.canModify),
          },
        ];
        const ITEM_HEIGHT = 30;
        return (
          <div className="chat_option">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              className=" m-0 p-0">
              <BiDotsHorizontalRounded />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '15ch',
                },
              }}>
              {options.map((option, i) => (
                <div
                  key={i}
                  onClick={() => {
                    if (option?.disabled) return false;
                    handleClose();
                    if (option.onClick) return option.onClick();
                    if (option.link) navigate(option.link);
                  }}>
                  <MenuItem disabled={option?.disabled}>
                    <div className="chat_option_name_div">{option.name}</div>
                  </MenuItem>
                </div>
              ))}
            </Menu>
          </div>
        );
      };
      const StatusDropdown = () => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = event => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        const handleStatusChange = status => {
          Block.circle('.meetings_table');
          Axios.put(`maxproject/meeting/${props.id}/status`, {
            status_id: status.id,
          })
            .then(({data}) => {
              Block.remove('.meetings_table');
              setStatus(status);
              setAnchorEl(null);
            })
            .catch(err => {
              Block.remove('.meetings_table');
            });
        };
        return (
          <React.Fragment>
            <Box>
              <Tooltip arrow>
                <p
                  onClick={handleClick}
                  className="Priority_dropdown"
                  style={{backgroundColor: status?.bg_color || 'white'}}>
                  <span style={{color: status?.text_color || 'black'}}>
                    {status?.name || 'Status'}
                    <AiFillCaretDown />
                  </span>
                </p>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
              <Divider />
              {statusFilters?.map(s => (
                <MenuItem onClick={() => handleStatusChange(s)}>
                  <p className="Priority_dropdown_item">
                    <BsFillCircleFill style={{color: s.bg_color}} />
                    {s.name}
                  </p>
                </MenuItem>
              ))}
            </Menu>
          </React.Fragment>
        );
      };
      return (
        <tr className="progress_all_tabel_tr_mor">
          <DeleteModal />
          <td>
            <div className="Participants_main_div_tr">
              <NavLink
                to={'/Management/meeting/' + props.id}
                className="link_a">
                {props.title.length > 30
                  ? props.title.slice(0, 30) + '...'
                  : props.title}
              </NavLink>
            </div>
          </td>
          <td>
            <div className="Participants_main_div_tr">
              {props?.noteCount || '-'}
            </div>
          </td>
          <td>
            <StatusDropdown />
          </td>
          <td>
            <div className="Participants_main_div_tr">
              {props?.Project?.name
                ? props?.Project?.name.length > 20
                  ? props?.Project?.name.slice(0, 20) + '...'
                  : props?.Project?.name
                : 'N/A'}
            </div>
          </td>
          <td className="Participants_main_div">
            <div className="Participants_main_div_inner">
              <MeetingUsersDropdown data={props?.MeetingParticipants} />
            </div>
          </td>
          <td>
            <div className="Participants_main_div_tr">{props?.User?.name}</div>
          </td>
          <td>
            <div className="Participants_main_div_tr">
              {getMeetingTime(props)}
            </div>
          </td>
          <td>
            <div className="Participants_main_div_tr">
              {props?.MeetingTimer ? (
                <div>
                  {props?.MeetingTimer?.status ? (
                    <AccessTimeIcon fontSize="small" style={{color: 'black'}} />
                  ) : (
                    <SnoozeIcon
                      color="black"
                      fontSize="small"
                      style={{color: 'black'}}
                    />
                  )}
                  <span
                    style={{
                      color: props?.MeetingTimer?.status ? 'blue' : 'green',
                      marginLeft: 6,
                    }}>
                    {calDuration(props?.MeetingTimer)}
                  </span>
                </div>
              ) : (
                'N/A'
              )}
            </div>
          </td>
          <td>
            <div className="mt-1">
              <div className="progress_all_option_icon">
                <ProjectListDropDown />
              </div>
            </div>
          </td>
        </tr>
      );
    };
    const MeetingsTable = () => {
      const NoDataView = () => {
        return (
          <div className="row">
            <div className="col-12">
              <div
                style={{
                  minHeight: '30rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '2rem',
                }}>
                <Img
                  backgroundImage={NoResultsSVG}
                  style={{
                    height: 250,
                    width: 250,
                  }}
                  className="project_summary_card_icon"
                />
                <span className="mb-3 w-50">
                  Sorry, We couldn't find any details that match your filter
                  criteria. Please consider clearing your filter or refining
                  your criteria for better results.
                </span>
              </div>
            </div>
          </div>
        );
      };
      if (!meetings.length) return <NoDataView />;
      return (
        <>
          <table className="table table-hover text-capitalize progress_all_tabel_tabel">
            <thead>
              <tr className="progress_all_tabel_tr">
                <th scope="col">Meeting Title</th>
                <th scope="col">Notes</th>

                <th scope="col" style={{paddingLeft: '27px'}}>
                  Status
                </th>
                <th scope="col">Project Name</th>
                <th scope="col">Participants</th>
                <th scope="col">Organiser</th>
                <th scope="col">Scheduled date</th>
                <th scope="col">Duration</th>
                <th scope="col">action</th>
              </tr>
            </thead>
            <tbody>
              {meetings?.slice((page - 1) * 10, 10 * page)?.map((val, i) => (
                <Meeting key={i} index={i} {...val} arr={val} />
              ))}
            </tbody>
          </table>
          {meetings?.length > 10 ? (
            <div className="projec_tabelt_list_pagination_div">
              <Stack spacing={2}>
                <Pagination
                  page={page}
                  onChange={handlePagination}
                  count={Math.ceil(meetings?.length / 10)}
                />
              </Stack>
            </div>
          ) : null}
        </>
      );
    };

    return (
      <div className="dashbaord_white_card">
        <MeetingsTable />
      </div>
    );
  };
  return (
    <div className="custom_container">
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 header_my">
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 order-md-1 order-2">
          <PageAllTopTitle title="meetings" />
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 order-md-2 order-1">
          <div className="header_input_div">
            <IoSearchOutline />
            <input
              type="search"
              value={query}
              onChange={handleSearch}
              className="form-control"
              placeholder="Search meeting title here"
            />
          </div>
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12  justify-content-end schedule_meeting_btn  order-md-3 order-3">
          <div className="blue_btn_top_div ">
            <FullBtn
              link="/Management/schedule_meeting"
              name="Schedule Meeting"
            />
          </div>
        </div>
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 filter_btn_top_div order-4">
          {/* <PageRightFilters /> */}
        </div>
      </div>
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
        <div className="col-12 meetings_table">
          <MeetingsList />
        </div>
      </div>
    </div>
  );
};
export const calDuration = timer => {
  if (!timer) return 0;
  //calculating duration
  let duration = timer.status
    ? moment(new Date()).diff(timer.updatedAt, 'seconds') + timer.duration
    : timer.duration;
  //returning formatted duration
  return formatDuration(duration, true);
};
