import React from 'react';
import {OutlineBtn} from '../../../../common/ManagementComponents/Btn/Btn';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Accordion from 'react-bootstrap/Accordion';
import {IoCloseSharp} from 'react-icons/io5';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
//
import {OutlineBtnWithIcon} from '../../../../common/ManagementComponents/Btn/Btn';
import {FaFilter} from 'react-icons/fa';
import {PageAllTopTitle} from '../../../../common/ManagementComponents/PageAllTopTitle';
import {FullBtn} from '../../../../common/ManagementComponents/Btn/Btn';
import {IoSearchOutline} from 'react-icons/io5';
import Select from 'react-select';
import {AiOutlineUnorderedList} from 'react-icons/ai';
import {VscListSelection} from 'react-icons/vsc';
import {useEffect, useState} from 'react';
import {BsListNested, BsListCheck} from 'react-icons/bs';
import {
  TasksListView,
  ClassicView,
  Kanban,
} from '../../../../common/ManagementComponents/tasks/View';
import {Block} from 'notiflix/build/notiflix-block-aio';
import Axios from './../../../../../config/api';
import {useNonInitialEffect} from '../../../../../utils/hooks/non-initial-effect.tsx';
import {useDispatch, useSelector} from 'react-redux';
import {getGlobal, setGlobal} from '../../../../../redux/slices/globalSlice.js';
// tabs
export const TasksList = ({project_id = null}) => {
  //hooks
  const dispatch = useDispatch();
  //states
  const {refreshTasks} = useSelector(getGlobal);
  const [taskType, setTaskType] = useState('my');
  const [tasks, setTasks] = useState([]);
  const [kanbanTasks, setKanbanTasks] = useState([]);
  const [deletedTasks, setDeletedTasks] = useState([]);
  const [classicTasks, setClassicTasks] = useState([]);
  const [taskStatuses, setTaskStatuses] = useState([]);
  const [taskStatusFilters, setTaskStatusFilters] = useState([]);
  const [taskPriorityFilters, setTaskPriorityFilters] = useState([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);
  const [viewType, setViewType] = useState('plain');
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [taskFilters, setTaskFilters] = useState({
    projects: [],
    statuses: [],
    priorities: [],
    users: [],
  });

  useEffect(() => {
    Block.circle('.task_table');
    fetchTaskFilters();
    fetchStatuses();
    return () => {
      dispatch(setGlobal({taskPage: null}));
    };
  }, []);
  useNonInitialEffect(() => {
    fetchTasks();
    return () => {};
  }, [taskType, selectedStatusFilter, refreshTasks]);

  useNonInitialEffect(() => {
    // Timer ID for the delayed fetch
    let timerId;
    // Clear previous timer, if any
    clearTimeout(timerId);
    // Set a new timer for the delayed fetch
    timerId = setTimeout(fetchTasks, 500);
    // Clean up timer on component unmount
    return () => clearTimeout(timerId);
  }, [searchQuery]);

  const handleSearch = e => {
    setSearchQuery(e.target.value);
  };
  const fetchTaskFilters = () => {
    Axios.fetch(`maxproject/random/status_filters/task`)
      .then(({data}) => {
        let filters = data?.filters?.map(filter => ({
          value: filter.id,
          label: <UserChangeLabel title={filter.name} />,
        }));
        setTaskStatuses(filters);
        setSelectedStatusFilter(0);
      })
      .catch(err => {});
    Axios.fetch(`maxproject/task/filters`)
      .then(({data}) => {
        setTaskFilters(old => ({
          ...old,
          users: data?.filters?.users,
          projects: data?.filters?.projects,
          statuses: data?.filters?.statuses,
          priorities: data?.filters?.priorities,
        }));
      })
      .catch(err => {});
  };
  const fetchStatuses = () => {
    Axios.fetch(`maxproject/random/statuses/task`)
      .then(({data}) => {
        setTaskPriorityFilters(data?.priority_statuses);
        setTaskStatusFilters(data?.statuses);
      })
      .catch(err => {
        Block.remove('.projects_table');
      });
  };
  const fetchTasks = (applyFilters = true) => {
    //if filters are applied
    setShowFilters(false);
    Block.circle('.task_table');
    let filters = {};
    if (applyFilters)
      Object.keys(taskFilters).forEach(key => {
        filters[key] = taskFilters[key]
          .filter(filter => filter?.isSelected)
          .map(filter => filter.id);
      });
    if (project_id) {
      filters['projects'] = [project_id];
      filters['isProjectTasks'] = 1;
    }
    const query = new URLSearchParams(filters);
    //getting filters tasks
    Axios.fetch(
      `maxproject/task?type=${taskType}&status_id=${
        selectedStatusFilter?.value || 0
      }&query=${searchQuery}&${query.toString()}`,
    )
      .then(({data}) => {
        setIsLoading(false);
        Block.remove('.task_table');
        setTasks(data?.tasks);
        setDeletedTasks(data?.deletedTasks);
        setClassicTasks(data?.classic_tasks);
        setKanbanTasks(data?.kanban_tasks);
      })
      .catch(err => {
        setIsLoading(false);
        Block.remove('.task_table');
      });
  };

  const UserChangeLabel = props => {
    return (
      <div className="view_change_label">
        {props.icon}
        <span>{props.title}</span>
      </div>
    );
  };
  const taskOptions = [
    {
      value: 'my',
      label: <UserChangeLabel title="My Tasks" icon={<BsListNested />} />,
    },
    {
      value: 'all',
      label: <UserChangeLabel title="All Tasks" icon={<BsListCheck />} />,
    },
  ];
  const ViewChangeLabel = props => {
    return (
      <div className="view_change_label">
        {props.icon} <span>{props.title}</span>
      </div>
    );
  };
  const ViewChange = [
    {
      value: 'plain',
      label: (
        <ViewChangeLabel title="Plain" icon={<AiOutlineUnorderedList />} />
      ),
    },
    {
      value: 'classic',
      label: <ViewChangeLabel title="Classic" icon={<VscListSelection />} />,
    },
    {
      value: 'kanban',
      label: <ViewChangeLabel title="Kanban" icon={<VscListSelection />} />,
    },
  ];
  const TasksTable = () => {
    return (
      <div className="project_list_tabs_main_div">
        {
          {
            classic: (
              <ClassicView
                tasks={classicTasks}
                setTasks={setClassicTasks}
                taskStatusFilters={taskStatusFilters}
                taskPriorityFilters={taskPriorityFilters}
              />
            ),
            plain: (
              <TasksListView
                isProjectTasks={Boolean(project_id)}
                tasks={tasks}
                deletedTasks={deletedTasks}
                setTasks={setTasks}
                taskStatusFilters={taskStatusFilters}
                taskPriorityFilters={taskPriorityFilters}
              />
            ),
            kanban: (
              <Kanban
                kanbanTasks={kanbanTasks}
                setKanbanTasks={setKanbanTasks}
                deletedTasks={deletedTasks}
                taskPriorityFilters={taskPriorityFilters}
              />
            ),
          }[viewType]
        }
      </div>
    );
  };
  const PageRightFilters = () => {
    const PriorityInner = props => (
      <div
        className="Priority_filter_inner"
        style={{backgroundColor: props.backgroundColor}}>
        <span style={{color: props.color}}>{props.title}</span>
      </div>
    );
    const RequestedAndRejected = props => (
      <div style={{color: props.color}}>{props.title}</div>
    );
    const RequestedBy = props => (
      <div className="Requested_by_div">
        <img src={props.UserPhoto} alt="icon" />
        {props.title}
      </div>
    );
    const onFilterChange = (filter, idx, checked) => {
      setTaskFilters(old => {
        let arr = old[filter].slice();
        arr[idx]['isSelected'] = checked;
        return {...old, [filter]: arr};
      });
    };
    const handleClearAllFilters = () => {
      setTaskFilters(old => {
        let filters = {};
        Object.keys(old).forEach(key => {
          filters[key] = taskFilters[key].map(filter => ({
            ...filter,
            isSelected: false,
          }));
        });
        return filters;
      });
      fetchTasks(false);
    };
    return (
      <>
        <OutlineBtnWithIcon
          onClick={() => setShowFilters(true)}
          name="All Filters"
          icon={<FaFilter />}
        />

        <Drawer anchor="right" open={showFilters}>
          <Box role="presentation" className="filter_new_top_div">
            <IoCloseSharp onClick={() => setShowFilters(false)} />
            <div className="row mt-3 mb-4">
              <div className="col-6">
                <p className="filter_title_1">All Filters</p>
              </div>
              <div className="col-6 text-end" onClick={handleClearAllFilters}>
                <p className="filter_title_2">Clear All</p>
              </div>
            </div>
            <div className="filter_new_top_div_inner">
              <div className="header_input_div">
                <IoSearchOutline />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Filter"
                />
              </div>
              <Accordion alwaysOpen>
                {taskFilters?.projects.length ? (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Project</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <FormGroup>
                          {taskFilters?.projects.map((project, i) => {
                            if (project_id && project.id !== project_id)
                              return null;
                            return (
                              <FormControlLabel
                                onChange={e =>
                                  onFilterChange(
                                    'projects',
                                    i,
                                    e.target.checked,
                                  )
                                }
                                checked={
                                  project?.isSelected ||
                                  project.id == project_id
                                }
                                disabled={project_id == project.id}
                                control={<Checkbox />}
                                label={project.name}
                              />
                            );
                          })}
                        </FormGroup>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : null}
                {taskFilters?.priorities.length ? (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Priority</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <FormGroup>
                          {taskFilters?.priorities.map((priority, i) => (
                            <FormControlLabel
                              key={priority.id}
                              control={<Checkbox />}
                              onChange={e =>
                                onFilterChange(
                                  'priorities',
                                  i,
                                  e.target.checked,
                                )
                              }
                              checked={priority?.isSelected}
                              label={
                                <PriorityInner
                                  color={priority.text_color}
                                  backgroundColor={priority.bg_color}
                                  title={priority.name}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : null}
                {taskFilters?.statuses.length ? (
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Status</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <FormGroup>
                          {taskFilters?.statuses.map((status, i) => (
                            <FormControlLabel
                              control={<Checkbox />}
                              onChange={e =>
                                onFilterChange('statuses', i, e.target.checked)
                              }
                              checked={status?.isSelected}
                              label={
                                <RequestedAndRejected
                                  title={status.name}
                                  color={status.text_color}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : null}
                {taskFilters?.users.length ? (
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Created By</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <FormGroup>
                          {taskFilters?.users.map((user, i) => (
                            <FormControlLabel
                              control={<Checkbox />}
                              onChange={e =>
                                onFilterChange('users', i, e.target.checked)
                              }
                              checked={user?.isSelected}
                              label={
                                <RequestedBy
                                  title={user.name}
                                  UserPhoto={user.avatar}
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : null}
              </Accordion>
            </div>
            <div className=" mt-4 d-flex justify-content-center align-items-center">
              <div className=" me-3" onClick={fetchTasks}>
                <FullBtn name="Apply" />
              </div>
              <div className="add_project_modal_btn_one">
                <OutlineBtn
                  onClick={() => setShowFilters(false)}
                  name="Close"
                />
              </div>
            </div>
          </Box>
        </Drawer>
      </>
    );
  };

  return (
    <div className="position-relative">
      <div className="row g-3 header_my">
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-1 col-sm-6 col-6 order-md-1 order-2">
          <PageAllTopTitle title="Tasks" />
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-8 col-sm-12 col-12 order-md-2 order-1">
          <div className="header_input_div">
            <IoSearchOutline />
            <input
              type="search"
              value={searchQuery}
              onChange={handleSearch}
              className="form-control"
              placeholder="Type task name here"
            />
          </div>
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-6 col-6 header_btn order-md-3 order-3">
          <div>
            <FullBtn link="/Management/create_task" name="Create New task" />
          </div>
        </div>
      </div>
      <div className="row g-3 mb-3">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 justify-content-start mb-0 set_view_type_btn">
          <div className="set_view_type_btn_inner">
            <div className="all_user_page all_user_page_new mb-0">
              <Select
                options={taskOptions}
                defaultValue={taskOptions[0]}
                isSearchable={false}
                onChange={({value}) => {
                  setTaskType(value);
                }}
              />
            </div>
          </div>
          <div className="new_btn_nav">
            <Select
              placeholder="Select Status"
              styles={{
                menuList: styles => ({
                  ...styles,
                  position: 'relative',
                  top: -10,
                  paddingTop: 10,
                }),
              }}
              options={taskStatuses}
              defaultValue={selectedStatusFilter}
              isSearchable={false}
              onChange={option => {
                setSelectedStatusFilter(option);
              }}
            />
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  set_view_type_btn">
          <div className="set_view_type_btn_inner">
            <Select
              options={ViewChange}
              defaultValue={ViewChange[0]}
              isSearchable={false}
              onChange={({value}) => {
                setViewType(value);
              }}
            />
          </div>
          <div className="new_btn_nav">
            <PageRightFilters />
          </div>
        </div>
      </div>
      <div style={{position: 'relative'}}>
        <div
          className="task_table"
          style={isLoading ? {height: '30rem'} : null}>
          {isLoading ? null : <TasksTable />}
        </div>
      </div>
    </div>
  );
};
