import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {BsArrowLeft, BsUpload} from 'react-icons/bs';
import {PageAllTopTitle} from '../../../../common/ManagementComponents/PageAllTopTitle';
import {
  Avatar,
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  Pagination,
  Radio,
  Stack,
  TextField,
  Tooltip,
  tooltipClasses,
  RadioGroup,
} from '@mui/material';
import {UserParticipants} from '../../../../common/ManagementComponents/Participants';
import {Modal, ButtonToolbar} from 'rsuite';
import DemoImg from '../../../../../pics/Management/tsc.png';
import DemoPdf from '../../../../../pics/Management/DemoPDF.pdf';
import {FaRegEye} from 'react-icons/fa';
import DemoPDFIcon from '../../../../../pics/Management/DemoPDF.png';
import {
  IoIosCloseCircleOutline,
  IoMdAttach,
  IoMdClose,
  IoMdCopy,
} from 'react-icons/io';
import {
  FullBtn,
  OutlineBtn,
} from '../../../../common/ManagementComponents/Btn/Btn';
import CommantIcon from '../../../../../pics/Management/commantIcon.svg';
import {
  MdFileDownload,
  MdOutlineApproval,
  MdOutlineMessage,
} from 'react-icons/md';
import {TbCalendarPlus} from 'react-icons/tb';
import {styled} from '@mui/material/styles';
import Accordionn from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Modall from 'react-bootstrap/Modal';
import {IoClose} from 'react-icons/io5';
import {Button} from 'react-bootstrap';
import {Block, Confirm, Notify} from 'notiflix';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {AiOutlineClose} from 'react-icons/ai';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Axios from './../../../../../config/api';
import {getUser} from '../../../../../redux/slices/userSlice';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import CommentsPdfIcon from '../../../../../pics/pdf.svg';
import CommentsPdfIcon2 from '../../../../../pics/word.svg';
import CommentsPdfIcon3 from '../../../../../pics/powerpoint.svg';
import CommentsPdfIcon4 from '../../../../../pics/excel.svg';
import CommentsPdfIcon5 from '../../../../../pics/file.png';
import RequestedIcon from '../../../../../pics/Management/RequestedIcon.svg';
import Approvedicon from '../../../../../pics/Management/Approvedicon.svg';
import Rejectedicon from '../../../../../pics/Management/Rejectedicon.svg';
import TaskIcon from '../../../../../pics/Management/add_task2.svg';
import {useForm, Controller} from 'react-hook-form';
import {setGlobal} from '../../../../../redux/slices/globalSlice';
import {NewEditor} from '../../../../common/NewComponents/NewEditor/NewEditor';

const BootstrapTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export const ReceivedApprovalList = () => {
  const user = useSelector(getUser);
  const params = useParams();
  const dispatch = useDispatch();
  //react-form-hook
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    getValues,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      action: null,
      reassignReason: null,
    },
  });
  //states
  const [reassignReason, setReassignReason] = useState('');
  const [showCommentError, setShowCommentError] = useState(false);
  const [approval, setApproval] = useState({});
  const [attachedFilesPreview, setAttachedFilesPreview] = useState([]);
  const [isActionTakenByUser, setIsActionTakenByUser] = useState(false);
  const [comment, setComment] = useState(null);
  const [Task, setTask] = useState({});
  const actionValue = watch('action');

  //? --> Hooks <--
  useEffect(() => {
    if (params?.approval_id) getApproavalData();
  }, [params?.approval_id]);

  //? --> Functions <--
  const getApproavalData = () => {
    //showing loader
    Block.circle('.approval_body', 'Loading...');
    const URL = `maxproject/approval/received_approval/${params?.approval_id}`;
    Axios.fetch(URL)
      .then(({data}) => {
        const ApprovalUser = data?.approval?.ApprovalUsers?.find(
          u => u?.User?.id == user.id,
        );
        setIsActionTakenByUser(ApprovalUser.status != 'PENDING');
        //getting task owner
        let TaskOwner = data?.approval?.Task?.TaskMembers.find(
          member => member.isOwner,
        );
        setTask(data?.approval?.Task || data?.approval?.ClientTask);
        setApproval({
          ...data?.approval,
          TaskOwner: TaskOwner?.User,
        });
        //removing loader
        Block.remove('.approval_body');
      })
      .catch(err => {
        //removing loader
        Block.remove('.approval_body');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleOpenTaskDetail = () => {
    dispatch(setGlobal({taskId: approval?.task_id, showTask: true}));
  };
  const handleApprove = payload => {
    //showing loader
    Block.circle('#response_section', 'Please wait...');
    //request to delete file from server
    Axios.post(`maxproject/approval/approve/${params?.approval_id}`, payload)
      .then(({data}) => {
        Notify.success(data.message);
        setIsActionTakenByUser(true);
        getApproavalData();
        setComment(null);
        setAttachedFilesPreview([]);
        //removing loader
        Block.remove('#response_section');
      })
      .catch(err => {
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleRejection = payload => {
    Confirm.show(
      `Reject?`,
      `This action is undonable!`,
      'Reject',
      'Cancel',
      () => {
        //showing loader
        Block.circle('#response_section', 'Please wait...');
        //request to delete file from server
        Axios.post(`maxproject/approval/reject/${params?.approval_id}`, payload)
          .then(({data}) => {
            Notify.success(data.message);
            setIsActionTakenByUser(true);
            getApproavalData();
            setComment(null);
            setAttachedFilesPreview([]);
            //removing loader
            Block.remove('#response_section');
          })
          .catch(err => {
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      },
    );
  };
  const handleFeedback = payload => {
    //showing loader
    Block.circle('#response_section', 'Please wait...');
    //initializing network request
    Axios.post(
      `maxproject/approval/feedback/${params?.approval_id}`,
      payload,
    ).then(({data}) => {
      Notify.success(data.message);
      getApproavalData();
      setComment(null);
      setAttachedFilesPreview([]);
      //removing loader
      Block.remove('#response_section');
    });
  };
  const handleResponseFormSubmit = data => {
    // Reset errors
    setShowCommentError(false);
    //if the selected action is 'feedback' and comment is empty
    if (data?.action === 'feedback' && !comment)
      return setShowCommentError(true);
    // Check if the selected reason is 'Other' and comment is empty
    if (
      data?.action === 'reject' &&
      data?.reassignReason?.value === 'Other' &&
      !comment
    )
      return setShowCommentError(true);
    //creating new formdata
    let formData = new FormData();
    //adding comment
    formData.append('comment', comment);
    //adding reason
    formData.append('reason', data?.reassignReason?.value);
    //adding attachments
    if (data?.attachments?.length)
      Array.from(data?.attachments).forEach(file =>
        formData.append('attachments[]', file),
      );
    //processing further based on action value
    if (data?.action === 'approve') handleApprove(formData);
    else if (data?.action === 'reject') handleRejection(formData);
    else if (data?.action === 'feedback') handleFeedback(formData);
  };
  const onAttachmentChange = e => {
    let files = e.target.files;
    // if (files) {
    //   let previews = [];
    //   Array.from(files).forEach(file =>
    //     previews.push({
    //       name: file.name,
    //       url:
    //         file.type == 'image/jpeg' || file.type == 'image/png'
    //           ? URL.createObjectURL(file)
    //           : PdfIcon,
    //     }),
    //   );
    setAttachedFilesPreview(files);
    // }
  };
  const handleRemoveNewDoc = fileToRemove => {
    const filesArray = Array.from(attachedFilesPreview);
    const updatedFilesArray = filesArray.filter(file => file !== fileToRemove);
    setAttachedFilesPreview(updatedFilesArray);
  };
  //? --> Components <--
  const Docs = ({data}) => {
    return (
      <div className="add_new_subtask_Uploaded_mai_div">
        {data?.map(doc => (
          <div className="item">
            <div className="add_new_subtask_Uploaded_img_div">
              <img
                src={doc.type == 'application/pdf' ? DemoPDFIcon : doc.url}
                alt="Uploaded photo"
              />
              <p>
                {doc.original_name.length > 12
                  ? doc.original_name?.substr(0, 12) + '...'
                  : doc.original_name}
              </p>

              <div className="add_new_subtask_Uploaded_img_div_overlay">
                <div className="modal_img_view_btn_div">
                  <a
                    className="modal_img_view_btn me-1"
                    href={doc.url}
                    download>
                    <MdFileDownload />
                  </a>
                  <ModalImgView
                    title={doc.original_name}
                    downloadItem={doc.url}
                    modalUi={
                      doc?.type === 'application/pdf' ? (
                        <object
                          data={doc.url}
                          style={{
                            width: '100%',
                            height: '35rem',
                          }}></object>
                      ) : (
                        <img
                          className="sub_task_modul_img_div"
                          src={doc.url}
                          alt="img"
                        />
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  const ApprovalUsersTimeline = () => (
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
      <div className="dashbaord_white_card">
        <p className="send_list_page_detai_approval_status_title">
          Approval status: <span>{approval?.status}</span>
        </p>
        <div className="received_list_modal_timeline_uper_div">
          <ul className=" approval_request_details_feedback_ul">
            <li>
              <img
                className="feedback_modal_ul_icon"
                src={approval?.RequestedUser?.avatar}
                alt={approval?.RequestedUser?.name}
              />
              <div className="feedback_modal_ul_main_div">
                <p className="feedback_modal_ul_name mb-1">
                  {approval?.RequestedUser?.name}
                  <span>({approval?.RequestedUser?.Designation?.name})</span>
                </p>
                <span
                  className="feedback_modal_ul_requested "
                  style={{
                    backgroundColor: '#e8f2fc',
                    borderColor: '#1a7fdd',
                  }}>
                  Requested By
                </span>

                <p className="feedback_modal_ul_time">
                  <TbCalendarPlus />
                  {moment(approval?.createdAt).calendar()}
                </p>
              </div>
            </li>
            {approval?.ApprovalUsers?.map(ApprovalUser => (
              <li>
                <img
                  className="feedback_modal_ul_icon"
                  src={ApprovalUser?.User?.avatar}
                  alt={ApprovalUser?.User?.name}
                />
                <div className="feedback_modal_ul_main_div">
                  <p className="feedback_modal_ul_name mb-1">
                    {ApprovalUser?.User?.name}
                    <span>({ApprovalUser?.User?.Designation?.name})</span>
                  </p>
                  <span
                    className="feedback_modal_ul_requested"
                    style={{
                      backgroundColor: getStatusBackgroundColor(
                        ApprovalUser?.status,
                      ),
                      borderColor: getStatusBorderColor(ApprovalUser?.status),
                    }}>
                    {ApprovalUser?.status === 'REASSIGNED'
                      ? 'Shift Control'
                      : ApprovalUser?.status}
                    {ApprovalUser?.status == 'REASSIGNED'
                      ? ` to (${ApprovalUser?.AssignedUser?.name})`
                      : null}
                  </span>
                  {ApprovalUser?.comment || ApprovalUser?.reason ? (
                    <>
                      <p className="feedback_modal_ul_commant mt-2">
                        <img src={CommantIcon} alt="icon" />
                        <span className="w-100">
                          {ApprovalUser?.comment
                            ? ReactHtmlParser(ApprovalUser?.comment)
                            : ApprovalUser?.reason}
                        </span>
                      </p>
                      <div style={{marginLeft: '20px'}}>
                        <Docs
                          data={ApprovalUser?.ApprovalUserAttachments || []}
                        />
                      </div>
                    </>
                  ) : null}
                  <p className="feedback_modal_ul_time">
                    <TbCalendarPlus />
                    {moment(ApprovalUser?.updatedAt).calendar()}
                  </p>
                </div>
                {ApprovalUser?.ApprovalFeedbacks?.map(Feedback => (
                  <div className="feedback_modal_ul_main_div">
                    <span
                      className="feedback_modal_ul_requested "
                      style={{
                        backgroundColor: '#e8f2fc',
                        borderColor: '#1a7fdd',
                      }}>
                      FEEDBACK
                    </span>
                    <p className="feedback_modal_ul_commant mt-2">
                      <img src={CommantIcon} alt="icon" />
                      <span className="w-100">
                        {Feedback?.comment
                          ? ReactHtmlParser(Feedback?.comment)
                          : Feedback?.reason}
                      </span>
                    </p>
                    <div style={{marginLeft: '20px'}}>
                      <Docs
                        data={Feedback?.ApprovalFeedbackAttachments || []}
                      />
                    </div>
                    <p className="feedback_modal_ul_time">
                      <TbCalendarPlus />
                      {moment(Feedback?.updatedAt).calendar()}
                    </p>
                  </div>
                ))}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
  const ModalImgView = props => {
    const [open, setOpen] = React.useState(false);
    const [size, setSize] = React.useState();
    const handleOpen = value => {
      setSize(value);
      setOpen(true);
    };
    const handleClose = () => setOpen(false);

    return (
      <>
        <ButtonToolbar>
          <span
            className="modal_img_view_btn p-0"
            size="lg"
            onClick={() => handleOpen('lg')}>
            <FaRegEye />
          </span>
        </ButtonToolbar>
        <Modal size={size} open={open} onClose={handleClose}>
          <Modal.Body
            className="sub_task_modul_body_div"
            style={{maxHeight: '35rem'}}>
            <p className="sub_task_modul_title_div">{props.title}</p>
            <hr />
            <IoMdClose
              className="sub_task_modul_top_close_btn_div"
              onClick={handleClose}
            />
            {/* ui */}
            {props.modalUi}
            {/* ui end */}
          </Modal.Body>
          <Modal.Footer>
            <div className="mt-3 d-flex align-items-center justify-content-end approval_modal_btn">
              <div className="me-2">
                <OutlineBtn onClick={handleClose} name="Cancel" />
              </div>
              <div className=" ms-2">
                <a
                  className="management_btn_full2 sub_task_modul_download_btn"
                  href={props.downloadItem}
                  download>
                  download
                </a>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  const NewSubTasks = () => {
    //? -->States<--
    const [page, setPage] = useState(1);
    const [subTasks, setSubTasks] = useState(approval?.ApprovalSubTasks || []);
    const [selectedFile, setSelectedFile] = useState(null);
    const [checkboxSwitch, setShowCheckboxSwitch] = useState(false);
    const [feedbackShow, setFeedbackShow] = useState(false);

    //? -->Functions<--
    const handlePagination = (e, page) => {
      setPage(page);
    };

    //? -->Components<--
    const ModalImgView = props => {
      const [open, setOpen] = React.useState(false);
      const [size, setSize] = React.useState();
      const handleOpen = value => {
        setSize(value);
        setOpen(true);
      };
      const handleClose = () => setOpen(false);

      return (
        <>
          <ButtonToolbar>
            <span
              className="modal_img_view_btn"
              size="lg"
              onClick={() => handleOpen('lg')}>
              <FaRegEye />
            </span>
          </ButtonToolbar>
          <Modal size={size} open={open} onClose={handleClose}>
            <Modal.Body
              className="sub_task_modul_body_div"
              style={{maxHeight: '35rem'}}>
              <p className="sub_task_modul_title_div">{props.title}</p>
              <hr />
              <IoMdClose
                className="sub_task_modul_top_close_btn_div"
                onClick={handleClose}
              />
              {/* ui */}
              {props.modalUi}
              {/* ui end */}
            </Modal.Body>
            <Modal.Footer>
              <div className="mt-3 d-flex align-items-center justify-content-end approval_modal_btn">
                <div className="me-2">
                  <OutlineBtn onClick={handleClose} name="Cancel" />
                </div>
                <div className=" ms-2">
                  <a
                    className="management_btn_full2 sub_task_modul_download_btn"
                    href={props.downloadItem}
                    download>
                    download
                  </a>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      );
    };
    const FeedbackModal = props => {
      return (
        <Modall
          show={feedbackShow}
          onHide={() => setFeedbackShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Body className="modal_body_new">
            <div>
              <p className="sub_task_modul_title_div">Feedback?</p>
              <hr />
              <IoMdClose className="sub_task_modul_top_close_btn_div" />
              {/* ui */}
              <div className="mt-3">
                <TextField
                  id="fullWidth"
                  label="Comments here"
                  placeholder="Write your comments here"
                  multiline
                  fullWidth
                />
              </div>
              <div className="add_new_subtask_Uploaded_mai_div">
                <div className="item">
                  <div className="add_new_subtask_Uploaded_img_div">
                    <img src={DemoImg} alt="Uploaded photo" />
                    <p>
                      {'Get a specific image by adding'.length > 15
                        ? 'Get a specific image by adding'?.substr(0, 15) +
                          '...'
                        : 'Get a specific image by adding'}
                    </p>

                    <span className="close_btn">
                      <IoClose />
                    </span>

                    <div className="add_new_subtask_Uploaded_img_div_overlay">
                      <div className="modal_img_view_btn_div">
                        <a
                          className="modal_img_view_btn me-1"
                          href={DemoImg}
                          download>
                          <MdFileDownload />
                        </a>
                        <ModalImgView
                          title="photo demo title"
                          downloadItem={DemoImg}
                          modalUi={
                            <>
                              <img
                                className="sub_task_modul_img_div"
                                src={DemoImg}
                                alt="img"
                              />
                            </>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="add_new_subtask_Uploaded_img_div">
                    <img
                      src="https://img.freepik.com/premium-vector/open-folder-icon-with-pdf-file-inside-folder-with-documents-blue-background-flat-design-vector-illustration_476325-459.jpg?w=1060"
                      alt="Uploaded photo"
                    />
                    <p>
                      {'pdf title'.length > 15
                        ? 'pdf title'?.substr(0, 15) + '...'
                        : 'pdf title'}
                    </p>

                    <span className="close_btn">
                      <IoClose />
                    </span>

                    <div className="add_new_subtask_Uploaded_img_div_overlay">
                      <div className="modal_img_view_btn_div">
                        <a
                          className="modal_img_view_btn me-1"
                          href={DemoImg}
                          download>
                          <MdFileDownload />
                        </a>
                        <ModalImgView
                          title="pdf demo title"
                          downloadItem={DemoImg}
                          modalUi={
                            <>
                              <object
                                data={DemoPdf}
                                style={{
                                  width: '100%',
                                  height: '35rem',
                                }}></object>
                            </>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {selectedFile && (
                  <div className="item">
                    <div className="add_new_subtask_Uploaded_img_div">
                      <img
                        src={URL.createObjectURL(selectedFile)}
                        alt="Uploaded photo"
                      />
                      <p>
                        {selectedFile.name.length > 10
                          ? selectedFile.name?.substr(0, 10) + '...'
                          : selectedFile.name}
                      </p>

                      <span className="close_btn">
                        <IoClose />
                      </span>

                      <div className="add_new_subtask_Uploaded_img_div_overlay">
                        <div className="modal_img_view_btn_div">
                          <a
                            className="modal_img_view_btn me-1"
                            href={URL.createObjectURL(selectedFile)}
                            download>
                            <MdFileDownload />
                          </a>
                          <ModalImgView
                            title={selectedFile.name}
                            downloadItem={URL.createObjectURL(selectedFile)}
                            modalUi={
                              <>
                                <img
                                  className="sub_task_modul_img_div"
                                  src={URL.createObjectURL(selectedFile)}
                                  alt="img"
                                />
                              </>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="add_new_subtask_top_ui_btn_div">
                <div className="task_detail_main_btn_div">
                  <input
                    type="file"
                    // onChange={handleFileChange}
                    id="subTaskFile2"
                    hidden
                  />
                  <BootstrapTooltip title="Attachment" placement="top">
                    <label htmlFor="subTaskFile2">
                      <IoMdAttach />
                    </label>
                  </BootstrapTooltip>
                </div>
                <Button
                  onClick={() => {
                    setFeedbackShow(false);
                  }}
                  className="btn1"
                  variant="outlined">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setFeedbackShow(false);
                  }}
                  className="btn2"
                  variant="contained">
                  Save
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modall>
      );
    };
    const SubTask = ({subTaskData, idx}) => {
      //? -->States<--
      const [subTask, setSubTask] = useState(subTaskData || {});
      const [addNewComment, setAddNewComment] = useState(false);
      const [comments, setComments] = useState(
        subTask?.ApprovalSubTaskComments || [],
      );
      //? -->Functions<--
      const handleApprove = () => {
        //showing up loader
        Block.circle('#sub_task_' + idx, 'Loading...');
        //initializing network request
        Axios.post(`maxproject/approval/approve_sub_task/${subTask?.id}`)
          .then(({data}) => {
            Block.remove('#sub_task_' + idx);
            //updating sub task
            setSubTask(old => ({...old, status: 'APPROVED'}));
            setComments(old => [...old, data?.comment]);
            Notify.success(data.message);
          })
          .catch(err => {
            //removing loader
            Block.remove('#sub_task_' + idx);
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      };
      const handleReject = () => {
        //showing up loader
        Block.circle('#sub_task_' + idx, 'Loading...');
        //initializing network request
        Axios.post(`maxproject/approval/reject_sub_task/${subTask?.id}`)
          .then(({data}) => {
            Block.remove('#sub_task_' + idx);
            //updating sub task
            setSubTask(old => ({...old, status: 'REJECTED'}));
            setComments(old => [...old, data?.comment]);
            Notify.success(data.message);
          })
          .catch(err => {
            //removing loader
            Block.remove('#sub_task_' + idx);
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      };
      //? -->Components<--
      const SubTaskComment = ({comment, idx}) => {
        return (
          <div key={idx} style={{marginLeft: '1rem'}}>
            <div className="sub_task_approval_request_details_commants_view_div">
              <img src={CommantIcon} alt="icon" />
              <p className="sub_task_approval_request_details_commants_view">
                {comment?.comment}
              </p>
            </div>
            <Docs data={comment?.ApprovalSubTaskCommentAttachments || []} />
          </div>
        );
      };
      const NewComment = () => {
        const [comment, setComment] = useState('');
        const [newDocs, setNewDocs] = useState([]);

        //? -->Functions<--

        const handleFileChange = e => {
          let files = e.target.files;
          setNewDocs(files);
        };
        const handleRemoveNewDoc = fileToRemove => {
          const filesArray = Array.from(newDocs);
          const updatedFilesArray = filesArray.filter(
            file => file !== fileToRemove,
          );
          setNewDocs(updatedFilesArray);
        };

        const handleAddSubTaskComment = e => {
          e.preventDefault();
          //getting values
          const {comment} = e.target;
          //showing up loader
          Block.circle('.new_sub_task_comment', 'Loading...');
          //preparing form data
          let formdata = new FormData();
          //adding form data
          formdata.append('comment', comment.value);
          //adding attachments
          if (newDocs)
            Array.from(newDocs).forEach(file =>
              formdata.append('attachments[]', file),
            );
          //initializing network request
          Axios.post(
            `maxproject/approval/sub_task_comment/${subTask?.id}`,
            formdata,
          )
            .then(({data}) => {
              Block.remove('.new_sub_task_comment');
              Notify.success(data.message);
              setComments(data?.comments);
              setAddNewComment(false);
            })
            .catch(err => {
              //removing loader
              Block.remove('.new_sub_task_comment');
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };

        //? -->Components<--

        const NewDocs = () => {
          return Array.from(newDocs).map((file, i) => (
            <div className="item item_new4">
              <div className="add_new_subtask_Uploaded_img_div">
                <img src={getFileIcon(file, true)} alt="Uploaded doc" />
                <p>
                  {file.name.length > 15
                    ? file.name?.substr(0, 15) + '...'
                    : file.name}
                </p>
                <span
                  className="close_btn"
                  onClick={() => handleRemoveNewDoc(file)}>
                  <IoClose />
                </span>
                <div className="add_new_subtask_Uploaded_img_div_overlay">
                  <div className="modal_img_view_btn_div">
                    <ModalImgView
                      title={file.name}
                      downloadItem={getFileIcon(file, true)}
                      modalUi={
                        <img
                          className="sub_task_modul_img_div"
                          src={getFileIcon(file, true)}
                          alt="img"
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ));
        };

        return (
          <div style={{marginLeft: '1rem'}} className="new_sub_task_comment">
            <form onSubmit={handleAddSubTaskComment}>
              <div className="mt-3">
                <TextField
                  id="fullWidth"
                  label="Comments here"
                  placeholder="Write your comments here"
                  name="comment"
                  required
                  multiline
                  fullWidth
                />
              </div>
              <div className="add_new_subtask_Uploaded_mai_div">
                <NewDocs />
              </div>
              <div className="add_new_subtask_top_ui_btn_div">
                <div className="task_detail_main_btn_div">
                  <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    id="subTaskFile2"
                    hidden
                  />
                  <BootstrapTooltip title="Attachment" placement="top">
                    <label htmlFor="subTaskFile2">
                      <IoMdAttach />
                    </label>
                  </BootstrapTooltip>
                </div>
                <Button
                  onClick={() => setAddNewComment(false)}
                  className="btn1"
                  variant="outlined">
                  Cancel
                </Button>
                <Button type="submit" className="btn2" variant="contained">
                  Save
                </Button>
              </div>
            </form>
          </div>
        );
      };
      const ModalViewFeedback = () => {
        const [open, setOpen] = React.useState(false);
        const [size, setSize] = React.useState();
        const handleOpen = value => {
          setSize(value);
          setOpen(true);
        };
        const handleClose = () => setOpen(false);

        return (
          <>
            <ButtonToolbar>
              <BootstrapTooltip title="View feedback" placement="top">
                <label
                  size="sm"
                  className="show_btn ms-0"
                  onClick={() => handleOpen('sm')}>
                  <MdOutlineMessage />
                </label>
              </BootstrapTooltip>
            </ButtonToolbar>
            <Modal size={size} open={open} onClose={handleClose}>
              <Modal.Body
                className="sub_task_modul_body_div"
                style={{maxHeight: '35rem'}}>
                <p className="sub_task_modul_title_div">
                  Final Status: {subTask?.status}
                </p>
                <hr />
                {comments?.map((Comment, i) => (
                  <>
                    <IoMdClose
                      className="sub_task_modul_top_close_btn_div"
                      onClick={handleClose}
                    />
                    {/* ui */}
                    <ul className="feedback_modal_ul">
                      <li>
                        <img
                          className="feedback_modal_ul_icon"
                          src={Comment?.User?.avatar}
                          alt={Comment?.User?.name}
                        />
                        <div className="feedback_modal_ul_main_div">
                          <p className="feedback_modal_ul_name mb-2">
                            {Comment?.User?.name}
                            <span>({Comment?.User?.Designation?.name})</span>
                          </p>
                          {Comment?.type == 'STATUS_CHANGE' ? (
                            <span
                              className="feedback_modal_ul_requested"
                              style={{
                                backgroundColor: getStatusBackgroundColor(
                                  Comment?.status,
                                ),
                                borderColor: getStatusBorderColor(
                                  Comment?.status,
                                ),
                              }}>
                              {Comment?.status}
                            </span>
                          ) : (
                            <SubTaskComment comment={Comment} idx={i} />
                          )}
                          <p className="feedback_modal_ul_time">
                            <TbCalendarPlus />
                            {moment(Comment?.createdAt).calendar()}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </>
                ))}

                {/* ui end */}
              </Modal.Body>
            </Modal>
          </>
        );
      };
      return (
        <div key={idx} className="sub_task_scroll_new" id={'sub_task_' + idx}>
          <div className="row m-0 ">
            {/* item view */}
            <div className="col-12 p-0">
              <div className="row m-0 task_detail_top_main_div">
                <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-8 col-sm-8 col-8 p-0 p-0">
                  <div className="task_detail_top_mani_div">
                    <div className="task_detail_chakbox">
                      {/* <FormControlLabel
                        control={<Checkbox onChange={setShowCheckboxSwitch} />}
                        className="my_checkbox"
                      /> */}
                      {/* <img
                        className="task_icon my_checkbox_icon"
                        src={SubTaskIcon}
                        alt="icon"
                      /> */}
                      <img
                        className="task_icon"
                        src={getSubTaskIcon(subTask?.status)}
                        alt="icon"
                      />
                    </div>
                    <div className="task_detail_top_inner_div">
                      <div className="row m-0">
                        <div className="col-6 p-0 task_detail_text_div_top_col">
                          <div className="task_detail_text_div ms-0">
                            <div>
                              <p>{subTask?.SubTask?.name}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 p-0 ">
                          <div className=" task_date_div_rel">
                            <div className="task_date_div_rel2 task_detail_main_btn_div">
                              {comments?.length ? <ModalViewFeedback /> : null}
                              {isActionTakenByUser ? null : (
                                <BootstrapTooltip
                                  title="Add comment"
                                  placement="top">
                                  <label
                                    className="show_btn"
                                    onClick={setAddNewComment}>
                                    <img src={CommantIcon} alt="icon" />
                                  </label>
                                </BootstrapTooltip>
                              )}
                              {subTask?.status == 'PENDING' &&
                              !isActionTakenByUser ? (
                                <>
                                  <BootstrapTooltip
                                    title="Reject"
                                    placement="top">
                                    <label
                                      className="show_btn ms-0"
                                      onClick={() => {
                                        Confirm.show(
                                          `Reject?`,
                                          `This action is undonable!`,
                                          'Cancel',
                                          'Reject',
                                          () => {},
                                          handleReject,
                                        );
                                      }}>
                                      <IoIosCloseCircleOutline />
                                    </label>
                                  </BootstrapTooltip>
                                  <BootstrapTooltip
                                    title="Approve"
                                    placement="top">
                                    <label
                                      className="show_btn ms-0"
                                      onClick={() => {
                                        Confirm.show(
                                          `Approve?`,
                                          `This action is undonable!`,
                                          'Cancel',
                                          'Approve',
                                          () => {},
                                          handleApprove,
                                        );
                                      }}>
                                      <MdOutlineApproval />
                                    </label>
                                  </BootstrapTooltip>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 p-0">
                          <Docs
                            data={subTask?.SubTask?.SubTaskAttachments || []}
                          />

                          {addNewComment ? <NewComment /> : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 task_date_div">
                  <p>
                    {subTask?.SubTask?.start_date
                      ? moment(subTask?.SubTask?.start_date).calendar()
                      : '-'}
                  </p>
                </div>
                <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2 task_date_div">
                  <p>
                    {subTask?.SubTask?.end_date
                      ? moment(subTask?.SubTask?.end_date).calendar()
                      : '-'}
                  </p>
                </div>
              </div>
            </div>
            {/* item view end */}
          </div>
        </div>
      );
    };
    return (
      <>
        <FeedbackModal />
        <div className="col-12">
          <div className="dashbaord_white_card">
            <Accordionn className="approval_modal_sub_title_Description_top_div2 mt-0">
              <div className="approval_modal_sub_title_Description_div">
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon className="pm_icon" />}
                  aria-controls="panel1-content"
                  id="panel1-header"></AccordionSummary>
                <div className="ms-1 approval_modal_sub_title_Description_new mt-0 mb-0">
                  Subtasks
                </div>
              </div>
              <AccordionDetails>
                <div className="row ">
                  <div className="col-12">
                    <div className="row mt-0 g-3">
                      <div className="col-xxl-8 col-xl-8 col-lg-8 col-lg-8 col-md-8 col-sm-12 col-12 d-flex align-items-center">
                        <div className="add_new_subtask_menu_div_top">
                          <span className="add_new_subtask_ui_title mt-0 me-3">
                            Subtasks
                          </span>
                        </div>
                      </div>
                      {checkboxSwitch ? (
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-lg-4 col-md-4 col-sm-12 approval_request_details_subtask_top_btn_div">
                          <label
                            style={{
                              backgroundColor: 'rgb(26 127 221 / 10%)',
                              border: '1px solid #1A7FDD',
                            }}
                            onClick={() => {
                              setFeedbackShow(true);
                            }}>
                            <MdOutlineMessage style={{color: '#1A7FDD'}} />
                            Feedback?
                          </label>
                          <label
                            style={{
                              backgroundColor: 'rgb(57 194 34 / 10%)',
                              border: '1px solid #39C222',
                            }}
                            onClick={() => {
                              Confirm.show(
                                `Approve?`,
                                `This action is undonable!`,
                                'Cancel',
                                'Approve',
                                () => {},
                                () => {},
                              );
                            }}>
                            <MdOutlineApproval style={{color: '#3BA317'}} />{' '}
                            Approve
                          </label>
                          <label
                            style={{
                              backgroundColor: '#rgb(255 62 72 / 10%)',
                              border: '1px solid #FF3E48',
                            }}
                            onClick={() => {
                              Confirm.show(
                                `Reject?`,
                                `This action is undonable!`,
                                'Cancel',
                                'Reject',
                                () => {},
                                () => {},
                              );
                            }}>
                            <IoIosCloseCircleOutline
                              style={{color: '#FF3E48'}}
                            />
                            Reject
                          </label>
                        </div>
                      ) : null}
                    </div>
                    {/*subtask data view ui */}
                    <div className="task_scrol_div1">
                      <div className="add_new_subtask_top_ui_div p-0 task_scrol_div2">
                        {/* item header */}
                        <div className="row">
                          <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-8 col-8">
                            <div className=" new_task_detail_top_title_one">
                              {/* <FormControlLabel
                        control={<Checkbox onChange={setShowCheckboxSwitch} />}
                      /> */}
                              <span style={{marginLeft: 50}} className="small">
                                Subtask Name
                              </span>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="new_task_detail_top_title_two">
                              <span className="small">Start date</span>
                            </div>
                          </div>
                          <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-2 col-sm-2 col-2">
                            <div className="new_task_detail_top_title_two task_date_div_rel_new">
                              <span className="small">End date</span>
                            </div>
                          </div>
                        </div>
                        {/* item header end */}
                        {subTasks
                          ?.slice((page - 1) * 5, 5 * page)
                          ?.map((subTask, i) => (
                            <SubTask subTaskData={subTask} idx={i} />
                          ))}
                      </div>
                      {subTasks?.length > 5 ? (
                        <Stack spacing={2} className=" align-items-end mt-2">
                          <Pagination
                            onChange={handlePagination}
                            count={Math.ceil(subTasks?.length / 5)}
                          />
                        </Stack>
                      ) : null}
                    </div>
                    {/*subtask data view ui end */}
                  </div>
                </div>
              </AccordionDetails>
            </Accordionn>
          </div>
        </div>
      </>
    );
  };
  const ReassignReasonLabel = props => {
    return (
      <div className="view_change_label">
        {props?.icon}
        <span>{props?.title}</span>
      </div>
    );
  };
  const ReassignModalNew = props => {
    //? --> States <--
    const [size, setSize] = useState();
    const [users, setUsers] = useState([]);
    const [reAssignUser, setReAssignUser] = useState(null);
    const [reassignModalShow, setReassignModalShow] = useState(false);

    //? -->Hooks<--

    useEffect(() => {
      if (reassignModalShow) fetchTeamMembers();
    }, [reassignModalShow]);

    //? --> Functions <--
    const fetchTeamMembers = () => {
      const URL = `maxproject/approval/re_assign_members/${params?.approval_id}`;
      Axios.fetch(URL)
        .then(({data}) => {
          let reassignUsers = data.members
            .filter(u => u.id != user.id)
            .map(u => ({
              label: u.name,
              value: u.id,
            }));
          setUsers(reassignUsers);
        })
        .catch(err => {
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };
    const handleReassign = () => {
      //request to delete file from server
      Axios.post(`maxproject/approval/reassign/${params?.approval_id}`, {
        assignedUserId: reAssignUser.value,
        reason: reassignReason,
        comment,
      })
        .then(({data}) => {
          Notify.success(data.message);
          setReassignModalShow(false);
          setIsActionTakenByUser(true);
          getApproavalData();
        })
        .catch(err => {
          setReassignModalShow(true);
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };
    const handleReassignValidation = () => {
      // Reset errors
      setShowCommentError(false);
      // Check if the selected reason is 'Other' and comment is empty
      if (reassignReason === 'Other' && !comment) {
        setShowCommentError(true);
        return;
      }

      handleOpen('sm');
    };
    const handleOpen = value => {
      setSize(value);
      setReassignModalShow(true);
    };
    const handleClose = () => setReassignModalShow(false);

    return (
      <>
        <ButtonToolbar>
          <div className="">
            <OutlineBtn
              size="sm"
              onClick={handleReassignValidation}
              name="Shift control to"
            />
          </div>
        </ButtonToolbar>
        <Modal size={size} open={reassignModalShow} onClose={handleClose}>
          <Modal.Body
            className="sub_task_modul_body_div"
            style={{maxHeight: '35rem'}}>
            <div className="delete_modal">
              <div className="row g-3">
                <div className="col-12">
                  <div className="approval_request_title">
                    <h3>Shift Control</h3>
                    <AiOutlineClose onClick={handleClose} />
                    <hr />
                  </div>
                </div>
                <div className="col-12">
                  <div className="all_user_page all_user_page_new mb-0 w-100">
                    <label className="reassign_my_label_new">
                      Transfer approval to
                    </label>
                    <Select
                      options={users}
                      isSearchable={true}
                      placeholder="Select a user"
                      onChange={item => setReAssignUser(item)}
                    />
                  </div>
                </div>
              </div>
              <div className="row reassign_btn_div">
                <div className="col-12 reassign_btn2_div">
                  <Button
                    onClick={handleReassign}
                    variant="contained"
                    // disabled={!reAssignUser}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };
  const ReassignReasonData = [
    {
      value: 'Lack the expected quality',
      label: 'Lack the expected quality',
    },
    {
      value: 'Cross-check for errors',
      label: 'Cross-check for errors',
    },
    {
      value: 'Client Dissatisfaction',
      label: 'Client Dissatisfaction',
    },
    {
      value: 'Other',
      label: <ReassignReasonLabel title="Other" />,
    },
  ];
  return (
    <div className="custom_container approval_body">
      <PageAllTopTitle link={-1} icon={<BsArrowLeft />} title="Approvals" />
      <div className="row g-xxl-3 g-xl-3 g-lg-3 g-md-3 g-sm-3 g-3">
        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
          <div className="dashbaord_white_card send_list_page_detail_div">
            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
              <div className="col-12">
                <h3 className="my_title">{approval?.title}
                </h3>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="sub_title_div">
                  <p>Date</p>
                  <div>{moment(approval?.createdAt).calendar()}</div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="sub_title_div">
                  <p>Approval requested by</p>
                  <div>{approval?.RequestedUser?.name}</div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="sub_title_div">
                  <p>Project</p>
                  <div>{approval?.Project?.name || 'N/A'}</div>
                </div>
              </div>
              <div className="col-12">
                <div className="sub_title_div" onClick={handleOpenTaskDetail}>
                  <p>Task</p>
                  <div className="link-primary" style={{cursor: 'pointer'}}>
                    {Task?.name}
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="sub_title_div">
                  <p>Task start date</p>
                  <div>{moment(Task?.start_date).calendar()}</div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="sub_title_div">
                  <p>Task end date</p>
                  <div>{moment(Task?.end_date).calendar()}</div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="sub_title_div">
                  <p>Priority</p>
                  <span
                    style={{
                      border: approval?.Priority?.bg_color,
                      backgroundColor: approval?.Priority?.bg_color,
                    }}>
                    {approval?.Priority?.name}
                  </span>
                </div>
              </div>
              {/* {approval?.ImpactData.map((val, i) => {
                  return (
                    <div
                      key={i}
                      className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="sub_title_div">
                        <p>Impact</p>
                        <span
                          style={{
                            border: approval?.borderColor,
                            backgroundColor: approval?.backgroundColor,
                          }}>
                          {approval?.PriorityTitle}
                        </span>
                      </div>
                    </div>
                  );
                })}
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="sub_title_div">
                    <p>Impact category</p>
                    <div>{approval?.ImpactCategory}</div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="sub_title_div">
                    <p>Impact sub category</p>
                    <div>
                      {approval?.ImpactSubCategory.length > 15
                        ? `${approval?.ImpactSubCategory.substring(0, 15)}...`
                        : approval?.ImpactSubCategory}
                      <BootstrapTooltip
                        title={approval?.ImpactSubCategory}
                        placement="top">
                        <label>
                          <FaInfoCircle />
                        </label>
                      </BootstrapTooltip>
                    </div>
                  </div>
                </div> */}
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="sub_title_div">
                  <p>Task created by</p>
                  <Avatar
                    alt={Task?.User?.name}
                    src={Task?.User?.avatar}
                    sx={{width: 24, height: 24}}
                  />
                </div>
              </div>
              {approval?.TaskOwner ? (
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="sub_title_div">
                    <p>Task owner</p>
                    <Avatar
                      alt={approval?.TaskOwner?.name}
                      src={approval?.TaskOwner?.avatar}
                      sx={{width: 24, height: 24}}
                    />
                  </div>
                </div>
              ) : null}
              {approval?.Task ? (
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="sub_title_div">
                    <p>Task assign to</p>
                    <div className="Participants_main_div_inner">
                      <UserParticipants data={Task?.TaskMembers || []} />
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="col-12">
                <div className="sub_title_div">
                  <p>Description</p>
                  <div>{ReactHtmlParser(approval?.desc)}</div>
                </div>
              </div>
              <div className="col-12">
                <div className="sub_title_div">
                  <p>Attachments</p>
                  <Docs data={approval?.ApprovalAttachments || []} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ApprovalUsersTimeline />

        {approval?.ApprovalSubTasks?.length ? <NewSubTasks /> : null}

        {isActionTakenByUser ||
        (approval?.assignedOrder &&
          approval?.currentApprover !== user.id) ? null : (
          <div className="col-12">
            <div className="dashbaord_white_card" id="response_section">
              <div className="row g-xxl-3 g-xl-3 g-lg-3 g-md-3 g-sm-3 g-3">
                <div className="col-12">
                  <div className="add_new_subtask_menu_div_top">
                    <span className="add_new_subtask_ui_title mt-0 me-3">
                      Response
                    </span>
                  </div>
                </div>
                <form onSubmit={handleSubmit(handleResponseFormSubmit)}>
                  <div className="col-12">
                    <FormControl component="fieldset">
                      <Controller
                        name="action"
                        control={control}
                        rules={{required: 'This field is required'}} // Add your validation rules here
                        render={({field}) => (
                          <RadioGroup
                            {...field}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label">
                            <FormControlLabel
                              value="feedback"
                              control={<Radio />}
                              label="Feedback"
                            />
                            <FormControlLabel
                              value="approve"
                              control={<Radio />}
                              label="Approve"
                            />
                            <FormControlLabel
                              value="reject"
                              control={<Radio />}
                              label="Reject"
                            />
                          </RadioGroup>
                        )}
                      />
                      {errors.action && (
                        <span style={{color: 'red'}}>
                          {errors.action.message}
                        </span>
                      )}
                    </FormControl>
                  </div>
                  {actionValue === 'reject' ? (
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                      <div className="all_user_page all_user_page_new my-4 w-100 dashboard_top_week_Select">
                        <Controller
                          name="reassignReason"
                          control={control}
                          rules={{
                            required:
                              actionValue === 'reject'
                                ? 'Please select a reason'
                                : false,
                          }}
                          render={({field}) => (
                            <Select
                              {...field}
                              options={ReassignReasonData}
                              isSearchable={false}
                              placeholder="Select a reason"
                              onChange={val => field.onChange(val)}
                            />
                          )}
                        />
                        {errors.reassignReason && (
                          <small className="error_message">
                            {errors.reassignReason.message}
                          </small>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className="col-12">
                    <NewEditor
                      value={comment}
                      onChange={e => setComment(e)}
                      placeholder="Desicription"
                    />
                    <div className="Hire_editer">
                      {showCommentError && (
                        <small className="error_message">
                          Please enter a comment
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row pt-4">
                      <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-sm-9 col-9">
                        <div className="brief_summery_div ">
                          <h3>Attachments:</h3>
                        </div>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-3 col-3">
                        <div
                          className="documents_app_btn"
                          style={{display: 'flex', justifyContent: 'flex-end'}}>
                          <input
                            type="file"
                            style={{display: 'none'}}
                            multiple={true}
                            id="attachemnt_input"
                            hidden
                            {...register('attachments', {
                              required: false,
                              onChange: onAttachmentChange,
                            })}
                          />
                          <label
                            className="btn btn-light btn-sm"
                            htmlFor="attachemnt_input">
                            <span>Add attachments</span>
                            <BsUpload />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="doc_img_main_div">
                      <div className="row g-3 mt-2">
                        {Array.from(attachedFilesPreview).map((file, i) => {
                          return (
                            <div className="col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-sm-4 col-6">
                              <div className="add_new_subtask_Uploaded_img_div">
                                <img
                                  src={getFileIcon(file, true)}
                                  alt="Uploaded doc"
                                />
                                <p>
                                  {file.name.length > 10
                                    ? file.name?.substr(0, 10) + '...'
                                    : file.name}
                                </p>
                                <span
                                  className="close_btn"
                                  onClick={() => handleRemoveNewDoc(file)}>
                                  <IoClose />
                                </span>
                                <div className="add_new_subtask_Uploaded_img_div_overlay">
                                  <div className="modal_img_view_btn_div">
                                    <ModalImgView
                                      title={file.name}
                                      downloadItem={getFileIcon(file, true)}
                                      modalUi={
                                        <img
                                          className="sub_task_modul_img_div"
                                          src={getFileIcon(file, true)}
                                          alt="img"
                                        />
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div
                      className="approval_request_btn2 mt-4"
                      style={{justifyContent: 'space-between'}}>
                      <ReassignModalNew />
                      <FullBtn type="submit" name="Submit Response" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export const getFileIcon = (file, crateImageObject = false) => {
  switch (file.type) {
    case 'image/jpeg':
      return crateImageObject ? URL.createObjectURL(file) : file.url;
    case 'image/png':
      return crateImageObject ? URL.createObjectURL(file) : file.url;
    case 'application/pdf':
      return CommentsPdfIcon;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return CommentsPdfIcon2;
    case 'application/ppx':
      return CommentsPdfIcon3;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return CommentsPdfIcon4;
    case 'application/zip':
      return CommentsPdfIcon5;
    default:
      return CommentsPdfIcon5;
  }
};
const getStatusBackgroundColor = status => {
  switch (status) {
    case 'APPROVED':
      return '#dfe9dc';
    case 'REJECTED':
      return '#ff000073';
    case 'REASSIGNED':
      return '#e8f2fc';
    default:
      return '#e8f2fc';
  }
};
const getStatusBorderColor = status => {
  switch (status) {
    case 'APPROVED':
      return '#3ca318';
    case 'REJECTED':
      return '#ff000073';
    case 'REASSIGNED':
      return '#1a7fdd';
    default:
      return '#1a7fdd';
  }
};
const getSubTaskIcon = status => {
  switch (status) {
    case 'PENDING':
      return RequestedIcon;
    case 'APPROVED':
      return Approvedicon;
    case 'REJECTED':
      return Rejectedicon;
    default:
      return TaskIcon;
  }
};
