import { ReportsIndex } from "../components/Pages/Reports/ReportsIndex";
const Reports = () => {
  return (
    <>
      <div className="custom_container">
        <ReportsIndex />
      </div>
    </>
  );
};

export default Reports;
