import styles from './TopNavbar.module.scss';
import {useContext} from 'react';
import NavContext from '../../Context/NavContext';
import {MdOutlineMenu} from 'react-icons/md';
import MyProfile from './Submenus/MyProfile/MyProfile';
import {NotificationOuter} from '../../../common/components/NotificationOuter';
import SearchBar from './Submenus/SearchBar/SearchBar';
import {DarkModeMenu} from './DarkModeMenu';
import {useSelector} from 'react-redux';
const RightNavbar = props => {
  const {nav, setNav} = useContext(NavContext);
  const storData = useSelector(state => state);
  return (
    <div className={styles.container} data-theme={storData.global.isMode}>
      {/* BURGER */}

      <div
        className={styles.burger_container}
        onClick={() => {
          setNav(!nav);
        }}>
        <MdOutlineMenu />
      </div>
      {props.logo}
      <SearchBar />
      {/* ACTIONS */}
      <DarkModeMenu />
      <div className={styles.actions}>
        <NotificationOuter />
      </div>

      {/* My Profile */}
      <MyProfile />
    </div>
  );
};

export default RightNavbar;
