import Select from 'react-select';
import {OutlineBtn, FullBtn, OutlineBtnWithIcon} from './Btn/Btn';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Accordion from 'react-bootstrap/Accordion';
import {IoCloseSharp} from 'react-icons/io5';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {FaFilter} from 'react-icons/fa';
import {IoSearchOutline} from 'react-icons/io5';
import * as React from 'react';
import useClickOutside from '../../Management/CustomHooks/ClickOutside';
//
export const PageRightFilters = () => {
  const [showFilters, setShowFilters] = React.useState(false);
  let UserIcon = 'https://source.unsplash.com/random/500x500/?user,face';
  const PriorityInner = props => {
    return (
      <div
        className="Priority_filter_inner"
        style={{backgroundColor: props.backgroundColor}}>
        {props.title}
      </div>
    );
  };
  const RequestedAndRejected = props => {
    return <div style={{color: props.color}}>{props.title}</div>;
  };
  const RequestedBy = props => {
    return (
      <div className="Requested_by_div">
        <img src={props.UserPhoto} alt="icon" />
        {props.title}
      </div>
    );
  };
  let domNode = useClickOutside(() => {
    setShowFilters(false);
  });
  return (
    <>
      <OutlineBtnWithIcon
        onClick={() => setShowFilters(true)}
        name="All Filters"
        icon={<FaFilter />}
      />

      <Drawer anchor="right" open={showFilters}>
        <Box ref={domNode} role="presentation" className="filter_new_top_div">
          <IoCloseSharp onClick={() => setShowFilters(false)} />
          <div className="row mt-3 mb-4">
            <div className="col-6">
              <p className="filter_title_1">All Filters</p>
            </div>
            <div className="col-6 text-end">
              <p className="filter_title_2">Clear All</p>
            </div>
          </div>
          <div className="filter_new_top_div_inner">
            <div className="header_input_div">
              <IoSearchOutline />
              <input
                type="text"
                className="form-control"
                placeholder="Search Filter"
              />
            </div>
            <Accordion alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Request</Accordion.Header>
                <Accordion.Body>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Website Hompeage Design Review"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Website Hompeage Design Review"
                      />
                    </FormGroup>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Priority</Accordion.Header>
                <Accordion.Body>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <PriorityInner
                            backgroundColor="#FF3E48"
                            title="high"
                          />
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <PriorityInner
                            backgroundColor="#31C971"
                            title="Low"
                          />
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <PriorityInner
                            backgroundColor="#F3D32C"
                            title="Medium"
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Status</Accordion.Header>
                <Accordion.Body>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <RequestedAndRejected
                            title="Requested"
                            color="#0054B7"
                          />
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <RequestedAndRejected
                            title="Rejected"
                            color="#FF3E48"
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Requested By</Accordion.Header>
                <Accordion.Body>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <RequestedBy
                            title="Sunny Chakrawal"
                            UserPhoto={UserIcon}
                          />
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <RequestedBy
                            title="Sunny Chakrawal"
                            UserPhoto={UserIcon}
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className=" mt-4 d-flex justify-content-center align-items-center">
            <div className=" me-3">
              <FullBtn name="Apply" />
            </div>
            <div className="add_project_modal_btn_one">
              <OutlineBtn onClick={() => setShowFilters(false)} name="Close" />
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  );
};
