import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useState} from 'react';
export const NewEditor = ({
  value = '',
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  placeholder = '',
}) => {
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    // ['blockquote', 'code-block'],
    ['link', 'image'],
    [{list: 'ordered'}, {list: 'bullet'}, {list: 'check'}],
    [{script: 'sub'}, {script: 'super'}], // superscript/subscript
    [{indent: '-1'}, {indent: '+1'}], // outdent/indent
    [{direction: 'rtl'}], // text direction
    [{header: [1, 2, 3, 4, 5, 6, false]}],
    [{color: []}, {background: []}], // dropdown with defaults from theme
    [{font: []}],
    [{align: []}],
    ['clean'],
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  return (
    <>
      <div className="new_editer">
        <ReactQuill
          theme="snow"
          modules={module}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          onBlur={onBlur}
        />
      </div>
    </>
  );
};
