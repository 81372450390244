import { MyPerformance } from "../components/Pages/Performance/MyPerformance";
const Performance = () => {
  return (
    <>
      <div className="custom_container">
        <MyPerformance />
      </div>
    </>
  );
};

export default Performance;
