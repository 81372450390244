import { Index } from "../components/Pages/Announcement/Index";
const Announcement = () => {
  return (
    <>
      <div className="custom_container">
        <Index />
      </div>
    </>
  );
};

export default Announcement;
