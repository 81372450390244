import {PageAllTopTitle} from '../../../../../common/ManagementComponents/PageAllTopTitle';
import {BsArrowLeft} from 'react-icons/bs';
import {InputBox} from '../../../../../common/ManagementComponents/form/Form';
import Select from 'react-select';
import {useState} from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {OutlineBtn} from '../../../../../common/ManagementComponents/Btn/Btn';
import * as React from 'react';
import {useEffect} from 'react';
import {Avatar, Button} from '@mui/material';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {Block} from 'notiflix/build/notiflix-block-aio';
import Axios from './../../../../../../config/api';
import {StatusCode} from '../../../../../../constants';
import ReactHtmlParser from 'react-html-parser';
import {Confirm} from 'notiflix/build/notiflix-confirm-aio';
import {styled} from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import {IoMdAttach} from 'react-icons/io';
import {Input, Modal, ButtonToolbar} from 'rsuite';
//
import {IoClose} from 'react-icons/io5';
import {
  MdOutlineDelete,
  MdOutlineModeEdit,
  MdOutlineTaskAlt,
} from 'react-icons/md';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';

import moment from 'moment';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Accordionn from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {FaUserPlus} from 'react-icons/fa6';
import {AiOutlineClose} from 'react-icons/ai';

import CommentsPdfIcon from '../../../../../../pics/pdf.svg';
import CommentsPdfIcon2 from '../../../../../../pics/word.svg';
import CommentsPdfIcon3 from '../../../../../../pics/powerpoint.svg';
import CommentsPdfIcon4 from '../../../../../../pics/excel.svg';
import CommentsPdfIcon5 from '../../../../../../pics/file.png';
import ApprovalIcon from '../../../../../../pics/approval_icon.svg';
import {NewEditor} from '../../../../../common/NewComponents/NewEditor/NewEditor';

const BootstrapTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
export const CreateTask = ({type, isUpdate}) => {
  const [taskType, setTaskType] = useState(null);
  const options = [
    {
      value: 'internal',
      label: 'Internal Employee',
    },
    {
      value: 'client',
      label: 'Client',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ];

  useEffect(() => {
    if (type == 'client') setTaskType(options[1]);
    else if (type == 'other') setTaskType(options[2]);
    else setTaskType(options[0]);
    return () => {};
  }, []);
  return (
    <div className="custom_container">
      <PageAllTopTitle link={-1} icon={<BsArrowLeft />} title="back" />
      <div className="custom_container_iner">
        <div className="CompanySetup_main_div">
          <div className="CompanySetup_inner_main_div">
            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
              <div className="col-12">
                <div className="create_task_title">
                  <h3>{isUpdate ? 'Update' : 'Create'} task</h3>
                </div>
              </div>
              <div className="col-12">
                <label className="all_page_my_label_new">
                  {isUpdate ? 'Updating' : 'Create'} Task For*
                </label>
                <div className="dashboard_top_week_Select drop_box">
                  <Select
                    isDisabled={isUpdate}
                    options={options}
                    value={taskType}
                    isSearchable={false}
                    onChange={value => setTaskType(value)}
                  />
                </div>
              </div>
              <div className="col-12">
                {
                  {
                    internal: <InternalEmployeeTask />,
                    client: <ClientTask />,
                    other: <OtherTask />,
                  }[taskType?.value]
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const InternalEmployeeTask = () => {
  const {state} = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  // Access the meeting_note_id from the state object
  const meetingNote = state?.meetingNote;

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm();
  const watchStartDate = watch('start_date', null);
  const watchEndDate = watch('end_date', null);
  //states
  const [task, setTask] = useState({});
  const [serverErrors, setServerErrors] = useState({});
  const [service, setService] = useState({});
  const [servicesData, setServicesData] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const [sendForApproval, setSendForApproval] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [approversSeries, setApproversSeries] = useState([null]);
  const [showErrors, setShowErrors] = useState(false);
  const [requireSwitchNew, setShowRequireSwitchnew] = useState(false);
  const [selectValues, setSelectValues] = useState({
    priorities: [],
    projects: [],
    frequencies: [],
    departments: [],
    services: [],
    milestones: [],
    users: [],
    allUsers: [],
    statuses: [],
  });

  //hooks
  useEffect(async () => {
    fetchTaskSelectFieldsData();
    if (params?.task_id) fetchTask();

    return () => {};
  }, []);
  //functions
  const fetchTask = () => {
    //showing up loader
    Block.circle('#task_form', 'Loading...');
    Axios.fetch(`maxproject/task/internal_task/${params.task_id}`)
      .then(({data}) => {
        let payload = {
          task_name: data.name,
          start_date: data.start_date,
          end_date: data.end_date,
          description: data.desc,
          priority: {
            label: data?.Priority?.name,
            value: data?.Priority?.id,
          },
          project: {
            label: data?.Project?.name,
            value: data?.Project?.id,
          },
          frequency: {
            label: data?.Frequency?.name,
            value: data?.Frequency?.id,
          },
          status: {
            label: data?.Status?.name,
            value: data?.Status?.id,
          },
          milestone: {
            label: data?.Milestone?.title,
            value: data?.Milestone?.id,
          },
          department: {
            label: data?.Department?.name,
            value: data?.Department?.id,
          },
          service: {
            label: data?.ProjectService?.Service?.name,
            value: data?.ProjectService?.project_service_id,
          },
          task_users: data?.TaskMembers?.map(user => ({
            label: user?.User?.name,
            value: user?.user_id,
          })),
        };
        //getting task owner
        let TaskOwner = data?.TaskMembers.find(member => member.isOwner);
        //if is there an task owner then set it
        if (TaskOwner)
          payload.task_owner = {
            label: TaskOwner?.User?.name,
            value: TaskOwner?.user_id,
          };
        setTask(payload);
        setService(data?.ProjectService);
        setSelectedAssignees(payload.task_users);
        reset(payload);
        Block.remove('#task_form');
      })
      .catch(err => {
        //removing loader
        Block.remove('#task_form');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const onProjectChange = project_id => {
    setValue('department', null);
    setValue('service', null);
    setValue('milestone', null);
    setValue('task_users', null);
    setSelectValues(old => ({
      ...old,
      departments: [],
      services: [],
      milestones: [],
      users: [],
    }));
    fetchDepartmentsByProjectId(project_id);
    fetchTeamByProjectId(project_id);
  };
  const onDepartmentChange = department_id => {
    setValue('service', null);
    setValue('milestone', null);
    setSelectValues(old => ({
      ...old,
      services: [],
      milestones: [],
    }));
    fetchProjectServicesByDepartmentId(department_id);
  };
  const onServiceChange = project_service_id => {
    setValue('milestone', null);
    setSelectValues(old => ({
      ...old,
      milestones: [],
    }));
    fetchProjectMilestonesByServiceId(project_service_id);
    const Service = servicesData.find(service => service?.project_service_id);
    setService(Service);
    setValue('start_date', null);
    setValue('end_date', null);
  };
  const fetchTaskSelectFieldsData = () => {
    Axios.fetch(
      `maxproject/random/task_select_fields?task_id=${params?.task_id}`,
    )
      .then(({data}) => {
        let projects = data?.projects?.map(el => ({
          value: el?.id,
          label: el?.name,
        }));
        let priorities = data?.priorities?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let statuses = data?.statuses?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let frequencies = data?.frequencies?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let departments = data.departments.map(d => ({
          label: d.Department.name,
          value: d.department_id,
        }));
        let services = data.services.map(d => ({
          label: d?.Service?.name,
          value: d?.project_service_id,
        }));
        let milestones = data.milestones.map(d => ({
          label: d?.title,
          value: d?.id,
        }));
        let users = data.users.map(u => ({
          label: u.name,
          value: u.id,
        }));
        let allUsers = data.allUsers.map(u => ({
          label: u.name,
          value: u.id,
        }));

        setSelectValues(old => ({
          ...old,
          projects,
          priorities,
          frequencies,
          departments,
          services,
          milestones,
          users,
          statuses,
          allUsers,
        }));

        if (meetingNote) {
          let payload = {};
          if (meetingNote.projectId)
            payload.project = projects.find(
              p => p.value == meetingNote.projectId,
            );
          payload.description = `<p>${meetingNote.noteTitle}</p>`;
          setTask(payload);
          reset(payload);
        }
      })
      .catch(err => {
        console.log(err);
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchDepartmentsByProjectId = project_id => {
    const URL = `maxproject/project_service/departments/${project_id}`;
    Axios.fetch(URL)
      .then(({data}) => {
        let departments = data.departments.map(d => ({
          label: d.Department.name,
          value: d.department_id,
        }));
        setSelectValues(old => ({...old, departments}));
        Block.remove('#task_form');
      })
      .catch(err => {
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchTeamByProjectId = project_id => {
    const URL = `maxproject/team/members/${project_id}`;
    Axios.fetch(URL)
      .then(({data}) => {
        let users = data.members.map(u => ({
          label: u.name,
          value: u.id,
        }));
        setSelectValues(old => ({...old, users}));
      })
      .catch(err => {
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchProjectServicesByDepartmentId = department_id => {
    let project = getValues('project');
    const URL = `maxproject/project_service/services_by_department/${department_id}/${project?.value}`;
    Axios.fetch(URL)
      .then(({data}) => {
        let services = data.services.map(d => ({
          label: d?.Service?.name,
          value: d?.project_service_id,
        }));
        setServicesData(data.services);
        setSelectValues(old => ({...old, services}));
      })
      .catch(err => {
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchProjectMilestonesByServiceId = project_service_id => {
    let project = getValues('project');
    const URL = `maxproject/project_milestone/by_service/${project_service_id}/${project?.value}`;
    Axios.fetch(URL)
      .then(({data}) => {
        let milestones = data.milestones.map(d => ({
          label: d?.title,
          value: d?.id,
        }));
        setSelectValues(old => ({...old, milestones}));
      })
      .catch(err => {
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = (data, e, force = false) => {
    if (sendForApproval && !showApprovalModal)
      return setShowApprovalModal(true);

    if (requireSwitchNew && approversSeries.some(el => el === null))
      return setShowErrors(true);

    let payload = {
      sendForApproval: sendForApproval ? 1 : 0,
      assignedOrder: requireSwitchNew ? 1 : 0,
      task_name: data.task_name,
      project_id: data.project.value,
      start_date: data.start_date,
      end_date: data.end_date,
      priority_id: data.priority.value,
      frequency_id: data.frequency.value,
      status_id: data.status.value,
      milestone_id: data.milestone.value,
      department_id: data.department.value,
      task_owner: data.task_owner?.value,
      service_id: data.service.value,
      task_users: data.task_users?.map(u => u.value),
      desc: task.description,
    };
    //if send for approval is true then add approvers
    if (sendForApproval) {
      payload['approvers'] = JSON.stringify(
        (requireSwitchNew ? approversSeries : data.approvers)?.map(
          u => u.value,
        ),
      );
      payload['approvalType'] = 'internal';
    }

    if (!params?.task_id)
      payload.task_users = JSON.stringify(payload.task_users);
    //creating form data
    let formData = new FormData();
    Object.keys(payload).forEach(key => {
      if (payload[key]) formData.append(key, payload[key]);
    });
    //preparing sub task payload
    const SubTasks = [];
    //appending sub tasks
    subTasks.forEach((sub, i) => {
      let {docs, ...rest} = sub;
      SubTasks.push(rest);
      docs.forEach((doc, j) => {
        formData.append(`sub_task_${i}_docs`, doc);
      });
    });
    //appending sub tasks
    formData.append('sub_tasks', JSON.stringify(SubTasks));
    //clearing server errors
    setServerErrors({});
    //showing up loader
    Block.circle('#task_form', 'Saving...');
    //initiating network request
    const Request = params?.task_id
      ? Axios.put(
          `maxproject/task/internal_task/${params.task_id}${
            force ? '?force=1' : ''
          }`,
          payload,
        )
      : Axios.post('maxproject/task/internal_task', formData);

    Request.then(res => {
      Block.remove('#task_form');
      Notify.success(res.data.message);
      if (res.data.status == 1) {
        // navigate to task page
        navigate(-1);
      } else if (res.data.status == -1)
        Confirm.show(res.data.message, 'Still Proceed!!', 'Yes', 'No', () =>
          handleFormSubmit(data, null, true),
        );
      else if (res.data.status == -2) Notify.failure(res.data.message);
    }).catch(err => {
      //removing loader
      Block.remove('#task_form');
      //checking for any error message
      if (err?.response?.data?.message)
        Notify.failure(err?.response?.data?.message);
      //checking and showing express validation errors
      if (err?.response?.status == StatusCode.ExpressErrors)
        err?.response?.data?.errors.forEach(el =>
          setServerErrors(old => ({...old, [el.param]: el.msg})),
        );
    });
  };
  const onAssigneeChange = assignees => {
    setSelectedAssignees(assignees);
    let found = assignees.find(a => a.value == getValues('task_owner')?.value);
    if (!found) setValue('task_owner', null);
  };
  //components
  const NewSubTask = () => {
    const [showAddSubTasks, setShowAddSubTasks] = useState(false);
    const [form, setForm] = useState({
      name: '',
      start_date: null,
      end_date: null,
    });
    const [newDocs, setNewDocs] = useState([]);
    const [formErrors, setFormErrors] = useState({
      name: '',
      start_date: '',
      end_date: '',
    });
    //hooks
    useEffect(() => {
      //if start date is greater than end date
      if (form.end_date && form.start_date.isAfter(form.end_date))
        setForm(old => ({...old, end_date: null}));
    }, [form.start_date]);

    const handleAddNewSubTask = e => {
      //getting values
      const {name, start_date, end_date} = form;
      //validating form
      if (!name || !start_date || !end_date)
        return setFormErrors({
          name: !name ? 'Name is required*' : '',
          start_date: !start_date ? 'Start Date is required*' : '',
          end_date: !end_date ? 'End Date is required*' : '',
        });

      //preparing payload
      const payload = {
        name: name,
        start_date: start_date,
        end_date: end_date,
        docs: newDocs,
      };
      //updating sub tasks
      setSubTasks(old => [...old, payload]);
    };
    const handleRemoveNewDoc = fileToRemove => {
      const filesArray = Array.from(newDocs);
      const updatedFilesArray = filesArray.filter(
        file => file !== fileToRemove,
      );
      setNewDocs(updatedFilesArray);
    };
    const handleFileChange = e => {
      setNewDocs(old => [...old, ...e.target.files]);
    };
    return (
      <div className="add_new_subtask_top_ui_div add_new_subtask_main_ui_new p-0">
        <div className="row m-0">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="add_new_subtask_data_top_title_ui">
              Subtask Name
            </div>
          </div>

          <div className="col-3 d-none d-xxl-block d-xl-block d-lg-block d-md-block">
            <div className="new_start_date">Start date</div>
          </div>
          <div className="col-3 d-none d-xxl-block d-xl-block d-lg-block d-md-block">
            <div className="end_start_date">End date</div>
          </div>
        </div>
        <div
          style={{
            padding: ' 15px 0 15px 0',
            borderTop: '1px solid #e5e5ea',
          }}>
          <form onSubmit={handleAddNewSubTask} id="newSubTask">
            <div className="row m-0 ">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="add_new_subtask_main_ui create_task_date_piker_new2">
                  <div className="add_new_subtask_main_ui_input_div create_task_date_piker_input">
                    <span>
                      <MdOutlineTaskAlt />
                    </span>
                    <Input
                      name="name"
                      required
                      onChange={txt => setForm({...form, name: txt})}
                      value={form.name}
                      placeholder="Subtask name"
                      onClick={setShowAddSubTasks}
                    />
                  </div>
                </div>
                <small className="error_message">{formErrors.name}</small>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="create_task_date_piker_new create_task_date_piker_new2">
                  <div>
                    <label className="all_page_my_label_new">Start Date*</label>
                    <input
                      required
                      type="date"
                      name="start_date"
                      disabled={!watchStartDate || !watchEndDate}
                      min={
                        watchStartDate
                          ? moment(watchStartDate).format('YYYY-MM-DD')
                          : ''
                      }
                      max={
                        watchEndDate
                          ? moment(watchEndDate).format('YYYY-MM-DD')
                          : ''
                      }
                      className="form-control intput_box "
                      value={form.start_date}
                      onChange={e =>
                        setForm({...form, start_date: e.target.value})
                      }
                    />
                    <small className="error_message">
                      {formErrors.start_date}
                    </small>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="create_task_date_piker_new create_task_date_piker_new2">
                  <div>
                    <label className="all_page_my_label_new">End Date*</label>
                    <input
                      required
                      name="end_date"
                      type="date"
                      value={form.end_date}
                      className="form-control intput_box"
                      disabled={
                        !watchStartDate || !watchEndDate || !form.start_date
                      }
                      min={
                        form.start_date
                          ? moment(form.start_date).format('YYYY-MM-DD')
                          : ''
                      }
                      max={
                        watchEndDate
                          ? moment(watchEndDate).format('YYYY-MM-DD')
                          : ''
                      }
                      onChange={e =>
                        setForm({...form, end_date: e.target.value})
                      }
                    />
                    <small className="error_message">
                      {formErrors.end_date}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            {showAddSubTasks ? (
              <div style={{padding: '0 15px'}}>
                <div
                  className="add_new_subtask_Uploaded_mai_div"
                  style={{paddingLeft: '24px'}}>
                  {Array.from(newDocs).map((file, i) => (
                    <div className="item">
                      <div className="add_new_subtask_Uploaded_img_div">
                        <img src={getFileIcon(file, true)} alt="Uploaded doc" />
                        <p>
                          {file.name.length > 15
                            ? file.name?.substr(0, 15) + '...'
                            : file.name}
                        </p>
                        <span
                          className="close_btn"
                          onClick={() => handleRemoveNewDoc(file)}>
                          <IoClose />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="add_new_subtask_top_ui_btn_div">
                  <div className="add_new_subtask_uploader_div">
                    <input
                      type="file"
                      onChange={handleFileChange}
                      id="subTaskFile"
                      hidden
                      multiple
                    />
                    <BootstrapTooltip title="Attachment" placement="top">
                      <label htmlFor="subTaskFile" className=" d-flex">
                        <IoMdAttach />
                      </label>
                    </BootstrapTooltip>
                  </div>
                  <Button
                    onClick={() => {
                      setShowAddSubTasks(false);
                    }}
                    className="btn1"
                    variant="outlined">
                    Cancel
                  </Button>
                  <Button
                    className="btn2"
                    variant="contained"
                    onClick={handleAddNewSubTask}>
                    Save
                  </Button>
                </div>
              </div>
            ) : null}
          </form>
        </div>
      </div>
    );
  };
  const Header = () => (
    <div className="row">
      <div className="col-8">
        <div className="add_new_subtask_data_top_title_ui">Subtask Name</div>
      </div>

      <div className="col-2 ">
        <div className={'new_start_date'}>Start date</div>
      </div>
      <div className="col-2 ">
        <div className={'end_start_date'}>End date</div>
      </div>
    </div>
  );
  const SubTask = ({sub, idx}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState(sub.name);
    const [docs, setDocs] = useState(sub.docs || []);
    const [startDate, setStartDate] = useState(
      sub?.start_date ? moment(sub?.start_date).format('YYYY-MM-DD') : null,
    );
    const [endDate, setEndDate] = useState(
      sub?.start_date ? moment(sub?.end_date).format('YYYY-MM-DD') : null,
    );
    const [formErrors, setFormErrors] = useState({
      name: '',
      start_date: '',
      end_date: '',
    });

    const handleFileChange = e => {
      setDocs(old => [...old, ...e.target.files]);
    };
    const handleRemoveNewDoc = fileToRemove => {
      const filesArray = Array.from(docs);
      const updatedFilesArray = filesArray.filter(
        file => file !== fileToRemove,
      );
      setDocs(updatedFilesArray);
    };
    const handleUpdate = () => {
      //validating form
      if (!name || !startDate || !endDate)
        return setFormErrors({
          name: !name ? 'Name is required*' : '',
          start_date: !startDate ? 'Start Date is required*' : '',
          end_date: !endDate ? 'End Date is required*' : '',
        });
      //preparing payload
      let payload = {
        name,
        start_date: startDate,
        end_date: endDate,
        docs,
      };
      //updating sub tasks
      setSubTasks(old => {
        let updated = [...old];
        updated[idx] = payload;
        return updated;
      });
    };
    const handleDeleteSubTask = () => {
      Confirm.show(
        'Delete Subtask',
        'Are you sure you want to delete this subtask?',
        'Yes',
        'No',
        () => {
          setSubTasks(old => old.filter((_, i) => i !== idx));
        },
      );
    };
    //Components
    const Docs = () => {
      return (
        <div className="add_new_subtask_Uploaded_mai_div">
          {Array.from(docs).map((doc, i) => (
            <div className="item">
              <div className="add_new_subtask_Uploaded_img_div">
                <img src={getFileIcon(doc, true)} alt="Uploaded doc" />
                <p>
                  {doc.name.length > 15
                    ? doc.name?.substr(0, 15) + '...'
                    : doc.name}
                </p>
                {isEditing ? (
                  <span
                    className="close_btn"
                    onClick={() => handleRemoveNewDoc(doc)}>
                    <IoClose />
                  </span>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      );
    };
    return (
      <div className="row m-0">
        <div className="col-12 p-0">
          <div
            className={
              isEditing
                ? 'add_new_subtask_data_top_ui add_new_subtask_main_ui_new'
                : 'add_new_subtask_data_top_ui'
            }>
            {isEditing ? (
              <div className="row">
                <div className="col-8">
                  <div className="add_new_subtask_main_ui">
                    <div className="add_new_subtask_main_ui_input_div create_task_date_piker_input">
                      <span>
                        <MdOutlineTaskAlt />
                      </span>
                      <Input
                        placeholder="Subtask name"
                        value={name}
                        onChange={value => setName(value)}
                      />
                    </div>
                  </div>
                  <small className="error_message">{formErrors.name}</small>
                </div>
                <div className="col-2">
                  <div className="create_task_date_piker_new">
                    <div className="">
                      <label className="all_page_my_label_new">
                        Start Date*
                      </label>
                      <input
                        className="form-control intput_box"
                        type="date"
                        min={moment().format('YYYY-MM-DD')}
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                      />
                      <small className="error_message">
                        {formErrors.start_date}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="create_task_date_piker_new">
                    <div className="end_start_date">
                      <label className="all_page_my_label_new">End Date*</label>
                      <input
                        className="form-control intput_box"
                        type="date"
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                      />
                      <small className="error_message">
                        {formErrors.end_date}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-8 position-relative">
                  <div className="add_new_subtask_main_ui">
                    <div className="add_new_subtask_main_ui_input_div create_task_date_piker_input">
                      <span>
                        <MdOutlineTaskAlt />
                      </span>
                      <p className="add_new_subtask_text_view">{sub?.name}</p>
                    </div>
                    <div className="add_new_subtask_top_ui_inner_div ">
                      <div className="add_new_subtask_uploader_div">
                        <div className="add_new_subtask_text_view_div add_new_subtask_text_view_div_new">
                          <BootstrapTooltip title="Delete" placement="top">
                            <label className="me-0 show_btn show_btn2 sub_task_delete_btn">
                              <MdOutlineDelete onClick={handleDeleteSubTask} />
                            </label>
                          </BootstrapTooltip>
                          {/* <DeleteModal /> */}
                          <BootstrapTooltip title="Edit" placement="top">
                            <label className="show_btn" onClick={setIsEditing}>
                              <MdOutlineModeEdit />
                            </label>
                          </BootstrapTooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <p className="end_and_start_date">
                    {sub?.start_date
                      ? moment(sub?.start_date).format('MMM D')
                      : 'N/A'}
                  </p>
                </div>
                <div className="col-2">
                  <p className="end_and_start_date">
                    {sub?.end_date
                      ? moment(sub?.end_date).format('MMM D')
                      : 'N/A'}
                  </p>
                </div>
              </div>
            )}

            <div>
              <Docs />
              {isEditing ? (
                <div className="add_new_subtask_top_ui_btn_div">
                  <div className="add_new_subtask_uploader_div">
                    <input
                      type="file"
                      onChange={handleFileChange}
                      id="subTaskFile2"
                      multiple
                      hidden
                    />
                    <BootstrapTooltip title="Attachment" placement="top">
                      <label htmlFor="subTaskFile2" className=" d-flex">
                        <IoMdAttach />
                      </label>
                    </BootstrapTooltip>
                  </div>
                  <Button
                    className="btn1"
                    variant="outlined"
                    onClick={() => {
                      setIsEditing(false);
                    }}>
                    Cancel
                  </Button>
                  <Button
                    className="btn2"
                    variant="contained"
                    onClick={handleUpdate}>
                    Save
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const ApprovalModal = () => {
    const [elementCount, setElementCount] = useState(0);
    const [elements, setElements] = useState([]);
    const Task = getValues();
    //functions

    const handleClose = () => setShowApprovalModal(false);
    const addElement = () => {
      setApproversSeries([...approversSeries, null]);
      setElementCount(elementCount + 1);
    };
    const deleteElement = idx => {
      setApproversSeries(old => {
        let arr = old.slice();
        arr.splice(idx, 1);
        return arr;
      });
    };
    const SwitchHandleChangeNew = () => {
      setShowRequireSwitchnew(!requireSwitchNew);
    };
    return (
      <>
        <ButtonToolbar>
          <Button
            style={{
              backgroundColor: '#0f62fe',
              fontSize: '14px',
              padding: '10px 20px',
              color: 'white',
              textTransform: 'capitalize',
              borderRadius: '6px',
            }}
            size="md"
            type="submit"
            form={'task_form'}>
            send for approvals
          </Button>
        </ButtonToolbar>

        <Modal size={'md'} open={showApprovalModal} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <ul className="approval_modal_top_title_div">
                <li>
                  <img src={ApprovalIcon} alt="icon" />
                </li>
                <li>
                  <p>Approval request</p>
                  <span>Approval request details</span>
                </li>
                <li>
                  <div>Requested</div>
                </li>
              </ul>
            </Modal.Title>
            <hr />
          </Modal.Header>
          <Modal.Body>
            <div>
              <p className="approval_modal_sub_title_p ">{Task?.task_name}</p>
              <div className="approval_modal_sub_title_div">
                <div className="row g-3">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <ul>
                      <li className="li_first">Date</li>
                      <li>: {moment().format('YYYY-MM-DD')}</li>
                    </ul>
                    <ul>
                      <li className="li_first">Type</li>
                      <li>: Internal</li>
                    </ul>
                    <ul>
                      <li className="li_first">Project</li>
                      <li>: {Task?.project?.label}</li>
                    </ul>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <ul>
                      <li className="li_first">Priority</li>
                      <li>: {Task?.priority?.label}</li>
                    </ul>
                    <ul>
                      <li className="li_first">Task start date</li>
                      <li>: {Task?.start_date}</li>
                    </ul>
                    <ul>
                      <li className="li_first">Task end date</li>
                      <li>: {Task?.end_date}</li>
                    </ul>
                  </div>
                  <div className="col-12">
                    <p className="approval_modal_sub_title_Description_new create_task_title_modal">
                      Description
                    </p>
                    <div>
                      {task?.description
                        ? ReactHtmlParser(task?.description)
                        : null}
                    </div>
                  </div>
                  {subTasks?.length ? (
                    <div className="col-12">
                      <Accordionn className="approval_modal_sub_title_Description_top_div2">
                        <div className="approval_modal_sub_title_Description_div">
                          <AccordionSummary
                            expandIcon={
                              <ArrowDropDownIcon className="pm_icon" />
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"></AccordionSummary>
                          <div className="ms-1 approval_modal_sub_title_Description_new mt-0 mb-0">
                            Subtasks
                          </div>
                        </div>
                        <AccordionDetails>
                          <div className="add_new_subtask_top_ui_div p-0">
                            <Header />
                            {subTasks?.map((sub, idx) => (
                              <SubTask sub={sub} idx={idx} />
                            ))}
                          </div>
                        </AccordionDetails>
                      </Accordionn>
                    </div>
                  ) : null}

                  <div className="col-12">
                    <FormGroup>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <AntSwitch
                          inputProps={{'aria-label': 'ant design'}}
                          checked={requireSwitchNew}
                          onChange={SwitchHandleChangeNew}
                        />
                        <Typography className="approval_request_input_switch_text">
                          Require a response from all users in the assigned
                          order
                        </Typography>
                      </Stack>
                    </FormGroup>
                  </div>
                  <div className="col-12">
                    <div>
                      {requireSwitchNew ? (
                        <div>
                          {approversSeries.map((element, i) => (
                            <div
                              className="row mb-3  "
                              style={{position: 'relative '}}
                              key={i}>
                              <div className="col-1">
                                <p className="approval_request_input_add_number">
                                  {i + 1}
                                </p>
                              </div>
                              <div className="col-10 dashboard_top_week_Select drop_box">
                                <Select
                                  onChange={el => {
                                    setApproversSeries(old => {
                                      let arr = old.slice();
                                      arr[i] = el;
                                      return arr;
                                    });
                                  }}
                                  value={element}
                                  options={selectValues?.allUsers}
                                  placeholder="Select Approver"
                                />
                              </div>
                              <div className="col-1">
                                <div className="approval_request_input_remove_btn ">
                                  <AiOutlineClose
                                    onClick={() => deleteElement(i)}
                                  />
                                </div>
                              </div>
                              <div className="col-1"></div>
                              <div className="col-11">
                                {!approversSeries[i] && showErrors ? (
                                  <small className="error_message">
                                    Please select a approver
                                  </small>
                                ) : null}
                              </div>
                            </div>
                          ))}
                          <div className="">
                            <p
                              onClick={addElement}
                              className="approval_request_input_add_btn">
                              <FaUserPlus />
                              Add additional recipient
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="col-12">
                          <div className="dashboard_top_week_Select drop_box drop_box2 scheduling_details_div">
                            <Controller
                              name="approvers"
                              control={control}
                              rules={{
                                required: requireSwitchNew
                                  ? false
                                  : 'Please select approvers',
                              }}
                              render={({field}) => (
                                <Select
                                  {...field}
                                  isSearchable={true}
                                  onChange={el => field.onChange(el)}
                                  isMulti
                                  options={selectValues?.allUsers}
                                  placeholder="select approvers"
                                />
                              )}
                            />
                            <small className="error_message">
                              {errors.approvers?.message ||
                                serverErrors?.approvers}
                            </small>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mt-5">
                    <div className=" d-flex align-items-center justify-content-end approval_modal_btn">
                      <div className="me-2">
                        <OutlineBtn onClick={handleClose} name="Cancel" />
                      </div>
                      <div className="ms-2">
                        <button
                          type="submit"
                          form="task_form"
                          className="management_btn_full2">
                          send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };
  return (
    <form id="task_form" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            type="text"
            label="Task Name*"
            placeholder="Enter task name"
            register={() =>
              register('task_name', {
                required: 'Please enter task name',
                value: task?.task_name,
              })
            }
            errorMessage={errors.task_name?.message || serverErrors?.task_name}
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Project*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="project"
              control={control}
              rules={{
                required: 'Please select a project',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  isDisabled={params?.task_id}
                  defaultValue={task?.project}
                  onChange={el => {
                    field.onChange(el);
                    onProjectChange(el.value);
                  }}
                  options={selectValues.projects}
                  placeholder={field.value || 'Select Project'}
                />
              )}
            />
            <small className="error_message">
              {errors.project?.message || serverErrors?.project}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Department*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="department"
              control={control}
              rules={{
                required: 'Please select a department',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.department}
                  onChange={el => {
                    field.onChange(el);
                    onDepartmentChange(el.value);
                  }}
                  options={selectValues.departments}
                  placeholder={field.value || 'Select Department*'}
                />
              )}
            />
            <small className="error_message">
              {errors.department?.message || serverErrors?.department}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Services*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="service"
              control={control}
              rules={{
                required: 'Please select a service',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.service}
                  onChange={el => {
                    field.onChange(el);
                    onServiceChange(el.value);
                  }}
                  options={selectValues.services}
                  placeholder={field.value || 'Select Service'}
                />
              )}
            />
            <small className="error_message">
              {errors.service?.message || serverErrors?.service}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Milestone*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="milestone"
              control={control}
              rules={{
                required: 'Please select a milestone',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.milestone}
                  onChange={el => field.onChange(el)}
                  options={selectValues.milestones}
                  placeholder={field.value || 'Select Milestone'}
                />
              )}
            />
            <small className="error_message">
              {errors.milestone?.message || serverErrors?.milestone}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Assign To*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="task_users"
              control={control}
              rules={{
                required: 'Please select a user',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  isMulti
                  defaultValue={task?.task_users}
                  onChange={el => {
                    field.onChange(el);
                    onAssigneeChange(el);
                  }}
                  options={selectValues.users}
                  placeholder={field.value || 'Select Team Members'}
                />
              )}
            />
            <small className="error_message">
              {errors.task_users?.message || serverErrors?.task_users}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Owner</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="task_owner"
              control={control}
              rules={{
                required: false,
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.task_owner}
                  onChange={el => field.onChange(el)}
                  options={selectedAssignees}
                  placeholder={field.value || 'Select Task Owner'}
                />
              )}
            />
            <small className="error_message">
              {errors.task_owner?.message || serverErrors?.task_owner}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            defaultValue={task?.start_date}
            register={() =>
              register('start_date', {
                required: 'Please enter start date',
                value: task?.start_date,
                // validate: {
                //   isBetween
                // },
              })
            }
            errorMessage={
              errors.start_date?.message || serverErrors?.start_date
            }
            min={service?.start_date || moment().format('YYYY-MM-DD')}
            max={service?.end_date}
            type="date"
            label="Start Date*"
            placeholder=""
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            defaultValue={task?.end_date}
            register={() =>
              register('end_date', {
                required: 'Please enter onboarding date',
                value: task?.end_date,
              })
            }
            errorMessage={errors.end_date?.message || serverErrors?.end_date}
            type="date"
            min={service?.start_date || moment().format('YYYY-MM-DD')}
            max={service?.end_date}
            label="End Date*"
            placeholder=""
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Priority*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="priority"
              control={control}
              rules={{
                required: 'Please select a priority',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.priority}
                  onChange={el => field.onChange(el)}
                  options={selectValues.priorities}
                  placeholder={field.value || 'Select Priority'}
                />
              )}
            />
            <small className="error_message">
              {errors.priority?.message || serverErrors?.priority}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Frequency*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="frequency"
              control={control}
              rules={{
                required: 'Please select a frequency',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.frequency}
                  onChange={el => field.onChange(el)}
                  options={selectValues.frequencies}
                  placeholder={field.value || 'Select Frequency'}
                />
              )}
            />
            <small className="error_message">
              {errors.frequency?.message || serverErrors?.frequency}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Status*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="status"
              control={control}
              rules={{
                required: 'Please select a task status',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  isDisabled={params?.task_id}
                  defaultValue={task?.status}
                  onChange={el => field.onChange(el)}
                  options={selectValues.statuses}
                  placeholder={field.value || 'Select Status'}
                />
              )}
            />
            <small className="error_message">
              {errors.status?.message || serverErrors?.status}
            </small>
          </div>
        </div>

        {params?.task_id ? null : <NewSubTask />}

        {subTasks.length ? (
          <div className="task_scrol_div1">
            <div className="add_new_subtask_top_ui_div task_scrol_div2 task_scrol_div3 p-0">
              <Header />
              {subTasks?.map((sub, idx) => (
                <SubTask sub={sub} idx={idx} />
              ))}
            </div>
          </div>
        ) : null}

        <div className="col-12">
          <label className="all_page_my_label_new">Description</label>
          <NewEditor
            value={task?.description}
            onChange={text => setTask(old => ({...old, description: text}))}
            placeholder="Describe the details of the task/ Write about the task in brief"
          />
        </div>
        {params?.task_id ? null : (
          <div className="col-12 d-flex justify-content-center">
            <FormGroup>
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch
                  inputProps={{'aria-label': 'ant design'}}
                  checked={sendForApproval}
                  onChange={() => setSendForApproval(!sendForApproval)}
                />
                <Typography className="approval_request_input_switch_text">
                  Do you want to send this task for approval?
                </Typography>
              </Stack>
            </FormGroup>
          </div>
        )}
        <div className="col-12 mb-3 mt-5">
          <div className=" d-flex align-items-center justify-content-center">
            <div className="me-2">
              <OutlineBtn onClick={() => navigate(-1)} name="Cancel" />
            </div>
            <div className="ms-2">
              {sendForApproval ? (
                <ApprovalModal />
              ) : (
                <button
                  className="management_btn_full2"
                  type="submit"
                  form="task_form">
                  {params?.task_id ? 'Update' : 'Save'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
const OtherTask = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm();

  const [task, setTask] = useState({});
  const [serverErrors, setServerErrors] = useState({});
  const [selectValues, setSelectValues] = useState({
    priorities: [],
    frequencies: [],
    users: [],
    statuses: [],
  });

  useEffect(async () => {
    fetchTaskSelectFieldsData();
    fetchTeamMembers();
    if (params?.task_id) fetchTask();

    return () => {};
  }, []);

  const fetchTask = () => {
    //showing up loader
    Block.circle('#task_form', 'Loading...');
    Axios.fetch(`maxproject/task/internal_task/${params.task_id}`)
      .then(({data}) => {
        let payload = {
          task_name: data.name,
          start_date: data.start_date,
          end_date: data.end_date,
          description: data.desc,
          priority: {
            label: data?.Priority?.name,
            value: data?.Priority?.id,
          },
          frequency: {
            label: data?.Frequency?.name,
            value: data?.Frequency?.id,
          },
          status: {
            label: data?.Status?.name,
            value: data?.Status?.id,
          },
          task_users: data?.TaskMembers?.map(user => ({
            label: user?.User?.name,
            value: user?.user_id,
          })),
        };
        setTask(payload);
        reset(payload);
        Block.remove('#task_form');
      })
      .catch(err => {
        //removing loader
        Block.remove('#task_form');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchTaskSelectFieldsData = () => {
    Axios.fetch(
      `maxproject/random/task_select_fields?task_id=${params?.task_id}`,
    )
      .then(({data}) => {
        let priorities = data?.priorities?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let statuses = data?.statuses?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let frequencies = data?.frequencies?.map(el => ({
          value: el.id,
          label: el.name,
        }));

        setSelectValues(old => ({
          ...old,
          priorities,
          frequencies,
          statuses,
        }));
      })
      .catch(err => {
        console.log(err);
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchTeamMembers = () => {
    const URL = `maxproject/team/members`;
    Axios.fetch(URL)
      .then(({data}) => {
        let users = data.members.map(u => ({
          label: u.name,
          value: u.id,
        }));
        setSelectValues(old => ({...old, users}));
      })
      .catch(err => {
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = (data, e, force = false) => {
    let payload = {
      task_name: data.task_name,
      start_date: data.start_date,
      end_date: data.end_date,
      priority_id: data.priority.value,
      frequency_id: data.frequency.value,
      status_id: data.status.value,
      task_users: data.task_users?.map(u => u.value),
      desc: task.description,
    };
    //clearing server errors
    setServerErrors({});
    //showing up loader
    Block.circle('#task_form', 'Saving...');
    //initiating network request
    const Request = params?.task_id
      ? Axios.put(
          `maxproject/task/other_task/${params.task_id}${
            force ? '?force=1' : ''
          }`,
          payload,
        )
      : Axios.post('maxproject/task/other_task', payload);

    Request.then(res => {
      Block.remove('#task_form');
      Notify.success(res.data.message);
      if (res.data.status == 1) navigate(-1);
      else if (res.data.status == -1)
        Confirm.show(res.data.message, 'Still Proceed!!', 'Yes', 'No', () =>
          handleFormSubmit(data, null, true),
        );
      else if (res.data.status == -2) Notify.failure(res.data.message);
    }).catch(err => {
      //removing loader
      Block.remove('#task_form');
      //checking for any error message
      if (err?.response?.data?.message)
        Notify.failure(err?.response?.data?.message);
      //checking and showing express validation errors
      if (err?.response?.status == StatusCode.ExpressErrors)
        err?.response?.data?.errors.forEach(el =>
          setServerErrors(old => ({...old, [el.param]: el.msg})),
        );
    });
  };
  return (
    <form id="task_form" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            type="text"
            label="Task Name*"
            placeholder="Enter task name"
            register={() =>
              register('task_name', {
                required: 'Please enter task name',
                value: task?.task_name,
              })
            }
            errorMessage={errors.task_name?.message || serverErrors?.task_name}
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Assign To*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="task_users"
              control={control}
              rules={{
                required: 'Please select a user',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  isMulti
                  defaultValue={task?.task_users}
                  onChange={el => field.onChange(el)}
                  options={selectValues.users}
                  placeholder={field.value || 'Select Team Members'}
                />
              )}
            />
            <small className="error_message">
              {errors.task_users?.message || serverErrors?.task_users}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            defaultValue={task?.start_date}
            register={() =>
              register('start_date', {
                required: 'Please enter start date',
                value: task?.start_date,
              })
            }
            errorMessage={
              errors.start_date?.message || serverErrors?.start_date
            }
            type="date"
            label="Start Date*"
            placeholder=""
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            defaultValue={task?.end_date}
            register={() =>
              register('end_date', {
                required: 'Please enter onboarding date',
                value: task?.end_date,
              })
            }
            errorMessage={errors.end_date?.message || serverErrors?.end_date}
            type="date"
            label="End Date*"
            placeholder=""
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Priority*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="priority"
              control={control}
              rules={{
                required: 'Please select a priority',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.priority}
                  onChange={el => field.onChange(el)}
                  options={selectValues.priorities}
                  placeholder={field.value || 'Select Priority'}
                />
              )}
            />
            <small className="error_message">
              {errors.priority?.message || serverErrors?.priority}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Frequency*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="frequency"
              control={control}
              rules={{
                required: 'Please select a frequency',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.frequency}
                  onChange={el => field.onChange(el)}
                  options={selectValues.frequencies}
                  placeholder={field.value || 'Select Frequency'}
                />
              )}
            />
            <small className="error_message">
              {errors.frequency?.message || serverErrors?.frequency}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Status*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="status"
              control={control}
              rules={{
                required: 'Please select a task status',
              }}
              render={({field}) => (
                <Select
                  // menuIsOpen={true}
                  {...field}
                  defaultValue={task?.status}
                  onChange={el => field.onChange(el)}
                  options={selectValues.statuses}
                  placeholder={field.value || 'Select Status'}
                />
              )}
            />
            <small className="error_message">
              {errors.status?.message || serverErrors?.status}
            </small>
          </div>
        </div>
        <div className="col-12">
          <label className="all_page_my_label_new">Description</label>
          <NewEditor
            value={task?.description}
            onChange={text => setTask(old => ({...old, description: text}))}
            placeholder="Describe the details of the task/ Write about the task in brief"
          />
        </div>
        <div className="col-12 mb-3 mt-5">
          <div className=" d-flex align-items-center justify-content-center">
            <div className="me-2">
              <OutlineBtn onClick={() => navigate(-1)} name="Cancel" />
            </div>
            <div className=" ms-2">
              <button
                className="management_btn_full2"
                type="submit"
                form={'task_form'}>
                {params?.task_id ? 'Update' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const ClientTask = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm();

  const [task, setTask] = useState({});
  const [serverErrors, setServerErrors] = useState({});
  const [selectValues, setSelectValues] = useState({
    priorities: [],
    projects: [],
    frequencies: [],
    statuses: [],
  });

  useEffect(async () => {
    fetchTaskSelectFieldsData();
    if (params?.task_id) fetchTask();

    return () => {};
  }, []);

  const fetchTask = () => {
    //showing up loader
    Block.circle('#task_form', 'Loading...');
    Axios.fetch(`maxproject/task/client_task/${params.task_id}`)
      .then(({data}) => {
        let payload = {
          task_name: data.name,
          start_date: data.start_date,
          end_date: data.end_date,
          description: data.desc,
          priority: {
            label: data?.Priority?.name,
            value: data?.Priority?.id,
          },
          project: {
            label: data?.Project?.name,
            value: data?.Project?.id,
          },
          frequency: {
            label: data?.Frequency?.name,
            value: data?.Frequency?.id,
          },
        };
        setTask(payload);
        reset(payload);
        Block.remove('#task_form');
      })
      .catch(err => {
        //removing loader
        Block.remove('#task_form');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchTaskSelectFieldsData = () => {
    //showing up loader
    Block.circle('#task_form', 'Loading...');
    Axios.fetch(
      `maxproject/random/task_select_fields${
        params?.client_id ? `?client_id=${params?.client_id}` : ``
      }`,
    )
      .then(({data}) => {
        let projects = data?.projects?.map(el => ({
          value: el?.id,
          label: el?.name,
        }));
        let statuses = data?.statuses?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let priorities = data?.priorities?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let frequencies = data?.frequencies?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        setSelectValues({
          projects,
          priorities,
          frequencies,
          statuses,
        });

        Block.remove('#task_form');
      })
      .catch(err => {
        //removing loader
        Block.remove('#task_form');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = data => {
    let payload = {
      task_name: data.task_name,
      project_id: data.project.value,
      start_date: data.start_date,
      end_date: data.end_date,
      status_id: data.status.value,
      priority_id: data.priority.value,
      frequency_id: data.frequency.value,
      desc: task.description,
    };
    //clearing server errors
    setServerErrors({});
    //showing up loader
    Block.circle('#task_form', 'Saving...');
    //initiating network request
    const Request = params?.task_id
      ? Axios.put('maxproject/task/client_task/' + params.task_id, payload)
      : Axios.post('maxproject/task/client_task', payload);

    Request.then(({data}) => {
      Block.remove('#task_form');
      Notify.success(data.message);
      navigate(-1);
    }).catch(err => {
      //removing loader
      Block.remove('#task_form');
      //checking for any error message
      if (err?.response?.data?.message)
        Notify.failure(err?.response?.data?.message);
      //checking and showing express validation errors
      if (err?.response?.status == StatusCode.ExpressErrors)
        err?.response?.data?.errors.forEach(el =>
          setServerErrors(old => ({...old, [el.param]: el.msg})),
        );
    });
  };
  return (
    <form id="task_form" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            type="text"
            label="Task Name*"
            placeholder="Enter task name"
            register={() =>
              register('task_name', {
                required: 'Please enter task name',
                value: task?.task_name,
              })
            }
            errorMessage={errors.task_name?.message || serverErrors?.task_name}
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Project*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="project"
              control={control}
              rules={{
                required: 'Please select a project',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  isDisabled={params?.task_id}
                  defaultValue={task?.project}
                  onChange={el => field.onChange(el)}
                  options={selectValues.projects}
                  placeholder={field.value || 'Select Project'}
                />
              )}
            />
            <small className="error_message">
              {errors.project?.message || serverErrors?.project}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            defaultValue={task?.start_date}
            register={() =>
              register('start_date', {
                required: 'Please enter start date',
                value: task?.start_date,
              })
            }
            errorMessage={
              errors.start_date?.message || serverErrors?.start_date
            }
            type="date"
            label="Start Date*"
            placeholder=""
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            defaultValue={task?.end_date}
            register={() =>
              register('end_date', {
                required: 'Please enter onboarding date',
                value: task?.end_date,
              })
            }
            errorMessage={errors.end_date?.message || serverErrors?.end_date}
            type="date"
            label="End Date*"
            placeholder=""
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Priority*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="priority"
              control={control}
              rules={{
                required: 'Please select a priority',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.priority}
                  onChange={el => field.onChange(el)}
                  options={selectValues.priorities}
                  placeholder={field.value || 'Select Priority'}
                />
              )}
            />
            <small className="error_message">
              {errors.priority?.message || serverErrors?.priority}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Frequency*</label>
          <Controller
            name="frequency"
            control={control}
            rules={{
              required: 'Please select a frequency',
            }}
            render={({field}) => (
              <Select
                {...field}
                defaultValue={task?.frequency}
                onChange={el => field.onChange(el)}
                options={selectValues.frequencies}
                placeholder={field.value || 'Select Frequency'}
              />
            )}
          />
          <small className="error_message">
            {errors.frequency?.message || serverErrors?.frequency}
          </small>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Status*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="status"
              control={control}
              rules={{
                required: 'Please select a task status',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.status}
                  onChange={el => field.onChange(el)}
                  options={selectValues.statuses}
                  placeholder={field.value || 'Select Status'}
                />
              )}
            />
            <small className="error_message">
              {errors.status?.message || serverErrors?.status}
            </small>
          </div>
        </div>
        <div className="col-12">
          <label className="all_page_my_label_new">Description</label>
          <NewEditor
            value={task?.description}
            onChange={text => setTask(old => ({...old, description: text}))}
            placeholder="Describe the details of the task/ Write about the task in brief"
          />
        </div>
        <div className="col-12 mb-3 mt-5">
          <div className=" d-flex align-items-center justify-content-center">
            <div className=" me-2">
              <OutlineBtn onClick={() => navigate(-1)} name="Cancel" />
            </div>
            <div className=" ms-2">
              <button
                className="management_btn_full2"
                type="submit"
                form={'task_form'}>
                {params?.task_id ? 'Update' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const getFileIcon = (file, crateImageObject = false) => {
  switch (file.type) {
    case 'image/jpeg':
      return crateImageObject ? URL.createObjectURL(file) : file.url;
    case 'image/png':
      return crateImageObject ? URL.createObjectURL(file) : file.url;
    case 'application/pdf':
      return CommentsPdfIcon;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return CommentsPdfIcon2;
    case 'application/ppx':
      return CommentsPdfIcon3;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return CommentsPdfIcon4;
    case 'application/zip':
      return CommentsPdfIcon5;
    default:
      return CommentsPdfIcon5;
  }
};
const AntSwitch = styled(Switch)(({theme}) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
const UserUi = props => {
  return (
    <div className="assign_to_modal_ui">
      <Avatar
        alt={props.userName}
        src={props.userImg}
        sx={{width: 24, height: 24}}
      />
      <span>{props.userName}</span>
    </div>
  );
};
