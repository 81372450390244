import { Index } from "../components/pages/Dashboard/Index";
const Dashboard = () => {
  return (
    <div className="custom_container">
      <Index />
    </div>
  );
};

export default Dashboard;
